import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const getOptionLabel = (clients) => (option) => {
  if (typeof option === 'string') {
    let client = clients.find((c) => c.id === option);
    if (client === undefined) {
      return 'UNKNOWN';
    }
    return client.name;
  }
  return option.name;
};

const AdministratorsDialogNewEdit = (props) => {
  return (
    <Dialog onClose={props.handleClose} aria-labelledby='administrator-dialog-new-edit-title' open={props.open} fullWidth maxWidth='xs'>
      <DialogTitle id='administrator-dialog-new-edit-title'>
        {props.isEdit ? <Translate id='administrators.dialogs.edit.title' /> : <Translate id='administrators.dialogs.create.title' />}
      </DialogTitle>
      <DialogContent>
        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
          <TextField
            label={props.translate('administrators.email')}
            name='email'
            value={props.administrator.email}
            onChange={props.onChange}
            fullWidth
            disabled={props.isEdit}
          />

          <TextField
            label={props.translate('administrators.name')}
            name='name'
            value={props.administrator.name}
            onChange={props.onChange}
            fullWidth
            disabled={props.isEdit}
          />

          {props.isSuperuser && (
            <FormControl disabled={props.isEdit}>
              <InputLabel id='administrator-select-type'>
                <Translate id='administrators.type' />
              </InputLabel>
              <Select labelId='administrator-select-type' name='type' value={props.administrator.type} onChange={props.onChange}>
                <MenuItem value='superuser'>
                  <Translate id='administrators.types.superuser' />
                </MenuItem>
                <MenuItem value='client'>
                  <Translate id='administrators.types.client' />
                </MenuItem>
              </Select>
            </FormControl>
          )}

          {!props.isEdit && (
            <FormControl>
              <InputLabel id='administrator-select-locale'>
                <Translate id='administrators.locale' />
              </InputLabel>
              <Select labelId='administrator-select-locale' name='locale' value={props.administrator.locale} onChange={props.onChange}>
                <MenuItem value='it_IT'>Italiano</MenuItem>
                <MenuItem value='en_GB'>English</MenuItem>
              </Select>
            </FormControl>
          )}

          {props.administrator.type === 'client' && props.isSuperuser && (
            <Autocomplete
              openOnFocus
              autoHighlight
              options={props.clients}
              getOptionLabel={getOptionLabel(props.clients)}
              getOptionSelected={(o, t) => o.id === t}
              value={props.administrator.client}
              onChange={(event, newValue) => props.onChange({ target: { name: 'client', value: newValue } })}
              disabled={props.isEdit}
              renderInput={(params) => <TextField {...params} label={props.translate('administrators.client')} />}
            />
          )}
          {props.clientType === 'main' && props.clients.length > 0 && (
            <Autocomplete
              openOnFocus
              autoHighlight
              options={props.clients}
              getOptionLabel={getOptionLabel(props.clients)}
              getOptionSelected={(o, t) => o.id === t}
              value={props.administrator.client}
              onChange={(event, newValue) => props.onChange({ target: { name: 'client', value: newValue } })}
              disabled={props.isEdit}
              renderInput={(params) => <TextField {...params} label={props.translate('administrators.client')} />}
            />
          )}
          {props.administrator.type === 'client' && (
            <FormControlLabel
              value='end'
              control={<Checkbox checked={props.administrator.hasPrivileges} name='hasPrivileges' onClick={props.onChange} color='primary' />}
              label={props.translate('administrators.hasPrivileges')}
              labelPlacement='end'
              disabled={props.isEdit ? (props.isSuperuser || props.hasPrivileges ? false : true) : false}
            />
          )}
          {props.administrator.type === 'client' && !props.administrator.hasPrivileges && (
            <FormControlLabel
              value='end'
              control={<Checkbox checked={props.administrator.canChangePayment} name='canChangePayment' onClick={props.onChange} color='primary' />}
              label={props.translate('administrators.canChangePayment')}
              labelPlacement='end'
              disabled={props.isEdit ? (props.isSuperuser || props.hasPrivileges ? false : true) : false}
            />
          )}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={props.handleClose} color='default' autoFocus>
          <Translate id='administrators.dialogs.close' />
        </Button>
        {props.isEdit ? (
          (props.hasPrivileges || props.isSuperuser) && (
            <Button onClick={props.submit} color='primary'>
              {props.isEdit ? <Translate id='administrators.dialogs.edit.submit' /> : <Translate id='administrators.dialogs.create.submit' />}
            </Button>
          )
        ) : (
          <Button onClick={props.submit} color='primary'>
            {props.isEdit ? <Translate id='administrators.dialogs.edit.submit' /> : <Translate id='administrators.dialogs.create.submit' />}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default withLocalize(AdministratorsDialogNewEdit);
