import React from 'react';
import { withRouter } from 'react-router-dom';
import { forgotPassword as CognitoForgotPassword, resetPassword as CognitoResetPassword } from '../../../aws/cognito';
import AuthWrapper from '../../../elements/authwrapper';
import Forgot from './presentation';

class ForgotContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			insertCode: false,
			email: '',
			code: '',
			passw: '',
			passw2: '',
			showPassw: false,
			error: null,
		};
		this.onKeyPress = this.onKeyPress.bind(this);
		this.onChange = this.onChange.bind(this);
		this.togglePasswordVisibility = this.togglePasswordVisibility.bind(this);
		this.forgot = this.forgot.bind(this);
		this.reset = this.reset.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	togglePasswordVisibility() {
		let showPassw = !this.state.showPassw;
		this.secureSetState({ showPassw });
	}

	onKeyPress(cb) {
		return (e) => (e.key === 'Enter' ? cb() : null);
	}

	onChange(e) {
		if (this.state.processing) {
			return;
		}
		const { value, name } = e.target;
		switch (name) {
			case 'email':
				this.secureSetState({ email: value, error: null, insertCode: false, code: '', passw: '', passw2: '', showPassw: false });
				break;

			default:
				this.secureSetState({ [name]: value, error: null });
				break;
		}
	}

	forgot() {
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true, error: null }, async () => {
			if (this.state.email.length === 0) {
				this.secureSetState({ processing: false, error: 'mandatory' });
				return;
			}
			try {
				await CognitoForgotPassword(this.state.email);
				this.secureSetState({ processing: false, error: null, insertCode: true });
			} catch (err) {
				switch (err.code) {
					case 'UserNotFoundException':
						this.secureSetState({ processing: false, error: 'notFound' });
						break;

					case 'LimitExceededException':
						this.secureSetState({ processing: false, error: 'limitExceeded' });
						break;

					default:
						this.secureSetState({ processing: false, error: 'generic' });
						break;
				}
			}
		});
	}

	reset() {
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true, error: null }, async () => {
			if (this.state.code.length === 0 || this.state.passw.length === 0 || this.state.passw2.length === 0) {
				this.secureSetState({ processing: false, error: 'mandatory' });
				return;
			}
			try {
				await CognitoResetPassword(this.state.email, this.state.code, this.state.passw);
				this.props.history.replace('/login');
			} catch (err) {
				switch (err.code) {
					case 'CodeMismatchException':
						this.secureSetState({ processing: false, error: 'invalidCode' });
						break;

					case 'ExpiredCodeException':
						this.secureSetState({ processing: false, error: 'expiredCode', insertCode: false });
						break;

					case 'InvalidPasswordException':
						this.secureSetState({ processing: false, error: 'invalidPassword' });
						break;

					case 'LimitExceededException':
						this.secureSetState({ processing: false, error: 'limitExceeded' });
						break;

					default:
						console.log(err.code);
						this.secureSetState({ processing: false, error: 'generic' });
						break;
				}
			}
		});
	}

	componentDidMount() {
		this.isMounted = true;
		document.title = 'Thor';
		if (typeof this.props.match.params.id === 'string') {
			this.secureSetState({ email: this.props.match.params.id, insertCode: true });
		}
	}

	componentDidUpdate(prevProps) {
		if (prevProps.match.params.id !== this.props.match.params.id && !this.props.match.params.id) {
			this.secureSetState({ email: '', insertCode: false });
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<AuthWrapper>
				<Forgot
					{...this.state}
					onKeyPress={this.onKeyPress}
					onChange={this.onChange}
					togglePasswordVisibility={this.togglePasswordVisibility}
					forgot={this.forgot}
					reset={this.reset}
				/>
			</AuthWrapper>
		);
	}
}

export default withRouter(ForgotContainer);
