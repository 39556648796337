import React from 'react';
import UserContext from '../../../context';
import DynamoDBChargeboxes from '../../../aws/dynamodb/chargeboxes';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import Transactions from './presentation';

class TransactionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { snackbar: null, chargeboxes: [], clients: [] };
    this.showHideSnackbar = this.showHideSnackbar.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  showHideSnackbar(msg) {
    this.secureSetState({ snackbar: msg });
  }

  async componentDidMount() {
    this.isMounted = true;
    try {
      let records = {
        Items: [],
      };
      let records_clients = {
        Items: [],
      };
      if (this.context.clientType === 'main' && this.context.clients.length > 0) {
        for (let i in this.context.clients) {
          let chargeboxes = await DynamoDBChargeboxes.getAll({ type: this.context.type, client: this.context.clients[i] });
          let record = await DynamoDBClients.get(this.context.clients[i]);
          records.Items = records.Items.concat(chargeboxes.Items);
          records_clients.Items.push(record.Item);
        }
      } else {
        records = await DynamoDBChargeboxes.getAll({ type: this.context.type, client: this.context.client });
        records_clients = await DynamoDBClients.getAll();
      }

      let chargeboxes = records.Items.filter((r) => r.type.msg !== 'nc').map((r) => ({ id: r.id, alias: r.alias, type: r.type }));
      let clients = records_clients.Items.map((r) => ({ id: r.id, name: r.name }));
      this.secureSetState({ chargeboxes, clients });
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return <Transactions {...this.state} user={this.context} showHideSnackbar={this.showHideSnackbar} />;
  }
}

TransactionsContainer.contextType = UserContext;
export default TransactionsContainer;
