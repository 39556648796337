import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_chargeboxes_heartbeats`;

const query = async (id, from, to) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		KeyConditionExpression: '#id = :id',
		ProjectionExpression: '#timestamp',
		ScanIndexForward: false,
		ExpressionAttributeNames: {
			'#id': 'id',
			'#timestamp': 'timestamp',
		},
		ExpressionAttributeValues: { ':id': id },
		ReturnConsumedCapacity: 'NONE',
	};
	if (from === null && to !== null) {
		params.KeyConditionExpression += ' and #timestamp < :to';
		params.ExpressionAttributeValues[':to'] = to;
	} else if (from !== null && to === null) {
		params.KeyConditionExpression += ' and #timestamp >= :from';
		params.ExpressionAttributeValues[':from'] = from;
	} else if (from !== null && to !== null) {
		params.KeyConditionExpression += ' and #timestamp between :from and :to';
		params.ExpressionAttributeValues[':from'] = from;
		params.ExpressionAttributeValues[':to'] = to;
	} else {
		return Promise.reject('missing_dates');
	}
	let data = await DDB.query(params).promise();
	return data.Items.map((i) => i.timestamp);
};

export default { query };
