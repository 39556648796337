import { Lambda, config as AWSConfig } from 'aws-sdk';
import env from '../../env';

const FunctionPrefix = env.substring(0, 1).toUpperCase() + env.substring(1) + 'Thor';

const invoke = async (name, params) => {
  try {
    const L = new Lambda({ credentials: AWSConfig.credentials });
    let data = await L.invoke({ FunctionName: `${FunctionPrefix}${name}`, Payload: JSON.stringify(params) }).promise();

    if (data && data.Payload && data.Payload !== 'null') {
      let ret = JSON.parse(data.Payload);
      if (ret !== null && ret.hasOwnProperty('errorMessage')) {
        throw ret.errorMessage;
      }
      return ret;
    } else return {};
  } catch (err) {
    console.log(err);
  }
};

export default { invoke };
