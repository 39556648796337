import React from 'react';
import { withLocalize } from 'react-localize-redux';
import DynamoDBEndUsers from '../../../../aws/dynamodb/endusers';
import DynamoDBAdministrators from '../../../../aws/dynamodb/administrators';
import DynamoDBMeterValues from '../../../../aws/dynamodb/meter-values';
import DynamoDBRFIDs from '../../../../aws/dynamodb/rfids';
import DynamoDBTransactionsRunning from '../../../../aws/dynamodb/transactions-running';
import Lambda from '../../../../aws/lambda';
import { asyncTimeout } from '../../../../utilities';
import RunningTransactions from './presentation';

class RunningTransactionsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      filters: {
        chargebox: null,
      },
      runningTransactions: [],
      users: {},
      rfids: {},
      chargeboxes: {},
      menuAnchorEl: null,
      selectedTransaction: null,
      dialogStopOpen: false,
      dialogUnlockOpen: false,
      dialogTrendOpen: false,
    };
    this.onChange = this.onChange.bind(this);
    this.search = this.search.bind(this);
    this.openCloseMenu = this.openCloseMenu.bind(this);
    this.toggleStopDialog = this.toggleStopDialog.bind(this);
    this.toggleUnlockDialog = this.toggleUnlockDialog.bind(this);
    this.waitForStop = this.waitForStop.bind(this);
    this.stop = this.stop.bind(this);
    this.unlock = this.unlock.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this.toggleDialogTrend = this.toggleDialogTrend.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  openCloseMenu(transaction) {
    return (event) => {
      if (transaction === null) {
        this.secureSetState({ menuAnchorEl: null, selectedTransaction: null });
      } else {
        this.secureSetState({ menuAnchorEl: event.currentTarget, selectedTransaction: { ...transaction } });
      }
    };
  }

  toggleStopDialog() {
    this.secureSetState({ menuAnchorEl: null, dialogStopOpen: !this.state.dialogStopOpen });
  }

  toggleUnlockDialog() {
    this.secureSetState({ menuAnchorEl: null, dialogUnlockOpen: !this.state.dialogUnlockOpen });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters[name] = value;
    this.secureSetState({ filters });
  }

  async waitForStop(chargebox, connector, transactionId) {
    for (let i = 0; i < 30; i++) {
      let data = await DynamoDBTransactionsRunning.getByChargebox(chargebox, true);
      if (data.Items && data.Items.findIndex((t) => t.chargebox === chargebox && t.connector === connector && t.transaction_id === transactionId) === -1) {
        return;
      }
      await asyncTimeout(1000);
    }
    throw 'not_stopped';
  }

  stop(confirm) {
    if (!confirm) {
      this.toggleStopDialog();
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      let functionName = '';
      if (
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.msg === 'soap' &&
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.version === '1.5'
      ) {
        functionName = 'OcppSoap15RemoteStopTransaction';
      } else if (
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.msg === 'json' &&
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.version === '1.6'
      ) {
        functionName = 'OcppJson16RemoteStopTransaction';
      } else {
        this.secureSetState({ processing: false, dialogStopOpen: !this.state.dialogStopOpen, selectedTransaction: null });
      }
      try {
        let data = await Lambda.invoke(functionName, {
          admin: this.props.user.id,
          transaction: {
            chargebox: this.state.selectedTransaction.chargebox,
            connector: this.state.selectedTransaction.connector,
            id: this.state.selectedTransaction.transactionId,
          },
        });
        if (data === null && functionName === 'OcppJson16RemoteStopTransaction') {
          await this.waitForStop(
            this.state.selectedTransaction.chargebox,
            this.state.selectedTransaction.connector,
            this.state.selectedTransaction.transactionId
          );
        }

        let runningTransactions = this.state.runningTransactions.filter(
          (tr) =>
            tr.chargebox !== this.state.selectedTransaction.chargebox ||
            tr.connector !== this.state.selectedTransaction.connector ||
            tr.transactionId !== this.state.selectedTransaction.transactionId
        );

        this.props.showHideSnackbar('stopSuccess');
        this.secureSetState({ processing: false, dialogStopOpen: !this.state.dialogStopOpen, selectedTransaction: null, runningTransactions });
      } catch (err) {
        console.log(err);
        this.props.showHideSnackbar('stopError');
        this.secureSetState({ processing: false, dialogStopOpen: !this.state.dialogStopOpen });
      }
    });
  }

  unlock(confirm) {
    if (!confirm) {
      this.toggleUnlockDialog();
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      let functionName = '';
      if (
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.msg === 'soap' &&
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.version === '1.5'
      ) {
        functionName = 'OcppSoap15UnlockConnector';
      } else if (
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.msg === 'json' &&
        this.state.chargeboxes[this.state.selectedTransaction.chargebox].type.version === '1.6'
      ) {
        functionName = 'OcppJson16UnlockConnector';
      } else {
        this.secureSetState({ processing: false, dialogUnlockOpen: !this.state.dialogUnlockOpen, selectedTransaction: null });
      }
      try {
        let data = await Lambda.invoke(functionName, {
          chargebox: this.state.selectedTransaction.chargebox,
          user: this.props.user.id,
          connector: this.state.selectedTransaction.connector,
        });
        if (data === null && functionName === 'OcppJson16UnlockConnector') {
          await this.waitForStop(
            this.state.selectedTransaction.chargebox,
            this.state.selectedTransaction.connector,
            this.state.selectedTransaction.transactionId
          );
        }

        let runningTransactions = this.state.runningTransactions.filter(
          (tr) =>
            tr.chargebox !== this.state.selectedTransaction.chargebox ||
            tr.connector !== this.state.selectedTransaction.connector ||
            tr.transactionId !== this.state.selectedTransaction.transactionId
        );

        this.props.showHideSnackbar('unlockSuccess');
        this.secureSetState({ processing: false, dialogUnlockOpen: !this.state.dialogUnlockOpen, selectedTransaction: null, runningTransactions });
      } catch (err) {
        console.log(err);
        this.props.showHideSnackbar('unlockError');
        this.secureSetState({ processing: false });
      }
    });
  }

  search() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, runningTransactions: [] }, async () => {
      let chargeboxes = this.props.chargeboxes.reduce((acc, c) => {
        acc[c.id] = { alias: c.alias, type: c.type };
        return acc;
      }, {});
      try {
        let data = null;
        if (this.state.filters.chargebox.id === '*') {
          if (this.props.user.type === 'client') {
            data = await DynamoDBTransactionsRunning.getByClient(this.props.user.client, true);
          } else {
            data = await DynamoDBTransactionsRunning.getAll(true);
          }
        } else {
          data = await DynamoDBTransactionsRunning.getByChargebox(this.state.filters.chargebox.id, true);
        }
        console.log(data);
        if (data.Count === 0) {
          this.secureSetState({ processing: false, chargeboxes });
          this.props.showHideSnackbar('runningNotFound');
          return;
        }
        let runningTransactions = [];
        let usersToGet = [];
        let adminsToGet = [];
        let rfidsToGet = [];
        for (let item of data.Items) {
          let lastMeterValue = await DynamoDBMeterValues.getLast(item.chargebox, item.connector, item.transaction_id);
          let runningTransaction = {
            meters: item.meters,
            chargebox: item.chargebox,
            type: item.type,
            connector: item.connector,
            meterStart: item.meter_start,
            transactionId: item.transaction_id,
            payment: item.hasOwnProperty('payment') ? item.payment : null,
            dateStarted: item.timestamp,
            lastMeterValue: '0 Wh',
            user: item.user,
            idTag: item.id_tag,
          };
          if (lastMeterValue) {
            let value = 0;
            if (lastMeterValue.uom.toLowerCase() === 'wh') {
              value = Math.round(lastMeterValue.value - item.meter_start);
            } else {
              value = Math.round((lastMeterValue.value - item.meter_start / 1000) * 1000) / 1000;
            }

            if (lastMeterValue.uom.toLowerCase() === 'wh' && value > 1000) {
              runningTransaction.lastMeterValue = `${Math.round(value) / 1000} kWh`;
            } else {
              runningTransaction.lastMeterValue = `${value} ${lastMeterValue.uom}`;
            }
          }
          runningTransactions.push(runningTransaction);
          if (item.type === 'local' && rfidsToGet.indexOf(item.id_tag) === -1 && !this.state.rfids.hasOwnProperty(item.id_tag)) {
            rfidsToGet.push(`${item.client}__${item.id_tag}`);
          } else if (item.type === 'remote' && usersToGet.indexOf(item.user) === -1 && !this.state.users.hasOwnProperty(item.user)) {
            if (item.payment && item.payment.method && item.payment.method === 'free') {
              if (adminsToGet.indexOf(item.user) === -1 && !this.state.users.hasOwnProperty(item.user)) {
                adminsToGet.push(item.user);
              }
            } else {
              usersToGet.push(item.user);
            }
          }
        }

        let users = JSON.parse(JSON.stringify(this.state.users));
        let rfids = JSON.parse(JSON.stringify(this.state.rfids));
        for (let i = 0; i < Math.ceil(rfidsToGet.length / 25); i++) {
          let ddbRFIDs = await DynamoDBRFIDs.batchGet(rfidsToGet.slice(i * 25, (i + 1) * 25), null);
          for (let rfid of ddbRFIDs) {
            let { id, alias, user } = rfid;
            rfids[id.split('__')[1]] = { alias, user };
            if (typeof user === 'string' && user.length > 0 && usersToGet.indexOf(user) === -1) {
              usersToGet.push(user);
            }
          }
        }
        for (let i = 0; i < Math.ceil(usersToGet.length / 25); i++) {
          let ddbUsers = await DynamoDBEndUsers.batchGet(usersToGet.slice(i * 25, (i + 1) * 25));
          for (let user of ddbUsers) {
            let { id, ...u } = user;
            users[id] = u;
          }
        }

        for (let i = 0; i < Math.ceil(adminsToGet.length / 25); i++) {
          let ddbAdmins = await DynamoDBAdministrators.batchGet(adminsToGet.slice(i * 25, (i + 1) * 25));
          for (let admin of ddbAdmins) {
            let { id, ...u } = admin;
            u.admin = true;
            users[id] = u;
          }
        }

        this.secureSetState({ processing: false, chargeboxes, runningTransactions, users, rfids });
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, chargeboxes });
        this.props.showHideSnackbar('runningFetchError');
      }
    });
  }

  toggleDialogTrend() {
    this.secureSetState({ dialogTrendOpen: !this.state.dialogTrendOpen, menuAnchorEl: null });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    let chargeboxesList = this.props.chargeboxes.slice();
    chargeboxesList.splice(0, 0, { id: '*', alias: this.props.translate('transactions.all') });
    return (
      <RunningTransactions
        {...this.state}
        user={this.props.user}
        chargeboxesList={chargeboxesList}
        openCloseMenu={this.openCloseMenu}
        onChange={this.onChange}
        search={this.search}
        toggleStopDialog={this.toggleStopDialog}
        toggleUnlockDialog={this.toggleUnlockDialog}
        stop={this.stop}
        unlock={this.unlock}
        toggleDialogTrend={this.toggleDialogTrend}
      />
    );
  }
}

export default withLocalize(RunningTransactionsContainer);
