import React from 'react';
import ApexChart from 'react-apexcharts';
import { withLocalize } from 'react-localize-redux';

const PaymentMethodsChart = ({ paymentMethods, translate }) => {
  let options = {
    chart: {
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
      redrawOnParentResize: true,
    },
    dataLabels: {
      enabled: true,
      style: {
        colors: ['#333333'],
      },
    },
    labels: [
      translate('home.paymentMethods.local'),
      translate('home.paymentMethods.stripe'),
      translate('home.paymentMethods.paypal'),
      translate('home.paymentMethods.api'),
      translate('home.paymentMethods.nayax'),
      translate('home.paymentMethods.undefined'),
      translate('home.paymentMethods.free'),
    ],
    fill: {
      colors: ['#CD6B97', '#8870FF', '#3D8EB9', '#FEC606', '#48A0FF', '#12cd92', '#FE0606'],
    },

    colors: ['#CD6B97', '#8870FF', '#3D8EB9', '#FEC606', '#48A0FF', '#12cd92', '#FE0606'],
  };
  console.log(paymentMethods);
  let series = [
    (paymentMethods.local || 0) + (paymentMethods.rfid || 0),
    paymentMethods.stripe || 0,
    paymentMethods.paypal || 0,
    paymentMethods.api || 0,
    paymentMethods.nayax || 0,
    paymentMethods.undefined || 0,
    (paymentMethods.null || 0) + (paymentMethods.free || 0) + (paymentMethods.gratis || 0),
  ];
  console.log(series);
  return <ApexChart type='donut' width='100%' options={options} series={series} />;
};

export default withLocalize(PaymentMethodsChart);
