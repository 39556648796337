import React from 'react';
import { withLocalize } from 'react-localize-redux';
import DynamoDBEndUsers from '../../../../aws/dynamodb/endusers';
import DynamoDBReservations from '../../../../aws/dynamodb/reservations';
import Lambda from '../../../../aws/lambda';
import { asyncTimeout } from '../../../../utilities';
import ActiveReservations from './presentation';

class ActiveReservationsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			filters: {
				chargebox: null,
			},
			activeReservations: [],
			users: {},
			chargeboxes: {},
			selectedReservation: null,
			dialogCancelReservationOpen: false,
		};
		this.onChange = this.onChange.bind(this);
		this.search = this.search.bind(this);
		this.toggleCancelReservationDialog = this.toggleCancelReservationDialog.bind(this);
		this.waitForCancel = this.waitForCancel.bind(this);
		this.cancelReservation = this.cancelReservation.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	toggleCancelReservationDialog(reservation) {
		this.secureSetState({
			dialogCancelReservationOpen: !this.state.dialogCancelReservationOpen,
			selectedReservation: reservation ? { ...reservation } : null,
		});
	}

	onChange(e) {
		if (this.state.processing) {
			return;
		}
		let { name, value } = e.target;
		let filters = JSON.parse(JSON.stringify(this.state.filters));
		filters[name] = value;
		this.secureSetState({ filters });
	}

	async waitForCancel(chargebox, reservationId) {
		for (let i = 0; i < 30; i++) {
			let data = await DynamoDBReservations.get(chargebox, reservationId);
			if (data && data.hasOwnProperty('Item') && data.Item && data.Item.status !== 'accepted') {
				return;
			}
			await asyncTimeout(1000);
		}
		throw 'not_cancelled';
	}

	cancelReservation(confirm, pay) {
		if (!confirm) {
			this.toggleCancelReservationDialog(null);
			return;
		}
		this.secureSetState({ processing: true }, async () => {
			let functionName = '';
			if (
				this.state.chargeboxes[this.state.selectedReservation.chargebox].type.msg === 'json' &&
				this.state.chargeboxes[this.state.selectedReservation.chargebox].type.version === '1.6'
			) {
				functionName = 'OcppJson16CancelReservation';
			} else {
				this.secureSetState({ processing: false, dialogCancelReservationOpen: !this.state.dialogCancelReservationOpen, selectedReservation: null });
			}
			try {
				let data = await Lambda.invoke(functionName, {
					admin: this.props.user.id,
					pay: pay,
					reservation: {
						chargebox: this.state.selectedReservation.chargebox,
						reservation_id: this.state.selectedReservation.reservationId,
					},
				});
				if (data === null && functionName === 'OcppJson16CancelReservation') {
					await this.waitForCancel(this.state.selectedReservation.chargebox, this.state.selectedReservation.reservationId);
				}

				this.props.showHideSnackbar('cancelSuccess');
				this.secureSetState(
					{
						processing: false,
						dialogCancelReservationOpen: !this.state.dialogCancelReservationOpen,
						selectedReservation: null,
					},
					this.search
				);
			} catch (err) {
				console.log(err);
				this.props.showHideSnackbar('cancelError');
				this.secureSetState({ processing: false });
			}
		});
	}

	search() {
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true, activeReservations: [] }, async () => {
			let chargeboxes = this.props.chargeboxes.reduce((acc, c) => {
				acc[c.id] = { alias: c.alias, type: c.type };
				return acc;
			}, {});
			try {
				let data = null;
				let requestor = { type: this.props.user.type, client: this.props.user.client };
				if (this.state.filters.chargebox.id === '*') {
					data = await DynamoDBReservations.getAll(requestor, { status: ['accepted'] }, null);
				} else {
					data = await DynamoDBReservations.getAll(requestor, { status: ['accepted'], chargebox: this.state.filters.chargebox.id }, null);
				}

				if (data.Count === 0) {
					this.secureSetState({ processing: false, chargeboxes });
					this.props.showHideSnackbar('runningNotFound');
					return;
				}
				let activeReservations = [];
				let usersToGet = [];
				let now = Date.now();
				for (let item of data.Items) {
					if (now > item.expiry_date) {
						continue;
					}
					let activeReservation = {
						chargebox: item.chargebox,
						connector: item.connector,
						dateAccepted: item.date_accepted,
						expiryDate: item.expiry_date,
						reservationId: item.reservation_id,
						idTag: item.id_tag,
						payment: item.payment,
						user: item.user,
					};
					activeReservations.push(activeReservation);
					if (usersToGet.indexOf(item.user) === -1) {
						usersToGet.push(item.user);
					}
				}

				let users = JSON.parse(JSON.stringify(this.state.users));
				for (let i = 0; i < Math.ceil(usersToGet.length / 25); i++) {
					let ddbUsers = await DynamoDBEndUsers.batchGet(usersToGet.slice(i * 25, (i + 1) * 25));
					for (let user of ddbUsers) {
						let { id, ...u } = user;
						users[id] = u;
					}
				}
				this.secureSetState({ processing: false, chargeboxes, activeReservations, users });
			} catch (err) {
				console.log(err);
				this.secureSetState({ processing: false, chargeboxes });
				this.props.showHideSnackbar('runningFetchError');
			}
		});
	}

	componentDidMount() {
		this.isMounted = true;
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		let chargeboxesList = this.props.chargeboxes.slice();
		chargeboxesList.splice(0, 0, { id: '*', alias: this.props.translate('reservations.all') });
		return (
			<ActiveReservations
				{...this.state}
				user={this.props.user}
				chargeboxesList={chargeboxesList}
				onChange={this.onChange}
				search={this.search}
				toggleCancelReservationDialog={this.toggleCancelReservationDialog}
				cancelReservation={this.cancelReservation}
			/>
		);
	}
}

export default withLocalize(ActiveReservationsContainer);
