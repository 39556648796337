import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_rfids`;

const get = (id) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Key: { id: id },
		ReturnConsumedCapacity: 'NONE',
	};

	return DDB.get(params).promise();
};

const batchGet = async (ids, client) => {
	function uniq(data, key) {
		return [...new Map(data.map((x) => [key(x), x])).values()];
	}
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });

	let params = {
		RequestItems: {},
		ReturnConsumedCapacity: 'NONE',
	};
	params.RequestItems[TableName] = {
		Keys: typeof client === 'string' && client.length > 0 ? ids.map((id) => ({ id: `${client}__${id}` })) : ids.map((id) => ({ id })),
	};
	params.RequestItems[TableName].Keys = uniq(params.RequestItems[TableName].Keys, (it) => it.id);
	let data = await DDB.batchGet(params).promise();
	return data.Responses[TableName];
};

const create = (data) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Item: data,
		ConditionExpression: 'attribute_not_exists(#id)',
		ExpressionAttributeNames: {
			'#id': 'id',
		},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};

	return DDB.put(params).promise();
};

const edit = async (id, data) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	if (Object.keys(data).length === 0) {
		return;
	}
	let params = {
		TableName: TableName,
		Key: {
			id: id,
		},
		ConditionExpression: 'attribute_exists(#id)',
		UpdateExpression: '',
		ExpressionAttributeNames: { '#id': 'id' },
		ExpressionAttributeValues: {},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};
	let updates = [];
	let removes = [];

	Object.keys(data).forEach((field) => {
		if ((field === 'user') & (data[field] === null)) {
			removes.push(`#${field}`);
			params.ExpressionAttributeNames[`#${field}`] = field;
		} else {
			updates.push(`#${field} = :${field}`);
			params.ExpressionAttributeNames[`#${field}`] = field;
			params.ExpressionAttributeValues[`:${field}`] = data[field];
		}
	});

	if (updates.length > 0) {
		params.UpdateExpression += ` set ${updates.join(', ')}`;
	}

	if (removes.length > 0) {
		params.UpdateExpression += ` remove ${removes.join(', ')}`;
	}
	return await DDB.update(params).promise();
};

const remove = (id) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Key: { id },
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};

	return DDB.delete(params).promise();
};

// const getAll = async (requestor, paginationOptions) => {
// 	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });

// 	let exclusiveStartKey = null;
// 	let params = {
// 		TableName: TableName,
// 		ReturnConsumedCapacity: 'NONE',
// 	};
// 	if (requestor.type !== 'superuser') {
// 		params.IndexName = 'client-index';
// 		params.KeyConditionExpression = '#client = :client';
// 		params.ExpressionAttributeNames = { '#client': 'client' };
// 		params.ExpressionAttributeValues = { ':client': requestor.client };
// 	}

// 	if (paginationOptions) {
// 		params.Limit = paginationOptions.limit;
// 		if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
// 			params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
// 		}
// 		if (requestor.type !== 'superuser') {
// 			return DDB.query(params).promise();
// 		}
// 		return DDB.scan(params).promise();
// 	}

// 	let ret = [];
// 	for (;;) {
// 		if (exclusiveStartKey !== null) {
// 			params.ExclusiveStartKey = exclusiveStartKey;
// 		}
// 		let data = null;
// 		if (requestor.type !== 'superuser') {
// 			data = await DDB.query(params).promise();
// 		} else {
// 			data = await DDB.scan(params).promise();
// 		}
// 		ret = ret.concat(data.Items);
// 		if (data.hasOwnProperty('LastEvaluatedKey') && data.LastEvaluatedKey) {
// 			exclusiveStartKey = data.LastEvaluatedKey;
// 		} else {
// 			return ret;
// 		}
// 	}
// };

// const getAllCount = (requestor) => {
// 	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
// 	let params = {
// 		TableName: TableName,
// 		ReturnConsumedCapacity: 'NONE',
// 		Select: 'COUNT',
// 	};
// 	if (requestor.type !== 'superuser') {
// 		params.IndexName = 'client-index';
// 		params.KeyConditionExpression = '#client = :client';
// 		params.ExpressionAttributeNames = { '#client': 'client' };
// 		params.ExpressionAttributeValues = { ':client': requestor.client };
// 		return DDB.query(params).promise();
// 	}
// 	return DDB.scan(params).promise();
// };

const query = async (requestor, getCount, paginationOptions, filters) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		ReturnConsumedCapacity: 'NONE',
		Select: getCount ? 'COUNT' : 'ALL_ATTRIBUTES',
	};

	let clientIndex = {
		enable: false,
		id: '',
	};
	if (requestor.type !== 'superuser') {
		clientIndex.enable = true;
		clientIndex.id = requestor.client;
	}

	let filterExpression = [];
	if (filters && Object.keys(filters).length > 0) {
		params.ExpressionAttributeNames = {};
		params.ExpressionAttributeValues = {};

		if (typeof filters.client === 'string' && filters.client.length > 0) {
			clientIndex.enable = true;
			clientIndex.id = filters.client;
		}
		if (typeof filters.id === 'string' && filters.id.length > 0) {
			filterExpression.push('contains(#id, :id)');
			params.ExpressionAttributeNames['#id'] = 'id';
			params.ExpressionAttributeValues[':id'] = filters.id.toLowerCase();
		}
		if (typeof filters.alias === 'string' && filters.alias.length > 0) {
			filterExpression.push('contains(#lc_alias, :lc_alias)');
			params.ExpressionAttributeNames['#lc_alias'] = 'lc_alias';
			params.ExpressionAttributeValues[':lc_alias'] = filters.alias.toLowerCase();
		} else if (typeof filters.user === 'string' && filters.user.length > 0) {
			filterExpression.push('#user = :user');
			params.ExpressionAttributeNames['#user'] = 'user';
			params.ExpressionAttributeValues[':user'] = filters.user;
		}
	}

	if (filterExpression.length > 0) {
		params.FilterExpression = filterExpression.join(' and ');
		let data = null;
		if (paginationOptions && paginationOptions.nextTokens[paginationOptions.page - 1]) {
			params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
		}
		if (clientIndex.enable) {
			params.IndexName = 'client-index';
			params.KeyConditionExpression = '#client = :client';
			params.ExpressionAttributeNames['#client'] = 'client';
			params.ExpressionAttributeValues[':client'] = clientIndex.id;
			if (getCount) {
				return DDB.query(params).promise();
			}
			data = await DDB.query(params).promise();
		} else {
			if (Object.keys(params.ExpressionAttributeValues).length === 0) {
				delete params.ExpressionAttributeValues;
			}
			if (getCount) {
				return DDB.scan(params).promise();
			}
			data = await DDB.scan(params).promise();
		}
		if (paginationOptions && typeof paginationOptions.limit === 'number') {
			let items = data.Items.slice(0, paginationOptions.limit);
			let lastEvaluatedKey = undefined;
			if (items.length > 0) {
				let lastItem = items[items.length - 1];
				if (clientIndex.enable) {
					lastEvaluatedKey = { client: lastItem.client };
				} else {
					lastEvaluatedKey = { id: lastItem.id };
				}
			}
			return {
				Items: items,
				Count: items.length,
				LastEvaluatedKey: lastEvaluatedKey,
			};
		}
		return data;
	}

	delete params.ExpressionAttributeNames;
	delete params.ExpressionAttributeValues;
	if (paginationOptions) {
		params.Limit = paginationOptions.limit;
		if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
			params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
		}
	}
	if (clientIndex.enable) {
		params.IndexName = 'client-index';
		params.KeyConditionExpression = '#client = :client';
		params.ExpressionAttributeNames = { '#client': 'client' };
		params.ExpressionAttributeValues = { ':client': clientIndex.id };
		return DDB.query(params).promise();
	}

	return DDB.scan(params).promise();
};

const getAll = (requestor, paginationOptions, filters) => query(requestor, false, paginationOptions, filters);

const getAllCount = (requestor, filters) => query(requestor, true, null, filters);

const updateBudget = (id, amount, transact) => {
	let amountStr = 'amount';
	if (transact) {
		return {
			Update: {
				TableName: TableName,
				Key: { id },
				UpdateExpression: `set budget.#${amountStr} = :amount`,
				ConditionExpression:
					'attribute_exists(#id) and attribute_exists(#budget) and attribute_type(#budget, :map) and attribute_exists(#budget.#amount)',
				ExpressionAttributeNames: {
					'#id': 'id',
					'#budget': 'budget',
					'#amount': 'amount',
				},
				ExpressionAttributeValues: {
					':map': 'M',
					':amount': amount,
				},
			},
		};
	}
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Key: { id },
		UpdateExpression: `set budget.#${amountStr} = :amount`,
		ConditionExpression: 'attribute_exists(#id) and attribute_exists(#budget) and attribute_type(#budget, :map) and attribute_exists(#budget.#amount)',
		ExpressionAttributeNames: {
			'#id': 'id',
			'#budget': 'budget',
			'#amount': 'amount',
		},
		ExpressionAttributeValues: {
			':map': 'M',
			':amount': { amount: amount },
		},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};
	console.log('TRY', params);
	return DDB.update(params).promise();
};

export default {
	get,
	batchGet,
	create,
	edit,
	remove,
	getAll,
	getAllCount,
	updateBudget,
};
