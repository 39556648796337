import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Grid from '@material-ui/core/Grid';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import withStyles from '@material-ui/core/styles/withStyles';
import ApexChart from 'react-apexcharts';
import ApexChartLocaleIT from 'apexcharts/dist/locales/it.json';
import ApexChartLocaleEN from 'apexcharts/dist/locales/en.json';
const styles = { button: { margin: '1rem' }, chart: { width: '100%', height: 'auto' } };

const getEnergy = (meters) => {
  let ret = [];
  for (let meter of meters) {
    ret.push({
      x: meter.timestamp,
      y: meter.value / 1000,
    });
  }
  return ret;
};

const getPower = (meters) => {
  let ret = [];
  if (meters.length < 2) {
    return ret;
  }
  for (let i = 1; i < meters.length; i++) {
    const power = Math.round((3600000 * (meters[i].value - meters[i - 1].value)) / (meters[i].timestamp - meters[i - 1].timestamp)) / 1000;
    const timestamp = Math.round((meters[i].timestamp + meters[i - 1].timestamp) / 2);

    ret.push({
      x: timestamp,
      y: power,
    });
  }
  return ret;
};

const getChartOptions = (graph, id, locale) => ({
  chart: {
    id: 'trend-chart',
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    redrawOnParentResize: false,
    redrawOnWindowResize: true,
    sparkline: {
      enabled: false,
    },
    dropShadow: {
      enabled: true,
    },
    defaultLocale: locale,
    locales: [ApexChartLocaleIT, ApexChartLocaleEN],
    toolbar: {
      tools: {
        download: true,
        selection: true,
        zoom: true,
        zoomin: true,
        zoomout: true,
        pan: true,
        reset: true,
      },
      export: {
        csv: {
          filename: `${id}__${graph}`,
          columnDelimiter: ',',
          headerCategory: 'Date',
          headerValue: 'value',
          dateFormatter: (timestamp) => `"${new Date(timestamp).toLocaleString()}"`,
        },
      },
      autoSelected: 'zoom',
    },
  },
  dataLabels: {
    enabled: false,
  },
  xaxis: {
    type: 'datetime',
    labels: {
      datetimeUTC: false,
      format: 'dd/MM/yyyy HH:mm:ss',
    },
    tooltip: { enabled: false },
  },
  yaxis: {
    forceNiceScale: true,
    min: 0,
    labels: {
      formatter: (v) => `${Math.round(v * 1000) / 1000} ${graph === 'energy' ? 'kWh' : 'kW'}`,
    },
  },
  tooltip: {
    x: { format: 'dd/MM/yyyy HH:mm:ss' },
  },
  stroke: {
    show: true,
    curve: graph === 'energy' ? 'straight' : 'smooth',
  },
  // fill: {
  // 	type: 'gradient',
  // 	gradient: {
  // 		type: 'vertical',
  // 		shadeIntensity: 1,
  // 		inverseColors: false,
  // 		opacityFrom: 0.5,
  // 		opacityTo: 0,
  // 		stops: [0, 90, 100],
  // 	},
  // },
  colors: ['#00796b'],
});

class DialogFinishedTransactionTrend extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      meterValues: {
        energy: [],
        power: [],
      },
      graph: 'energy',
    };
    this.changeGraph = this.changeGraph.bind(this);
    this.getMeterValues = this.getMeterValues.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
    this.chart = null;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  async getMeterValues() {
    const ordered_meters = Object.keys(this.props.transaction.meters)
      .sort()
      .reduce((obj, key) => {
        obj[key] = this.props.transaction.meters[key];
        return obj;
      }, {});
    try {
      let meters = [{ value: 0, timestamp: this.props.transaction.dateStarted }];

      for (let item in ordered_meters) {
        meters.push({
          value: this.props.transaction.meters[parseInt(item)] - this.props.transaction.meterStart,
          timestamp: parseInt(item),
        });
      }
      let energy = getEnergy(meters);
      let power = getPower(meters);
      this.secureSetState({ meterValues: { energy, power } });
    } catch (err) {
      console.log(err);
    }
  }

  changeGraph(e, g) {
    if (g) {
      this.secureSetState({ graph: g });
    }
  }

  componentDidMount() {
    this.isMounted = true;
  }

  async componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      await this.getMeterValues();
    } else if (prevProps.open && !this.props.open) {
      this.secureSetState({ meterValues: { energy: [], power: [] }, graph: 'energy' });
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Dialog onClose={this.props.handleClose} aria-labelledby='finished-transactions-dialog-rfid-title' open={this.props.open} fullWidth maxWidth='md'>
        <DialogContent>
          <Grid container direction='row' alignItems='center' justifyContent='center'>
            <ToggleButtonGroup size='small' value={this.state.graph} exclusive onChange={this.changeGraph} aria-label='change graph'>
              <ToggleButton value='energy' aria-label='Energy'>
                <Translate id='transactions.meters.energy' />
              </ToggleButton>
              <ToggleButton value='power' aria-label='Power'>
                <Translate id='transactions.meters.power' />
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
          <Grid container direction='row' alignItems='center' justifyContent='center'>
            {this.props.transaction !== null && (
              <ApexChart
                options={getChartOptions(this.state.graph, this.props.transaction.id, this.props.user.locale.split('_')[0])}
                series={[
                  {
                    name: this.props.translate(`transactions.meters.${this.state.graph}`),
                    data: this.state.meterValues[this.state.graph],
                  },
                ]}
                type='line'
                className={this.props.classes.chart}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color='default' autoFocus>
            <Translate id='documents.dialogs.close' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(DialogFinishedTransactionTrend));
