import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
//import Accordion from '@material-ui/core/Accordion';
//import AccordionSummary from '@material-ui/core/AccordionSummary';
//import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
//import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
//import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
  buttonRow: {
    marginTop: '1rem',
  },
};

class CustomizationPaymentReceipt extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      effatta: { enabled: false, fiscalCode: '', password: '', pin: '', vat: '' },
    };
    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
    this.submitEnabled = this.submitEnabled.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let effatta = JSON.parse(JSON.stringify(this.state.effatta));
    let { name, value } = e.target;
    if (name === 'enabled') {
      effatta[name] = !this.state.effatta.enabled;
    } else {
      effatta[name] = value;
    }
    this.secureSetState({ effatta });
  }

  submitEnabled(method) {
    /*switch (method) {
      case 'stripe':
        if (!this.state.paymentMethods.stripe.enabled) {
          return true;
        }
        return this.state.paymentMethods.stripe.publishable_key.length > 0 && this.state.paymentMethods.stripe.secret_key.length > 0;
      case 'paypal':
        if (!this.state.paymentMethods.paypal.enabled) {
          return true;
        }
        return this.state.paymentMethods.paypal.client_secret.length > 0 && this.state.paymentMethods.paypal.client_id.length > 0;
      case 'nayax':
        if (!this.state.paymentMethods.nayax.enabled) {
          return true;
        }
        return this.state.paymentMethods.nayax && this.state.paymentMethods.nayax.enabled;
      default:
        return false;
    }*/
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({
      effatta: JSON.parse(JSON.stringify(this.props.effatta)),
    });
  }

  delete(method) {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let effatta = JSON.parse(JSON.stringify(this.props.effatta));
        effatta = { enabled: false, fiscalCode: '', password: '', pin: '', vat: '' };

        let params = {
          action: 'remove_field',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            fields: 'effatta',
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('effatta', effatta);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('paymentMethodDeleteError');
        });
      }
    });
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        if (this.state.effatta && this.state.effatta.enabled) {
          if (
            this.state.effatta.fiscalCode &&
            this.state.effatta.password &&
            this.state.effatta.pin &&
            this.state.effatta.vat &&
            this.state.effatta.fiscalCode !== '' &&
            this.state.effatta.password !== '' &&
            this.state.effatta.pin !== '' &&
            this.state.effatta.vat !== ''
          ) {
          } else {
            throw 'paymentReceiptError';
          }
        }

        let effatta = JSON.parse(JSON.stringify(this.state.effatta));
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            effatta: effatta,
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          effatta = JSON.parse(JSON.stringify(this.props.effatta));
          this.props.update('effatta', effatta);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar(err);
        });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let effatta = JSON.parse(JSON.stringify(this.state.effatta));
    if (
      prevProps.effatta.enabled !== this.props.effatta.enabled ||
      prevProps.effatta.fiscalCode !== this.props.effatta.fiscalCode ||
      prevProps.effatta.password !== this.props.effatta.password ||
      prevProps.effatta.pin !== this.props.effatta.pin ||
      prevProps.effatta.vat !== this.props.effatta.vat
    ) {
      effatta = JSON.parse(JSON.stringify(this.props.effatta));
      this.secureSetState({ effatta });
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.receipt.title' />} subheader={<Translate id='customization.receipt.subheader' />} />
        <CardContent>
          <Grid container direction='column'>
            <FormControlLabel
              value='end'
              control={<Checkbox checked={this.state.effatta.enabled} name='enabled' onClick={this.onChange} color='primary' />}
              label={this.props.translate('customization.paymentMethods.enabled')}
              labelPlacement='end'
            />
            {this.state.effatta.enabled && (
              <>
                <TextField
                  label={this.props.translate('customization.receipt.fiscalCode')}
                  value={this.state.effatta.fiscalCode}
                  name='fiscalCode'
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField
                  label={this.props.translate('customization.receipt.password')}
                  value={this.state.effatta.password}
                  name='password'
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField
                  label={this.props.translate('customization.receipt.pin')}
                  value={this.state.effatta.pin}
                  name='pin'
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField
                  label={this.props.translate('customization.receipt.vat')}
                  value={this.state.effatta.vat}
                  name='vat'
                  onChange={this.onChange}
                  fullWidth
                />
              </>
            )}
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            {/*<Button size='small' onClick={this.delete}>
              <Translate id='customization.reset' />
						</Button>*/}
            <Button size='small' color='primary' onClick={() => this.submit()} /*disabled={!this.submitEnabled('stripe')}*/>
              <Translate id='customization.paymentMethods.add' />
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationPaymentReceipt));
