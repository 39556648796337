import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Translate } from 'react-localize-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  divider: {
    alignSelf: 'stretch',
    margin: '1rem 0',
  },
});

const Total = (props) => {
  const classes = useStyles(props);
  let revenue = Math.round(Object.values(props.revenue).reduce((acc, v) => acc + v.y, 0) * 100) / 100;
  let energy = Math.round(Object.values(props.energy).reduce((acc, v) => acc + v.y, 0));
  return (
    <Grid container direction='column' alignItems='center' justifyContent='flex-start'>
      <Typography variant='h6' color='textSecondary'>
        <Translate id='home.total.revenue' />
      </Typography>
      <Typography variant='h2'>{revenue}</Typography>
      <Typography variant='h4'>{props.currency === 'chf' ? 'chf' : '€'}</Typography>
      <Divider variant='fullWidth' className={classes.divider} />
      <Typography variant='h6' color='textSecondary'>
        <Translate id='home.total.energy' />
      </Typography>
      <Typography variant='h2'>{energy}</Typography>
      <Typography variant='h4'>kWh</Typography>
    </Grid>
  );
};

export default Total;
