import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListIcon from '@material-ui/icons/FilterList';
import RefreshIcon from '@material-ui/icons/Refresh';
import DialogUpload from './presentation.dialog.upload';
import DialogDelete from './presentation.dialog.delete';

const useStyles = makeStyles({
  listItemIcon: {
    minWidth: '2rem',
  },
  filtersContainer: { padding: '1rem' },
});

const Months = {
  it_IT: ['Gennaio', 'Febbraio', 'Marzo', 'Aprile', 'Maggio', 'Giugno', 'Luglio', 'Agosto', 'Settembre', 'Ottobre', 'Novembre', 'Dicembre'],
  en_GB: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
};

const getMonth = (date, locale) => {
  let year = parseInt(date.substr(0, 4), 10);
  let month = parseInt(date.substr(4, 2), 10) - 1;
  let l = Months.hasOwnProperty(locale) ? locale : 'en_GB';
  return <Typography variant='body2'>{`${Months[l][month]} ${year}`}</Typography>;
};

const FiltersMenu = (props) => {
  let [client, setClient] = React.useState('');

  React.useEffect(() => {
    if (props.anchorEl === null) {
      setClient(props.filters.client);
    }
  }, [props.anchorEl, props.filters.client]);
  return (
    <Popover
      anchorEl={props.anchorEl}
      open={props.anchorEl !== null}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ className: props.classes.filtersContainer }}
    >
      <Grid container spacing={3} direction='column'>
        <Grid item>
          <Typography color='textSecondary'>
            <Translate id='filter' />
          </Typography>
        </Grid>
        {props.user.type === 'superuser' && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='filter-client'>
                <Translate id='chargeboxes.client' />
              </InputLabel>
              <Select labelId='filter-client' value={client} onChange={(e) => setClient(e.target.value)}>
                {props.clients.map((c) => (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {props.user.clientType === 'main' && props.user.clients.length > 0 && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='filter-client'>
                <Translate id='chargeboxes.client' />
              </InputLabel>
              <Select labelId='filter-client' value={client} onChange={(e) => setClient(e.target.value)}>
                {props.clients.map((c) => (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item container direction='row' alignItems='center' justifyContent='center'>
          <Button
            onClick={() => {
              setClient('');
            }}
          >
            <Translate id='chargeboxes.filters.clear' />
          </Button>
          <Button color='primary' onClick={() => props.submit(client)}>
            <Translate id='chargeboxes.filters.submit' />
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

const Documents = (props) => {
  const classes = useStyles(props);
  return (
    <Paper elevation={3}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.snackbar !== null} autoHideDuration={6000} onClose={props.onSnackbarClose}>
        <Alert
          elevation={6}
          variant='filled'
          severity='info'
          onClose={props.onSnackbarClose}
          icon={false}
          action={
            <IconButton size='small' aria-label='close' color='inherit' onClick={props.onSnackbarClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          {props.snackbar === null ? '' : <Translate id={`documents.snackbar.${props.snackbar}`} />}
        </Alert>
      </Snackbar>
      <DialogUpload handleClose={props.closeUploadDialog} open={props.uploadDocumentOpen} user={props.user} clients={props.clients} />
      <DialogDelete
        handleClose={() => props.closeDeleteDialog(false)}
        submit={() => props.closeDeleteDialog(true)}
        open={props.deleteDocumentOpen}
        document={props.selectedDocument}
      />
      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Translate id='documents.month' />
                </TableCell>
                <TableCell>
                  <Translate id='documents.type' />
                </TableCell>
                <TableCell>
                  <Translate id='documents.user' />
                </TableCell>
                <TableCell>
                  <Translate id='documents.address' />
                </TableCell>
                <TableCell>
                  <Translate id='documents.taxvat' />
                </TableCell>
                <TableCell>
                  <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='nowrap'>
                    <Grid item>
                      <IconButton onClick={props.getDocuments}>
                        <RefreshIcon />
                      </IconButton>
                      <IconButton onClick={props.openUploadDialog}>
                        <CloudUploadIcon />
                      </IconButton>
                    </Grid>
                    {props.user.clientType === 'main' && props.user.clients.length > 0 && (
                      <IconButton onClick={props.openCloseFilters}>
                        <FilterListIcon />
                      </IconButton>
                    )}
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((row, id) => (
                <TableRow key={id}>
                  <TableCell>{getMonth(row.date.split('_')[0], props.user.locale)}</TableCell>
                  <TableCell>{<Translate id={`documents.types.${row.type}`} />}</TableCell>
                  <TableCell>{props.users[row.user].name || props.users[row.user].company_name}</TableCell>
                  <TableCell>
                    <Typography component='p' variant='body2'>
                      {props.users[row.user].address}
                    </Typography>
                    <Typography component='p' variant='body2'>
                      {props.users[row.user].city} ({props.users[row.user].zip_code})
                    </Typography>
                    <Typography component='p' variant='body2'>
                      {props.users[row.user].country}
                    </Typography>
                  </TableCell>
                  <TableCell>{props.users[row.user].tax_code || props.users[row.user].vat_number}</TableCell>
                  <TableCell>
                    <IconButton onClick={props.openCloseMenu(row)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPageOptions={[10, 20, 50]}
          count={props.count}
          rowsPerPage={props.pagination.rowsPerPage}
          page={props.pagination.page}
          onChangePage={props.handleChangePage}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
          labelRowsPerPage={props.translate('table.rowsPerPage')}
          nextIconButtonText={props.translate('table.next')}
          backIconButtonText={props.translate('table.previous')}
        />
        <Menu anchorEl={props.menuAnchorEl} open={props.menuAnchorEl !== null} onClose={props.openCloseMenu(null)}>
          <MenuItem button onClick={props.downloadDocument}>
            <ListItemIcon className={classes.listItemIcon}>
              <CloudDownloadIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit' noWrap>
              <Translate id='documents.download' />
            </Typography>
          </MenuItem>
          <MenuItem button onClick={props.openDeleteDialog}>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit' noWrap>
              <Translate id='documents.delete' />
            </Typography>
          </MenuItem>
        </Menu>
        <FiltersMenu
          anchorEl={props.filtersMenuAnchorEl}
          onClose={props.openCloseFilters}
          user={props.user}
          filters={props.filters}
          submit={props.getFilteredDocuments}
          clients={props.clients}
          classes={classes}
        />
      </Grid>
    </Paper>
  );
};
export default withLocalize(Documents);
