import React from 'react';

const UserContext = React.createContext({
	id: '',
	email: '',
	name: '',
	locale: '',
	type: '',
	client: '',
	hasPrivileges: false,
	canChangePayment: false,
	changeName: (n) => {},
	changeLanguage: (l) => {},
});

export default UserContext;
