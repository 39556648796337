import React from 'react';
import { withLocalize } from 'react-localize-redux';
import HomePage from './presentation';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import DynamoDBChargeboxes from '../../../aws/dynamodb/chargeboxes';
import DynamoDBTransactionsFinished from '../../../aws/dynamodb/transactions-finished';
import DynamoDBTransactionsRunning from '../../../aws/dynamodb/transactions-running';
import DynamoDBEndUsers from '../../../aws/dynamodb/endusers';
import DynamoDBRestAPICalls from '../../../aws/dynamodb/rest-api-calls';
import UserContext from '../../../context';
import { getDaysInMonth } from 'date-fns';
import { asyncTimeout } from '../../../utilities';

let timeToStr = (t) => {
  let d = new Date(t);
  return `${d.getFullYear().toString().padStart(4, '0')}/${(d.getMonth() + 1).toString().padStart(2, '0')}/${d.getDate().toString().padStart(2, '0')}`;
};

let strToTime = (str) => {
  let parts = str.split('/');
  let year = parseInt(parts[0]);
  let month = parseInt(parts[1]) - 1;
  let date = parseInt(parts[2]);
  return new Date(year, month, date, 0, 0, 0, 0).getTime();
};

let getDateRange = (yearmonth) => {
  let year = parseInt(yearmonth.substr(0, 4));
  let month = parseInt(yearmonth.substr(5)) - 1;
  let from = new Date(year, month, 1, 0, 0, 0, 0);
  let to = new Date(year, month + 1, 0, 23, 59, 59, 999);
  return { from, to };
};

class HomePageContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      month: '',
      clients: [],
      client: null,
      autorefresh: false,
      chargeboxes: {},
      status: {},
      running: [],
      energy: {},
      revenue: {},
      paymentMethods: {},
      charges: {},
      api: {},
      mostUsedChargeboxes: [],
      mostActiveUsers: [],
    };
    this.changeMonth = this.changeMonth.bind(this);
    this.selectClient = this.selectClient.bind(this);
    this.refresh = this.refresh.bind(this);
    this.toggleAutoRefresh = this.toggleAutoRefresh.bind(this);
    this.fetch = this.fetch.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }
  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  changeMonth(e, month) {
    if (this.state.processing) {
      return;
    }
    if (month !== null) {
      let autorefresh = this.state.autorefresh;
      let now = new Date();
      let currentMonth = `${now.getFullYear()}/${now.getMonth() + 1}`;
      if (month !== currentMonth) {
        autorefresh = false;
      }
      this.secureSetState({ month, autorefresh, processing: true }, async () => {
        if (this.client !== null) {
          await this.fetch();
        } else {
          this.secureSetState({ processing: false });
        }
      });
    }
  }

  selectClient(e, client) {
    if (this.state.processing) {
      return;
    }
    if (client) {
      this.secureSetState({ client: client }, this.fetch);
    } else {
      this.secureSetState({ client: null });
    }
  }

  refresh() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, this.fetch);
  }

  toggleAutoRefresh() {
    if (this.state.processing) {
      return;
    }
    if (this.state.autorefresh) {
      this.secureSetState({ autorefresh: false });
    } else {
      this.secureSetState({ autorefresh: true }, async () => {
        for (;;) {
          if (!this.state.autorefresh || this.state.client === null) {
            return;
          }
          this.refresh();
          await asyncTimeout(900000);
        }
      });
    }
  }

  async fetch() {
    try {
      let dateRange = getDateRange(this.state.month);
      let cbs = null;
      let transactionsFinished = null;
      if (this.state.client.id === '*') {
        cbs = await DynamoDBChargeboxes.getAll({ type: 'superuser' });
        transactionsFinished = await DynamoDBTransactionsFinished.getAll(
          { type: 'superuser' },
          {
            dateFrom: dateRange.from.getTime(),
            dateTo: dateRange.to.getTime(),
          }
        );
      } else {
        cbs = await DynamoDBChargeboxes.getAll({ type: 'client', client: this.state.client.id });
        transactionsFinished = await DynamoDBTransactionsFinished.getAll(
          {
            type: 'client',
            client: this.state.client.id,
          },
          {
            dateFrom: dateRange.from.getTime(),
            dateTo: dateRange.to.getTime(),
          }
        );
      }
      let status = { online: 0, offline: 0 };
      let chargeboxes = {};
      let energy = {};
      let revenue = {};
      let paymentMethods = {};
      let charges = Array(getDaysInMonth(dateRange.from)).fill(0);
      let chargesPerChargebox = {};
      let chargesPerUser = {};
      for (let chargebox of cbs.Items) {
        chargeboxes[chargebox.id] = chargebox.alias;
        if (!chargebox.active) {
          continue;
        }
        switch (chargebox.connected) {
          case true:
            status.online++;
            break;
          case false:
            status.offline++;
            break;

          default:
            break;
        }
      }
      console.log(transactionsFinished);
      for (let tr of transactionsFinished.Items) {
        // if (tr.date_finished - tr.date_started < 60000) {
        // 	continue;
        // }
        let dateStr = timeToStr(tr.date_started);
        if (!energy.hasOwnProperty(dateStr)) {
          energy[dateStr] = 0;
        }
        if (!revenue.hasOwnProperty(dateStr)) {
          revenue[dateStr] = 0;
        }
        energy[dateStr] += tr.meter_stop - tr.meter_start;
        if (!tr.status.unlocked) {
          charges[parseInt(dateStr.substr(8, 2)) - 1]++;
          if (chargesPerChargebox.hasOwnProperty(tr.chargebox)) {
            chargesPerChargebox[tr.chargebox]++;
          } else {
            chargesPerChargebox[tr.chargebox] = 1;
          }
          if (tr.user) {
            if (chargesPerUser.hasOwnProperty(tr.user)) {
              chargesPerUser[tr.user]++;
            } else {
              chargesPerUser[tr.user] = 1;
            }
          }
          if (tr.payment) {
            if (tr.payment.method !== 'free' && tr.payment.method !== 'gratis') {
              if (tr.payment.hasOwnProperty('success') && tr.payment.success === true) {
                revenue[dateStr] += tr.payment.price || tr.payment.amount || 0;
              }
              /*if (tr.type === 'local') {
                if (tr.payment.hasOwnProperty('success') && tr.payment.success === true && tr.payment.hasOwnProperty('currency')) {
                  revenue[dateStr] += tr.payment.price || tr.payment.amount || 0; //tr.payment.amount tolto in quanto sbagliato e quindi sfalsa il conteggio
                }
              } else {
                revenue[dateStr] += tr.payment.price || tr.payment.amount || 0;
              }*/
            }
          }
          if (tr.payment && tr.type !== 'api') {
            if (paymentMethods.hasOwnProperty(tr.payment.method)) {
              paymentMethods[tr.payment.method]++;
            } else {
              paymentMethods[tr.payment.method] = 1;
            }
          } else if (tr.type === 'local' || tr.type === 'api') {
            if (paymentMethods.hasOwnProperty(tr.type)) {
              paymentMethods[tr.type]++;
            } else {
              paymentMethods[tr.type] = 1;
            }
          }
        }
      }
      let topHighestChargesPerChargebox = new Set(
        Object.values(chargesPerChargebox)
          .sort((a, b) => b - a)
          .slice(0, 5)
      );
      let mostUsedChargeboxes = [];
      for (let v of topHighestChargesPerChargebox) {
        for (let cb in chargesPerChargebox) {
          if (chargesPerChargebox[cb] === v) {
            mostUsedChargeboxes.push({ chargebox: cb, charges: v });
          }
          if (mostUsedChargeboxes.length >= 5) {
            break;
          }
        }
        if (mostUsedChargeboxes.length >= 5) {
          break;
        }
      }

      let topHighestChargesPerUser = new Set(
        Object.values(chargesPerUser)
          .sort((a, b) => b - a)
          .slice(0, 3)
      );
      let mostActiveUsers = [];
      for (let v of topHighestChargesPerUser) {
        for (let u in chargesPerUser) {
          if (chargesPerUser[u] === v) {
            let user = await DynamoDBEndUsers.get(u);
            if (user.Item) {
              mostActiveUsers.push({ name: `${user.Item.name || user.Item.company_name || user.Item.ragsoc}`, charges: v });
            }
          }
          if (mostActiveUsers.length >= 5) {
            break;
          }
        }
        if (mostActiveUsers.length >= 5) {
          break;
        }
      }
      for (let i = 1; i < getDaysInMonth(dateRange.from); i++) {
        let key = timeToStr(dateRange.from.setDate(i));
        if (!energy.hasOwnProperty(key)) {
          energy[key] = 0;
        }
        if (!revenue.hasOwnProperty(key)) {
          revenue[key] = 0;
        }
      }
      let energyList = Object.keys(energy).map((d) => {
        let time = strToTime(d);
        return { x: time, y: Math.round(energy[d] / 10) / 100 };
      });
      let revenueList = Object.keys(revenue).map((d) => {
        let time = strToTime(d);
        return { x: time, y: Math.round(revenue[d] * 100) / 100 };
      });

      let transactionsRunning = null;
      if (this.state.client.id === '*') {
        transactionsRunning = await DynamoDBTransactionsRunning.getAll(true);
      } else {
        transactionsRunning = await DynamoDBTransactionsRunning.getByClient(this.state.client.id, true);
      }
      let running = transactionsRunning.Items.map((t) => ({
        chargebox: t.chargebox,
        connector: t.connector,
        energy: t.hasOwnProperty('current_meter') && t.hasOwnProperty('meter_start') ? Math.round((t.current_meter - t.meter_start) / 10) / 100 : 0,
      }));

      let api = {};
      if (this.state.client.rest_api && this.state.client.rest_api.enabled) {
        let month = this.state.month.split('/');
        let date = parseInt(`${month[0].padStart(4, '0')}${month[1].padStart(2, 0)}`);
        let result = await DynamoDBRestAPICalls.get(this.state.client.api_key, date);
        if (result.Item) {
          api.callsIn = result.Item.callsIn;
          api.callsOut = result.Item.callsOut;
        }
      }
      this.secureSetState({
        processing: false,
        chargeboxes,
        status,
        running,
        energy: energyList,
        revenue: revenueList,
        paymentMethods,
        charges,
        mostUsedChargeboxes,
        mostActiveUsers,
        api,
      });
    } catch (err) {
      console.log(err);
      this.secureSetState({ processing: false });
    }
  }

  async componentDidMount() {
    this.isMounted = true;

    let now = new Date();
    let month = `${now.getFullYear()}/${now.getMonth() + 1}`;
    if (this.context.type === 'superuser') {
      try {
        let clients = await DynamoDBClients.getAll();
        this.secureSetState({
          month,
          processing: false,
          clients: [...clients.Items.map((c) => ({ id: c.id, name: c.name, rest_api: c.rest_api, api_key: c.api_key, currency: c.payment_limit.currency }))],
        });
      } catch (err) {
        console.log(err);
      }
    } else if (this.context.clientType === 'main' && this.context.clients.length > 0) {
      let clients = await DynamoDBClients.batchGet(this.context.clients);
      this.secureSetState({
        month,
        clients: clients.map((c) => ({
          id: c.id,
          name: c.name,
          rest_api: c.rest_api,
          api_key: c.api_key,
          currency: c.payment_limit.currency,
        })) /*[...clients]*/,
        processing: false,
      });
    } else {
      console.log(this.context);
      let client = await DynamoDBClients.get(this.context.client);
      this.secureSetState(
        {
          month,
          client: { id: this.context.client, rest_api: client.Item.rest_api, api_key: client.Item.api_key, currency: client.Item.payment_limit.currency },
        },
        this.fetch
      );
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <HomePage
        user={this.context}
        {...this.state}
        changeMonth={this.changeMonth}
        selectClient={this.selectClient}
        toggleAutoRefresh={this.toggleAutoRefresh}
        refresh={this.refresh}
      />
    );
  }
}

HomePageContainer.contextType = UserContext;
export default withLocalize(HomePageContainer);
