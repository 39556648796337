import { S3, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const BucketPrefix = env === 'prd' ? '' : `${env}.`;

const randomId = () => {
  let ret = new Date().getTime().toString(16),
    letters = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  while (ret.length < 32) {
    ret += letters[Math.floor(Math.random() * letters.length)];
  }
  return ret;
};

const getTermsLink = (id) => `https://s3-eu-west-1.amazonaws.com/${BucketPrefix}clients.thor.tools/public/terms/${id}.pdf`;

const getManualLink = () => `https://s3-eu-west-1.amazonaws.com/${BucketPrefix}clients.thor.tools/public/manual/documentazioneITA.pdf`;

const putTerms = (id, terms) => {
  const S = new S3({ credentials: AWSConfig.credentials });
  return S.putObject({
    Bucket: `${BucketPrefix}clients.thor.tools`,
    Key: `public/terms/${id}.pdf`,
    Body: terms,
    ContentType: 'application/pdf',
    ACL: 'public-read',
  }).promise();
};

const getLogo = async (id) => {
  const S = new S3({ credentials: AWSConfig.credentials });
  let data = await S.getObject({ Bucket: `${BucketPrefix}clients.thor.tools`, Key: `public/logos/${id}.png` }).promise();
  if (data.hasOwnProperty('Body') && data.hasOwnProperty('ContentType')) {
    var blob = new Blob([data.Body], { type: data.ContentType });
    var imageUrl = URL.createObjectURL(blob);
    return imageUrl;
  }
  throw 'not_found';
};

const putLogo = (id, logo) => {
  const S = new S3({ credentials: AWSConfig.credentials });
  return S.putObject({
    Bucket: `${BucketPrefix}clients.thor.tools`,
    Key: `public/logos/${id}.png`,
    Body: logo,
    ContentType: 'image/png',
    ACL: 'public-read',
  }).promise();
};

const getInvoice = (user, path) => {
  const S = new S3({ credentials: AWSConfig.credentials });
  return S.getObject({ Bucket: `${BucketPrefix}documents.thor.tools`, Key: `private/${user}/${path}` }).promise();
};

const deleteInvoice = (user, path) => {
  const S = new S3({ credentials: AWSConfig.credentials });
  return S.deleteObject({ Bucket: `${BucketPrefix}documents.thor.tools`, Key: `private/${user}/${path}` }).promise();
};

const putInvoice = async (user, pdf) => {
  const S = new S3({ credentials: AWSConfig.credentials });
  const id = `${randomId()}.pdf`;
  const key = `private/${user}/${id}`;
  await S.putObject({
    Bucket: `${BucketPrefix}documents.thor.tools`,
    Key: key,
    Body: pdf,
    ContentType: 'application/pdf',
  }).promise();
  return id;
};

export default {
  getTermsLink,
  putTerms,
  getLogo,
  putLogo,
  getInvoice,
  deleteInvoice,
  putInvoice,
  getManualLink,
};
