import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import withStyles from '@material-ui/core/styles/withStyles';
import Lambda from '../../../aws/lambda';

const styles = {
  button: { margin: '1rem' },
};
class ChargeDialogTrigger extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processing: false, connector: null };

    this.changeConnector = this.changeConnector.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  changeConnector(c) {
    return () => this.secureSetState({ connector: c });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.secureSetState({ connector: null });
    }
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    let connettore = this.state.connector;
    this.secureSetState({ processing: true }, async () => {
      try {
        console.log('THIS', this.props);
        let functionName = '';
        if (this.props.chargebox.type.msg === 'json' && this.props.chargebox.type.version === '1.6') {
          functionName = 'OcppJson16TriggerMessage';
        }
        if (this.props.command === '' || connettore === null) {
          this.secureSetState({ processing: false }, () => this.props.handleClose(false, false));
          return;
        }
        await Lambda.invoke(functionName, {
          chargebox: this.props.chargebox.id,
          user: this.props.user.id,
          requestedMessage: this.props.command,
          connector: connettore,
        });
        this.secureSetState({ processing: false }, () => this.props.handleClose(true, false));
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false });
        this.cancel();
      }
    });
  }

  cancel() {
    this.props.handleClose(false, true);
  }

  render() {
    return (
      <Dialog onClose={this.cancel} aria-labelledby='chargebox-dialog-unlock-title' open={this.props.open} fullWidth maxWidth='xs'>
        <DialogTitle id='chargebox-dialog-trigger-title'>
          <Translate id='chargeboxes.dialogs.trigger.title' />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Translate id='chargeboxes.dialogs.trigger.text' />
          </Typography>
          <Typography>{`${this.props.chargebox.id} - ${this.props.chargebox.alias}`}</Typography>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id='chargebox-dialog-newedit-select-trigger'>
                <Translate id='chargeboxes.command' />
              </InputLabel>
              <Select labelId='chargebox-dialog-newedit-select-trigger' name='command' value={this.props.command} onChange={this.props.onChange} fullWidth>
                <MenuItem value='BootNotification'>BootNotification</MenuItem>
                <MenuItem value='DiagnosticsStatusNotification'>DiagnosticsStatusNotification</MenuItem>
                <MenuItem value='FirmwareStatusNotification'>FirmwareStatusNotification</MenuItem>
                <MenuItem value='Heartbeat'>Heartbeat</MenuItem>
                <MenuItem value='MeterValues'>MeterValues</MenuItem>
                <MenuItem value='StatusNotification'>StatusNotification</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid container direction='row' alignItems='center' justifyContent='center' wrap='wrap'>
            <Button
              key={0}
              className={this.props.classes.button}
              onClick={this.changeConnector(0)}
              color={this.state.connector === 0 ? 'primary' : 'default'}
              variant='contained'
            >
              0
            </Button>
            {this.props.chargebox.connectors.map((c, i) => (
              <Button
                key={i + 1}
                className={this.props.classes.button}
                onClick={this.changeConnector(i + 1)}
                color={this.state.connector === i + 1 ? 'primary' : 'default'}
                variant='contained'
              >
                {i + 1}
              </Button>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancel} color='default' autoFocus>
            <Translate id='chargeboxes.dialogs.close' />
          </Button>
          <Button onClick={this.submit} color='primary' disabled={this.props.command === null}>
            <Translate id='chargeboxes.dialogs.trigger.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(ChargeDialogTrigger));
