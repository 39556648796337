import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ColorPicker } from 'material-ui-color';
import { copyToClipboard } from '../../../utilities';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
  tableButton: {
    textTransform: 'none',
    fontWeight: 'normal',
  },
  title: {
    marginTop: '.5rem',
    marginBottom: '.5rem',
  },
};

class CustomizationCDN extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      primaryColor: '',
      secondaryColor: '',
      font: '',
    };
    this.onCopyToClipboardClick = this.onCopyToClipboardClick.bind(this);
    this.colorChanged = this.colorChanged.bind(this);
    this.onFontChanged = this.onFontChanged.bind(this);
    this.reset = this.reset.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onCopyToClipboardClick = (text) => {
    return async () => {
      let success = await copyToClipboard(text);
      if (success) {
        this.props.showSnackbar('copied');
      }
    };
  };

  colorChanged(color, e) {
    if (this.state.processing || !e || !e.hasOwnProperty('hex')) {
      return;
    }
    let value = '#' + e.hex;

    switch (color) {
      case 'primary':
        this.secureSetState({ primaryColor: value });
        break;
      case 'secondary':
        this.secureSetState({ secondaryColor: value });
        break;

      default:
        break;
    }
  }

  onFontChanged(e, font) {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ font });
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({
      primaryColor: this.props.cdn.colors.primary,
      secondaryColor: this.props.cdn.colors.secondary,
      font: this.props.cdn.font,
    });
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      let { primaryColor, secondaryColor, font } = this.state;
      primaryColor = primaryColor.trim();
      secondaryColor = secondaryColor.trim();
      font = font.trim();
      let cdn = { ...this.props.cdn };
      cdn.colors = {
        primary: primaryColor,
        secondary: secondaryColor,
      };
      cdn.font = font;

      try {
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            cdn: cdn,
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('cdn', cdn);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('cdnError');
        });
      }
    });
  }

  componentDidUpdate(prevProps) {
    let state = {};
    if (this.props.cdn === null) {
      return;
    }
    if ((prevProps.cdn === null && this.props.cdn !== null) || prevProps.cdn.colors.primary !== this.props.cdn.colors.primary) {
      state.primaryColor = this.props.cdn.colors.primary || '#319795';
    }
    if ((prevProps.cdn === null && this.props.cdn !== null) || prevProps.cdn.colors.secondary !== this.props.cdn.colors.secondary) {
      state.secondaryColor = this.props.cdn.colors.secondary || '#319795';
    }
    if ((prevProps.cdn === null && this.props.cdn !== null) || prevProps.cdn.font !== this.props.cdn.font) {
      state.font = this.props.cdn.font || 'mono';
    }

    if (Object.keys(state).length > 0) {
      this.secureSetState(state);
    }
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.cdn.title' />} subheader={<Translate id='customization.cdn.subheader' />} />
        <CardContent>
          {this.props.cdn === null ? (
            <Typography variant='body1'>
              <Translate id='customization.cdn.noCDN' />
            </Typography>
          ) : (
            <>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel-cdn1-content' id='panel-cdn1-header'>
                  <Typography>
                    <Translate id='customization.cdn.info.title' />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction='column'>
                    <Typography color='textSecondary' variant='subtitle2'>
                      <Translate id='customization.cdn.info.urls' />
                    </Typography>
                    <List dense>
                      {this.props.cdn.url.map((u, i) => (
                        <ListItem key={i}>
                          <ListItemText primary={u} />
                          <ListItemSecondaryAction>
                            <IconButton aria-label='copy' edge='start' onClick={this.onCopyToClipboardClick(u)}>
                              <FileCopyIcon />
                            </IconButton>
                            <IconButton aria-label='open' edge='end' href={`https://${u}`} target='_blank' rel='noopener noreferrer'>
                              <OpenInNewIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                    </List>
                    <Typography color='textSecondary' variant='subtitle2'>
                      <Translate id='customization.cdn.info.records' />
                    </Typography>
                    <TableContainer>
                      <Table aria-label='dns records' size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Translate id='customization.cdn.info.name' />
                            </TableCell>
                            <TableCell>
                              <Translate id='customization.cdn.info.type' />
                            </TableCell>
                            <TableCell>
                              <Translate id='customization.cdn.info.value' />
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.props.cdn.records.map((record, idx) => (
                            <TableRow key={idx}>
                              <TableCell>
                                <Button size='small' className={this.props.classes.tableButton} onClick={this.onCopyToClipboardClick(record.name)}>
                                  {record.name}
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button size='small' className={this.props.classes.tableButton} onClick={this.onCopyToClipboardClick(record.type)}>
                                  {record.type}
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Button size='small' className={this.props.classes.tableButton} onClick={this.onCopyToClipboardClick(record.value)}>
                                  {record.value}
                                </Button>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>

                    <Typography color='textSecondary' variant='subtitle2' gutterBottom className={this.props.classes.title}>
                      <Translate id='customization.cdn.info.emailAddrFrom' />
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText primary={this.props.cdn.from} />
                        <ListItemSecondaryAction>
                          <IconButton aria-label='copy' edge='start' onClick={this.onCopyToClipboardClick(this.props.cdn.from)}>
                            <FileCopyIcon />
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                  </Grid>
                </AccordionDetails>
              </Accordion>
              <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel-cdn2-content' id='panel-cdn2-header'>
                  <Typography>
                    <Translate id='customization.cdn.configuration.title' />
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container direction='column'>
                    <Typography color='textSecondary' variant='subtitle2' gutterBottom className={this.props.classes.title}>
                      <Translate id='customization.cdn.configuration.colors.title' />
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText primary={<Translate id='customization.cdn.configuration.colors.primary' />} />
                        <ListItemSecondaryAction>
                          <ColorPicker
                            defaultValue='#319795'
                            value={this.state.primaryColor}
                            deferred
                            onChange={(e) => this.colorChanged('primary', e)}
                            disableAlpha
                            hideTextfield
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                      <ListItem>
                        <ListItemText primary={<Translate id='customization.cdn.configuration.colors.secondary' />} />
                        <ListItemSecondaryAction>
                          <ColorPicker
                            defaultValue='#319795'
                            value={this.state.secondaryColor}
                            deferred
                            onChange={(e) => this.colorChanged('secondary', e)}
                            disableAlpha
                            hideTextfield
                          />
                        </ListItemSecondaryAction>
                      </ListItem>
                    </List>
                    <Typography color='textSecondary' variant='subtitle2' gutterBottom className={this.props.classes.title}>
                      <Translate id='customization.cdn.configuration.font' />
                    </Typography>
                    <ToggleButtonGroup value={this.state.font} exclusive onChange={this.onFontChanged} aria-label='font family'>
                      <ToggleButton value='mono' aria-label='monospace' className={this.props.classes.tableButton}>
                        <Typography style={{ fontFamily: 'monospace' }}>Monospace</Typography>
                      </ToggleButton>
                      <ToggleButton value='sans' aria-label='sans-serif' className={this.props.classes.tableButton}>
                        <Typography style={{ fontFamily: 'sans-serif' }}>Sans-serif</Typography>
                      </ToggleButton>
                      <ToggleButton value='serif' aria-label='serif' className={this.props.classes.tableButton}>
                        <Typography style={{ fontFamily: 'serif' }}>Serif</Typography>
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </>
          )}
        </CardContent>
        {this.props.cdn !== null && (
          <CardActions>
            <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
              <Button size='small' onClick={this.reset}>
                <Translate id='customization.reset' />
              </Button>
              <Button size='small' color='primary' onClick={this.submit}>
                <Translate id='customization.confirm' />
              </Button>
            </Grid>
          </CardActions>
        )}
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationCDN));
