import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

const CancelReservationDialog = (props) => (
  <Dialog
    onClose={props.handleClose}
    aria-labelledby='cancel-reservation-dialog-title'
    aria-describedby='cancel-reservation-dialog-description'
    open={props.open}
    fullWidth
    maxWidth='xs'
  >
    <DialogTitle id='cancel-reservation-dialog-title'>
      <Translate id='reservations.dialogs.cancel.title' />
    </DialogTitle>
    <DialogContent>
      <DialogContentText id='cancel-reservation-dialog-description'>
        <Typography>
          {props.connector !== null && props.chargebox !== null && props.user !== null && (
            <Translate
              id='reservations.dialogs.cancel.text'
              data={{
                chargebox: props.chargebox,
                connector: props.connector,
                user: props.user,
              }}
            />
          )}
        </Typography>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Grid container direction='column' alignItems='flex-start' justifyContent='space-between' spacing={2}>
        <Grid item>
          <Button onClick={props.handleClose} color='default' autoFocus>
            <Translate id='reservations.dialogs.cancel.cancel' />
          </Button>
        </Grid>
        {/* <Grid item>
					<Button onClick={() => props.submit(true)} color="primary">
						<Translate id="reservations.dialogs.cancel.submitPay" />
					</Button>
				</Grid> */}
        <Grid item>
          <Button onClick={() => props.submit(true)} color='primary'>
            <Translate id='reservations.dialogs.cancel.submitDontPay' />
          </Button>
        </Grid>
      </Grid>
    </DialogActions>
  </Dialog>
);

export default withLocalize(CancelReservationDialog);
