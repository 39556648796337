import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';

const Currencies = { eur: '€', usd: '$', gbp: '£', chf: 'chf' };

const DialogFinishedTransactionPayment = (props) => {
  return (
    <Dialog onClose={props.handleClose} aria-labelledby='finished-transactions-dialog-payment-title' open={props.open} maxWidth='sm'>
      <DialogContent>
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableBody>
              <TableRow>
                <TableCell>
                  <Translate id='transactions.success' />
                </TableCell>
                <TableCell>{props.unlocked ? <ClearIcon color='error' /> : <CheckIcon color='primary' />}</TableCell>
              </TableRow>
              {!props.payment && (
                <TableRow>
                  <TableCell>
                    <Translate id='transactions.price' />
                  </TableCell>
                  <TableCell>
                    <Translate id='transactions.paymentMethods.gratis' />
                  </TableCell>
                </TableRow>
              )}
              {props.payment && (
                <React.Fragment>
                  <TableRow>
                    <TableCell>
                      <Translate id='transactions.paymentMethod' />
                    </TableCell>
                    <TableCell>
                      <Translate id={`transactions.paymentMethods.${props.payment.method ? props.payment.method : 'gratis'}`} />
                    </TableCell>
                  </TableRow>
                  {props.payment.hasOwnProperty('amount') && !props.payment.hasOwnProperty('price') && props.payment.hasOwnProperty('currency') && (
                    <TableRow>
                      <TableCell>
                        <Translate id='transactions.price' />
                      </TableCell>
                      <TableCell>
                        <Typography variant='body1'>{`${Currencies[props.currencies[props.client]]} ${props.payment.amount}`}</Typography>
                        {console.log(props)}
                        {props.reservation && (
                          <Typography variant='caption'>
                            <Translate
                              id='transactions.reservationPrice'
                              data={{
                                currency: Currencies[props.currencies[props.client]],
                                amount: props.payment.reservation > props.reservation ? props.payment.reservation : props.reservation,
                              }}
                            />
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {props.payment.hasOwnProperty('price') && props.payment.hasOwnProperty('currency') && (
                    <TableRow>
                      <TableCell>
                        <Translate id='transactions.price' />
                      </TableCell>
                      <TableCell>
                        <Typography variant='body1'>{`${Currencies[props.currencies[props.client]]} ${props.payment.price}`}</Typography>
                        {props.reservation && (
                          <Typography variant='caption'>
                            <Translate
                              id='transactions.reservationPrice'
                              data={{
                                currency: Currencies[props.currencies[props.client]],
                                amount: props.payment.reservation > props.reservation ? props.payment.reservation : props.reservation,
                              }}
                            />
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {!props.payment.hasOwnProperty('price') && props.price && (
                    <TableRow>
                      <TableCell>
                        <Translate id='transactions.price' />
                      </TableCell>
                      <TableCell>
                        <Typography variant='body1'>{`${Currencies[props.currencies[props.client]]} ${props.price}`}</Typography>
                        {props.reservation && (
                          <Typography variant='caption'>
                            <Translate
                              id='transactions.reservationPrice'
                              data={{
                                currency: Currencies[props.currencies[props.client]],
                                amount: props.payment.reservation > props.reservation ? props.reservation.reservation : props.reservation,
                              }}
                            />
                          </Typography>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                  {props.reservation && (
                    <TableRow>
                      <TableCell>
                        <Translate id='transactions.reservationID' />
                      </TableCell>
                      <TableCell>{props.payment.reservation_id}</TableCell>
                    </TableRow>
                  )}
                  {props.type === 'remote' && props.payment.transaction && (
                    <TableRow>
                      <TableCell>
                        <Translate id='transactions.paymentTransactionID' />
                      </TableCell>
                      <TableCell>{props.payment.transaction}</TableCell>
                    </TableRow>
                  )}
                </React.Fragment>
              )}
              {props.type === 'local' && (
                <TableRow>
                  <TableCell>
                    <Translate id='transactions.rfidID' />
                  </TableCell>
                  <TableCell>{props.rfid}</TableCell>
                </TableRow>
              )}
              {props.user && (
                <>
                  <TableRow>
                    <TableCell>{props.user.admin === true ? <Translate id='transactions.admin' /> : <Translate id='transactions.enduser' />}</TableCell>
                    <TableCell>
                      <Typography component='p' variant='body2'>
                        {props.user.name || props.user.ragsoc}
                      </Typography>
                      <Typography component='a' variant='body2' color='textSecondary' href={`mailto:${props.user.email}`}>
                        {props.user.email}
                      </Typography>
                      {props.user.admin !== true && (
                        <>
                          {props.user.sdi && (
                            <Typography component='p' variant='body2' color='textSecondary' href={`mailto:${props.user.pec}`}>
                              {props.user.pec}
                            </Typography>
                          )}
                          <Typography component='p' variant='body2'>
                            {props.user.tax_code || props.user.vat_code}
                          </Typography>
                          {props.user.sdi && (
                            <Typography component='p' variant='body2'>
                              {props.user.sdi}
                            </Typography>
                          )}
                          <Typography component='p' variant='body2'>
                            {props.user.address}
                          </Typography>
                          <Typography component='p' variant='body2'>
                            {props.user.city} - {props.user.zip_code} ({props.user.country})
                          </Typography>
                        </>
                      )}
                    </TableCell>
                  </TableRow>
                  {props.user.admin && props.hasOwnProperty('notes') && props.notes !== undefined && props.notes !== null && props.notes !== '' && (
                    <TableRow>
                      <TableCell>
                        <Translate id='transactions.notes' />
                      </TableCell>
                      <TableCell>{props.notes}</TableCell>
                    </TableRow>
                  )}
                </>
              )}
              {props.type === 'local' && props.rfidAlias !== null && (
                <TableRow>
                  <TableCell>
                    <Translate id='transactions.rfidAlias' />
                  </TableCell>
                  <TableCell>{props.rfidAlias}</TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color='default' autoFocus>
          <Translate id='documents.dialogs.close' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withLocalize(DialogFinishedTransactionPayment);
