import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Collapse from '@material-ui/core/Collapse';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import FileCopyIcon from '@material-ui/icons/FileCopyOutlined';
import Power from '@material-ui/icons/Power';
import ReplyIcon from '@material-ui/icons/Reply';
import FilterListIcon from '@material-ui/icons/FilterList';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PaymentIcon from '@material-ui/icons/Payment';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import SettingsApplications from '@material-ui/icons/SettingsApplications';
import SystemUpdateAlt from '@material-ui/icons/SystemUpdateAlt';
import AddToQueue from '@material-ui/icons/AddToQueue';
import Input from '@material-ui/icons/Input';

import ThumbsUpDownIcon from '@material-ui/icons/ThumbsUpDown';
import DialogNewEdit from './presentation.dialog.newedit';
import DialogDelete from './presentation.dialog.delete';
import DialogReset from './presentation.dialog.reset';
import DialogStartCharge from './presentation.dialog.start';
import DialogUnlock from './presentation.dialog.unlock';
import DialogStatus from './presentation.dialog.status';
import DialogHeartbeat from './presentation.dialog.heartbeats';
import DialogUpgradeFirmware from './presentation.dialog.upgradefirmware';
import DialogTriggerMessage from './presentation.dialog.triggermessage';
import DialogCustomPayment from './presentation.dialog.custompayment';
import DialogSendCommand from './presentation.dialog.sendcommand';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

import env from '../../../env';
import { copyToClipboard } from '../../../utilities';
//import { LocalConvenienceStoreOutlined } from '@material-ui/icons';

const PaymentLink = `https://${env === 'prd' ? '' : env + '.'}api.thor.tools/latest/payment/chargebox-link`;

const useStyles = makeStyles({
  filtersContainer: { padding: '1rem' },
  tableCell: { borderBottom: 0, textOverflow: 'ellipsis' },
  collapseContainer: { paddingBottom: 0, paddingTop: 0 },
  collapseContent: { padding: '1rem 2rem' },
  collapseItemTitle: { marginRight: 4, fontWeight: 'bold' },
  listItemIcon: { minWidth: '2rem' },
});

const getOnlineIcon = (data, translate) => {
  let lastHeartbeatThreshold = Date.now() - 1200000;
  let connected = null;
  if (data.type.msg !== 'nc') {
    if (data.hasOwnProperty('connected')) {
      connected = data.connected;
    } else if (data.hasOwnProperty('last_heartbeat')) {
      connected = data.last_heartbeat >= lastHeartbeatThreshold;
    }
  }

  let style = {
    height: '1.5rem',
    width: '1.5rem',
    WebkitBoxShadow: '2px 2px 5px 0px rgba(0,0,0,0.35)',
    MozBoxShadow: '2px 2px 5px 0px rgba(0,0,0,0.35)',
    boxShadow: '2px 2px 5px 0px rgba(0,0,0,0.35)',
    borderRadius: 9999,
  };
  if (connected === true) {
    return <div style={{ backgroundColor: '#27ae60', ...style }} />;
  } else if (connected === false) {
    return (
      <Tooltip title={translate('chargeboxes.offlineTooltip')} placement='left'>
        <div style={{ backgroundColor: '#c0392b', ...style }} />
      </Tooltip>
    );
  } else {
    return (
      <Tooltip title={translate('chargeboxes.unknownStatus')} placement='left'>
        <div style={{ backgroundColor: '#d1d8e0', ...style }} />
      </Tooltip>
    );
  }
};

const getVehicleIcon = (vehicle) => {
  switch (vehicle) {
    case 'bicycle':
      return <DirectionsBikeIcon color='action' />;
    case 'car':
      return <DirectionsCarIcon color='action' />;

    default:
      return null;
  }
};

const DateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const getDate = (timestamp, locale) => {
  if (typeof timestamp !== 'number') {
    return '';
  }
  let date = new Date(timestamp);
  return date.toLocaleTimeString(locale.replace('_', '-'), DateOptions);
};

const FiltersMenu = (props) => {
  let [client, setClient] = React.useState('');
  let [alias, setAlias] = React.useState('');
  let [vehicle, setVehicle] = React.useState('');
  let [status, setStatus] = React.useState('');

  React.useEffect(() => {
    if (props.anchorEl === null) {
      setClient(props.filters.client);
      setAlias(props.filters.alias);
      setVehicle(props.filters.vehicle);
      setStatus(props.filters.status);
    }
  }, [props.anchorEl, props.filters.client, props.filters.alias, props.filters.vehicle, props.filters.status]);
  return (
    <Popover
      anchorEl={props.anchorEl}
      open={props.anchorEl !== null}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ className: props.classes.filtersContainer }}
    >
      <Grid container spacing={3} direction='column'>
        <Grid item>
          <Typography color='textSecondary'>
            <Translate id='filter' />
          </Typography>
        </Grid>
        {props.user.type === 'superuser' && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='filter-client'>
                <Translate id='chargeboxes.client' />
              </InputLabel>
              <Select labelId='filter-client' value={client} onChange={(e) => setClient(e.target.value)}>
                {props.clients.map((c) => (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        {props.user.clientType === 'main' && props.user.clients.length > 0 && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='filter-client'>
                <Translate id='chargeboxes.client' />
              </InputLabel>
              <Select labelId='filter-client' value={client} onChange={(e) => setClient(e.target.value)}>
                {props.clients.map((c) => (
                  <MenuItem value={c.id} key={c.id}>
                    {c.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <TextField label={<Translate id='chargeboxes.alias' />} value={alias} onChange={(e) => setAlias(e.target.value)} fullWidth />
        </Grid>
        <Grid item>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>
              <Translate id='chargeboxes.vehicle' />
            </FormLabel>
            <RadioGroup aria-label='vehicle' value={vehicle} onChange={(e) => setVehicle(e.target.value)}>
              <FormControlLabel value='car' control={<Radio color='primary' />} label={<Translate id='vehicles.car' />} />
              <FormControlLabel value='bicycle' control={<Radio color='primary' />} label={<Translate id='vehicles.bicycle' />} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>
              <Translate id='chargeboxes.status' />
            </FormLabel>
            <RadioGroup aria-label='status' value={status} onChange={(e) => setStatus(e.target.value)}>
              <FormControlLabel value='online' control={<Radio color='primary' />} label={<Translate id='chargeboxes.filters.online' />} />
              <FormControlLabel value='offline' control={<Radio color='primary' />} label={<Translate id='chargeboxes.filters.offline' />} />
              <FormControlLabel value='unknown' control={<Radio color='primary' />} label={<Translate id='chargeboxes.filters.unknown' />} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item container direction='row' alignItems='center' justifyContent='center'>
          <Button
            onClick={() => {
              setClient('');
              setAlias('');
              setVehicle('');
              setStatus('');
            }}
          >
            <Translate id='chargeboxes.filters.clear' />
          </Button>
          <Button color='primary' onClick={() => props.submit(client, alias, vehicle, status)}>
            <Translate id='chargeboxes.filters.submit' />
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

const ChargeboxMenu = (props) => {
  const [opn_adv, setOpnAdv] = React.useState(false);
  const handleClick = () => {
    setOpnAdv(!opn_adv);
  };
  let items = [
    <MenuItem button onClick={props.openEditDialog} key={0}>
      <ListItemIcon className={props.classes.listItemIcon}>
        <SearchIcon fontSize='small' />
      </ListItemIcon>
      <ListItemText>
        <Translate id='chargeboxes.details' />
      </ListItemText>
    </MenuItem>,
  ];
  if (props.user.type.toLowerCase() === 'superuser' || props.user.hasPrivileges) {
    items.push(
      <MenuItem button onClick={props.duplicateChargebox} key={1}>
        <ListItemIcon className={props.classes.listItemIcon}>
          <FileCopyIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>
          <Translate id='chargeboxes.duplicate' />
        </ListItemText>
      </MenuItem>
    );
  }

  if (props.chargeboxSelected.type.msg.length > 0 && props.chargeboxSelected.type.msg !== 'nc') {
    items.push(
      props.chargeboxSelected.connected && (
        <MenuItem button onClick={props.openStartChargeDialog} key={2}>
          <ListItemIcon className={props.classes.listItemIcon}>
            <Power fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            <Translate id='chargeboxes.start' />
          </ListItemText>
        </MenuItem>
      ),
      props.chargeboxSelected.connected && props.chargeboxSelected.type.version === '1.6' && props.user.type === 'superuser' && (
        <MenuItem button onClick={handleClick} key='adv.0'>
          <ListItemIcon>
            <SettingsApplications fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            <Translate id='chargeboxes.advanced' />
          </ListItemText>
          {opn_adv ? <ExpandLess /> : <ExpandMore />}
        </MenuItem>
      ),
      props.chargeboxSelected.connected && props.chargeboxSelected.type.version === '1.6' && props.user.type === 'superuser' && (
        <Collapse in={opn_adv} timeout='auto' unmountOnExit key='adv'>
          <MenuItem button onClick={props.openUpgradeFirmwareDialog} key='adv.1'>
            <ListItemIcon className={props.classes.listItemIcon}>
              <SystemUpdateAlt fontSize='small' />
            </ListItemIcon>
            <ListItemText>
              <Translate id='chargeboxes.upgradeFirmware' />
            </ListItemText>
          </MenuItem>
          <MenuItem button onClick={props.openTriggerMessageDialog} key='adv.2'>
            <ListItemIcon className={props.classes.listItemIcon}>
              <Input fontSize='small' />
            </ListItemIcon>
            <ListItemText>
              <Translate id='chargeboxes.triggerMessage' />
            </ListItemText>
          </MenuItem>
          <MenuItem button onClick={props.startGetDiagnostic} key='adv.3'>
            <ListItemIcon className={props.classes.listItemIcon}>
              <AddToQueue fontSize='small' />
            </ListItemIcon>
            <ListItemText>
              <Translate id='chargeboxes.getDiagnostic' />
            </ListItemText>
          </MenuItem>
          <MenuItem button onClick={props.openSendCommandDialog} key={'adv.4'}>
            <ListItemIcon className={props.classes.listItemIcon}>
              <ReplyIcon fontSize='small' />
            </ListItemIcon>
            <ListItemText>
              <Translate id='chargeboxes.sendCommand' />
            </ListItemText>
          </MenuItem>
          {console.log(props)}
          <MenuItem button onClick={props.CallClearCache} key='adv.5'>
            <ListItemIcon className={props.classes.listItemIcon}>
              <SystemUpdateAlt fontSize='small' />
            </ListItemIcon>
            <ListItemText>
              Clear Cache
              {/*<Translate id='chargeboxes.upgradeFirmware' />*/}
            </ListItemText>
          </MenuItem>
        </Collapse>
      ),
      <MenuItem button onClick={props.openResetDialog} key={3}>
        <ListItemIcon className={props.classes.listItemIcon}>
          <AutorenewIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>
          <Translate id='chargeboxes.reset' />
        </ListItemText>
      </MenuItem>,
      <MenuItem button onClick={props.openUnlockDialog} key={4}>
        <ListItemIcon className={props.classes.listItemIcon}>
          <LockOpenIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>
          <Translate id='chargeboxes.unlock' />
        </ListItemText>
      </MenuItem>,
      <MenuItem button onClick={props.toggleStatusDialog} key={5}>
        <ListItemIcon className={props.classes.listItemIcon}>
          <ThumbsUpDownIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>
          <Translate id='chargeboxes.status' />
        </ListItemText>
      </MenuItem>,
      <MenuItem button onClick={props.toggleHeartbeatsDialog} key={6}>
        <ListItemIcon className={props.classes.listItemIcon}>
          <FavoriteBorderIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>
          <Translate id='chargeboxes.heartbeat' />
        </ListItemText>
      </MenuItem>
    );

    if (props.user.type === 'superuser' || props.user.hasPrivileges || props.user.canChangePayment) {
      items.push(
        <MenuItem button onClick={props.openCustomPaymentDialog} key={7}>
          <ListItemIcon className={props.classes.listItemIcon}>
            <PaymentIcon fontSize='small' />
          </ListItemIcon>
          <ListItemText>
            <Translate id='chargeboxes.customPayment' />
          </ListItemText>
        </MenuItem>
      );
    }
  }
  if (props.user.type.toLowerCase() === 'superuser' || props.user.hasPrivileges) {
    items.push(
      <MenuItem button onClick={props.openDeleteDialog} key={8}>
        <ListItemIcon className={props.classes.listItemIcon}>
          <DeleteIcon fontSize='small' />
        </ListItemIcon>
        <ListItemText>
          <Translate id='chargeboxes.delete' />
        </ListItemText>
      </MenuItem>
    );
  }
  return (
    <Menu anchorEl={props.anchorEl} open={props.anchorEl !== null} onClose={props.onClose}>
      {items}
    </Menu>
  );
};

const Row = ({ user, clients, chargebox, openCloseChargeboxMenu, translate, classes }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        {(user.type === 'superuser' || (user.clientType === 'main' && user.clients.length > 0)) && (
          <TableCell className={classes.tableCell}>{clients[chargebox.client]}</TableCell>
        )}
        <TableCell className={classes.tableCell}>{chargebox.id}</TableCell>
        <TableCell className={classes.tableCell}>{chargebox.alias}</TableCell>
        <TableCell className={classes.tableCell}>{chargebox.active ? <CheckIcon color='disabled' /> : <ClearIcon color='disabled' />}</TableCell>
        <TableCell className={classes.tableCell}>{chargebox.public ? <CheckIcon color='disabled' /> : <ClearIcon color='disabled' />}</TableCell>
        <TableCell className={classes.tableCell}>{getOnlineIcon(chargebox, translate)}</TableCell>
        <TableCell className={classes.tableCell}>{getVehicleIcon(chargebox.type.vehicle)}</TableCell>
        <TableCell className={classes.tableCell}>
          <IconButton onClick={openCloseChargeboxMenu}>
            <MoreVertIcon />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell className={classes.collapseContainer} colSpan={99}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' spacing={2} className={classes.collapseContent}>
              {chargebox.type.msg !== 'nc' && (
                <>
                  <Grid item xs={6} sm={4}>
                    <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                      <Translate id='chargeboxes.serialNumber' />
                      {':'}
                    </Typography>
                    <Typography variant='caption' component='span'>
                      {chargebox.serial_number}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                      <Translate id='chargeboxes.model' />
                      {':'}
                    </Typography>
                    <Typography variant='caption' component='span'>
                      {chargebox.model}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                      <Translate id='chargeboxes.firmwareVersion' />
                      {':'}
                    </Typography>
                    <Typography variant='caption' component='span'>
                      {chargebox.firmware_version}
                    </Typography>
                  </Grid>
                  <Grid container item xs={6} sm={4} justifyContent='flex-start' alignItems='center'>
                    <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                      <Translate id='chargeboxes.customPayment' />
                      {':'}
                    </Typography>
                    {chargebox.hasOwnProperty('custom_payment') && chargebox.custom_payment !== null ? (
                      <CheckIcon color='disabled' />
                    ) : (
                      <ClearIcon color='disabled' />
                    )}
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                      <Translate id='chargeboxes.lastHeartbeat' />
                      {':'}
                    </Typography>
                    <Typography variant='caption' component='span'>
                      {getDate(chargebox.last_heartbeat, user.locale)}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sm={4}>
                    <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                      <Translate id='chargeboxes.paymentLink' />
                      {':'}
                    </Typography>
                    <IconButton aria-label='copy' edge='end' size='small' onClick={() => copyToClipboard(`${PaymentLink}?id=${chargebox.id}`)}>
                      <FileCopyIcon fontSize='small' />
                    </IconButton>
                  </Grid>
                </>
              )}
              <Grid item xs={6} sm={4}>
                <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                  <Translate id='chargeboxes.timezone' />
                  {':'}
                </Typography>
                <Typography variant='caption' component='span'>
                  {chargebox.timezone}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Typography variant='caption' component='span' className={classes.collapseItemTitle}>
                  <Translate id='chargeboxes.coordinates' />
                  {':'}
                </Typography>
                <Typography variant='caption' component='span'>
                  {chargebox.coordinates.latitude},{chargebox.coordinates.longitude}
                </Typography>
              </Grid>
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

const Chargeboxes = (props) => {
  const classes = useStyles(props);
  return (
    <Paper elevation={3}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.snackbar !== null} autoHideDuration={6000} onClose={props.onSnackbarClose}>
        <Alert
          elevation={6}
          variant='filled'
          severity='info'
          onClose={props.onSnackbarClose}
          icon={false}
          action={
            <IconButton size='small' aria-label='close' color='inherit' onClick={props.onSnackbarClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          {props.snackbar === null ? '' : <Translate id={`chargeboxes.snackbar.${props.snackbar}`} />}
        </Alert>
      </Snackbar>
      <DialogNewEdit
        handleClose={() => props.closeCreateDialog(false)}
        submit={() => props.closeCreateDialog(true)}
        open={props.createChargeboxOpen}
        onChange={props.onChange}
        chargebox={props.chargeboxToCreate}
        clients={props.clientsList}
        vendors={props.vendors}
        currencies={props.currencies}
        user={props.user}
      />
      <DialogNewEdit
        handleClose={() => props.closeEditDialog(false)}
        submit={() => props.closeEditDialog(true)}
        open={props.editChargeboxOpen}
        onChange={props.onChange}
        chargebox={props.chargeboxSelected}
        clients={props.clientsList}
        vendors={props.vendors}
        user={props.user}
        currencies={props.currencies}
        isEdit
      />
      {props.chargeboxSelected.type.msg === 'json' && props.chargeboxSelected.type.version === '1.6' && (
        <DialogSendCommand handleClose={props.closeSendCommandDialog} open={props.sendCommandOpen} chargebox={props.chargeboxSelected} user={props.user} />
      )}
      <DialogDelete
        handleClose={() => props.closeDeleteDialog(false)}
        submit={() => props.closeDeleteDialog(true)}
        open={props.deleteChargeboxOpen}
        chargebox={props.chargeboxSelected}
      />
      <DialogReset handleClose={props.closeResetDialog} open={props.resetChargeboxOpen} chargebox={props.chargeboxSelected} user={props.user} />
      <DialogStartCharge handleClose={props.closeStartChargeDialog} open={props.startChargeOpen} chargebox={props.chargeboxSelected} user={props.user} />
      <DialogUnlock handleClose={props.closeUnlockDialog} open={props.unlockChargeboxOpen} chargebox={props.chargeboxSelected} user={props.user} />
      <DialogStatus handleClose={props.toggleStatusDialog} open={props.statusChargeboxOpen} chargebox={props.chargeboxSelected} user={props.user} />
      <DialogHeartbeat handleClose={props.toggleHeartbeatsDialog} open={props.heartbeatsOpen} chargebox={props.chargeboxSelected} user={props.user} />
      <DialogStatus handleClose={props.toggleStatusDialog} open={props.statusChargeboxOpen} chargebox={props.chargeboxSelected} user={props.user} />
      <DialogUpgradeFirmware
        handleClose={props.closeUpgradeFirmwareDialog}
        open={props.upgradefirmwareOpen}
        chargebox={props.chargeboxSelected}
        user={props.user}
        updatepath={props.updatepath}
        onChange={props.onChange}
      />
      <DialogTriggerMessage
        handleClose={props.closeTriggerMessageDialog}
        open={props.triggermessageOpen}
        chargebox={props.chargeboxSelected}
        user={props.user}
        command={props.command}
        connector={props.connector}
        onChange={props.onChange}
      />
      <DialogCustomPayment
        handleClose={props.closeCustomPaymentDialog}
        open={props.customPaymentOpen}
        chargebox={props.chargeboxSelected}
        currencies={props.currencies}
      />

      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.tableCell} />
                {(props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) && (
                  <TableCell className={classes.tableCell}>
                    <Translate id='chargeboxes.client' />
                  </TableCell>
                )}
                <TableCell className={classes.tableCell}>
                  <Translate id='chargeboxes.id' />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Translate id='chargeboxes.alias' />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Translate id='chargeboxes.enabled' />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Translate id='chargeboxes.public' />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Translate id='chargeboxes.online' />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Translate id='chargeboxes.vehicle' />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='nowrap'>
                    <Grid item>
                      <IconButton onClick={props.getChargeboxes}>
                        <RefreshIcon />
                      </IconButton>
                      {(props.user.type.toLowerCase() === 'superuser' || props.user.hasPrivileges) && (
                        <IconButton onClick={props.openCreateDialog}>
                          <PlusOneIcon />
                        </IconButton>
                      )}
                    </Grid>
                    <IconButton onClick={props.openCloseFilters}>
                      <FilterListIcon />
                    </IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((cb) => (
                <Row
                  key={cb.id}
                  user={props.user}
                  clients={props.clients}
                  chargebox={cb}
                  openCloseChargeboxMenu={props.openCloseChargeboxMenu(cb)}
                  translate={props.translate}
                  classes={classes}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPageOptions={[10, 20, 50]}
          count={props.count}
          rowsPerPage={props.pagination.rowsPerPage}
          page={props.pagination.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
          labelRowsPerPage={props.translate('table.rowsPerPage')}
          nextIconButtonText={props.translate('table.next')}
          backIconButtonText={props.translate('table.previous')}
        />
        <ChargeboxMenu
          anchorEl={props.chargeboxMenuAnchorEl}
          onClose={props.openCloseChargeboxMenu(null)}
          duplicateChargebox={props.duplicateChargebox}
          openEditDialog={props.openEditDialog}
          chargeboxSelected={props.chargeboxSelected}
          openResetDialog={props.openResetDialog}
          openStartChargeDialog={props.openStartChargeDialog}
          openUnlockDialog={props.openUnlockDialog}
          openSendCommandDialog={props.openSendCommandDialog}
          openUpgradeFirmwareDialog={props.openUpgradeFirmwareDialog}
          openTriggerMessageDialog={props.openTriggerMessageDialog}
          startGetDiagnostic={props.startGetDiagnostic}
          toggleStatusDialog={props.toggleStatusDialog}
          toggleHeartbeatsDialog={props.toggleHeartbeatsDialog}
          user={props.user}
          openCustomPaymentDialog={props.openCustomPaymentDialog}
          openDeleteDialog={props.openDeleteDialog}
          CallClearCache={props.CallClearCache}
          classes={classes}
        />
        <FiltersMenu
          anchorEl={props.filtersMenuAnchorEl}
          onClose={props.openCloseFilters}
          user={props.user}
          filters={props.filters}
          submit={props.getFilteredChargeboxes}
          clients={props.clientsList}
          classes={classes}
        />
      </Grid>
    </Paper>
  );
};
export default withLocalize(Chargeboxes);
