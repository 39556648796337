import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import Lambda from '../../../aws/lambda';

const styles = {
  button: { margin: '1rem' },
};
class ChargeDialogUpdate extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processing: false, connector: null };

    this.changeConnector = this.changeConnector.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  changeConnector(c) {
    return () => this.secureSetState({ connector: c });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.secureSetState({ connector: null });
    }
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let functionName = '';
        if (this.props.chargebox.type.msg === 'json' && this.props.chargebox.type.version === '1.6') {
          functionName = 'OcppJson16UpdateFirmware';
        }
        const now = new Date();
        const date_now = now.toISOString();
        await Lambda.invoke(functionName, {
          chargebox: this.props.chargebox.id,
          user: this.props.user.id,
          location: this.props.updatepath,
          retrieveDate: date_now,
          retries: 3,
          retryInterval: 60,
        });
        this.secureSetState({ processing: false }, () => this.props.handleClose(true, false));
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false }, () => this.props.handleClose(false, false));
      }
    });
  }

  cancel() {
    this.props.handleClose(false, true);
  }

  render() {
    return (
      <Dialog onClose={this.cancel} aria-labelledby='chargebox-dialog-unlock-title' open={this.props.open} fullWidth maxWidth='xs'>
        <DialogTitle id='chargebox-dialog-update-title'>
          <Translate id='chargeboxes.dialogs.update.title' />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Translate id='chargeboxes.dialogs.update.text' />
          </Typography>
          <Typography>{`${this.props.chargebox.id} - ${this.props.chargebox.alias}`}</Typography>
          <Grid item container direction='row' alignItems='center' justifyContent='center' spacing={2}>
            <Grid item xs={12} sm={12}>
              <TextField
                label={this.props.translate('chargeboxes.updatepath')}
                name='updatepath'
                value={this.props.updatepath}
                fullWidth
                onChange={this.props.onChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancel} color='default' autoFocus>
            <Translate id='chargeboxes.dialogs.close' />
          </Button>
          <Button onClick={this.submit} color='primary' disabled={this.props.updatepath === null}>
            <Translate id='chargeboxes.dialogs.start.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(ChargeDialogUpdate));
