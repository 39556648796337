import React from 'react';
import { Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import withStyles from '@material-ui/core/styles/withStyles';
import S3 from '../../../aws/s3';
import Lambda from '../../../aws/lambda';
import { convertDataURIToBinary } from '../../../utilities';

const styles = {
  card: {
    maxWidth: '90vw',
  },
  logo: {
    maxHeight: '6rem',
    maxWidth: '80%',
  },
  input: {
    display: 'none',
  },
};

class CustomizationLogoTerms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      logo: null,
      uploadType: null,
    };
    this.onTermsChange = this.onTermsChange.bind(this);
    this.onLogoChange = this.onLogoChange.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
    this.reader = new FileReader();
    this.reader.onloadend = this.submit;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onTermsChange(e) {
    let files = e.target.files;
    this.secureSetState({ uploadType: 'terms' }, () => this.reader.readAsDataURL(files[0]));
  }

  onLogoChange(e) {
    let files = e.target.files;
    this.secureSetState({ uploadType: 'logo' }, () => this.reader.readAsDataURL(files[0]));
  }

  submit() {
    this.secureSetState({ processing: true }, async () => {
      if (this.state.uploadType === 'logo') {
        try {
          await S3.putLogo(this.props.user.client, convertDataURIToBinary(this.reader.result));
          this.secureSetState({ processing: false, uploadType: null }, () => {
            this.props.showSnackbar('success');
            this.props.update('logo', this.reader.result);
          });
        } catch (err) {
          console.error(err);
          this.secureSetState({ processing: false, uploadType: null }, () => {
            this.props.showSnackbar('logoError');
          });
        }
      } else if (this.state.uploadType === 'terms') {
        try {
          let params = {
            action: 'edit',
            user: this.props.user.id,
            client: {
              id: this.props.user.client,
              last_terms_update: Math.round(Date.now() / 1000),
            },
          };
          await Lambda.invoke('ManageClient', params);
          await S3.putTerms(this.props.user.client, convertDataURIToBinary(this.reader.result));
          this.secureSetState({ processing: false, uploadType: null }, () => {
            this.props.showSnackbar('success');
          });
        } catch (err) {
          console.error(err);
          this.secureSetState({ processing: false, uploadType: null }, () => {
            this.props.showSnackbar('termsError');
          });
        }
      } else {
        this.secureSetState({ processing: false, uploadType: null });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let state = {};
    if (prevProps.logo !== this.props.logo) {
      state.logo = this.props.logo;
    }
    if (Object.keys(state).length > 0) {
      this.secureSetState(state);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.logoTerms.titleTerms' />} subheader={<Translate id='customization.logoTerms.subheaderTerms' />} />
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <Button size='small' component='a' href={S3.getTermsLink(this.props.user.client)} target='_blank' rel='noopener noreferrer'>
              <Translate id='customization.logoTerms.download' />
            </Button>

            <input
              accept='application/pdf'
              className={this.props.classes.input}
              id='terms-button-file'
              type='file'
              onChange={this.onTermsChange}
              disabled={this.state.processing}
            />
            <label htmlFor='terms-button-file'>
              <Button size='small' color='primary' component='span' disabled={this.state.processing}>
                <Translate id='customization.logoTerms.uploadTerms' />
              </Button>
            </label>
          </Grid>
        </CardActions>
        <Divider />
        <CardHeader title={<Translate id='customization.logoTerms.titleLogo' />} />
        <CardContent>
          <Grid container direction='row' alignItems='center' justifyContent='center'>
            {this.state.logo && <img alt='Logo' src={this.state.logo} className={this.props.classes.logo} />}
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <input
              accept='image/*'
              className={this.props.classes.input}
              id='icon-button-file'
              type='file'
              onChange={this.onLogoChange}
              disabled={this.state.processing}
            />
            <label htmlFor='icon-button-file'>
              <Button size='small' color='primary' component='span' disabled={this.state.processing}>
                <Translate id='customization.logoTerms.uploadLogo' />
              </Button>
            </label>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(CustomizationLogoTerms);
