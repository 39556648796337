import { DynamoDB, config as AWSConfig } from 'aws-sdk';

const transactWrite = (transactItems) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TransactItems: transactItems,
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
	};
	console.log(params);
	return DDB.transactWrite(params).promise();
};

export default { transactWrite };
