import React from 'react';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import DynamoDBChargeboxes from '../../../aws/dynamodb/chargeboxes';
import Lambda from '../../../aws/lambda';
import UserContext from '../../../context';
import Clients from './presentation';

class ClientsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      list: [],
      menuAnchorEl: null,
      clientToCreate: {
        clientName: '',
        adminEmail: '',
        adminName: '',
        adminLanguage: '',
        hasPrivileges: true,
      },
      selectedClient: {
        id: '',
        name: '',
      },
      filters: {
        open: false,
        value: '',
      },
      snackbar: null,
      createClientOpen: false,
      editClientOpen: false,
      deleteClientOpen: false,
    };
    this.getClients = this.getClients.bind(this);
    this.openCreateDialog = this.openCreateDialog.bind(this);
    this.closeCreateDialog = this.closeCreateDialog.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openCloseMenu = this.openCloseMenu.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
    this._list = [];
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  toggleFilters() {
    this.secureSetState({ filters: { open: !this.state.filters.open, value: '' }, list: this._list.slice() });
  }

  filterChanged(e) {
    let value = e.target.value;
    this.secureSetState({ filters: { open: true, value: value } });
  }

  filterSearch() {
    if (this.state.processing) {
      return;
    }
    let value = this.state.filters.value.trim().toLowerCase();
    if (value.length === 0) {
      this.secureSetState({ list: this._list.slice() });
      return;
    }
    let list = this._list.filter((el) => {
      if (el.id.toLowerCase().includes(value) || el.name.toLowerCase().includes(value)) {
        return true;
      }
      return false;
    });
    this.secureSetState({ list });
  }

  getClients() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, filtrers: { open: false, value: '' } }, async () => {
      try {
        let records = {
          Items: [],
        };
        if (this.context.type === 'superuser') {
          records = await DynamoDBClients.getAll();
        } else {
          if (this.context.clients.length > 0) {
            let ids = [...this.context.clients];
            ids.pop(ids.indexOf(this.context.client));
            let clients = await DynamoDBClients.batchGet(ids);
            records.Items = records.Items.concat(clients);
          }
        }
        this._list = [];
        for (let r of records.Items) {
          let data = await DynamoDBChargeboxes.getAllCount({ type: this.context.type, client: this.context.client }, { client: r.id });
          this._list.push({ ...r, numChargeboxes: data.Count || 0 });
        }
        this.secureSetState({ list: this._list.slice(), processing: false });
      } catch (err) {
        console.log(err);
        this._list = [];
        this.secureSetState({ list: [], processing: false });
      }
    });
  }

  openCloseMenu(client) {
    return (event) => {
      if (client === null) {
        this.secureSetState({ menuAnchorEl: null, selectedClient: { name: '' } });
      } else {
        this.secureSetState({ menuAnchorEl: event.currentTarget, selectedClient: { ...client } });
      }
    };
  }

  openCreateDialog() {
    this.secureSetState({
      createClientOpen: true,
      clientToCreate: {
        clientName: '',
        adminEmail: '',
        adminName: '',
        adminLanguage: '',
        hasPrivileges: true,
      },
    });
  }

  closeCreateDialog(create) {
    if (!create) {
      this.secureSetState({
        createClientOpen: false,
        clientToCreate: {
          clientName: '',
          adminEmail: '',
          adminName: '',
          adminLanguage: '',
          hasPrivileges: true,
        },
      });
      return;
    }
    if (this.state.processing) {
      return;
    }

    this.secureSetState({ processing: true, snackbar: null }, async () => {
      let { clientName, adminEmail, adminName, adminLanguage, hasPrivileges } = this.state.clientToCreate;
      clientName = clientName.trim();
      adminEmail = adminEmail.trim();
      adminName = adminName.trim();
      adminLanguage = adminLanguage.trim();
      if (clientName.length === 0 || adminEmail.length === 0 || adminName.length === 0 || adminLanguage.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }

      try {
        let clientID = await Lambda.invoke('ManageClient', {
          action: 'add',
          user: this.context.id,
          client: { name: clientName },
        });
        await Lambda.invoke('ManageAdmin', {
          action: 'add',
          requestor: this.context.id,
          user: { email: adminEmail, name: adminName, locale: adminLanguage, has_privileges: hasPrivileges, type: 'client', client: clientID },
        });
        this.secureSetState(
          {
            processing: false,
            createClientOpen: false,
            clientToCreate: {
              clientName: '',
              adminEmail: '',
              adminName: '',
              adminLanguage: '',
              hasPrivileges: true,
            },
            snackbar: 'success',
          },
          this.getClients
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'createErrror' });
      }
    });
  }

  openEditDialog() {
    this.secureSetState({ menuAnchorEl: null, editClientOpen: true });
  }

  closeEditDialog(edit) {
    if (!edit) {
      this.secureSetState({ editClientOpen: false, selectedClient: { id: '', name: '' } });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      let { name, id } = this.state.selectedClient;
      name = name.trim();
      id = id.trim();
      if (name.length === 0 || id.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }

      try {
        await Lambda.invoke('ManageClient', {
          action: 'edit',
          user: this.context.id,
          client: { name, id },
        });
        this.secureSetState(
          {
            processing: false,
            editClientOpen: false,
            selectedClient: { id: '', name: '' },
            snackbar: 'success',
          },
          this.getClients
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'editErrror' });
      }
    });
  }

  openDeleteDialog() {
    this.secureSetState({ menuAnchorEl: null, deleteClientOpen: true });
  }

  closeDeleteDialog(remove) {
    if (!remove) {
      this.secureSetState({ deleteClientOpen: false, selectedClient: { id: '', name: '' } });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        await Lambda.invoke('ManageClient', { action: 'delete', client: this.state.selectedClient.id, user: this.context.id });
        this.secureSetState(
          {
            processing: false,
            deleteClientOpen: false,
            selectedClient: { id: '', name: '' },
            snackbar: 'success',
          },
          this.getClients
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'deleteErrror' });
      }
    });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    if (this.state.createClientOpen) {
      let { clientToCreate } = this.state;
      if (name === 'hasPrivileges') {
        clientToCreate.hasPrivileges = !clientToCreate.hasPrivileges;
      } else {
        clientToCreate[name] = value;
      }
      this.secureSetState({ clientToCreate });
    } else if (this.state.editClientOpen) {
      let { selectedClient } = this.state;
      selectedClient[name] = value;
      this.secureSetState({ selectedClient });
    }
  }

  onSnackbarClose() {
    this.secureSetState({ snackbar: null });
  }

  componentDidMount() {
    this.isMounted = true;
    this.getClients();
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Clients
        {...this.state}
        openCloseMenu={this.openCloseMenu}
        openCreateDialog={this.openCreateDialog}
        closeCreateDialog={this.closeCreateDialog}
        openEditDialog={this.openEditDialog}
        closeEditDialog={this.closeEditDialog}
        openDeleteDialog={this.openDeleteDialog}
        closeDeleteDialog={this.closeDeleteDialog}
        getClients={this.getClients}
        onChange={this.onChange}
        onSnackbarClose={this.onSnackbarClose}
        toggleFilters={this.toggleFilters}
        filterChanged={this.filterChanged}
        filterSearch={this.filterSearch}
        user={this.context}
      />
    );
  }
}

ClientsContainer.contextType = UserContext;
export default ClientsContainer;
