import React from 'react';
import Geohash from 'latlon-geohash';
import DynamoDBChargeboxes from '../../../aws/dynamodb/chargeboxes';
import DynamoDBVendors from '../../../aws/dynamodb/vendors';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import DynamoDBTransactionsRunning from '../../../aws/dynamodb/transactions-running';
import UserContext from '../../../context';
import Chargeboxes from './presentation';
import Lambda from '../../../aws/lambda';

const getTimezone = async (coordinates) => {
  try {
    let response = await fetch(`https://secure.geonames.org//timezoneJSON?lat=${coordinates.latitude}&lng=${coordinates.longitude}&username=thor_chargeboxes`);
    let jsonResp = await response.json();
    if (jsonResp && jsonResp.hasOwnProperty('timezoneId') && jsonResp.timezoneId.length > 0) {
      return jsonResp.timezoneId;
    }
    return null;
  } catch (err) {
    console.log(err);
    return null;
  }
};

const getEmptyChargeboxStruct = (client) => ({
  active: true,
  alias: '',
  text_it: '',
  text_en: '',
  client: client ? { id: client, name: '' } : null,
  connection: null,
  connectors: [],
  coordinates: {
    latitude: 0,
    longitude: 0,
  },
  e015: false,
  e015Params: {
    codice_postale: '',
    comune: '',
    indirizzo: '',
    provincia: '',
    regione: '',
    sostenibilità: false,
    tipologia_indirizzo: '',
  },
  address: 'undefined',
  city: 'undefined',
  country: 'undefined',
  id: '',
  public: true,
  reservable: false,
  type: {
    msg: '',
    vehicle: '',
    version: '',
  },
  vendor: null,
});

const duplicateChargebox = (cb) => ({
  active: true,
  alias: cb.alias,
  text_it: cb.text_it,
  text_en: cb.text_en,
  client: cb.client,
  connection: cb.connection
    ? {
        host: '',
        path: cb.connection.path,
        port: `${cb.connection.port}`,
        type: cb.connection.type,
      }
    : null,
  connectors: cb.connectors.slice(),
  coordinates: {
    latitude: cb.coordinates.latitude,
    longitude: cb.coordinates.longitude,
  },
  address: cb.address,
  city: cb.city,
  e015: cb.e015 || false,
  e015Params: cb.e015Params,
  country: cb.country,
  id: cb.type.msg === 'nc' ? `nc-${Date.now().toString(16)}` : '',
  public: cb.public,
  reservable: cb.reservable,
  type: {
    msg: cb.type.msg,
    vehicle: cb.type.vehicle,
    version: cb.type.version,
  },
  vendor: cb.vendor,
});

const filterAddress = async (array, filter) => {
  let addressesFiltered = array.filter((r) => r.types.includes(filter));
  return addressesFiltered;
};

const updateState = (currentState, name, value) => {
  let connectorID = 0;
  let values = null;
  switch (name) {
    case 'sostenibilità':
      currentState.e015Params[name] = !currentState.e015Params[name];
      break;
    case 'active':
    case 'public':
    case 'reservable':
    case 'e015':
      console.log(currentState[name], name);
      currentState[name] = !currentState[name];
      console.log(currentState[name], name);
      break;
    case 'vehicle':
      currentState.type.vehicle = value;
      break;
    case 'format':
      values = value.split('-');
      if (value === 'nc-nc') {
        currentState.id = `nc-${Date.now().toString(16)}`;
        currentState.public = true;
        currentState.connection = null;
      } else if (value === 'soap-1.5') {
        currentState.connection = { host: '', path: '', port: '', type: '' };
      } else {
        currentState.connection = null;
      }
      currentState.type.msg = values[0];
      currentState.type.version = values[1];
      break;
    case 'add-connector':
      currentState.connectors.push({ power: null, type: '', format: '', power_type: '', max_amperage: null });
      break;
    case 'delete-connector':
      currentState.connectors.splice(value, 1);
      break;
    case 'connector-type':
      values = value.split('-');
      connectorID = parseInt(values[0], 10);
      currentState.connectors[connectorID].type = values[1];
      break;
    case 'connector-format':
      values = value.split('-');
      connectorID = parseInt(values[0], 10);
      currentState.connectors[connectorID].format = values[1];
      break;
    case 'connector-power_type':
      values = value.split('-');
      connectorID = parseInt(values[0], 10);
      currentState.connectors[connectorID].power_type = values[1];
      break;
    case 'connector-max_amperage':
      values = value.split('-');
      connectorID = parseInt(values[0], 10);
      if (/^\d+$/gim.test(values[1])) {
        currentState.connectors[connectorID].max_amperage = parseInt(values[1]);
      } else if (values[1].length === 0) {
        currentState.connectors[connectorID].max_amperage = null;
      }
      break;
    case 'connector-power':
      values = value.split('-');
      connectorID = parseInt(values[0], 10);
      if (/^\d+$/gim.test(values[1])) {
        currentState.connectors[connectorID].power = parseInt(values[1]);
      } else if (values[1].length === 0) {
        currentState.connectors[connectorID].power = null;
      }
      break;
    case 'connector-terminal_id':
      values = value.split('|');
      connectorID = parseInt(values[0], 10);
      currentState.connectors[connectorID].terminal_id = values[1];
      break;
    case 'connector-token':
      values = value.split('|');
      connectorID = parseInt(values[0], 10);
      currentState.connectors[connectorID].token = values[1];
      break;
    case 'connectionType':
      currentState.connection.type = value;
    case 'port':
      if (/^\d*$/gim.test(value)) {
        currentState.connection[name] = value;
      }
      break;
    case 'host':
    case 'path':
      currentState.connection[name] = value;
      break;
    default:
      currentState[name] = value;
      break;
  }
  // Disable the reservable flag for chargeboxes that aren't made by smartbit or that aren't OCPP v1.6 compatible
  if (
    currentState.type.msg !== 'json' ||
    currentState.type.version !== '1.6' ||
    currentState.vendor === null ||
    (currentState.vendor.id !== 'smartbit' && currentState.vendor.id !== 'alfen')
  ) {
    currentState.reservable = false;
  }
  return currentState;
};

class ChargeboxesContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      count: 0,
      list: [],
      vendors: [],
      clients: {},
      clientsList: [],
      chargeboxMenuAnchorEl: null,
      filtersMenuAnchorEl: null,
      chargeboxToCreate: getEmptyChargeboxStruct(null),
      chargeboxSelected: getEmptyChargeboxStruct(null),
      snackbar: null,
      createChargeboxOpen: false,
      editChargeboxOpen: false,
      startChargeOpen: false,
      sendCommandOpen: false,
      resetChargeboxOpen: false,
      unlockChargeboxOpen: false,
      statusChargeboxOpen: false,
      heartbeatsOpen: false,
      customPaymentOpen: false,
      deleteChargeboxOpen: false,
      upgradefirmwareOpen: false,
      connector: 1,
      command: '',
      updatepath: '',
      running: [],
      pagination: {
        page: 0,
        rowsPerPage: 10,
        nextTokens: [],
      },
      filters: {
        client: '',
        vehicle: '',
        status: '',
        alias: '',
      },
    };
    this.getChargeboxes = this.getChargeboxes.bind(this);
    this.getFilteredChargeboxes = this.getFilteredChargeboxes.bind(this);
    this.openCreateDialog = this.openCreateDialog.bind(this);
    this.duplicateChargebox = this.duplicateChargebox.bind(this);
    this.closeCreateDialog = this.closeCreateDialog.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.openSendCommandDialog = this.openSendCommandDialog.bind(this);
    this.closeSendCommandDialog = this.closeSendCommandDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.openResetDialog = this.openResetDialog.bind(this);
    this.openStartChargeDialog = this.openStartChargeDialog.bind(this);
    this.closeStartChargeDialog = this.closeStartChargeDialog.bind(this);
    this.closeResetDialog = this.closeResetDialog.bind(this);
    this.openUnlockDialog = this.openUnlockDialog.bind(this);
    this.closeUnlockDialog = this.closeUnlockDialog.bind(this);
    this.toggleStatusDialog = this.toggleStatusDialog.bind(this);
    this.toggleHeartbeatsDialog = this.toggleHeartbeatsDialog.bind(this);
    this.openCustomPaymentDialog = this.openCustomPaymentDialog.bind(this);
    this.closeCustomPaymentDialog = this.closeCustomPaymentDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openCloseChargeboxMenu = this.openCloseChargeboxMenu.bind(this);
    this.openCloseFilters = this.openCloseFilters.bind(this);
    this.openUpgradeFirmwareDialog = this.openUpgradeFirmwareDialog.bind(this);
    this.closeUpgradeFirmwareDialog = this.closeUpgradeFirmwareDialog.bind(this);
    this.openTriggerMessageDialog = this.openTriggerMessageDialog.bind(this);
    this.closeTriggerMessageDialog = this.closeTriggerMessageDialog.bind(this);
    this.onChange = this.onChange.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this.startGetDiagnostic = this.startGetDiagnostic.bind(this);
    this.CallClearCache = this.CallClearCache.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  handleChangePage(event, newPage) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = newPage;
    this.secureSetState({ pagination }, this.getChargeboxes);
  }

  handleChangeRowsPerPage(event) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    pagination.rowsPerPage = event.target.value;
    this.secureSetState({ pagination }, this.getChargeboxes);
  }

  getFilteredChargeboxes(client, alias, vehicle, status) {
    let { pagination } = this.state;
    pagination.page = 0;
    this.secureSetState(
      {
        filters: { client, vehicle, status, alias },
        filtersMenuAnchorEl: null,
        pagination,
      },
      this.getChargeboxes
    );
  }

  getChargeboxes() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let filters = {};
        if (this.state.filters.alias.length > 0) {
          filters.alias = this.state.filters.alias;
        }
        if (this.state.filters.client.length > 0) {
          filters.client = this.state.filters.client;
        }
        if (this.state.filters.vehicle.length > 0) {
          filters.type = { vehicle: this.state.filters.vehicle };
        }
        if (this.state.filters.status.length > 0) {
          if (this.state.filters.status === 'unknown') {
            filters.connected = null;
          } else {
            filters.connected = this.state.filters.status === 'online';
          }
        }
        let records = await DynamoDBChargeboxes.getAll(
          {
            type: this.context.type,
            client: this.context.client,
          },
          {
            limit: this.state.pagination.rowsPerPage,
            page: this.state.pagination.page,
            nextTokens: this.state.pagination.page === 0 ? [] : this.state.pagination.nextTokens,
          },
          filters
        );
        let count = await DynamoDBChargeboxes.getAllCount({ type: this.context.type, client: this.context.client }, filters);
        let { pagination } = this.state;
        if (records.LastEvaluatedKey) {
          if (pagination.page === 0) {
            pagination.nextTokens = [records.LastEvaluatedKey];
          } else if (pagination.nextTokens.length <= pagination.page) {
            pagination.nextTokens.push(records.LastEvaluatedKey);
          } else {
            pagination.nextTokens[pagination.page] = records.LastEvaluatedKey;
          }
        }
        this.secureSetState({ list: records.Items, count: count.Count, processing: false, pagination });
      } catch (err) {
        console.log(err);
        this.secureSetState({ list: [], count: 0, pagination: { page: 0, rowsPerPage: 10, nextTokens: [] }, processing: false });
      }
    });
  }

  openCloseFilters(event) {
    if (this.state.filtersMenuAnchorEl === null) {
      this.secureSetState({ filtersMenuAnchorEl: event.currentTarget });
    } else {
      this.secureSetState({ filtersMenuAnchorEl: null });
    }
  }

  openCloseChargeboxMenu(chargebox) {
    if (chargebox == null) {
      return () => this.secureSetState({ chargeboxMenuAnchorEl: null, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
    }
    return (event) => {
      try {
        let chargeboxSelected = { ...chargebox };
        let vendor = this.state.vendors.find((v) => v.id === chargeboxSelected.vendor);
        if (vendor !== undefined) {
          chargeboxSelected.vendor = { id: chargeboxSelected.vendor, name: vendor.name };
        } else {
          chargeboxSelected.vendor = { id: chargeboxSelected.vendor, name: '' };
        }

        if (chargeboxSelected.e015Params === undefined) {
          chargeboxSelected.e015Params = {
            codice_postale: '',
            comune: '',
            indirizzo: '',
            provincia: '',
            regione: '',
            sostenibilità: false,
            tipologia_indirizzo: '',
          };
        }
        let nayax = this.state.clientsList.find((v) => v.id === chargeboxSelected.client);
        if (this.state.clients.hasOwnProperty(chargeboxSelected.client)) {
          chargeboxSelected.client = {
            id: chargeboxSelected.client,
            name: this.state.clients[chargeboxSelected.client],
            nayax: nayax.nayax,
          };
        } else {
          chargeboxSelected.client = { id: chargeboxSelected.client, name: '', nayax: false };
        }
        let running = this.state.running.filter((v) => v.chargebox === chargeboxSelected.id);
        if (running !== undefined && running.length > 0) {
          for (let element of running) {
            let connector = element.connector;
            chargeboxSelected.connectors[connector - 1].charging = true;
          }
          chargeboxSelected.running = running;
        } else {
          chargeboxSelected.running = [];
        }
        this.secureSetState({ chargeboxMenuAnchorEl: event.currentTarget, chargeboxSelected });
      } catch (err) {
        console.log(err);
        return null;
      }
    };
  }

  CallClearCache() {
    this.secureSetState({ processing: true, snackbar: null, chargeboxMenuAnchorEl: null }, async () => {
      try {
        let functionName = 'OcppJson16ClearCache';

        console.log('clear cache');
        await Lambda.invoke(functionName, {
          user: this.context.id,
          chargebox: this.state.chargeboxSelected.id,
        });

        this.secureSetState({
          processing: false,
          snackbar: 'success',
        });
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'updateError' });
      }
    });
  }

  duplicateChargebox() {
    let chargebox = { ...this.state.chargeboxSelected };
    for (let c in chargebox.connectors) {
      if (chargebox.connectors[c].hasOwnProperty('charging')) {
        delete chargebox.connectors[c].charging;
      }
    }
    this.secureSetState({
      chargeboxMenuAnchorEl: null,
      createChargeboxOpen: true,
      chargeboxToCreate: duplicateChargebox(chargebox /*this.state.chargeboxSelected*/),
    });
  }

  openCreateDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, createChargeboxOpen: true, chargeboxToCreate: getEmptyChargeboxStruct(this.context.client) });
  }

  closeCreateDialog(create) {
    if (!create) {
      this.secureSetState({ createChargeboxOpen: false, chargeboxToCreate: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.geocoder = new window.google.maps.Geocoder();

    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        let {
          active,
          alias,
          text_it,
          text_en,
          client,
          connection,
          connectors,
          coordinates,
          id,
          e015,
          e015Params,
          public: pblc,
          reservable,
          type,
          vendor,
        } = this.state.chargeboxToCreate;
        id = id.trim();
        alias = alias.trim();
        if (
          id.length === 0 ||
          alias.length === 0 ||
          client === null ||
          client.id.length === 0 ||
          vendor === null ||
          vendor.id.length === 0 ||
          connectors.length === 0 ||
          type.msg.length === 0 ||
          type.vehicle.length === 0 ||
          type.version.length === 0 ||
          coordinates.latitude === 0 ||
          coordinates.longitude === 0
        ) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }

        for (let connector of connectors) {
          if (connector.hasOwnProperty('charging') && connector.charging === true) {
            //delete connectors[connectors.indexOf(connector)].charging;
            delete connector.charging;
          }
        }

        const geohash = Geohash.encode(coordinates.latitude, coordinates.longitude, 3);
        let addresses_ = await this.geocoder.geocode(
          {
            location: {
              lat: coordinates.latitude,
              lng: coordinates.longitude,
            },
          },
          (results, status) => {
            if (status !== 'OK' || !results[0]) {
              return null;
            }
            return results[0];
          }
        );
        let addresses = addresses_.results[0].address_components;
        let addressObj = await filterAddress(addresses, 'route');
        let countryObj = await filterAddress(addresses, 'country');
        let localityObj = await filterAddress(addresses, 'locality');
        let adminLevel3Obj = await filterAddress(addresses, 'administrative_area_level_3');
        let cityObj = localityObj.length > 0 ? localityObj : adminLevel3Obj.length > 0 ? adminLevel3Obj : 'undefined';

        let chargebox = {
          id,
          active,
          public: pblc,
          reservable,
          alias,
          text_it,
          text_en,
          lc_alias: alias.toLowerCase(),
          coordinates,
          address: addressObj && addressObj[0] && addressObj[0].short_name ? addressObj[0].short_name : 'undefined',
          city: cityObj && cityObj[0] && cityObj[0].short_name ? cityObj[0].short_name : 'undefined',
          country: countryObj && countryObj[0] && countryObj[0].short_name ? countryObj[0].short_name : 'undefined',
          connectors,
          client: client.id,
          vendor: vendor.id,
          type: type,
          timezone: await getTimezone(coordinates),
          geohash: geohash,
          date_created: new Date().toISOString(),
          creator: this.context.id,
        };

        if (e015) {
          let postalCodeObj = await filterAddress(addresses, 'postal_code');
          let provinceObj = await filterAddress(addresses, 'administrative_area_level_2');
          let regionObj = await filterAddress(addresses, 'administrative_area_level_1');
          let streetNumberObj = await filterAddress(addresses, 'street_number');

          if (streetNumberObj && streetNumberObj[0] && streetNumberObj[0].short_name) {
            e015Params.indirizzo =
              (addressObj && addressObj[0] && addressObj[0].long_name ? addressObj[0].long_name : 'undefined') + ' ' + streetNumberObj[0].short_name;
          } else {
            e015Params.indirizzo = addressObj && addressObj[0] && addressObj[0].long_name ? addressObj[0].long_name : 'undefined';
          }
          e015Params.codice_postale = postalCodeObj && postalCodeObj[0] && postalCodeObj[0].short_name ? postalCodeObj[0].short_name : 'undefined';
          e015Params.comune = cityObj && cityObj[0] && cityObj[0].short_name ? cityObj[0].short_name : 'undefined';
          e015Params.provincia = provinceObj && provinceObj[0] && provinceObj[0].short_name ? provinceObj[0].short_name : 'undefined';
          e015Params.regione = regionObj && regionObj[0] && regionObj[0].short_name ? regionObj[0].short_name : 'undefined';
          e015Params.tipologia_indirizzo = addressObj && addressObj[0] && addressObj[0].types && addressObj[0].types[0] ? addressObj[0].types[0] : 'undefined';
          e015Params.sostenibilità = e015Params.sostenibilità && e015Params.sostenibilità === true ? 'Vero' : 'Falso';

          chargebox.e015 = e015.toString();
          chargebox.e015Params = e015Params;
        }

        if (type.msg === 'soap' && type.version === '1.5') {
          if (connection === null || connection.type.length === 0 || connection.port.length === 0) {
            this.secureSetState({ processing: false, snackbar: 'mandatory' });
            return;
          }
          chargebox.connection = connection;
          if (connection.path !== null) {
            connection.path = connection.path.replace(/^\/+/gim, '');
            if (connection.path.length > 0) {
              chargebox.connection.path = `/${connection.path}`;
            } else {
              chargebox.connection.path = null;
            }
          }
        } else {
          chargebox.connection = null;
        }

        for (let connector of connectors) {
          if (connector.type.length === 0 || connector.power === null || connector.format === null || connector.max_amperage === 0) {
            this.secureSetState({ processing: false, snackbar: 'mandatory' });
            return;
          }
        }

        await DynamoDBChargeboxes.create(chargebox);
        this.secureSetState(
          {
            processing: false,
            createChargeboxOpen: false,
            chargeboxToCreate: getEmptyChargeboxStruct(this.context.client),
            snackbar: 'success',
          },
          this.getChargeboxes
        );
      } catch (err) {
        console.log(err);
        if (err.code && err.code === 'ConditionalCheckFailedException') {
          this.secureSetState({ processing: false, snackbar: 'duplicate' });
        } else {
          this.secureSetState({ processing: false, snackbar: 'createErrror' });
        }
      }
    });
  }

  openEditDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, editChargeboxOpen: true });
  }

  closeEditDialog(edit) {
    if (!edit) {
      this.secureSetState({ editChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.geocoder = new window.google.maps.Geocoder();
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        let {
          active,
          alias,
          text_it,
          text_en,
          connection,
          connectors,
          coordinates,
          id,
          public: pblc,
          reservable,
          type,
          vendor,
          e015,
          e015Params,
        } = this.state.chargeboxSelected;
        id = id.trim();
        alias = alias.trim();
        if (
          id.length === 0 ||
          alias.length === 0 ||
          vendor === null ||
          vendor.id.length === 0 ||
          connectors.length === 0 ||
          type.msg.length === 0 ||
          type.vehicle.length === 0 ||
          type.version.length === 0 ||
          coordinates.latitude === 0 ||
          coordinates.longitude === 0
        ) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }

        for (let connector of connectors) {
          if (connector.hasOwnProperty('charging') && connector.charging === true) {
            //delete connectors[connectors.indexOf(connector)].charging;
            delete connector.charging;
          }
        }

        const geohash = Geohash.encode(coordinates.latitude, coordinates.longitude, 3);
        let addresses_ = await this.geocoder.geocode(
          {
            location: {
              lat: coordinates.latitude,
              lng: coordinates.longitude,
            },
          },
          async (results, status) => {
            if (status !== 'OK' || !results[0]) {
              return null;
            }
            return results;
          }
        );
        let addresses = addresses_.results[0].address_components;

        console.log(addresses);

        let addressObj = await filterAddress(addresses, 'route');
        let countryObj = await filterAddress(addresses, 'country');
        let localityObj = await filterAddress(addresses, 'locality');
        let adminLevel3Obj = await filterAddress(addresses, 'administrative_area_level_3');
        let cityObj = localityObj.length > 0 ? localityObj : adminLevel3Obj.length > 0 ? adminLevel3Obj : 'undefined';

        let data = {
          sets: {
            active,
            public: pblc,
            reservable,
            alias,
            text_it,
            text_en,
            lc_alias: alias.toLowerCase(),
            coordinates,
            address: addressObj && addressObj[0] && addressObj[0].short_name ? addressObj[0].short_name : 'undefined',
            city: cityObj && cityObj[0] && cityObj[0].short_name ? cityObj[0].short_name : 'undefined',
            country: countryObj && countryObj[0] && countryObj[0].short_name ? countryObj[0].short_name : 'undefined',
            connectors,
            vendor: vendor.id,
            type: type,
            timezone: await getTimezone(coordinates),
            geohash: geohash,
          },
        };

        if (e015) {
          let postalCodeObj = await filterAddress(addresses, 'postal_code');
          let provinceObj = await filterAddress(addresses, 'administrative_area_level_2');
          let regionObj = await filterAddress(addresses, 'administrative_area_level_1');
          let streetNumberObj = await filterAddress(addresses, 'street_number');

          if (streetNumberObj && streetNumberObj[0] && streetNumberObj[0].short_name) {
            e015Params.indirizzo =
              (addressObj && addressObj[0] && addressObj[0].long_name ? addressObj[0].long_name : 'undefined') + ' ' + streetNumberObj[0].short_name;
          } else {
            e015Params.indirizzo = addressObj && addressObj[0] && addressObj[0].long_name ? addressObj[0].long_name : 'undefined';
          }
          e015Params.codice_postale = postalCodeObj && postalCodeObj[0] && postalCodeObj[0].short_name ? postalCodeObj[0].short_name : 'undefined';
          e015Params.comune = cityObj && cityObj[0] && cityObj[0].short_name ? cityObj[0].short_name : 'undefined';
          e015Params.provincia = provinceObj && provinceObj[0] && provinceObj[0].short_name ? provinceObj[0].short_name : 'undefined';
          e015Params.regione = regionObj && regionObj[0] && regionObj[0].short_name ? regionObj[0].short_name : 'undefined';
          e015Params.tipologia_indirizzo = addressObj && addressObj[0] && addressObj[0].types && addressObj[0].types[0] ? addressObj[0].types[0] : 'undefined';
          e015Params.sostenibilità = e015Params.sostenibilità && e015Params.sostenibilità === true ? 'Vero' : 'Falso';

          data.sets.e015 = e015.toString();
          data.sets.e015Params = e015Params;
        }

        if (type.msg === 'soap' && type.version === '1.5') {
          if (connection === null || connection.type.length === 0 || connection.port.length === 0) {
            this.secureSetState({ processing: false, snackbar: 'mandatory' });
            return;
          }
          data.sets.connection = connection;
          if (connection.path !== null) {
            connection.path = connection.path.replace(/^\/+/gim, '');
            if (connection.path.length > 0) {
              data.sets.connection.path = `/${connection.path}`;
            } else {
              data.sets.connection.path = null;
            }
          }
        } else {
          data.sets.connection = null;
        }

        for (let connector of connectors) {
          if (connector.type.length === 0 || connector.power === null || connector.max_amperage === 0 || connector.format === null) {
            this.secureSetState({ processing: false, snackbar: 'mandatory' });
            return;
          }
        }

        if (!data.sets.text_en || data.sets.text_en === null) {
          data.sets.text_en = '';
        }
        if (!data.sets.text_it || data.sets.text_it === null) {
          data.sets.text_it = '';
        }

        await DynamoDBChargeboxes.edit(id, data);
        this.secureSetState(
          {
            processing: false,
            editChargeboxOpen: false,
            chargeboxSelected: getEmptyChargeboxStruct(this.context.client),
            snackbar: 'success',
          },
          this.getChargeboxes
        );
      } catch (err) {
        console.log(err);
        if (err.code && err.code === 'ConditionalCheckFailedException') {
          this.secureSetState({ processing: false, snackbar: 'duplicate' });
        } else {
          this.secureSetState({ processing: false, snackbar: 'editErrror' });
        }
      }
    });
  }

  openResetDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, resetChargeboxOpen: true });
  }

  openStartChargeDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, startChargeOpen: true });
  }

  openSendCommandDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, sendCommandOpen: true });
  }

  closeSendCommandDialog(success, cancel, err = null) {
    if (cancel) {
      this.secureSetState({ sendCommandOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (success) {
      this.secureSetState(
        { sendCommandOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client), snackbar: 'success' },
        this.getChargeboxes
      );
    } else {
      this.secureSetState({ snackbar: err });
    }
  }

  closeResetDialog(success, cancel) {
    if (cancel) {
      this.secureSetState({ resetChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (success) {
      this.secureSetState({ resetChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client), snackbar: 'success' });
    } else {
      this.secureSetState({ resetChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client), snackbar: 'resetError' });
    }
  }

  closeStartChargeDialog(success, cancel) {
    if (cancel) {
      this.secureSetState({ startChargeOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (success) {
      this.secureSetState(
        { startChargeOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client), snackbar: 'success' },
        this.getChargeboxes
      );
    } else {
      this.secureSetState(
        { startChargeOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client), snackbar: 'startError' },
        this.getChargeboxes
      );
    }
  }

  openUnlockDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, unlockChargeboxOpen: true });
  }

  closeUnlockDialog(success, cancel) {
    if (cancel) {
      this.secureSetState({ unlockChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (success) {
      this.secureSetState({ unlockChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client), snackbar: 'success' });
    } else {
      this.secureSetState({ unlockChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client), snackbar: 'unlockError' });
    }
  }

  toggleStatusDialog() {
    let { statusChargeboxOpen } = this.state;
    this.secureSetState({ chargeboxMenuAnchorEl: null, statusChargeboxOpen: !statusChargeboxOpen });
  }

  toggleHeartbeatsDialog() {
    let { heartbeatsOpen } = this.state;
    this.secureSetState({ chargeboxMenuAnchorEl: null, heartbeatsOpen: !heartbeatsOpen });
  }

  openCustomPaymentDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, customPaymentOpen: true });
  }

  closeCustomPaymentDialog(edit, customPayment) {
    if (!edit) {
      this.secureSetState({ customPaymentOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        let { id } = this.state.chargeboxSelected;
        id = id.trim();

        if (id.length === 0) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }

        let data = {};

        if (customPayment === null) {
          data.removes = ['custom_payment'];
        } else {
          data.sets = { custom_payment: customPayment };
        }

        await DynamoDBChargeboxes.edit(id, data);
        this.secureSetState(
          {
            processing: false,
            customPaymentOpen: false,
            chargeboxSelected: getEmptyChargeboxStruct(this.context.client),
            snackbar: 'success',
          },
          this.getChargeboxes
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'customPaymentErrror' });
      }
    });
  }

  openDeleteDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, deleteChargeboxOpen: true });
  }

  closeDeleteDialog(remove) {
    if (!remove) {
      this.secureSetState({ deleteChargeboxOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        await DynamoDBChargeboxes.remove(this.state.chargeboxSelected.id);
        this.secureSetState(
          {
            processing: false,
            deleteChargeboxOpen: false,
            chargeboxSelected: getEmptyChargeboxStruct(this.context.client),
            snackbar: 'success',
          },
          this.getChargeboxes
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'deleteErrror' });
      }
    });
  }

  openUpgradeFirmwareDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, upgradefirmwareOpen: true });
  }

  closeUpgradeFirmwareDialog(remove) {
    if (!remove) {
      this.secureSetState({ upgradefirmwareOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        let functionName = '';
        if (this.state.chargeboxSelected.type.msg === 'json' && this.state.chargeboxSelected.type.version === '1.6') {
          functionName = 'OcppJson16UpdateFirmware';
          const now = new Date();
          const date_now = now.toISOString();
          await Lambda.invoke(functionName, {
            chargebox: this.state.chargeboxSelected.id,
            user: this.context.id,
            location: this.state.updatepath,
            retrieveDate: date_now,
            retries: 3,
            retryInterval: 60,
          });
        }

        this.secureSetState({
          processing: false,
          upgradefirmwareOpen: false,
          chargeboxSelected: getEmptyChargeboxStruct(this.context.client),
          snackbar: 'success',
        });
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'updateError' });
      }
    });
  }

  openTriggerMessageDialog() {
    this.secureSetState({ chargeboxMenuAnchorEl: null, triggermessageOpen: true });
  }

  closeTriggerMessageDialog(remove, close) {
    if (!remove) {
      if (!close) {
        this.secureSetState({ snackbar: 'mandatory' });
        return;
      }
      this.secureSetState({ triggermessageOpen: false, chargeboxSelected: getEmptyChargeboxStruct(this.context.client) });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      //let connettore = this.state.connector;
      try {
        /*let functionName = '';*/

        if (this.state.chargeboxSelected.type.msg === 'json' && this.state.chargeboxSelected.type.version === '1.6') {
          //functionName = 'OcppJson16TriggerMessage';
          /*await Lambda.invoke(functionName, {
						chargebox: this.state.chargeboxSelected.id,
						user: this.context.id,
						requestedMessage: this.state.command,
						connector: connettore,
					});*/
          this.secureSetState({
            processing: false,
            triggermessageOpen: false,
            chargeboxSelected: getEmptyChargeboxStruct(this.context.client),
            snackbar: 'success',
          });
        }
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'updateError' });
      }
    });
  }

  startGetDiagnostic() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        let functionName = '';
        if (this.state.chargeboxSelected.type.msg === 'json' && this.state.chargeboxSelected.type.version === '1.6') {
          functionName = 'OcppJson16GetDiagnostics';
          await Lambda.invoke(functionName, {
            chargebox: this.state.chargeboxSelected.id,
            location: 'https://api.chargeboxes.smartbit.io/latest/logs/',
            retries: 3,
            retryInterval: 60,
          });
        }

        this.secureSetState({
          processing: false,
          chargeboxSelected: getEmptyChargeboxStruct(this.context.client),
          snackbar: 'success',
        });
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'updateError' });
      }
    });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    if (this.state.createChargeboxOpen) {
      let { chargeboxToCreate } = this.state;
      chargeboxToCreate = updateState(chargeboxToCreate, name, value);
      this.secureSetState({ chargeboxToCreate });
    } else if (this.state.editChargeboxOpen) {
      let { chargeboxSelected } = this.state;
      chargeboxSelected = updateState(chargeboxSelected, name, value);
      this.secureSetState({ chargeboxSelected });
    } else if (this.state.upgradefirmwareOpen) {
      this.secureSetState({ updatepath: value });
      return;
    } else if (this.state.triggermessageOpen) {
      this.secureSetState({ command: value });
      return;
    }
  }

  onSnackbarClose() {
    this.secureSetState({ snackbar: null });
  }

  async componentDidMount() {
    this.isMounted = true;
    try {
      let state = {};
      let vendors = await DynamoDBVendors.getAll();
      state.vendors = vendors.Items;
      let running;
      if (this.state.filters.client.length > 0) {
        running = await DynamoDBTransactionsRunning.getByClient(this.state.filters.client, true);
      } else {
        running = await DynamoDBTransactionsRunning.getAll(true);
      }
      state.running = running.Items;
      if (this.context.type === 'superuser') {
        let clients = await DynamoDBClients.getAll();
        state.clients = {};
        state.clientsList = [];
        for (let c of clients.Items) {
          state.clients[c.id] = c.name;
          state.clientsList.push({
            id: c.id,
            name: c.name,
            public: c.public,
            nayax: c.payment_methods.nayax ? c.payment_methods.nayax.enabled : false,
          });
        }
      } else if (this.context.clientType === 'main' && this.context.clients.length > 0) {
        let clients = await DynamoDBClients.batchGet(this.context.clients);
        state.clients = {};
        state.clientsList = [];
        for (let c of clients) {
          state.clients[c.id] = c.name;
          state.clientsList.push({
            id: c.id,
            name: c.name,
            public: c.public,
            nayax: c.payment_methods.nayax ? c.payment_methods.nayax.enabled : false,
          });
        }
      } else {
        let clients = {
          Items: [],
        };
        let client = await DynamoDBClients.get(this.context.client);
        clients.Items.push(client.Item);
        state.clients = {};
        state.clientsList = [];
        for (let c of clients.Items) {
          state.clients[c.id] = c.name;
          state.clientsList.push({
            id: c.id,
            name: c.name,
            public: c.public,
            nayax: c.payment_methods.nayax ? c.payment_methods.nayax.enabled : false,
          });
        }
      }
      let clients = await DynamoDBClients.getCurrency();
      let arrayCurrency = [];
      for (let client of clients.Items) {
        arrayCurrency[client.id] = client.payment_limit.currency;
      }
      this.secureSetState({ currencies: arrayCurrency });
      this.secureSetState(state, this.getChargeboxes);
    } catch (err) {
      console.log(err);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Chargeboxes
        {...this.state}
        user={this.context}
        openCloseChargeboxMenu={this.openCloseChargeboxMenu}
        openCloseFilters={this.openCloseFilters}
        duplicateChargebox={this.duplicateChargebox}
        openCreateDialog={this.openCreateDialog}
        closeCreateDialog={this.closeCreateDialog}
        openEditDialog={this.openEditDialog}
        openSendCommandDialog={this.openSendCommandDialog}
        closeSendCommandDialog={this.closeSendCommandDialog}
        closeEditDialog={this.closeEditDialog}
        openResetDialog={this.openResetDialog}
        openStartChargeDialog={this.openStartChargeDialog}
        closeStartChargeDialog={this.closeStartChargeDialog}
        closeResetDialog={this.closeResetDialog}
        openUnlockDialog={this.openUnlockDialog}
        closeUnlockDialog={this.closeUnlockDialog}
        openUpgradeFirmwareDialog={this.openUpgradeFirmwareDialog}
        closeUpgradeFirmwareDialog={this.closeUpgradeFirmwareDialog}
        openTriggerMessageDialog={this.openTriggerMessageDialog}
        closeTriggerMessageDialog={this.closeTriggerMessageDialog}
        toggleStatusDialog={this.toggleStatusDialog}
        toggleHeartbeatsDialog={this.toggleHeartbeatsDialog}
        openDeleteDialog={this.openDeleteDialog}
        closeCustomPaymentDialog={this.closeCustomPaymentDialog}
        openCustomPaymentDialog={this.openCustomPaymentDialog}
        closeDeleteDialog={this.closeDeleteDialog}
        getChargeboxes={this.getChargeboxes}
        getFilteredChargeboxes={this.getFilteredChargeboxes}
        onChange={this.onChange}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        onSnackbarClose={this.onSnackbarClose}
        startGetDiagnostic={this.startGetDiagnostic}
        CallClearCache={this.CallClearCache}
      />
    );
  }
}

ChargeboxesContainer.contextType = UserContext;
export default ChargeboxesContainer;
