import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_endusers`;

const get = (id) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    Key: { id: id },
    ReturnConsumedCapacity: 'NONE',
  };

  return DDB.get(params).promise();
};

const getByEmail = async (email) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    //FilterExpression: '#email = :email',
    FilterExpression: 'contains(#email, :email)',
    ExpressionAttributeNames: { '#email': 'email' },
    ExpressionAttributeValues: { ':email': email.toLowerCase() },
    ReturnConsumedCapacity: 'NONE',
  };
  console.log('Params', params);

  let data,
    found = false;
  do {
    data = await DDB.scan(params).promise();
    if (data.Count === 0) {
      params.ExclusiveStartKey = data.LastEvaluatedKey;
      continue;
    } else {
      found = true;
    }
  } while (!found && data.LastEvaluatedKey);
  if (data.Count === 0) {
    return null;
  }
  return data.Items[0];
};

const batchGet = async (ids) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });

  let params = {
    RequestItems: {},
    ReturnConsumedCapacity: 'NONE',
  };
  params.RequestItems[TableName] = {
    Keys: ids.map((id) => ({ id: id })),
  };
  let data = await DDB.batchGet(params).promise();
  return data.Responses[TableName];
};

export default {
  get,
  getByEmail,
  batchGet,
};
