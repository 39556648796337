import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_clients`;

const get = (id) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Key: { id },
		ReturnConsumedCapacity: 'NONE',
	};

	return DDB.get(params).promise();
};

const getAll = (paginationOptions) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		ReturnConsumedCapacity: 'NONE',
	};

	if (paginationOptions) {
		params.Limit = paginationOptions.limit;
		if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
			params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
		}
	}
	return DDB.scan(params).promise();
};

const getCurrency = () => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		ProjectionExpression: '#id, #payment_limit',
		ExpressionAttributeNames: {
			'#id': 'id',
			'#payment_limit': 'payment_limit',
		},
		ReturnConsumedCapacity: 'NONE',
	};

	return DDB.scan(params).promise();
};

const getAllCount = () => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		ReturnConsumedCapacity: 'NONE',
		Select: 'COUNT',
	};
	return DDB.scan(params).promise();
};

const _update = (id, updateExpression, expressionAttributeNames, expressionAttributeValues) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Key: { id },
		ConditionExpression: 'attribute_exists(#id)',
		ExpressionAttributeNames: {},
		UpdateExpression: updateExpression,
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};

	if (expressionAttributeNames !== null) {
		params.ExpressionAttributeNames = expressionAttributeNames;
	}

	if (expressionAttributeValues !== null) {
		params.ExpressionAttributeValues = expressionAttributeValues;
	}
	params.ExpressionAttributeNames['#id'] = 'id';

	return DDB.update(params).promise();
};

const deletePaymentMethod = (id, method) => {
	let updateExpression = 'remove #payment_methods.#method';
	let expressionAttributeNames = { '#payment_methods': 'payment_methods', '#method': method };
	let expressionAttributeValues = null;
	return _update(id, updateExpression, expressionAttributeNames, expressionAttributeValues);
};

const batchGet = async (ids, fields) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		RequestItems: {},
		ReturnConsumedCapacity: 'NONE',
	};
	params.RequestItems[TableName] = {
		Keys: ids.map((id) => ({ id: id })),
	};
	if (fields && fields.length > 0) {
		params.RequestItems[TableName].ProjectionExpression = fields
			.map((f) =>
				f
					.split('.')
					.map((sf) => `#${sf}`)
					.join('.')
			)
			.join(', ');
		params.RequestItems[TableName].ExpressionAttributeNames = fields.reduce((acc, f) => {
			f.split('.').forEach((sf) => (acc[`#${sf}`] = sf));
			return acc;
		}, {});
	}
	let data = await DDB.batchGet(params).promise();
	return data.Responses[TableName];
};

export default {
	get,
	getAll,
	getAllCount,
	deletePaymentMethod,
	getCurrency,
	batchGet,
};
