import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Popover from '@material-ui/core/Popover';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Select from '@material-ui/core/Select';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import RefreshIcon from '@material-ui/icons/Refresh';
import RemoveIcon from '@material-ui/icons/Remove';
import SearchIcon from '@material-ui/icons/Search';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import DialogAddRemoveBudget from './presentation.dialog.addremovebudget';
import DialogNewEdit from './presentation.dialog.newedit';
import DialogDelete from './presentation.dialog.delete';
import DialogInfiniteOn from './presentation.dialog.infiniteOn';

const useStyles = makeStyles({
  filtersContainer: {
    padding: '1rem',
  },
  listItemIcon: {
    minWidth: '2rem',
  },
});

const FiltersMenu = (props) => {
  let [client, setClient] = React.useState('');
  let [id, setID] = React.useState('');
  let [useAliasEmail, setUseAliasEmail] = React.useState('alias');
  let [aliasEmail, setAliasEmail] = React.useState('');

  React.useEffect(() => {
    if (props.anchorEl === null) {
      setClient(props.filters.client);
      setID(props.filters.id);
      setUseAliasEmail(props.filters.useAliasEmail);
      setAliasEmail(props.filters.aliasEmail);
    }
  }, [props.anchorEl, props.filters.client, props.filters.id, props.filters.useAliasEmail, props.filters.aliasEmail]);

  return (
    <Popover
      anchorEl={props.anchorEl}
      open={props.anchorEl !== null}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ className: props.classes.filtersContainer }}
    >
      <Grid container spacing={3} direction='column'>
        <Grid item>
          <Typography color='textSecondary'>
            <Translate id='filter' />
          </Typography>
        </Grid>
        {(props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) && (
          <Grid item>
            <FormControl fullWidth>
              <InputLabel id='filter-client'>
                <Translate id='rfids.client' />
              </InputLabel>
              <Select labelId='filter-client' value={client} onChange={(e) => setClient(e.target.value)}>
                {Object.keys(props.clients).map((id) => (
                  <MenuItem value={id} key={id}>
                    {props.clients[id]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item>
          <TextField label={<Translate id='rfids.id' />} value={id} onChange={(e) => setID(e.target.value.toLowerCase())} fullWidth />
        </Grid>
        <Grid item>
          <RadioGroup aria-label='use alias or email' value={useAliasEmail} onChange={(e) => setUseAliasEmail(e.target.value)}>
            <FormControlLabel value='alias' control={<Radio color='primary' />} label={<Translate id='rfids.filters.useAlias' />} />
            <FormControlLabel value='email' control={<Radio color='primary' />} label={<Translate id='rfids.filters.useEmail' />} />
          </RadioGroup>
        </Grid>
        <Grid item>
          <TextField
            label={useAliasEmail === 'alias' ? <Translate id='rfids.alias' /> : <Translate id='rfids.email' />}
            value={aliasEmail}
            onChange={(e) => setAliasEmail(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item container direction='row' alignItems='center' justifyContent='center'>
          <Button
            onClick={() => {
              setClient('');
              setID('');
              setUseAliasEmail('alias');
              setAliasEmail('');
            }}
          >
            <Translate id='rfids.filters.clear' />
          </Button>
          <Button color='primary' onClick={() => props.submit(client, id, useAliasEmail, aliasEmail)}>
            <Translate id='rfids.filters.submit' />
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

const clientCurrency = (curr) => {
  let currency = '';
  switch (curr) {
    case 'chf':
      currency = 'chf';
      break;
    case 'eur':
    default:
      currency = '€';
      break;
  }
  return currency;
};

const Rfids = (props) => {
  const classes = useStyles(props);
  let currency;
  if (props.currency) {
    currency = clientCurrency(props.currency);
  } else if (props.rfidToCreate && props.rfidToCreate.client && props.rfidToCreate.client.currency) {
    currency = clientCurrency(props.rfidToCreate.client.currency);
  } else if (props.selectedRfid.currency) {
    currency = clientCurrency(props.selectedRfid.currency);
  } else if (props.filters.client) {
    currency = clientCurrency(props.clientsCurrency[props.filters.client]);
  } else {
    currency = '';
  }

  return (
    <Paper elevation={3}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.snackbar !== null} autoHideDuration={6000} onClose={props.onSnackbarClose}>
        <Alert
          elevation={6}
          variant='filled'
          severity='info'
          onClose={props.onSnackbarClose}
          icon={false}
          action={
            <IconButton size='small' aria-label='close' color='inherit' onClick={props.onSnackbarClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          {props.snackbar === null ? '' : <Translate id={`rfids.snackbar.${props.snackbar}`} />}
        </Alert>
      </Snackbar>
      <DialogNewEdit
        handleClose={() => props.closeCreateDialog(false)}
        submit={() => props.closeCreateDialog(true)}
        open={props.createRfidOpen}
        onChange={props.onChange}
        rfid={props.rfidToCreate}
        clients={props.clientsList}
        user={props.user}
        currency={currency}
      />
      <DialogNewEdit
        handleClose={() => props.closeEditDialog(false)}
        submit={() => props.closeEditDialog(true)}
        open={props.editRfidOpen}
        onChange={props.onChange}
        rfid={props.selectedRfid}
        clients={props.clientsList}
        user={props.user}
        currency={currency}
        isEdit
      />
      <DialogInfiniteOn
        list={props.list}
        handleClose={() => props.closeInfiniteOnDialog(false)}
        submit={() => props.closeInfiniteOnDialog(true)}
        open={props.infiniteOnOpen}
        onChange={props.onChange}
        rfid={props.selectedRfid}
        user={props.user}
        users={props.users}
        classes={classes}
      />
      <DialogAddRemoveBudget
        handleClose={() => props.closeAddRemoveBudgetDialog(null)}
        submit={props.closeAddRemoveBudgetDialog}
        open={props.addBudgetOpen || props.removeBudgetOpen}
        rfid={props.selectedRfid}
        users={props.users}
        action={props.removeBudgetOpen ? 'remove' : 'add'}
        currency={currency}
      />
      <DialogDelete
        handleClose={() => props.closeDeleteDialog(false)}
        submit={() => props.closeDeleteDialog(true)}
        open={props.deleteRfidOpen}
        rfid={props.selectedRfid}
        users={props.users}
      />
      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableHead>
              <TableRow>
                {(props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) && (
                  <TableCell>
                    <Translate id='rfids.client' />
                  </TableCell>
                )}
                <TableCell>
                  <Translate id='rfids.id' />
                </TableCell>
                <TableCell>{<Translate id='rfids.aliasUser' />}</TableCell>
                <TableCell>
                  <Translate id='rfids.active' />
                </TableCell>
                <TableCell>
                  <Translate id='rfids.budget' />
                </TableCell>
                <TableCell>
                  <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='nowrap'>
                    <Grid item>
                      <IconButton onClick={props.getRfids}>
                        <RefreshIcon />
                      </IconButton>
                      {(props.user.type.toLowerCase() === 'superuser' || props.user.hasPrivileges) && (
                        <IconButton onClick={props.openCreateDialog}>
                          <PlusOneIcon />
                        </IconButton>
                      )}
                    </Grid>
                    <IconButton onClick={props.openCloseFilters}>
                      <FilterListIcon />
                    </IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((row) => (
                <TableRow key={row.client + row.id}>
                  {(props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) && (
                    <TableCell>{props.clients[row.client]}</TableCell>
                  )}
                  <TableCell>{row.id}</TableCell>
                  <TableCell>
                    {row.user === null ? (
                      row.alias
                    ) : (
                      <React.Fragment>
                        <Typography variant='body2' component='p'>
                          {props.users[row.user].name || props.users[row.user].company_name}
                        </Typography>
                        <Typography variant='caption' component='p'>
                          {props.users[row.user].email}
                        </Typography>
                      </React.Fragment>
                    )}
                  </TableCell>
                  <TableCell>
                    <Checkbox
                      checked={row.active}
                      onClick={() => props.enableDisableRfid(row.id, row.client, row.active)}
                      color='primary'
                      disabled={props.user.type.toLowerCase() === 'superuser' || props.user.hasPrivileges ? false : true}
                    />
                  </TableCell>
                  <TableCell>
                    {row.budget === null ? (
                      <Typography variant='h5' color='textSecondary'>
                        &#8734;
                      </Typography>
                    ) : (
                      `${Math.round(row.budget.amount * 100) / 100} ${currency ? currency : clientCurrency(props.clientsCurrency[row.client])}`
                    )}
                  </TableCell>
                  <TableCell>
                    <IconButton onClick={props.openCloseMenu(row)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPageOptions={[10, 20, 50]}
          count={props.count}
          rowsPerPage={props.pagination.rowsPerPage}
          page={props.pagination.page}
          onPageChange={props.handleChangePage}
          onRowsPerPageChange={props.handleChangeRowsPerPage}
          labelRowsPerPage={props.translate('table.rowsPerPage')}
          nextIconButtonText={props.translate('table.next')}
          backIconButtonText={props.translate('table.previous')}
        />
        <Menu anchorEl={props.menuAnchorEl} open={props.menuAnchorEl !== null} onClose={props.openCloseMenu(null)}>
          <MenuItem button onClick={props.openEditDialog}>
            <ListItemIcon className={classes.listItemIcon}>
              <SearchIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit' noWrap>
              <Translate id='rfids.details' />
            </Typography>
          </MenuItem>
          {props.infiniteOnDialogShow && (
            <MenuItem button onClick={props.openInfiniteOnDialog}>
              <ListItemIcon className={classes.listItemIcon}>
                <MoneyOffIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit' noWrap>
                <Translate id='rfids.infiniteOn' />
              </Typography>
            </MenuItem>
          )}
          {props.user.type === 'client' &&
            (props.user.hasPrivileges || props.user.canChangePayment) &&
            props.selectedRfid.budget && [
              <MenuItem button onClick={props.openAddBudgetDialog} key={0}>
                <ListItemIcon className={classes.listItemIcon}>
                  <AddIcon fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit' noWrap>
                  <Translate id='rfids.addBudget' />
                </Typography>
              </MenuItem>,
              <MenuItem button onClick={props.openRemoveBudgetDialog} key={1}>
                <ListItemIcon className={classes.listItemIcon}>
                  <RemoveIcon fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit' noWrap>
                  <Translate id='rfids.removeBudget' />
                </Typography>
              </MenuItem>,
            ]}
          {(props.user.type.toLowerCase() === 'superuser' || props.user.hasPrivileges) && (
            <MenuItem button onClick={props.openDeleteDialog}>
              <ListItemIcon className={classes.listItemIcon}>
                <DeleteIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit' noWrap>
                <Translate id='rfids.delete' />
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Grid>
      <FiltersMenu
        anchorEl={props.filtersMenuAnchorEl}
        onClose={props.openCloseFilters}
        user={props.user}
        filters={props.filters}
        submit={props.getFilteredRFIDs}
        clients={props.clients}
        classes={classes}
      />
    </Paper>
  );
};
export default withLocalize(Rfids);
