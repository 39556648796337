import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_vendors`;

const create = (data) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Item: data,
		ConditionExpression: 'attribute_not_exists(#id)',
		ExpressionAttributeNames: {
			'#id': 'id',
		},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};

	return DDB.put(params).promise();
};

const edit = (id, data) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
			TableName: TableName,
			Key: {
				id: id,
			},
			ConditionExpression: 'attribute_exists(#id)',
			UpdateExpression: 'set ',
			ExpressionAttributeNames: { '#id': 'id' },
			ExpressionAttributeValues: {},
			ReturnConsumedCapacity: 'NONE',
			ReturnItemCollectionMetrics: 'NONE',
			ReturnValues: 'NONE',
		},
		updates = [];

	Object.keys(data).forEach((field) => {
		updates.push(`#${field} = :${field}`);
		params.ExpressionAttributeNames[`#${field}`] = field;
		params.ExpressionAttributeValues[`:${field}`] = data[field];
	});

	params.UpdateExpression += updates.join(', ');
	return DDB.update(params).promise();
};

const remove = (id) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Key: {
			id: id,
		},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};

	return DDB.delete(params).promise();
};

const getAll = (paginationOptions) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		ReturnConsumedCapacity: 'NONE',
	};

	if (paginationOptions) {
		params.Limit = paginationOptions.limit;
		if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
			params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
		}
	}
	return DDB.scan(params).promise();
};

const getAllCount = () => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		ReturnConsumedCapacity: 'NONE',
		Select: 'COUNT',
	};
	return DDB.scan(params).promise();
};

export default {
	create,
	edit,
	remove,
	getAll,
	getAllCount,
};
