import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({ container: { height: '100vh' } });

const Loading = (props) => {
  const classes = useStyles(props);
  return (
    <Grid container direction='column' justifyContent='center' alignItems='center' className={classes.container}>
      <CircularProgress size={48} />
    </Grid>
  );
};

export default Loading;
