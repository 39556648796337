import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_chargeboxes_status`;

const getAll = (chargebox, connector, timestamp, scanIndexForward, paginationOptions) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		KeyConditionExpression: '',
		ExpressionAttributeNames: {},
		ExpressionAttributeValues: {},
		ScanIndexForward: scanIndexForward,
		ReturnConsumedCapacity: 'NONE',
	};

	if (connector === null) {
		params.IndexName = 'chargebox-timestamp-index';
		params.KeyConditionExpression = '#chargebox = :chargebox';
		params.ExpressionAttributeNames['#chargebox'] = 'chargebox';
		params.ExpressionAttributeValues[':chargebox'] = chargebox;
	} else {
		params.KeyConditionExpression = '#id = :id';
		params.ExpressionAttributeNames['#id'] = 'id';
		params.ExpressionAttributeValues[':id'] = `${chargebox}__${connector}`;
	}

	if (timestamp !== null && Object.keys(timestamp).length > 0) {
		params.KeyConditionExpression += ' and #timestamp between :timestamp_start and :timestamp_end';
		params.ExpressionAttributeNames['#timestamp'] = 'timestamp';
		params.ExpressionAttributeValues[':timestamp_start'] = timestamp.start;
		params.ExpressionAttributeValues[':timestamp_end'] = timestamp.end;
	}

	if (paginationOptions) {
		params.Limit = paginationOptions.limit;
		if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
			params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
			if (connector === null) {
				params.ExclusiveStartKey.chargebox = paginationOptions.nextTokens[paginationOptions.page - 1].chargebox;
			}
		}
	}

	return DDB.query(params).promise();
};

const getAllCount = (chargebox, connector, timestamp) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		KeyConditionExpression: '',
		ExpressionAttributeNames: {},
		ExpressionAttributeValues: {},
		ReturnConsumedCapacity: 'NONE',
		Select: 'COUNT',
	};

	if (connector === null) {
		params.IndexName = 'chargebox-timestamp-index';
		params.KeyConditionExpression = '#chargebox = :chargebox';
		params.ExpressionAttributeNames['#chargebox'] = 'chargebox';
		params.ExpressionAttributeValues[':chargebox'] = chargebox;
	} else {
		params.KeyConditionExpression = '#id = :id';
		params.ExpressionAttributeNames['#id'] = 'id';
		params.ExpressionAttributeValues[':id'] = `${chargebox}__${connector}`;
	}

	if (timestamp !== null && Object.keys(timestamp).length > 0) {
		params.KeyConditionExpression += ' and #timestamp between :timestamp_start and :timestamp_end';
		params.ExpressionAttributeNames['#timestamp'] = 'timestamp';
		params.ExpressionAttributeValues[':timestamp_start'] = timestamp.start;
		params.ExpressionAttributeValues[':timestamp_end'] = timestamp.end;
	}

	return DDB.query(params).promise();
};

export default {
	getAll,
	getAllCount,
};
