import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Lambda from '../../../aws/lambda';

const commandsList = [
  'AuthorizationCacheEnabled',
  'MeterValueSampleInterval',
  'MeterValuesAlignedData',
  'StopTransactionOnInvalid',
  'GetConfiguration',
  'log.debug',
  'log.powerDebug',
  'ocpp.server.url',
  'ocpp.server.port',
  'ocpp.server.path',
  'AuthorizeRemoteTxRequests',
];

const commandsValuesList = ['bool', 'int', 'string', 'bool', '', 'bool', 'bool', 'string', 'int', 'string', 'bool'];

const styles = {
  button: { margin: '1rem' },
};
class ChargeDialogStart extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processing: false, key_name: null, value: '', custom: false };

    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.secureSetState({ connector: null });
    }
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let functionName = 'OcppJson16SendCommand';
        let params = {
          user: this.props.user.id,
          chargebox: this.props.chargebox.id,
          ocpp_command: 'ChangeConfiguration',
          key_name: this.state.key_name,
          value: this.state.value || null,
        };

        if (this.state.key_name === 'GetConfiguration') {
          params.ocpp_command = this.state.key_name;
          delete params.key_name;
          delete params.value;
        }
        if (this.state.key_name === null || this.state.key_name === '') {
          throw 'KeyNameMandatory';
        }
        switch (commandsValuesList[commandsList.indexOf(this.state.key_name)]) {
          case 'int':
            if (!/^\d+$/.test(this.state.value)) {
              throw 'sendErrorInt';
            }
            break;
          case 'bool':
            if (this.state.value !== 'true' && this.state.value !== 'false') {
              throw 'sendErrorBool';
            }
            break;
          default:
            break;
        }
        await Lambda.invoke(functionName, params);
        this.secureSetState({ processing: false }, () => this.props.handleClose(true, false));
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false }, () => this.props.handleClose(false, false, err));
      }
    });
  }

  cancel() {
    this.props.handleClose(false, true);
  }

  render() {
    return (
      <Dialog onClose={this.cancel} aria-labelledby='chargebox-dialog-unlock-title' open={this.props.open} fullWidth maxWidth='xs'>
        <DialogTitle id='chargebox-dialog-start-title'>
          <Translate id='chargeboxes.dialogs.command.title' />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Translate id='chargeboxes.dialogs.command.text' />
          </Typography>
          <Typography>{`${this.props.chargebox.id} - ${this.props.chargebox.alias}`}</Typography>
          <br></br>
          <FormControlLabel
            control={
              <Checkbox
                color='primary'
                onChange={() => {
                  this.secureSetState({ custom: !this.state.custom });
                }}
              ></Checkbox>
            }
            label={this.props.translate('chargeboxes.dialogs.command.custom')}
          />
          <Grid container direction='row' alignItems='center' justifyContent='center' wrap='wrap'>
            {this.state.custom === false && (
              <>
                <Typography>
                  <label>{this.props.translate('chargeboxes.dialogs.command.commandName')}:</label>
                </Typography>
                <Autocomplete
                  options={commandsList}
                  getOptionSelected={(o, t) => o === t}
                  openOnFocus
                  autoHighlight
                  fullWidth
                  onChange={(event, newValue) => {
                    if (newValue === 'GetConfiguration') {
                      this.secureSetState({ key_name: newValue, value: '' });
                    }
                    if (commandsValuesList[commandsList.indexOf(newValue)] === 'bool') {
                      this.secureSetState({ key_name: newValue, value: 'false' });
                    } else {
                      this.secureSetState({ key_name: newValue });
                    }
                  }}
                  renderInput={(params) => <TextField {...params} label={commandsList[commandsList.indexOf(this.value)]} />}
                />
              </>
            )}
            {this.state.custom === true && (
              <>
                <TextField
                  fullWidth
                  label={this.props.translate('chargeboxes.dialogs.command.commandName')}
                  value={this.state.key_name}
                  onChange={(event) => {
                    if (event.target.value === 'GetConfiguration') {
                      this.secureSetState({ key_name: event.target.value, value: '' });
                    }
                    if (commandsValuesList[commandsList.indexOf(event.target.value)] === 'bool') {
                      this.secureSetState({ key_name: event.target.value, value: 'false' });
                    } else {
                      this.secureSetState({ key_name: event.target.value });
                    }
                  }}
                ></TextField>
              </>
            )}
            {commandsValuesList[commandsList.indexOf(this.state.key_name)] === 'bool' ? (
              <>
                <Button
                  className={this.props.classes.button}
                  onClick={() => {
                    this.secureSetState({ value: 'true' });
                  }}
                  color={this.state.value === 'true' ? 'primary' : 'default'}
                  variant='contained'
                >
                  <Translate id='chargeboxes.dialogs.command.true'></Translate>
                </Button>
                <Button
                  className={this.props.classes.button}
                  onClick={() => {
                    this.secureSetState({ value: 'false' });
                  }}
                  color={this.state.value === 'false' ? 'primary' : 'default'}
                  variant='contained'
                >
                  <Translate id='chargeboxes.dialogs.command.false'></Translate>
                </Button>
              </>
            ) : (
              <>
                <TextField
                  disabled={this.state.key_name === 'GetConfiguration' ? true : false}
                  fullWidth
                  type={
                    commandsValuesList[commandsList.indexOf(this.state.key_name)] === 'int' ||
                    commandsValuesList[commandsList.indexOf(this.state.key_name)] === 'float'
                      ? 'number'
                      : null
                  }
                  label={this.props.translate('chargeboxes.dialogs.command.value')}
                  value={this.state.value}
                  onChange={(e) => {
                    this.secureSetState({ value: e.target.value });
                  }}
                ></TextField>
              </>
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancel} color='default' autoFocus>
            <Translate id='chargeboxes.dialogs.close' />
          </Button>
          <Button onClick={this.submit} color='primary' disabled={this.state.connector === null}>
            <Translate id='chargeboxes.dialogs.command.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(ChargeDialogStart));
