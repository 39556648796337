import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import ApexChart from 'react-apexcharts';
import { withLocalize, Translate } from 'react-localize-redux';

const StatusChart = ({ chargeboxes, status, running, translate }) => {
	let options = {
		chart: {
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			redrawOnParentResize: true,
		},
		dataLabels: {
			enabled: true,
			style: {
				colors: ['#333333'],
			},
		},
		labels: [translate('home.status.online'), translate('home.status.offline')],
		fill: {
			colors: ['#71BA51', '#CF000F'],
		},

		colors: ['#71BA51', '#CF000F'],
	};
	let series = [status.online || 0, status.offline || 0];

	return (
		<>
			<Typography variant="h5" color="textPrimary">
				<Translate id="home.status.connectionTitle" />
			</Typography>
			<ApexChart type="donut" width="100%" options={options} series={series} />

			<Typography variant="h5" color="textPrimary">
				<Translate id="home.status.runningTitle" />
			</Typography>
			{running.length > 0 ? (
				<TableContainer>
					<Table aria-label="table" size="small">
						<TableHead>
							<TableRow>
								<TableCell>
									<Translate id="home.status.chargebox" />
								</TableCell>
								<TableCell>
									<Translate id="home.status.connector" />
								</TableCell>
								<TableCell>
									<Translate id="home.status.energy" />
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{running.map((tr, idx) => (
								<TableRow key={idx}>
									<TableCell>{chargeboxes[tr.chargebox]}</TableCell>
									<TableCell>{tr.connector}</TableCell>
									<TableCell>{tr.energy}</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<Typography variant="body1">
					<Translate id="home.status.noRunning" />
				</Typography>
			)}
		</>
	);
};

export default withLocalize(StatusChart);
