import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
  buttonsContainer: {
    marginBottom: '1rem',
  },
  buttonRow: {
    marginTop: '1rem',
  },
};

const MinValues = { eur: 0.5, usd: 0.5, gbp: 0.3, chf: 0.5 };

class CustomizationPaymentType extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      paymentType: {
        bicycle: { options: { unit: '', currency: '', amount: '', theshold_hour: '', start_charge: '' }, type: '' },
        car: { options: { unit: '', currency: '', amount: '', theshold_hour: '', start_charge: '' }, type: '' },
      },
    };
    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
    this.submitEnabled = this.submitEnabled.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let paymentType = JSON.parse(JSON.stringify(this.state.paymentType));
    let { name, value } = e.target;
    let nameParts = name.split('-');
    if (nameParts[1] === 'type') {
      paymentType[nameParts[0]].type = value;
      switch (value) {
        case 'free':
          paymentType[nameParts[0]].options = {};
          break;
        case 'time-based':
          paymentType[nameParts[0]].options = { start_charge: '' };
          break;
        case 'consume-based':
          paymentType[nameParts[0]].options = { unit: '', currency: 'eur', amount: '', theshold_hour: '', start_charge: '' };
          break;
        case 'flat':
          paymentType[nameParts[0]].options = { currency: 'eur', amount: '', minimum_threshold: '' };
          break;

        default:
          break;
      }
    } else if (nameParts[1] === 'minimum_threshold') {
      if (value.length === 0) {
        paymentType[nameParts[0]].options[nameParts[1]] = null;
      } else if (/^\d+$/gim.test(value)) {
        paymentType[nameParts[0]].options[nameParts[1]] = parseInt(value, 10);
      }
    } else {
      paymentType[nameParts[0]].options[nameParts[1]] = value;
    }
    this.secureSetState({ paymentType });
  }

  submitEnabled(vehicle) {
    switch (this.state.paymentType[vehicle].type) {
      case 'free':
        return true;
      case 'time-based':
        return (
          this.state.paymentType[vehicle].options.hasOwnProperty('unit') &&
          //this.state.paymentType[vehicle].options.hasOwnProperty('currency') &&
          this.state.paymentType[vehicle].options.hasOwnProperty('amount') &&
          this.state.paymentType[vehicle].options.unit.length > 0 &&
          this.state.paymentType[vehicle].options.hasOwnProperty('start_charge') &&
          //this.state.paymentType[vehicle].options.currency.length > 0 &&
          /^\d+(\.\d+)?$/gim.test(this.state.paymentType[vehicle].options.amount) &&
          /^\d+(\.\d+)?$/gim.test(this.state.paymentType[vehicle].options.start_charge)
        );
      case 'consume-based':
        let result =
          this.state.paymentType[vehicle].options.hasOwnProperty('unit') &&
          //this.state.paymentType[vehicle].options.hasOwnProperty('currency') &&
          this.state.paymentType[vehicle].options.hasOwnProperty('amount') &&
          this.state.paymentType[vehicle].options.unit.length > 0 &&
          this.state.paymentType[vehicle].options.hasOwnProperty('theshold_hour') &&
          this.state.paymentType[vehicle].options.hasOwnProperty('start_charge') &&
          //this.state.paymentType[vehicle].options.currency.length > 0 &&
          /^\d+(\.\d+)?$/gim.test(this.state.paymentType[vehicle].options.amount) &&
          /^\d+(\.\d+)?$/gim.test(this.state.paymentType[vehicle].options.theshold_hour) &&
          /^\d+(\.\d+)?$/gim.test(this.state.paymentType[vehicle].options.start_charge);
        console.log('RESULT', result, this.state.paymentType[vehicle].options);
        return result;

      case 'flat':
        return (
          //this.state.paymentType[vehicle].options.hasOwnProperty('currency') &&
          this.state.paymentType[vehicle].options.hasOwnProperty('amount') &&
          this.state.paymentType[vehicle].options.hasOwnProperty('minimum_threshold') &&
          //this.state.paymentType[vehicle].options.currency.length > 0 &&
          /^\d+(\.\d+)?$/gim.test(this.state.paymentType[vehicle].options.amount) &&
          /^\d+(\.\d+)?$/gim.test(this.state.paymentType[vehicle].options.minimum_threshold) &&
          MinValues[this.state.paymentType[vehicle].options.currency] <= parseFloat(this.state.paymentType[vehicle].options.amount) &&
          this.state.paymentType[vehicle].options.minimum_threshold !== null
        );
      default:
        return false;
    }
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    console.log('Payment Type', this.props.paymentType);
    this.secureSetState({
      paymentType: JSON.parse(JSON.stringify(this.props.paymentType)),
    });
  }

  submit(vehicle) {
    if (this.state.processing) {
      return;
    }

    let paymentType = JSON.parse(JSON.stringify(this.props.paymentType));

    /*if (
      paymentType[vehicle].options.hasOwnProperty('amount') ||
      paymentType[vehicle].options.hasOwnProperty('theshold_hour') ||
      paymentType[vehicle].options.hasOwnProperty('start_charge')
    ) {
      this.secureSetState({ processing: false }, () => {
        this.props.showSnackbar('paymentTypeError');
      });
      return;
    }*/
    this.secureSetState({ processing: true }, async () => {
      try {
        console.log('Vehicle', vehicle);
        paymentType[vehicle] = JSON.parse(JSON.stringify(this.state.paymentType[vehicle]));
        if (paymentType[vehicle].options.hasOwnProperty('amount')) {
          paymentType[vehicle].options.amount = parseFloat(paymentType[vehicle].options.amount);
        }
        if (paymentType[vehicle].options.hasOwnProperty('theshold_hour')) {
          paymentType[vehicle].options.theshold_hour = parseFloat(paymentType[vehicle].options.theshold_hour);
        }
        if (paymentType[vehicle].options.hasOwnProperty('start_charge')) {
          paymentType[vehicle].options.start_charge = parseFloat(paymentType[vehicle].options.start_charge);
        }
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            payment_type: paymentType,
          },
        };

        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('payment_type', paymentType);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('paymentTypeError');
        });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let paymentType = JSON.parse(JSON.stringify(this.state.paymentType));
    if (
      // Bicycle
      prevProps.paymentType.bicycle.type !== this.props.paymentType.bicycle.type ||
      prevProps.paymentType.bicycle.options.unit !== this.props.paymentType.bicycle.options.unit ||
      prevProps.paymentType.bicycle.options.currency !== this.props.paymentType.bicycle.options.currency ||
      prevProps.paymentType.bicycle.options.amount !== this.props.paymentType.bicycle.options.amount ||
      prevProps.paymentType.bicycle.options.theshold_hour !== this.props.paymentType.bicycle.options.theshold_hour ||
      prevProps.paymentType.bicycle.options.start_charge !== this.props.paymentType.bicycle.options.start_charge ||
      prevProps.paymentType.bicycle.options.minimum_threshold !== this.props.paymentType.bicycle.options.minimum_threshold ||
      // Car
      prevProps.paymentType.car.type !== this.props.paymentType.car.type ||
      prevProps.paymentType.car.options.unit !== this.props.paymentType.car.options.unit ||
      prevProps.paymentType.car.options.currency !== this.props.paymentType.car.options.currency ||
      prevProps.paymentType.car.options.amount !== this.props.paymentType.car.options.amount ||
      prevProps.paymentType.car.options.theshold_hour !== this.props.paymentType.car.options.theshold_hour ||
      prevProps.paymentType.car.options.start_charge !== this.props.paymentType.car.options.start_charge ||
      prevProps.paymentType.car.options.minimum_threshold !== this.props.paymentType.car.options.minimum_threshold
    ) {
      paymentType.bicycle = JSON.parse(JSON.stringify(this.props.paymentType.bicycle));
      paymentType.car = JSON.parse(JSON.stringify(this.props.paymentType.car));
      if (paymentType.bicycle.options.hasOwnProperty('amount')) {
        paymentType.bicycle.options.amount = paymentType.bicycle.options.amount.toString();
      }
      if (paymentType.car.options.hasOwnProperty('amount')) {
        paymentType.car.options.amount = paymentType.car.options.amount.toString();
      }
      if (paymentType.bicycle.options.hasOwnProperty('theshold_hour')) {
        paymentType.bicycle.options.theshold_hour = paymentType.bicycle.options.theshold_hour.toString();
      }
      if (paymentType.car.options.hasOwnProperty('theshold_hour')) {
        paymentType.car.options.theshold_hour = paymentType.car.options.theshold_hour.toString();
      }
      if (paymentType.bicycle.options.hasOwnProperty('start_charge')) {
        paymentType.bicycle.options.start_charge = paymentType.bicycle.options.start_charge.toString();
      }
      if (paymentType.car.options.hasOwnProperty('start_charge')) {
        paymentType.car.options.start_charge = paymentType.car.options.start_charge.toString();
      }
      this.secureSetState({ paymentType });
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  getAccordion(vehicle) {
    const types = ['free', 'time-based', 'consume-based', 'flat'];
    let fields = null;
    let currency = '€';
    switch (this.props.currency) {
      case 'chf':
        currency = 'chf';
        break;
      case 'eur':
      default:
        currency = '€';
        break;
    }
    switch (this.state.paymentType[vehicle].type) {
      case 'time-based':
        fields = (
          <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
            <FormControl>
              <InputLabel id='payment-type-time-unit'>
                <Translate id='paymentTypes.timeUnit' />
              </InputLabel>
              <Select
                labelId='payment-type-time-unit'
                name={`${vehicle}-unit`}
                value={this.state.paymentType[vehicle].options.unit || ''}
                onChange={this.onChange}
              >
                <MenuItem value='min'>
                  <Translate id='paymentTypes.minute' />
                </MenuItem>
                <MenuItem value='hour'>
                  <Translate id='paymentTypes.hour' />
                </MenuItem>
              </Select>
            </FormControl>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <Input
                  label={this.props.translate('paymentTypes.amount')}
                  value={this.state.paymentType[vehicle].options.amount || ''}
                  name={`${vehicle}-amount`}
                  onChange={this.onChange}
                  fullWidth
                  startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='payment-type-start_charge'>
                    <Translate id='paymentTypes.start_charge' />
                  </InputLabel>
                  <Input
                    label={this.props.translate('paymentTypes.start_charge')}
                    value={this.state.paymentType[vehicle].options.start_charge || ''}
                    name={`${vehicle}-start_charge`}
                    onChange={this.onChange}
                    fullWidth
                    startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                  />
                </FormControl>
                <Typography variant='caption'>
                  <Translate id='paymentTypes.start_charge1' />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
        break;
      case 'consume-based':
        fields = (
          <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
            <FormControl>
              <InputLabel id='payment-type-consume-unit'>
                <Translate id='paymentTypes.consumeUnit' />
              </InputLabel>
              <Select
                labelId='payment-type-consume-unit'
                name={`${vehicle}-unit`}
                value={this.state.paymentType[vehicle].options.unit || ''}
                onChange={this.onChange}
              >
                <MenuItem value='wh'>
                  <Translate id='paymentTypes.wh' />
                </MenuItem>
                <MenuItem value='kwh'>
                  <Translate id='paymentTypes.kwh' />
                </MenuItem>
              </Select>
            </FormControl>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <Input
                  label={this.props.translate('paymentTypes.amount')}
                  value={this.state.paymentType[vehicle].options.amount || ''}
                  name={`${vehicle}-amount`}
                  onChange={this.onChange}
                  fullWidth
                  startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                />
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='payment-type-theshold_hour'>
                    <Translate id='paymentTypes.theshold_hour' />
                  </InputLabel>
                  <Input
                    label={this.props.translate('paymentTypes.theshold_hour')}
                    value={this.state.paymentType[vehicle].options.theshold_hour || ''}
                    name={`${vehicle}-theshold_hour`}
                    onChange={this.onChange}
                    fullWidth
                    startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                  />
                </FormControl>
                <Typography variant='caption'>
                  <Translate id='paymentTypes.theshold_hour1' />
                </Typography>
              </Grid>
            </Grid>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='payment-type-start_charge'>
                    <Translate id='paymentTypes.start_charge' />
                  </InputLabel>
                  <Input
                    label={this.props.translate('paymentTypes.start_charge')}
                    value={this.state.paymentType[vehicle].options.start_charge || ''}
                    name={`${vehicle}-start_charge`}
                    onChange={this.onChange}
                    fullWidth
                    startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                  />
                </FormControl>
                <Typography variant='caption'>
                  <Translate id='paymentTypes.start_charge1' />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        );
        break;
      case 'flat':
        fields = (
          <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
            <Grid container direction='row' spacing={2}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id='payment-type-currencty'>
                    <Translate id='paymentTypes.currency' />
                  </InputLabel>
                  <Select
                    labelId='payment-type-currencty'
                    name={`${vehicle}-currency`}
                    value={this.state.paymentType[vehicle].options.currency || ''}
                    onChange={this.onChange}
                    fullWidth
                    disabled
                  >
                    <MenuItem value='eur'>€</MenuItem>
                    <MenuItem value='chf'>chf</MenuItem>
                    {/* <MenuItem value="gbp">£</MenuItem>
										<MenuItem value="usd">$</MenuItem> */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={this.props.translate('paymentTypes.amount')}
                  value={this.state.paymentType[vehicle].options.amount || ''}
                  name={`${vehicle}-amount`}
                  onChange={this.onChange}
                  fullWidth
                  startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
                />
              </Grid>
            </Grid>
            <TextField
              label={this.props.translate('paymentTypes.minimumThreshold')}
              value={this.state.paymentType[vehicle].options.minimum_threshold || ''}
              name={`${vehicle}-minimum_threshold`}
              onChange={this.onChange}
              fullWidth
            />
          </Grid>
        );
        break;

      default:
        break;
    }

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${vehicle}-content`} id={`panel-${vehicle}-header`}>
          <Typography>
            <Translate id={`vehicles.${vehicle}`} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction='column'>
            <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2} className={this.props.classes.buttonsContainer}>
              {types.map((t, i) => (
                <Grid item key={i}>
                  <Button
                    onClick={() => this.onChange({ target: { name: `${vehicle}-type`, value: t } })}
                    variant='contained'
                    color={this.state.paymentType[vehicle].type === t ? 'primary' : 'default'}
                  >
                    <Translate id={`paymentTypes.${t}`} />
                  </Button>
                </Grid>
              ))}
            </Grid>
            {fields}
            <Grid container direction='row' alignItems='center' justifyContent='center' className={this.props.classes.buttonRow}>
              <Button
                size='small'
                color='primary'
                onClick={() => {
                  this.submit(vehicle);
                }}
                disabled={!this.submitEnabled(vehicle)}
              >
                <Translate id='customization.paymentMethods.edit' />
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.paymentType.title' />} subheader={<Translate id='customization.paymentType.subheader' />} />
        <CardContent>
          {this.getAccordion('bicycle')}
          {this.getAccordion('car')}
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <Button size='small' onClick={this.reset}>
              <Translate id='customization.reset' />
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationPaymentType));
