import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_reservations`;

const get = (chargebox, reservationId) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    Key: { chargebox: chargebox, reservation_id: reservationId },
    ReturnConsumedCapacity: 'NONE',
  };

  return DDB.get(params).promise();
};

const getAll = async (requestor, filters, paginationOptions) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    ReturnConsumedCapacity: 'NONE',
    ScanIndexForward: false,
    ExpressionAttributeNames: {},
    ExpressionAttributeValues: {},
  };
  let conditionExpressions = [];

  if (paginationOptions) {
    params.Limit = paginationOptions.limit;
    if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
      params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
    }
  }

  if (filters) {
    if (filters.chargebox) {
      params.IndexName = 'chargebox-dateaccepted-index';
      conditionExpressions.push('#chargebox = :chargebox');
      params.ExpressionAttributeNames['#chargebox'] = 'chargebox';
      params.ExpressionAttributeValues[':chargebox'] = filters.chargebox;
    }
    if (filters.dateFrom && filters.dateTo) {
      conditionExpressions.push('#date_accepted between :from and :to');
      params.ExpressionAttributeNames['#date_accepted'] = 'date_accepted';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    } else if (filters.dateFrom && !filters.dateTo) {
      conditionExpressions.push('#date_accepted >= :from');
      params.ExpressionAttributeNames['#date_accepted'] = 'date_accepted';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
    } else if (!filters.dateFrom && filters.dateTo) {
      conditionExpressions.push('#date_accepted <= :to');
      params.ExpressionAttributeNames['#date_accepted'] = 'date_accepted';
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    }

    if (Array.isArray(filters.status) && filters.status.length > 0) {
      if (filters.status.length === 1) {
        params.FilterExpression = '#status = :status';
        params.ExpressionAttributeNames['#status'] = 'status';
        params.ExpressionAttributeValues[':status'] = filters.status[0];
      } else {
        let status = [];
        params.ExpressionAttributeNames['#status'] = 'status';
        for (let i = 0; i < filters.status.length; i++) {
          let statusValue = `:status${i}`;
          status.push(`#status = :status${i}`);
          params.ExpressionAttributeValues[statusValue] = filters.status[i];
        }
        params.FilterExpression = `(${status.join(' or ')})`;
      }
    }
  }

  if (!params.hasOwnProperty('IndexName')) {
    params.IndexName = 'client-dateaccepted-index';
    conditionExpressions.push('#client = :client');
    params.ExpressionAttributeNames['#client'] = 'client';
    params.ExpressionAttributeValues[':client'] = requestor.client;
  }

  if (Object.keys(params.ExpressionAttributeNames).length === 0) {
    delete params.ExpressionAttributeNames;
  }

  if (Object.keys(params.ExpressionAttributeValues).length === 0) {
    delete params.ExpressionAttributeValues;
  }

  if (params.hasOwnProperty('IndexName')) {
    if (conditionExpressions.length > 0) {
      params.KeyConditionExpression = conditionExpressions.join(' and ');
    }
    if (params.hasOwnProperty('FilterExpression')) {
      delete params.Limit;
    }
    let data = await DDB.query(params).promise();
    if (paginationOptions) {
      let ret = {
        Items: data.Items.slice(0, paginationOptions.limit),
        LastEvaluatedKey:
          data.Items.length > paginationOptions.limit
            ? {
                chargebox: data.Items[paginationOptions.limit - 1].chargebox,
                reservation_id: data.Items[paginationOptions.limit - 1].reservation_id,
                client: params.IndexName.startsWith('client') ? data.Items[paginationOptions.limit - 1].client : undefined,
                date_accepted: data.Items[paginationOptions.limit - 1].date_accepted,
              }
            : null,
      };
      return ret;
    } else {
      return data;
    }
  }

  if (conditionExpressions.length > 0) {
    if (params.hasOwnProperty('FilterExpression')) {
      params.FilterExpression += `and ${conditionExpressions.join(' and ')}`;
    } else {
      params.FilterExpression = conditionExpressions.join(' and ');
    }
    delete params.Limit;
    let data = await DDB.scan(params).promise();
    if (paginationOptions) {
      let ret = {
        Items: data.Items.slice(0, paginationOptions.limit),
        LastEvaluatedKey:
          data.Items.length > paginationOptions.limit
            ? {
                chargebox: data.Items[paginationOptions.limit - 1].chargebox,
                reservation_id: data.Items[paginationOptions.limit - 1].reservation_id,
              }
            : null,
      };
      return ret;
    } else {
      return data;
    }
  } else {
    return DDB.scan(params).promise();
  }
};

const getAllCount = (requestor, filters) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    ReturnConsumedCapacity: 'NONE',
    ExpressionAttributeNames: {},
    ExpressionAttributeValues: {},
    Select: 'COUNT',
  };
  let conditionExpressions = [];

  if (filters) {
    if (filters.chargebox !== null) {
      params.IndexName = 'chargebox-dateaccepted-index';
      conditionExpressions.push('#chargebox = :chargebox');
      params.ExpressionAttributeNames = { '#chargebox': 'chargebox' };
      params.ExpressionAttributeValues = { ':chargebox': filters.chargebox };
    }

    if (filters.dateFrom !== null && filters.dateTo !== null) {
      conditionExpressions.push('#date_accepted between :from and :to');
      params.ExpressionAttributeNames['#date_accepted'] = 'date_accepted';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    } else if (filters.dateFrom !== null && filters.dateTo === null) {
      conditionExpressions.push('#date_accepted >= :from');
      params.ExpressionAttributeNames['#date_accepted'] = 'date_accepted';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
    } else if (filters.dateFrom === null && filters.dateTo !== null) {
      conditionExpressions.push('#date_accepted <= :to');
      params.ExpressionAttributeNames['#date_accepted'] = 'date_accepted';
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    }

    if (Array.isArray(filters.status) && filters.status.length > 0) {
      if (filters.status.length === 1) {
        params.FilterExpression = '#status = :status';
        params.ExpressionAttributeNames['#status'] = 'status';
        params.ExpressionAttributeValues[':status'] = filters.status[0];
      } else {
        let status = [];
        params.ExpressionAttributeNames['#status'] = 'status';
        for (let i = 0; i < filters.status.length; i++) {
          let statusValue = `:status${i}`;
          status.push(`#status = :status${i}`);
          params.ExpressionAttributeValues[statusValue] = filters.status[i];
        }
        params.FilterExpression = `(${status.join(' or ')})`;
      }
    }
  }

  if (!params.hasOwnProperty('IndexName') && requestor.type !== 'superuser') {
    params.IndexName = 'client-dateaccepted-index';
    conditionExpressions.push('#client = :client');
    params.ExpressionAttributeNames['#client'] = 'client';
    params.ExpressionAttributeValues[':client'] = requestor.client;
  }

  if (Object.keys(params.ExpressionAttributeNames).length === 0) {
    delete params.ExpressionAttributeNames;
  }

  if (Object.keys(params.ExpressionAttributeValues).length === 0) {
    delete params.ExpressionAttributeValues;
  }
  if (params.hasOwnProperty('IndexName')) {
    if (conditionExpressions.length > 0) {
      params.KeyConditionExpression = conditionExpressions.join(' and ');
    }
    return DDB.query(params).promise();
  }

  if (conditionExpressions.length > 0) {
    if (params.hasOwnProperty('FilterExpression')) {
      params.FilterExpression += `and ${conditionExpressions.join(' and ')}`;
    } else {
      params.FilterExpression = conditionExpressions.join(' and ');
    }
  }
  return DDB.scan(params).promise();
};

export default {
  get,
  getAll,
  getAllCount,
};
