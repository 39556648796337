import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
  buttonRow: {
    marginTop: '1rem',
  },
};

class CustomizationPaymentMethods extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      paymentMethods: {
        paypal: { client_id: '', client_secret: '', enabled: false, exists: false },
        stripe: { enabled: false, publishable_key: '', secret_key: '', exists: false },
        nayax: { terminal_id: '', token: '', enabled: false, exists: false },
      },
    };
    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
    this.submitEnabled = this.submitEnabled.bind(this);
    this.submit = this.submit.bind(this);
    this.delete = this.delete.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let paymentMethods = JSON.parse(JSON.stringify(this.state.paymentMethods));
    let { name, value } = e.target;
    let nameParts = name.split('-');
    if (nameParts[1] === 'enabled') {
      paymentMethods[nameParts[0]][nameParts[1]] = !paymentMethods[nameParts[0]][nameParts[1]];
    } else {
      paymentMethods[nameParts[0]][nameParts[1]] = value;
    }
    this.secureSetState({ paymentMethods });
  }

  submitEnabled(method) {
    switch (method) {
      case 'stripe':
        if (!this.state.paymentMethods.stripe.enabled) {
          return true;
        }
        return this.state.paymentMethods.stripe.publishable_key.length > 0 && this.state.paymentMethods.stripe.secret_key.length > 0;
      case 'paypal':
        if (!this.state.paymentMethods.paypal.enabled) {
          return true;
        }
        return this.state.paymentMethods.paypal.client_secret.length > 0 && this.state.paymentMethods.paypal.client_id.length > 0;
      case 'nayax':
        if (!this.state.paymentMethods.nayax.enabled) {
          return true;
        }
        return this.state.paymentMethods.nayax && this.state.paymentMethods.nayax.enabled;
      default:
        return false;
    }
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({
      paymentMethods: JSON.parse(JSON.stringify(this.props.paymentMethods)),
    });
  }

  delete(method) {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let paymentMethods = JSON.parse(JSON.stringify(this.props.paymentMethods));
        switch (method) {
          case 'stripe':
            paymentMethods.stripe = { enabled: false, publishable_key: '', secret_key: '', exists: false };
            break;
          case 'paypal':
            paymentMethods.paypal = { client_id: '', client_secret: '', enabled: false, exists: false };
            break;
          case 'nayax':
            paymentMethods.nayax = { terminal_id: '', token: '', enabled: false, exists: false };
            break;
          default:
            break;
        }

        let params = {
          action: 'remove_field',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            fields: [`payment_methods.${method}`],
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('payment_methods', paymentMethods);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('paymentMethodDeleteError');
        });
      }
    });
  }

  submit(method) {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let paymentMethods = JSON.parse(JSON.stringify(this.props.paymentMethods));
        paymentMethods[method] = JSON.parse(JSON.stringify(this.state.paymentMethods[method]));
        for (let key in paymentMethods) {
          delete paymentMethods[key].exists;
        }
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            payment_methods: paymentMethods,
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          paymentMethods = JSON.parse(JSON.stringify(this.props.paymentMethods));
          paymentMethods[method] = JSON.parse(JSON.stringify(this.state.paymentMethods[method]));
          paymentMethods[method].exists = true;
          this.props.update('payment_methods', paymentMethods);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('paymentMethodEditError');
        });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let paymentMethods = JSON.parse(JSON.stringify(this.state.paymentMethods));
    if (
      // PayPal
      prevProps.paymentMethods.paypal.client_id !== this.props.paymentMethods.paypal.client_id ||
      prevProps.paymentMethods.paypal.client_secret !== this.props.paymentMethods.paypal.client_secret ||
      prevProps.paymentMethods.paypal.enabled !== this.props.paymentMethods.paypal.enabled ||
      prevProps.paymentMethods.paypal.exists !== this.props.paymentMethods.paypal.exists ||
      // Stripe
      prevProps.paymentMethods.stripe.publishable_key !== this.props.paymentMethods.stripe.publishable_key ||
      prevProps.paymentMethods.stripe.secret_key !== this.props.paymentMethods.stripe.secret_key ||
      prevProps.paymentMethods.stripe.enabled !== this.props.paymentMethods.stripe.enabled ||
      prevProps.paymentMethods.stripe.exists !== this.props.paymentMethods.stripe.exists ||
      // Nayax
      prevProps.paymentMethods.nayax.terminal_id !== this.props.paymentMethods.nayax.terminal_id ||
      prevProps.paymentMethods.nayax.token !== this.props.paymentMethods.nayax.token ||
      prevProps.paymentMethods.nayax.enabled !== this.props.paymentMethods.nayax.enabled ||
      prevProps.paymentMethods.nayax.exists !== this.props.paymentMethods.nayax.exists
    ) {
      paymentMethods.paypal = JSON.parse(JSON.stringify(this.props.paymentMethods.paypal));
      paymentMethods.stripe = JSON.parse(JSON.stringify(this.props.paymentMethods.stripe));
      paymentMethods.nayax = JSON.parse(JSON.stringify(this.props.paymentMethods.nayax));
      this.secureSetState({ paymentMethods });
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.paymentMethods.title' />} subheader={<Translate id='customization.paymentMethods.subheader' />} />
        <CardContent>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel1a-content' id='panel1a-header'>
              <Typography>Stripe</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction='column'>
                <FormControlLabel
                  value='end'
                  control={<Checkbox checked={this.state.paymentMethods.stripe.enabled} name='stripe-enabled' onClick={this.onChange} color='primary' />}
                  label={this.props.translate('customization.paymentMethods.enabled')}
                  labelPlacement='end'
                />
                <TextField
                  label={this.props.translate('customization.paymentMethods.secretKey')}
                  value={this.state.paymentMethods.stripe.secret_key}
                  name='stripe-secret_key'
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField
                  label={this.props.translate('customization.paymentMethods.publishableKey')}
                  value={this.state.paymentMethods.stripe.publishable_key}
                  name='stripe-publishable_key'
                  onChange={this.onChange}
                  fullWidth
                />
                <Grid container direction='row' alignItems='center' justifyContent='center' className={this.props.classes.buttonRow}>
                  {this.state.paymentMethods.stripe.exists && (
                    <Button size='small' onClick={() => this.delete('stripe')}>
                      {`${this.props.translate('customization.paymentMethods.delete')} Stripe`}
                    </Button>
                  )}
                  <Button size='small' color='primary' onClick={() => this.submit('stripe')} disabled={!this.submitEnabled('stripe')}>
                    {this.state.paymentMethods.stripe.exists ? (
                      <Translate id='customization.paymentMethods.edit' />
                    ) : (
                      <Translate id='customization.paymentMethods.add' />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header'>
              <Typography>PayPal</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction='column'>
                <FormControlLabel
                  value='end'
                  control={<Checkbox checked={this.state.paymentMethods.paypal.enabled} name='paypal-enabled' onClick={this.onChange} color='primary' />}
                  label={this.props.translate('customization.paymentMethods.enabled')}
                  labelPlacement='end'
                />
                <TextField
                  label={this.props.translate('customization.paymentMethods.clientID')}
                  value={this.state.paymentMethods.paypal.client_id}
                  name='paypal-client_id'
                  onChange={this.onChange}
                  fullWidth
                />
                <TextField
                  label={this.props.translate('customization.paymentMethods.clientSecret')}
                  value={this.state.paymentMethods.paypal.client_secret}
                  name='paypal-client_secret'
                  onChange={this.onChange}
                  fullWidth
                />
                <Grid container direction='row' alignItems='center' justifyContent='center' className={this.props.classes.buttonRow}>
                  {this.state.paymentMethods.paypal.exists && (
                    <Button size='small' onClick={() => this.delete('paypal')}>
                      {`${this.props.translate('customization.paymentMethods.delete')} PayPal`}
                    </Button>
                  )}
                  <Button size='small' color='primary' onClick={() => this.submit('paypal')} disabled={!this.submitEnabled('paypal')}>
                    {this.state.paymentMethods.paypal.exists ? (
                      <Translate id='customization.paymentMethods.edit' />
                    ) : (
                      <Translate id='customization.paymentMethods.add' />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls='panel2a-content' id='panel2a-header'>
              <Typography>Nayax</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container direction='column'>
                <FormControlLabel
                  value='end'
                  control={<Checkbox checked={this.state.paymentMethods.nayax.enabled} name='nayax-enabled' onClick={this.onChange} color='primary' />}
                  label={this.props.translate('customization.paymentMethods.enabled')}
                  labelPlacement='end'
                />

                <Grid container direction='row' alignItems='center' justifyContent='center' className={this.props.classes.buttonRow}>
                  <Button size='small' color='primary' onClick={() => this.submit('nayax')} disabled={!this.submitEnabled('nayax')}>
                    {this.state.paymentMethods.nayax.exists ? (
                      <Translate id='customization.paymentMethods.edit' />
                    ) : (
                      <Translate id='customization.paymentMethods.add' />
                    )}
                  </Button>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <Button size='small' onClick={this.reset}>
              <Translate id='customization.reset' />
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationPaymentMethods));
