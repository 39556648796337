import React from 'react';
import { changePassword as CognitoChangePassword } from '../../../aws/cognito';
import DynamoDBAdministrators from '../../../aws/dynamodb/administrators';
import Lambda from '../../../aws/lambda';
import UserContext from '../../../context';
import Settings from './presentation';

class SettingsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: true,
			passw: {
				old: '',
				new: '',
				new2: '',
			},
			notifications: {
				failures: false,
				transactions: false,
			},
			language: '',
			name: '',
			snackbar: null,
		};
		this.onSnackbarClose = this.onSnackbarClose.bind(this);
		this.onChange = this.onChange.bind(this);
		this.submitName = this.submitName.bind(this);
		this.submitPassword = this.submitPassword.bind(this);
		this.submitNotifications = this.submitNotifications.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	async onChange(e) {
		if (this.state.processing) {
			return;
		}
		const { name, value, checked } = e.target;
		switch (name) {
			case 'old':
			case 'new':
			case 'new2':
				let { passw } = this.state;
				passw[name] = value;
				this.secureSetState({ passw });
				break;
			case 'name':
				this.secureSetState({ name: value });
				break;

			case 'language':
				await this.context.changeLanguage(value);
				this.secureSetState({ language: value });
				break;
			case 'notifications.failures':
			case 'notifications.transactions':
				let { notifications } = this.state;
				let field = name.split('.')[1];
				notifications[field] = checked;
				this.secureSetState({ notifications });
				break;
			default:
				break;
		}
	}

	submitName() {
		if (this.state.processing) {
			return;
		}

		this.secureSetState({ processing: true, snackbar: null }, async () => {
			if (this.state.name.length === 0) {
				this.secureSetState({ processing: false, snackbar: 'invalidName' });
				return;
			}
			await this.context.changeName(this.state.name);
			this.secureSetState({ processing: false, snackbar: 'success' });
		});
	}

	submitPassword() {
		if (this.state.processing) {
			return;
		}

		this.secureSetState({ processing: true, snackbar: null }, async () => {
			if (this.state.passw.old.length === 0 || this.state.passw.new.length === 0 || this.state.passw.new2.length === 0) {
				this.secureSetState({ processing: false, snackbar: 'passwMandatory' });
				return;
			}
			if (this.state.passw.new !== this.state.passw.new2) {
				this.secureSetState({ processing: false, snackbar: 'passwDontMatch' });
				return;
			}
			try {
				await CognitoChangePassword(this.state.passw.old, this.state.passw.new);
				this.secureSetState({
					processing: false,
					snackbar: 'success',
					passw: {
						old: '',
						new: '',
						new2: '',
					},
				});
			} catch (err) {
				console.log(err);
				this.secureSetState({ processing: false, snackbar: 'passwGeneric' });
			}
		});
	}

	submitNotifications() {
		if (this.state.processing) {
			return;
		}

		this.secureSetState({ processing: true, snackbar: null }, async () => {
			try {
				await Lambda.invoke('ManageAdmin', {
					action: 'edit',
					requestor: this.context.id,
					user: { id: this.context.id, notifications: this.state.notifications },
				});
				this.secureSetState({ processing: false, snackbar: 'success' });
			} catch (err) {
				console.log(err);
				this.secureSetState({ processing: false, snackbar: 'notificationsGeneric' });
			}
		});
	}

	onSnackbarClose() {
		this.secureSetState({ snackbar: null });
	}

	async componentDidMount() {
		this.isMounted = true;
		let language = this.context.locale;
		let name = this.context.name;
		let { notifications } = this.state;
		try {
			let data = await DynamoDBAdministrators.get(this.context.id);
			notifications = data.Item.notifications;
		} catch (err) {
			console.log(err);
		}
		this.secureSetState({ language, name, notifications, processing: false });
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Settings
				{...this.state}
				type={this.context.type}
				onSnackbarClose={this.onSnackbarClose}
				onChange={this.onChange}
				submitName={this.submitName}
				submitPassword={this.submitPassword}
				submitNotifications={this.submitNotifications}
			/>
		);
	}
}

SettingsContainer.contextType = UserContext;
export default SettingsContainer;
