import React from 'react';
import Grid from '@material-ui/core/Grid';
import Connection from './connection';
import API from './api';
import UserContext from '../../../context';

const Developers = () => {
  let user = React.useContext(UserContext);
  return (
    <Grid container direction='row' alignItems='flex-start' justifyContent='flex-start' spacing={2}>
      <Grid item sm={12} lg={6} xl={4}>
        <Connection />
      </Grid>

      {(user.hasPrivileges || user.type === 'superuser') && (
        <Grid item sm={12} lg={6} xl={4}>
          <API user={user} />
        </Grid>
      )}
    </Grid>
  );
};

export default Developers;
