import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
};

class CustomizationPaymentLimit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      amount: null,
      currency: 'eur',
      overflow_action: '',
      permit_negative_budget: false,
    };
    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
    this.submitEnabled = this.submitEnabled.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    if (name === 'amount') {
      if (value.length === 0) {
        this.secureSetState({ [name]: null });
      } else if (/^\d+$/gim.test(value)) {
        this.secureSetState({ [name]: parseInt(value, 10) });
      }
    } else if (name === 'permit_negative_budget') {
      if (value === 'true') {
        this.secureSetState({ [name]: true });
      } else {
        this.secureSetState({ [name]: false });
      }
    } else {
      this.secureSetState({ [name]: value });
    }
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({
      amount: this.props.paymentLimit.amount,
      currency: this.props.paymentLimit.currency,
      overflow_action: this.props.paymentLimit.overflow_action,
      permit_negative_budget: this.props.paymentLimit.permit_negative_budget,
    });
  }

  submitEnabled() {
    if (this.state.amount === null) {
      return false;
    }
    if (this.state.currency.length === 0) {
      return false;
    }
    if (this.state.overflow_action.length === 0) {
      return false;
    }

    return true;
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      let { amount, currency, overflow_action, permit_negative_budget } = this.state;
      let paymentLimit = { amount, currency, overflow_action, permit_negative_budget };

      try {
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            payment_limit: paymentLimit,
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('payment_limit', paymentLimit);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('paymentLimitError');
        });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let state = {};
    if (prevProps.paymentLimit.amount !== this.props.paymentLimit.amount) {
      state.amount = this.props.paymentLimit.amount;
    }
    if (prevProps.paymentLimit.currency !== this.props.paymentLimit.currency) {
      state.currency = this.props.paymentLimit.currency;
    }
    if (prevProps.paymentLimit.overflow_action !== this.props.paymentLimit.overflow_action) {
      state.overflow_action = this.props.paymentLimit.overflow_action;
    }
    if (prevProps.paymentLimit.permit_negative_budget !== this.props.paymentLimit.permit_negative_budget) {
      state.permit_negative_budget = this.props.paymentLimit.permit_negative_budget;
    }

    if (Object.keys(state).length > 0) {
      this.secureSetState(state);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.paymentLimit.title' />} />
        <CardContent>
          <Typography variant='caption'>
            <Translate id='customization.paymentLimit.label1' />
          </Typography>
          <Grid container direction='row' alignItems='center' justifyContent='space-between'>
            <FormControl fullWidth>
              <InputLabel id='customization-paymentlimit-currency'>
                <Translate id='paymentTypes.currency' />
              </InputLabel>
              <Select labelId='customization-paymentlimit-currency' name='currency' value={this.state.currency} onChange={this.onChange} fullWidth>
                <MenuItem value='eur'>€</MenuItem>
                <MenuItem value='chf'>chf</MenuItem>
                {/* <MenuItem value="gbp">£</MenuItem>
								<MenuItem value="usd">$</MenuItem> */}
              </Select>
            </FormControl>
            <TextField
              label={this.props.translate('paymentTypes.amount')}
              value={this.state.amount === null ? '' : this.state.amount.toString()}
              name='amount'
              onChange={this.onChange}
              fullWidth
            />
          </Grid>
          <FormControl fullWidth>
            <InputLabel id='customization-paymentlimit-overflowaction'>
              <Translate id='customization.paymentLimit.overflowAction' />
            </InputLabel>
            <Select
              labelId='customization-paymentlimit-overflowaction'
              name='overflow_action'
              value={this.state.overflow_action}
              onChange={this.onChange}
              fullWidth
            >
              <MenuItem value='stop'>
                <Translate id='customization.paymentLimit.stop' />
              </MenuItem>
              <MenuItem value='free'>
                <Translate id='customization.paymentLimit.free' />
              </MenuItem>
            </Select>
          </FormControl>
          <Typography variant='caption'>
            <Translate id='customization.paymentLimit.label2' />
          </Typography>
          {this.state.overflow_action === 'stop' && (
            <Typography variant='caption'>
              <Translate id='customization.paymentLimit.labelStop' />
            </Typography>
          )}
          {this.state.overflow_action === 'free' && (
            <Typography variant='caption'>
              <Translate id='customization.paymentLimit.labelFree' />
            </Typography>
          )}
          <FormControl fullWidth>
            <InputLabel id='customization-paymentlimit-permitNegativeBudget'>
              <Translate id='customization.paymentLimit.permitNegativeBudget' />
            </InputLabel>
            <Select
              labelId='customization-paymentlimit-permitNegativeBudget'
              name='permit_negative_budget'
              value={this.state.permit_negative_budget}
              onChange={this.onChange}
              fullWidth
            >
              <MenuItem value='false'>
                <Translate id='customization.paymentLimit.false' />
              </MenuItem>
              <MenuItem value='true'>
                <Translate id='customization.paymentLimit.true' />
              </MenuItem>
            </Select>
          </FormControl>
          <Typography variant='caption'>
            <Translate id='customization.paymentLimit.label2' />
          </Typography>
          {this.state.permit_negative_budget === 'false' && (
            <Typography variant='caption'>
              <Translate id='customization.paymentLimit.labelFalse' />
            </Typography>
          )}
          {this.state.permit_negative_budget === 'true' && (
            <Typography variant='caption'>
              <Translate id='customization.paymentLimit.labelTrue' />
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <Button size='small' onClick={this.reset}>
              <Translate id='customization.reset' />
            </Button>
            <Button size='small' color='primary' onClick={this.submit} disabled={!this.submitEnabled()}>
              <Translate id='customization.confirm' />
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationPaymentLimit));
