import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Translate } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import Typography from '@material-ui/core/Typography';
const getOptionLabel = (option) => option.name;
const getOptionSelected = (o, t) => o.id === t.id;

const useStyles = makeStyles({
  toggleButtonGroup: { width: '100%' },
  autocomplete: { marginTop: '1rem' },
  refreshContainer: { marginTop: '1rem' },
});

const Filters = (props) => {
  const classes = useStyles();

  let locale = props.user.locale.substr(0, 2);
  let now = new Date();
  let text0 = now.toLocaleDateString(locale, { year: 'numeric', month: 'long' });
  let month0 = `${now.getFullYear()}/${now.getMonth() + 1}`;

  now.setMonth(now.getMonth() - 1, 1);
  let text1 = now.toLocaleDateString(locale, { year: 'numeric', month: 'long' });
  let month1 = `${now.getFullYear()}/${now.getMonth() + 1}`;

  now.setMonth(now.getMonth() - 1, 1);
  let text2 = now.toLocaleDateString(locale, { year: 'numeric', month: 'long' });
  let month2 = `${now.getFullYear()}/${now.getMonth() + 1}`;

  return (
    <>
      <ToggleButtonGroup
        value={props.month}
        exclusive
        orientation='vertical'
        onChange={props.changeMonth}
        aria-label='text alignment'
        className={classes.toggleButtonGroup}
      >
        <ToggleButton value={month0} aria-label='this month'>
          {text0}
        </ToggleButton>
        <ToggleButton value={month1} aria-label='last month'>
          {text1}
        </ToggleButton>
        <ToggleButton value={month2} aria-label='two months ago'>
          {text2}
        </ToggleButton>
      </ToggleButtonGroup>
      {props.user.type === 'superuser' && (
        <Autocomplete
          openOnFocus
          autoHighlight
          options={props.clients}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          fullWidth
          value={props.client}
          onChange={props.selectClient}
          disabled={props.processing}
          renderInput={(params) => <TextField {...params} label={<Translate id='home.filters.client' />} />}
          renderOption={(option) =>
            option.id === '*' ? (
              <Typography noWrap>
                <b>{option.name}</b>
              </Typography>
            ) : (
              <Typography noWrap>{option.name}</Typography>
            )
          }
          className={classes.autocomplete}
        />
      )}
      {props.user.clientType === 'main' && props.user.clients.length > 0 && (
        <Autocomplete
          openOnFocus
          autoHighlight
          options={props.clients}
          getOptionLabel={getOptionLabel}
          getOptionSelected={getOptionSelected}
          fullWidth
          value={props.client}
          onChange={props.selectClient}
          disabled={props.processing}
          renderInput={(params) => <TextField {...params} label={<Translate id='home.filters.client' />} />}
          renderOption={(option) =>
            option.id === '*' ? (
              <Typography noWrap>
                <b>{option.name}</b>
              </Typography>
            ) : (
              <Typography noWrap>{option.name}</Typography>
            )
          }
          className={classes.autocomplete}
        />
      )}
      <Grid container direction='row' justifyContent='space-between' alignItems='center' className={classes.refreshContainer} spacing={1}>
        <Grid item>
          <Button variant='contained' color='primary' onClick={props.refresh} disabled={props.processing || props.client === null}>
            <Translate id='home.filters.refresh' />
          </Button>
        </Grid>
        <Grid item>
          <FormControlLabel
            control={
              <Checkbox
                checked={props.client === null ? false : props.autorefresh}
                onChange={props.toggleAutoRefresh}
                name='checkedB'
                color='primary'
                disabled={props.client === null || props.month !== month0}
              />
            }
            label={<Translate id='home.filters.autorefresh' />}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Filters;
