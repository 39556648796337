import { FastRewindTwoTone } from '@material-ui/icons';
import React from 'react';
import DynamoDBAdministrators from '../../../aws/dynamodb/administrators';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import Lambda from '../../../aws/lambda';
import UserContext from '../../../context';
import Administrators from './presentation';

class AdministratorsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      list: [],
      clientsList: [],
      clientsObj: {},
      menuAnchorEl: null,
      administratorToCreate: {
        email: '',
        name: '',
        type: '',
        locale: '',
        hasPrivileges: false,
        canChangePayment: false,
        client: null,
      },
      selectedAdministrator: {
        id: '',
        email: '',
        name: '',
        type: '',
        locale: '',
        hasPrivileges: false,
        canChangePayment: false,
        client: null,
      },
      filters: {
        open: false,
        value: '',
      },
      snackbar: null,
      createAdministratorOpen: false,
      editAdministratorOpen: false,
      deleteAdministratorOpen: false,
    };
    this.getAdministrators = this.getAdministrators.bind(this);
    this.openCreateDialog = this.openCreateDialog.bind(this);
    this.closeCreateDialog = this.closeCreateDialog.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openCloseMenu = this.openCloseMenu.bind(this);
    this.onChange = this.onChange.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.toggleFilters = this.toggleFilters.bind(this);
    this.filterChanged = this.filterChanged.bind(this);
    this.filterSearch = this.filterSearch.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
    this._list = [];
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  toggleFilters() {
    this.secureSetState({ filters: { open: !this.state.filters.open, value: '' }, list: this._list.slice() });
  }

  filterChanged(e) {
    let value = e.target.value;
    this.secureSetState({ filters: { open: true, value: value } });
  }

  filterSearch() {
    if (this.state.processing) {
      return;
    }
    let value = this.state.filters.value.trim().toLowerCase();
    if (value.length === 0) {
      this.secureSetState({ list: this._list.slice() });
      return;
    }
    let clientsIDs = [];
    if (value === '_') {
      clientsIDs = [''];
    } else {
      clientsIDs = this.state.clientsList.reduce((acc, c) => {
        if (c.name.toLowerCase().includes(value)) {
          acc.push(c.id);
        }
        return acc;
      }, []);
    }
    let list = this._list.filter((el) => {
      if (el.name.toLowerCase().includes(value) || el.email.includes(value)) {
        return true;
      }
      if (clientsIDs.includes(el.client)) {
        return true;
      }
      return false;
    });
    this.secureSetState({ list });
  }

  getAdministrators() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, filtrers: { open: false, value: '' } }, async () => {
      try {
        let records = {
          Items: [],
        };

        if (this.context.clientType === 'main' && this.context.clients.length > 0) {
          for (let i in this.context.clients) {
            let record = await DynamoDBAdministrators.getAll({ id: this.context.id, type: this.context.type, client: this.context.clients[i] });
            records.Items = records.Items.concat(record.Items);
          }
        } else {
          records = await DynamoDBAdministrators.getAll({ id: this.context.id, type: this.context.type, client: this.context.client });
        }
        this._list = records.Items.map((r) => ({
          id: r.id,
          name: r.name,
          email: r.email,
          locale: r.locale,
          type: r.type,
          client: r.client || '',
          hasPrivileges: r.has_privileges || false,
          canChangePayment: r.can_change_payment && r.can_change_payment === 1 ? true : false,
        }));
        this.secureSetState({ list: this._list.slice(), processing: false });
      } catch (err) {
        console.log(err);
        this._list = [];
        this.secureSetState({ list: [], processing: false });
      }
    });
  }

  openCloseMenu(administrator) {
    return (event) => {
      if (administrator === null) {
        this.secureSetState({
          menuAnchorEl: null,
          selectedAdministrator: {
            id: '',
            email: '',
            name: '',
            type: '',
            locale: '',
            hasPrivileges: false,
            canChangePayment: false,
            client: null,
          },
        });
      } else {
        this.secureSetState({
          menuAnchorEl: event.currentTarget,
          selectedAdministrator: { ...administrator },
        });
      }
    };
  }

  openCreateDialog() {
    this.secureSetState({
      createAdministratorOpen: true,
      administratorToCreate: {
        email: '',
        name: '',
        type: this.context.type === 'client' ? this.context.type : '',
        locale: '',
        hasPrivileges: false,
        canChangePayment: false,
        client: this.context.type === 'client' ? { id: this.context.client, name: '' } : null,
      },
    });
  }

  closeCreateDialog(create) {
    if (!create) {
      this.secureSetState({
        createAdministratorOpen: false,
        administratorToCreate: {
          email: '',
          name: '',
          type: '',
          locale: '',
          hasPrivileges: false,
          canChangePayment: false,
          client: null,
        },
      });
      return;
    }
    if (this.state.processing) {
      return;
    }

    this.secureSetState({ processing: true, snackbar: null }, async () => {
      let { name, email, locale, hasPrivileges, canChangePayment, type, client } = this.state.administratorToCreate;
      name = name.trim();
      email = email.trim();
      locale = locale.trim();
      type = type.trim();

      if (name.length === 0 || email.length === 0 || locale.length === 0 || type.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }

      try {
        let administrator = {
          email,
          name,
          locale: locale,
          type: type,
        };
        if (type === 'client') {
          administrator.has_privileges = hasPrivileges;
          administrator.can_change_payment = canChangePayment ? 1 : 0;
          if (client === null || client.id.trim().length === 0) {
            this.secureSetState({ processing: false, snackbar: 'mandatory' });
            return;
          }
          administrator.client = client.id.trim();
        }
        await Lambda.invoke('ManageAdmin', {
          action: 'add',
          requestor: this.context.id,
          user: administrator,
        });
        this.secureSetState(
          {
            processing: false,
            createAdministratorOpen: false,
            administratorToCreate: {
              email: '',
              name: '',
              type: '',
              locale: '',
              hasPrivileges: false,
              canChangePayment: false,
              client: null,
            },
            snackbar: 'success',
          },
          this.getAdministrators
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'createErrror' });
      }
    });
  }

  openEditDialog() {
    this.secureSetState({ menuAnchorEl: null, editAdministratorOpen: true });
  }

  closeEditDialog(edit) {
    if (!edit) {
      this.secureSetState({
        editAdministratorOpen: false,
        selectedAdministrator: { id: '', email: '', name: '', type: '', locale: '', hasPrivileges: false, canChangePayment: false },
      });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      let { id, hasPrivileges, canChangePayment } = this.state.selectedAdministrator;
      id = id.trim();
      if (id.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }

      try {
        await Lambda.invoke('ManageAdmin', {
          action: 'edit',
          requestor: this.context.id,
          user: { id, has_privileges: hasPrivileges, can_change_payment: canChangePayment ? 1 : 0 },
        });
        this.secureSetState(
          {
            processing: false,
            editAdministratorOpen: false,
            selectedAdministrator: {
              id: '',
              email: '',
              name: '',
              type: '',
              locale: '',
              hasPrivileges: false,
              canChangePayment: false,
              client: null,
            },
            snackbar: 'success',
          },
          this.getAdministrators
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'editErrror' });
      }
    });
  }

  openDeleteDialog() {
    this.secureSetState({ menuAnchorEl: null, deleteAdministratorOpen: true });
  }

  closeDeleteDialog(remove) {
    if (!remove) {
      this.secureSetState({
        deleteAdministratorOpen: false,
        selectedAdministrator: {
          id: '',
          email: '',
          name: '',
          type: '',
          locale: '',
          hasPrivileges: false,
          canChangePayment: false,
          client: null,
        },
      });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        await Lambda.invoke('ManageAdmin', { action: 'delete', requestor: this.context.id, user: this.state.selectedAdministrator.id });
        this.secureSetState(
          {
            processing: false,
            deleteAdministratorOpen: false,
            selectedAdministrator: {
              id: '',
              email: '',
              name: '',
              type: '',
              locale: '',
              hasPrivileges: false,
              canChangePayment: false,
              client: null,
            },
            snackbar: 'success',
          },
          this.getAdministrators
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'deleteErrror' });
      }
    });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }

    let { name, value } = e.target;
    if (this.state.createAdministratorOpen) {
      let { administratorToCreate } = this.state;
      if (name === 'hasPrivileges') {
        administratorToCreate.hasPrivileges = !administratorToCreate.hasPrivileges;
        administratorToCreate.canChangePayment = !administratorToCreate.canChangePayment;
      } else if (name === 'canChangePayment') {
        administratorToCreate.canChangePayment = !administratorToCreate.canChangePayment;
      } else {
        administratorToCreate[name] = value;
      }
      this.secureSetState({ administratorToCreate });
    } else if (this.state.editAdministratorOpen) {
      let { selectedAdministrator } = this.state;
      if (name === 'hasPrivileges') {
        selectedAdministrator.hasPrivileges = !selectedAdministrator.hasPrivileges;
        selectedAdministrator.canChangePayment = !selectedAdministrator.canChangePayment;
      } else if (name === 'canChangePayment') {
        selectedAdministrator.canChangePayment = !selectedAdministrator.canChangePayment;
      } else {
        selectedAdministrator[name] = value;
      }
      this.secureSetState({ selectedAdministrator });
    }
  }

  onSnackbarClose() {
    this.secureSetState({ snackbar: null });
  }

  async componentDidMount() {
    this.isMounted = true;
    if (this.context.type === 'superuser') {
      try {
        let records = await DynamoDBClients.getAll();
        let clientsList = [];
        let clientsObj = {};
        for (let r of records.Items) {
          clientsList.push({ id: r.id, name: r.name });
          clientsObj[r.id] = r.name;
        }
        this.secureSetState({ clientsList, clientsObj }, this.getAdministrators);
      } catch (err) {
        console.log(err);
      }
    } else if (this.context.clientType === 'main' && this.context.clients.length > 0) {
      try {
        let records = [];
        for (let i in this.context.clients) {
          let record = await DynamoDBClients.get(this.context.clients[i]);
          records.push(record.Item);
        }
        let clientsList = [];
        let clientsObj = {};
        for (let r of records) {
          clientsList.push({ id: r.id, name: r.name });
          clientsObj[r.id] = r.name;
        }
        this.secureSetState({ clientsList, clientsObj }, this.getAdministrators);
      } catch (err) {
        console.log(err);
      }
    } else {
      this.getAdministrators();
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Administrators
        {...this.state}
        isSuperuser={this.context.type === 'superuser'}
        hasPrivileges={this.context.hasPrivileges}
        clientType={this.context.clientType}
        sub={this.context.clients}
        openCloseMenu={this.openCloseMenu}
        openCreateDialog={this.openCreateDialog}
        closeCreateDialog={this.closeCreateDialog}
        openEditDialog={this.openEditDialog}
        closeEditDialog={this.closeEditDialog}
        openDeleteDialog={this.openDeleteDialog}
        closeDeleteDialog={this.closeDeleteDialog}
        getAdministrators={this.getAdministrators}
        onChange={this.onChange}
        onSnackbarClose={this.onSnackbarClose}
        toggleFilters={this.toggleFilters}
        filterChanged={this.filterChanged}
        filterSearch={this.filterSearch}
      />
    );
  }
}

AdministratorsContainer.contextType = UserContext;
export default AdministratorsContainer;
