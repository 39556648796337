import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Background from '../../img/background.jpg';
import ThorHorizontal from '../../img/thor/thor-horizontal.png';

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
    backgroundImage: `linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.3)), url(${Background})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  card: {
    width: '25rem',
    maxWidth: '85vw',
  },
  headerImg: {
    width: '12rem',
    maxWidth: '85%',
    display: 'block',
    marginTop: 0,
    marginBottom: '1rem',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
});

const AuthWrapper = (props) => {
  const classes = useStyles(props);
  return (
    <Grid container direction='column' justifyContent='center' alignItems='center' className={classes.container}>
      <Card className={classes.card}>
        <CardContent>
          <img src={ThorHorizontal} alt='Thor' className={classes.headerImg} />
          {props.children}
        </CardContent>
      </Card>
    </Grid>
  );
};

export default AuthWrapper;
