import React from 'react';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VpnKey from '@material-ui/icons/VpnKey';

const Forgot = (props) => {
  let insertCodeFields = [];
  let submitButton = null;
  let submitFunc = null;
  if (props.insertCode) {
    submitFunc = props.reset;
    insertCodeFields = [
      <Grid item container spacing={1} direction='row' alignItems='flex-end' wrap='nowrap' key={0}>
        <Grid item>
          <AccountCircle color='action' />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={props.translate('auth.forgot.code')}
            name='code'
            value={props.code}
            onKeyPress={props.onKeyPress(submitFunc)}
            onChange={props.onChange}
            fullWidth
          />
        </Grid>
      </Grid>,
      <Grid item container spacing={1} direction='row' alignItems='flex-end' wrap='nowrap' key={1}>
        <Grid item>
          <VpnKey color='action' />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={props.translate('auth.forgot.passw')}
            name='passw'
            value={props.passw}
            onKeyPress={props.onKeyPress(submitFunc)}
            onChange={props.onChange}
            type={props.showPassw ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={props.togglePasswordVisibility}>
                    {props.showPassw ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      </Grid>,
      <Grid item container spacing={1} direction='row' alignItems='flex-end' wrap='nowrap' key={2}>
        <Grid item>
          <VpnKey color='action' />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={props.translate('auth.forgot.passw2')}
            name='passw2'
            value={props.passw2}
            onKeyPress={props.onKeyPress(submitFunc)}
            onChange={props.onChange}
            type={props.showPassw ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={props.togglePasswordVisibility}>
                    {props.showPassw ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      </Grid>,
    ];
    submitButton = (
      <React.Fragment>
        <Grid item>
          <Button variant='contained' color='primary' onClick={props.reset} disabled={props.processing}>
            {props.processing ? <CircularProgress color='primary' size={24} /> : <Translate id='auth.forgot.reset' />}
          </Button>
        </Grid>
        <Grid item>
          <Link to='/forgot'>
            <Typography>
              <Translate id='auth.forgot.lost' />
            </Typography>
          </Link>
        </Grid>
      </React.Fragment>
    );
  } else {
    submitFunc = props.forgot;
    insertCodeFields = [
      <Grid item container spacing={1} direction='row' alignItems='flex-end' wrap='nowrap' key={0}>
        <Grid item>
          <AccountCircle color='action' />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={props.translate('auth.forgot.email')}
            name='email'
            value={props.email}
            onKeyPress={props.onKeyPress(submitFunc)}
            onChange={props.onChange}
            fullWidth
          />
        </Grid>
      </Grid>,
    ];
    submitButton = (
      <Button variant='contained' color='primary' onClick={props.forgot} disabled={props.processing}>
        {props.processing ? <CircularProgress color='primary' size={24} /> : <Translate id='auth.forgot.forgot' />}
      </Button>
    );
  }
  return (
    <Grid container spacing={1} direction='column' justifyContent='center' alignItems='center'>
      {insertCodeFields}
      <Grid item>
        <Typography color='error' variant='caption'>
          {props.error !== null ? <Translate id={`auth.forgot.errors.${props.error}`} /> : ' '}
        </Typography>
      </Grid>
      <Grid item container spacing={1} direction='column' justifyContent='center' alignItems='center'>
        {submitButton}
      </Grid>
      <Grid item>
        <Link to='/login'>
          <Typography>
            <Translate id='auth.forgot.login' />
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default withLocalize(Forgot);
