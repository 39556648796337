import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_transactions_finished`;

const getAll = async (requestor, filters, paginationOptions) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    ReturnConsumedCapacity: 'NONE',
    ScanIndexForward: false,
    ExpressionAttributeNames: {},
    ExpressionAttributeValues: {},
  };
  let conditionExpressions = [];

  if (paginationOptions) {
    params.Limit = paginationOptions.limit;
    if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
      params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
    }
  }

  if (filters) {
    if (filters.chargebox) {
      params.IndexName = 'chargebox-datestarted-index';
      conditionExpressions.push('#chargebox = :chargebox');
      params.ExpressionAttributeNames = { '#chargebox': 'chargebox' };
      params.ExpressionAttributeValues = { ':chargebox': filters.chargebox };
    }
    if (filters.dateFrom && filters.dateTo) {
      conditionExpressions.push('#date_started between :from and :to');
      params.ExpressionAttributeNames['#date_started'] = 'date_started';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    } else if (filters.dateFrom && !filters.dateTo) {
      conditionExpressions.push('#date_started >= :from');
      params.ExpressionAttributeNames['#date_started'] = 'date_started';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
    } else if (!filters.dateFrom && filters.dateTo) {
      conditionExpressions.push('#date_started <= :to');
      params.ExpressionAttributeNames['#date_started'] = 'date_started';
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    }
    if (filters.method) {
      params.FilterExpression = 'payment.#method = :method';
      params.ExpressionAttributeNames['#method'] = 'method';
      params.ExpressionAttributeValues[':method'] = filters.method;
    } else if (filters.type) {
      params.FilterExpression = '#type = :type';
      params.ExpressionAttributeNames['#type'] = 'type';
      params.ExpressionAttributeValues[':type'] = filters.type;
    }

    if (filters.getReadyForInvoicing) {
      params.FilterExpression = '#type = :type and #type = :type and attribute_exists(#user) and attribute_exists(#payment) and #status.#unlocked = :false';
      params.ExpressionAttributeNames['#type'] = 'type';
      params.ExpressionAttributeNames['#user'] = 'user';
      params.ExpressionAttributeNames['#payment'] = 'payment';
      params.ExpressionAttributeNames['#status'] = 'status';
      params.ExpressionAttributeNames['#unlocked'] = 'unlocked';
      params.ExpressionAttributeValues[':type'] = 'remote';
      params.ExpressionAttributeValues[':false'] = false;
    }
  }

  if (!params.hasOwnProperty('IndexName')) {
    params.IndexName = 'client-datestarted-index';
    conditionExpressions.push('#client = :client');
    params.ExpressionAttributeNames['#client'] = 'client';
    params.ExpressionAttributeValues[':client'] = requestor.client;
  }

  if (Object.keys(params.ExpressionAttributeNames).length === 0) {
    delete params.ExpressionAttributeNames;
  }

  if (Object.keys(params.ExpressionAttributeValues).length === 0) {
    delete params.ExpressionAttributeValues;
  }

  if (params.hasOwnProperty('IndexName')) {
    if (conditionExpressions.length > 0) {
      params.KeyConditionExpression = conditionExpressions.join(' and ');
    }

    let data,
      result = null;
    if (paginationOptions) {
      do {
        data = await DDB.query(params).promise();

        if (result === null) {
          result = data;
        } else {
          result.Items = [...result.Items, ...data.Items];
          result.Count = result.Count + data.Count;
        }
        params.ExclusiveStartKey = data.LastEvaluatedKey;
        result.LastEvaluatedKey = data.LastEvaluatedKey;
      } while (result.Items.length < paginationOptions.limit && data.LastEvaluatedKey);
      if (result.Items.length > paginationOptions.limit && data.LastEvaluatedKey) {
        result.LastEvaluatedKey.id = result.Items[paginationOptions.limit - 1].id;
        result.LastEvaluatedKey.date_started = result.Items[paginationOptions.limit - 1].date_started;

        if (result.LastEvaluatedKey.hasOwnProperty('chargebox')) {
          result.LastEvaluatedKey.chargebox = result.Items[paginationOptions.limit - 1].chargebox;
        }

        if (result.LastEvaluatedKey.hasOwnProperty('client')) {
          result.LastEvaluatedKey.client = result.Items[paginationOptions.limit - 1].client;
        }
        result.Items = result.Items.slice(0, paginationOptions.limit);
      } else if (!data.LastEvaluatedKey) {
        delete result.LastEvaluatedKey;
      }
      return result;
    } else {
      data = await DDB.query(params).promise();
      return data;
    }
  }
  if (conditionExpressions.length > 0) {
    params.FilterExpression = conditionExpressions.join(' and ');
    delete params.Limit;
    let data = await DDB.scan(params).promise();
    if (paginationOptions) {
      let ret = {
        Items: data.Items.slice(0, paginationOptions.limit),
        LastEvaluatedKey: data.Items.length > paginationOptions.limit ? { id: data.Items[paginationOptions.limit - 1].id } : null,
      };
      console.log('Case 11', ret);
      return ret;
    } else {
      console.log('Case 22', data);
      return data;
    }
  } else {
    let result = DDB.scan(params).promise();
    console.log('Result', result);
    return result;
  }
};

const getAllCount = async (requestor, filters) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    ReturnConsumedCapacity: 'NONE',
    ExpressionAttributeNames: {},
    ExpressionAttributeValues: {},
    Select: 'COUNT',
  };
  let conditionExpressions = [];

  if (filters) {
    if (filters.chargebox !== null) {
      params.IndexName = 'chargebox-datestarted-index';
      conditionExpressions.push('#chargebox = :chargebox');
      params.ExpressionAttributeNames = { '#chargebox': 'chargebox' };
      params.ExpressionAttributeValues = { ':chargebox': filters.chargebox };
    }

    if (filters.dateFrom !== null && filters.dateTo !== null) {
      conditionExpressions.push('#date_started between :from and :to');
      params.ExpressionAttributeNames['#date_started'] = 'date_started';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    } else if (filters.dateFrom !== null && filters.dateTo === null) {
      conditionExpressions.push('#date_started >= :from');
      params.ExpressionAttributeNames['#date_started'] = 'date_started';
      params.ExpressionAttributeValues[':from'] = filters.dateFrom;
    } else if (filters.dateFrom === null && filters.dateTo !== null) {
      conditionExpressions.push('#date_started <= :to');
      params.ExpressionAttributeNames['#date_started'] = 'date_started';
      params.ExpressionAttributeValues[':to'] = filters.dateTo;
    }
    if (filters.method) {
      params.FilterExpression = 'payment.#method = :method';
      params.ExpressionAttributeNames['#method'] = 'method';
      params.ExpressionAttributeValues[':method'] = filters.method;
    } else if (filters.type) {
      params.FilterExpression = '#type = :type';
      params.ExpressionAttributeNames['#type'] = 'type';
      params.ExpressionAttributeValues[':type'] = filters.type;
    }
  }

  if (!params.hasOwnProperty('IndexName')) {
    params.IndexName = 'client-datestarted-index';
    conditionExpressions.push('#client = :client');
    params.ExpressionAttributeNames['#client'] = 'client';
    params.ExpressionAttributeValues[':client'] = requestor.client;
  }

  if (Object.keys(params.ExpressionAttributeNames).length === 0) {
    delete params.ExpressionAttributeNames;
  }

  if (Object.keys(params.ExpressionAttributeValues).length === 0) {
    delete params.ExpressionAttributeValues;
  }
  if (params.hasOwnProperty('IndexName')) {
    if (conditionExpressions.length > 0) {
      params.KeyConditionExpression = conditionExpressions.join(' and ');
    }
    return await DDB.query(params).promise();
  }

  if (conditionExpressions.length > 0) {
    params.FilterExpression = conditionExpressions.join(' and ');
  }
  console.log('Params', params);
  return DDB.scan(params).promise();
};

export default {
  getAll,
  getAllCount,
};
