import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import DynamoDBEndUsers from '../../../aws/dynamodb/endusers';

const getOptionLabel = (list) => (option) => {
  if (typeof option === 'string') {
    let element = list.find((c) => c.id === option);
    if (element === undefined) {
      return 'UNKNOWN';
    }
    return element.name;
  }
  return option.name;
};

const getOptionSelected = (o, t) => {
  if (typeof t === 'string') {
    return o.id === t;
  }
  return o.id === t.id;
};

const styles = {
  email: {
    flex: 1,
    marginRight: '1rem',
  },
};

class RfidsDialogNewEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      linkToUser: true,
      email: '',
      searchResult: 'idle',
    };
    this.onChange = this.onChange.bind(this);
    this.searchUser = this.searchUser.bind(this);
    this.getSearchButton = this.getSearchButton.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    let { name, value } = e.target;
    if (name === 'linkToUser') {
      this.secureSetState({ linkToUser: !this.state.linkToUser, searchResult: 'idle' });
      this.props.onChange({ target: { name: 'user', value: null } });
      this.props.onChange({ target: { name: 'alias', value: null } });
      return;
    }
    this.secureSetState({ [name]: value, searchResult: 'idle' });
  }

  searchUser() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let { email } = this.state;
        email = email.replace(/ /gim, '').toLowerCase();
        let user = await DynamoDBEndUsers.getByEmail(email);
        if (user === null) {
          this.props.onChange({ target: { name: 'user', value: null } });
          this.secureSetState({ processing: false, searchResult: 'notFound' });
        } else {
          this.props.onChange({ target: { name: 'user', value: user.id } });
          this.secureSetState({ processing: false, searchResult: 'found' });
        }
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, searchResult: 'error' });
      }
    });
  }

  async componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEdit) {
      if (!prevProps.open && this.props.open) {
        if (this.props.rfid.user !== null) {
          this.secureSetState({ linkToUser: true, processing: true }, async () => {
            try {
              let user = await DynamoDBEndUsers.get(this.props.rfid.user);
              if (!user.Item) {
                throw 'user_not_found';
              }
              this.secureSetState({ processing: false, email: user.Item.email, searchResult: 'found' });
            } catch (err) {
              console.log(err);
              this.secureSetState({ processing: false, email: '', searchResult: 'error' });
            }
          });
        } else {
          this.secureSetState({ linkToUser: false, email: '', searchResult: 'idle', processing: false });
        }
      }
    } else {
      if (!prevProps.open && this.props.open) {
        this.secureSetState({ linkToUser: true, email: '', searchResult: 'idle' });
      }
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  getSearchButton() {
    if (this.state.processing) {
      return <CircularProgress size='2rem' />;
    }
    switch (this.state.searchResult) {
      case 'found':
        return (
          <IconButton disabled={this.state.email.length === 0} onClick={this.searchUser} color='primary'>
            <CheckIcon />
          </IconButton>
        );
      case 'error':
        return (
          <IconButton disabled={this.state.email.length === 0} onClick={this.searchUser}>
            <ClearIcon htmlColor={this.state.email.length === 0 ? null : '#CF000F'} />
          </IconButton>
        );

      default:
        return (
          <IconButton disabled={this.state.email.length === 0} onClick={this.searchUser}>
            <SearchIcon />
          </IconButton>
        );
    }
  }

  render() {
    let infiniteBudget = this.props.rfid.budget === null;
    return (
      <Dialog onClose={this.props.handleClose} aria-labelledby='rfid-dialog-new-edit-title' open={this.props.open} fullWidth maxWidth='xs'>
        <DialogTitle id='rfid-dialog-new-edit-title'>
          {this.props.isEdit ? <Translate id='rfids.dialogs.edit.title' /> : <Translate id='rfids.dialogs.create.title' />}
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={2}>
            {(this.props.user.type === 'superuser' || (this.props.user.clientType === 'main' && this.props.user.clients.length > 0)) && (
              <Autocomplete
                openOnFocus
                autoHighlight
                options={this.props.clients}
                getOptionLabel={getOptionLabel(this.props.clients)}
                getOptionSelected={getOptionSelected}
                fullWidth
                value={this.props.rfid.client}
                onChange={(event, newValue) => this.props.onChange({ target: { name: 'client', value: newValue } })}
                disabled={this.props.isEdit}
                renderInput={(params) => <TextField {...params} label={this.props.translate('rfids.client')} />}
              />
            )}
            <TextField
              label={this.props.translate('rfids.id')}
              name='id'
              value={this.props.rfid.id}
              onChange={this.props.onChange}
              fullWidth
              disabled={this.props.isEdit}
              margin='normal'
            />
            {this.props.isEdit && (
              <FormControlLabel
                value='end'
                control={<Checkbox checked={this.props.rfid.active} name='active' onClick={this.props.onChange} color='primary' />}
                label={this.props.translate('rfids.active')}
                labelPlacement='end'
                disabled={this.props.user.type.toLowerCase() === 'superuser' || this.props.user.hasPrivileges ? false : true}
              />
            )}
            <FormControlLabel
              value='end'
              control={<Checkbox checked={this.state.linkToUser} name='linkToUser' onClick={this.onChange} color='primary' />}
              label={this.props.translate('rfids.linkToUser')}
              labelPlacement='end'
              disabled={this.props.user.type.toLowerCase() === 'superuser' || this.props.user.hasPrivileges ? false : true}
            />
            {this.state.linkToUser ? (
              <Grid container direction='row' alignItems='center' justifyContent='space-between'>
                <TextField
                  label={this.props.translate('rfids.email')}
                  name='email'
                  value={this.state.email}
                  onChange={this.onChange}
                  className={this.props.classes.email}
                  helperText={this.state.searchResult === 'notFound' ? this.props.translate('rfids.notFound') : ''}
                  error={this.state.searchResult === 'notFound' || this.state.searchResult === 'error'}
                  disabled={this.props.user.type.toLowerCase() === 'superuser' || this.props.user.hasPrivileges ? false : true}
                />
                {this.getSearchButton()}
              </Grid>
            ) : (
              <TextField
                label={this.props.translate('rfids.alias')}
                name='alias'
                value={this.props.rfid.alias || ''}
                onChange={this.props.onChange}
                fullWidth
                disabled={this.props.user.type.toLowerCase() === 'superuser' || this.props.user.hasPrivileges ? false : true}
              />
            )}
            {this.props.isEdit && !infiniteBudget ? null : (
              <FormControlLabel
                control={<Checkbox checked={infiniteBudget} onChange={this.props.onChange} name='infinite' color='primary' disabled={this.props.isEdit} />}
                label={this.props.translate('rfids.infiniteBudget')}
                labelPlacement='end'
              />
            )}
            {!infiniteBudget && (
              <TextField
                type='number'
                label={this.props.isEdit ? this.props.translate('rfids.currentAmount') : this.props.translate('rfids.initialAmount')}
                defaultValue={infiniteBudget || this.props.rfid.budget.amount === null ? '' : this.props.rfid.budget.amount.toString()}
                name='amount'
                onChange={this.props.onChange}
                margin='normal'
                disabled={infiniteBudget || this.props.isEdit}
                fullWidth
                InputProps={{
                  startAdornment: <InputAdornment position='start'>{this.props.currency}</InputAdornment>,
                }}
              />
            )}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color='default' autoFocus>
            <Translate id='rfids.dialogs.close' />
          </Button>
          {(this.props.user.type.toLowerCase() === 'superuser' || this.props.user.hasPrivileges) && (
            <Button onClick={this.props.submit} color='primary'>
              {this.props.isEdit ? <Translate id='rfids.dialogs.edit.submit' /> : <Translate id='rfids.dialogs.create.submit' />}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(RfidsDialogNewEdit));
