import React from 'react';
import DynamoDBVendors from '../../../aws/dynamodb/vendors';
import UserContext from '../../../context';
import Vendors from './presentation';

class VendorsContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			processing: false,
			list: [],
			menuAnchorEl: null,
			vendorToCreate: {
				id: '',
				name: '',
			},
			selectedVendor: {
				id: '',
				name: '',
			},
			filters: {
				open: false,
				value: '',
			},
			snackbar: null,
			createVendorOpen: false,
			editVendorOpen: false,
			deleteVendorOpen: false,
		};
		this.getVendors = this.getVendors.bind(this);
		this.openCreateDialog = this.openCreateDialog.bind(this);
		this.closeCreateDialog = this.closeCreateDialog.bind(this);
		this.openEditDialog = this.openEditDialog.bind(this);
		this.closeEditDialog = this.closeEditDialog.bind(this);
		this.openDeleteDialog = this.openDeleteDialog.bind(this);
		this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
		this.openCloseMenu = this.openCloseMenu.bind(this);
		this.onChange = this.onChange.bind(this);
		this.onSnackbarClose = this.onSnackbarClose.bind(this);
		this.toggleFilters = this.toggleFilters.bind(this);
		this.filterChanged = this.filterChanged.bind(this);
		this.filterSearch = this.filterSearch.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
		this._list = [];
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	toggleFilters() {
		this.secureSetState({ filters: { open: !this.state.filters.open, value: '' }, list: this._list.slice() });
	}

	filterChanged(e) {
		let value = e.target.value;
		this.secureSetState({ filters: { open: true, value: value } });
	}

	filterSearch() {
		if (this.state.processing) {
			return;
		}
		let value = this.state.filters.value.trim().toLowerCase();
		if (value.length === 0) {
			this.secureSetState({ list: this._list.slice() });
			return;
		}
		let list = this._list.filter((el) => {
			if (el.id.toLowerCase().includes(value) || el.name.toLowerCase().includes(value)) {
				return true;
			}
			return false;
		});
		this.secureSetState({ list });
	}

	getVendors() {
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true, filtrers: { open: false, value: '' } }, async () => {
			try {
				let records = await DynamoDBVendors.getAll();
				this._list = records.Items;
				this.secureSetState({ list: this._list.slice(), processing: false });
			} catch (err) {
				console.log(err);
				this._list = [];
				this.secureSetState({ list: [], processing: false });
			}
		});
	}

	openCloseMenu(vendor) {
		return (event) => {
			if (vendor === null) {
				this.secureSetState({ menuAnchorEl: null, selectedVendor: { id: '', name: '' } });
			} else {
				this.secureSetState({ menuAnchorEl: event.currentTarget, selectedVendor: { ...vendor } });
			}
		};
	}

	openCreateDialog() {
		this.secureSetState({ createVendorOpen: true, vendorToCreate: { id: '', name: '' } });
	}

	closeCreateDialog(create) {
		if (!create) {
			this.secureSetState({ createVendorOpen: false, vendorToCreate: { id: '', name: '' } });
			return;
		}
		if (this.state.processing) {
			return;
		}

		this.secureSetState({ processing: true, snackbar: null }, async () => {
			let { name, id } = this.state.vendorToCreate;
			name = name.trim();
			id = id.trim();
			if (name.length === 0 || id.length === 0) {
				this.secureSetState({ processing: false, snackbar: 'mandatory' });
				return;
			}

			try {
				await DynamoDBVendors.create({ id: id.toLowerCase(), name: name });
				this.secureSetState(
					{
						processing: false,
						createVendorOpen: false,
						vendorToCreate: { id: '', name: '' },
						snackbar: 'success',
					},
					this.getVendors
				);
			} catch (err) {
				console.log(err);
				if (err.code && err.code === 'ConditionalCheckFailedException') {
					this.secureSetState({ processing: false, snackbar: 'duplicate' });
				} else {
					this.secureSetState({ processing: false, snackbar: 'createErrror' });
				}
			}
		});
	}

	openEditDialog() {
		this.secureSetState({ menuAnchorEl: null, editVendorOpen: true });
	}

	closeEditDialog(edit) {
		if (!edit) {
			this.secureSetState({ editVendorOpen: false, selectedVendor: { id: '', name: '' } });
			return;
		}
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true, snackbar: null }, async () => {
			let { name, id } = this.state.selectedVendor;
			name = name.trim();
			id = id.trim();
			if (name.length === 0 || id.length === 0) {
				this.secureSetState({ processing: false, snackbar: 'mandatory' });
				return;
			}

			try {
				await DynamoDBVendors.edit(id.toLowerCase(), { name });
				this.secureSetState(
					{
						processing: false,
						editVendorOpen: false,
						selectedVendor: { id: '', name: '' },
						snackbar: 'success',
					},
					this.getVendors
				);
			} catch (err) {
				console.log(err);
				if (err.code && err.code === 'ConditionalCheckFailedException') {
					this.secureSetState({ processing: false, snackbar: 'duplicate' });
				} else {
					this.secureSetState({ processing: false, snackbar: 'editErrror' });
				}
			}
		});
	}

	openDeleteDialog() {
		this.secureSetState({ menuAnchorEl: null, deleteVendorOpen: true });
	}

	closeDeleteDialog(remove) {
		if (!remove) {
			this.secureSetState({ deleteVendorOpen: false, selectedVendor: { id: '', name: '' } });
			return;
		}
		if (this.state.processing) {
			return;
		}
		this.secureSetState({ processing: true, snackbar: null }, async () => {
			try {
				await DynamoDBVendors.remove(this.state.selectedVendor.id.toLowerCase());
				this.secureSetState(
					{
						processing: false,
						deleteVendorOpen: false,
						selectedVendor: { id: '', name: '' },
						snackbar: 'success',
					},
					this.getVendors
				);
			} catch (err) {
				console.log(err);
				this.secureSetState({ processing: false, snackbar: 'deleteErrror' });
			}
		});
	}

	onChange(e) {
		if (this.state.processing) {
			return;
		}
		let { name, value } = e.target;
		if (this.state.createVendorOpen) {
			let { vendorToCreate } = this.state;
			vendorToCreate[name] = value;
			this.secureSetState({ vendorToCreate });
		} else if (this.state.editVendorOpen) {
			let { selectedVendor } = this.state;
			selectedVendor[name] = value;
			this.secureSetState({ selectedVendor });
		}
	}

	onSnackbarClose() {
		this.secureSetState({ snackbar: null });
	}

	componentDidMount() {
		this.isMounted = true;
		this.getVendors();
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return (
			<Vendors
				{...this.state}
				openCloseMenu={this.openCloseMenu}
				openCreateDialog={this.openCreateDialog}
				closeCreateDialog={this.closeCreateDialog}
				openEditDialog={this.openEditDialog}
				closeEditDialog={this.closeEditDialog}
				openDeleteDialog={this.openDeleteDialog}
				closeDeleteDialog={this.closeDeleteDialog}
				getVendors={this.getVendors}
				onChange={this.onChange}
				onSnackbarClose={this.onSnackbarClose}
				toggleFilters={this.toggleFilters}
				filterChanged={this.filterChanged}
				filterSearch={this.filterSearch}
			/>
		);
	}
}

VendorsContainer.contextType = UserContext;
export default VendorsContainer;
