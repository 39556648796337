import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Checkbox from '@material-ui/core/Checkbox';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import S3 from '../../../aws/s3';

const useStyles = makeStyles({
  card: {
    width: '20rem',
    maxWidth: '90vw',
    margin: '0.5rem',
  },
});

const PasswordCard = (props) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardHeader title={<Translate id='settings.password.title' />} subheader={<Translate id='settings.password.subtitle' />} />
      <CardContent>
        <TextField
          label={props.translate('settings.password.old')}
          autoComplete='new-password'
          name='old'
          value={props.old}
          onChange={props.onChange}
          type='password'
          fullWidth
        />
        <TextField
          label={props.translate('settings.password.new')}
          autoComplete='new-password'
          name='new'
          value={props.new}
          onChange={props.onChange}
          type='password'
          fullWidth
        />
        <TextField
          label={props.translate('settings.password.new2')}
          autoComplete='new-password'
          name='new2'
          value={props.new2}
          onChange={props.onChange}
          type='password'
          fullWidth
        />
      </CardContent>
      <CardActions>
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Button onClick={props.submit}>
            <Translate id='settings.confirm' />
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

const LanguageCard = (props) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardHeader title={<Translate id='settings.language.title' />} subheader={<Translate id='settings.language.subtitle' />} />
      <CardContent>
        <RadioGroup aria-label='language' name='language' value={props.language} onChange={props.onChange}>
          <FormControlLabel value='it_IT' control={<Radio color='primary' />} label='Italiano' />
          <FormControlLabel value='en_GB' control={<Radio color='primary' />} label='English' />
        </RadioGroup>
      </CardContent>
    </Card>
  );
};

const NameCard = (props) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardHeader title={<Translate id='settings.name.title' />} subheader={<Translate id='settings.name.subtitle' />} />
      <CardContent>
        <TextField label={props.translate('settings.name.label')} name='name' value={props.name} onChange={props.onChange} type='text' fullWidth />
      </CardContent>
      <CardActions>
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Button onClick={props.submit}>
            <Translate id='settings.confirm' />
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

const NotificationsCard = (props) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardHeader title={<Translate id='settings.notifications.title' />} subheader={<Translate id='settings.notifications.subtitle' />} />
      <CardContent>
        <FormControl component='fieldset'>
          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={props.notifications.failures} onChange={props.onChange} name='notifications.failures' color='primary' />}
              label={props.translate('settings.notifications.failures')}
            />
            <FormControlLabel
              control={<Checkbox checked={props.notifications.transactions} onChange={props.onChange} name='notifications.transactions' color='primary' />}
              label={props.translate('settings.notifications.transactions')}
            />
          </FormGroup>
        </FormControl>
      </CardContent>
      <CardActions>
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Button onClick={props.submit}>
            <Translate id='settings.confirm' />
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

const MailingListCard = (props) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardHeader title={<Translate id='settings.mailingList.title' />} subheader={<Translate id='settings.mailingList.subtitle' />} />
      <CardActions>
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Button href='http://eepurl.com/g9l_cH' target='_blank' rel='noopener noreferrer'>
            <Translate id='settings.mailingList.subscribe' />
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

const ManualCard = (props) => {
  const classes = useStyles(props);
  return (
    <Card className={classes.card}>
      <CardHeader title={<Translate id='settings.manual.title' />} subheader={<Translate id='settings.manual.subtitle' />} />
      <CardActions>
        <Grid container direction='row' justifyContent='flex-end' alignItems='center'>
          <Button size='small' component='a' href={S3.getManualLink()} target='_blank' rel='noopener noreferrer'>
            <Translate id='customization.logoTerms.download' />
          </Button>
        </Grid>
      </CardActions>
    </Card>
  );
};

const Settings = (props) => (
  <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' wrap='wrap'>
    <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.snackbar !== null} autoHideDuration={6000} onClose={props.onSnackbarClose}>
      <Alert
        elevation={6}
        variant='filled'
        severity='info'
        onClose={props.onSnackbarClose}
        icon={false}
        action={
          <IconButton size='small' aria-label='close' color='inherit' onClick={props.onSnackbarClose}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        {props.snackbar === null ? '' : props.translate(`settings.snackbar.${props.snackbar}`)}
      </Alert>
    </Snackbar>
    <Grid item>
      <PasswordCard {...props.passw} onChange={props.onChange} translate={props.translate} submit={props.submitPassword} />
    </Grid>
    <Grid item>
      <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
        <LanguageCard language={props.language} onChange={props.onChange} translate={props.translate} />
        <NameCard name={props.name} onChange={props.onChange} translate={props.translate} submit={props.submitName} />
      </Grid>
    </Grid>
    <Grid item>
      <Grid container direction='column' justifyContent='flex-start' alignItems='flex-start'>
        {props.type === 'client' && (
          <NotificationsCard notifications={props.notifications} onChange={props.onChange} submit={props.submitNotifications} translate={props.translate} />
        )}
        <MailingListCard />
        <ManualCard />
      </Grid>
    </Grid>
  </Grid>
);

export default withLocalize(Settings);
