import React from 'react';
import { Translate } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';
import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import ActiveReservations from './active';
import FinishedReservations from './finished';

const Reservations = (props) => (
  <Grid container direction='column' spacing={1}>
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={props.snackbar !== null}
      autoHideDuration={6000}
      onClose={() => props.showHideSnackbar(null)}
    >
      <Alert
        elevation={6}
        variant='filled'
        severity='info'
        onClose={() => props.showHideSnackbar(null)}
        icon={false}
        action={
          <IconButton size='small' aria-label='close' color='inherit' onClick={() => props.showHideSnackbar(null)}>
            <CloseIcon fontSize='small' />
          </IconButton>
        }
      >
        {props.snackbar === null ? '' : <Translate id={`reservations.snackbar.${props.snackbar}`} />}
      </Alert>
    </Snackbar>
    <Grid item xs={12}>
      <ActiveReservations user={props.user} chargeboxes={props.chargeboxes} showHideSnackbar={props.showHideSnackbar} />
    </Grid>
    <Grid item xs={12}>
      <FinishedReservations user={props.user} chargeboxes={props.chargeboxes} clients={props.clients} showHideSnackbar={props.showHideSnackbar} />
    </Grid>
  </Grid>
);
export default Reservations;
