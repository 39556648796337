import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import Snackbar from '@material-ui/core/Snackbar';
import Switch from '@material-ui/core/Switch';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import RepeatIcon from '@material-ui/icons/Repeat';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import withStyles from '@material-ui/core/styles/withStyles';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import Lambda from '../../../aws/lambda';
import { isValidIP, copyToClipboard } from '../../../utilities';

const getOptionLabel = (option) => option.name;
const getOptionSelected = (o, t) => o.id === t.id;

const styles = (theme) => ({
  accordionTitle: {
    flexBasis: '33.33%',
  },
  accordionSubtitle: {
    flexBasis: '66.66%',
    color: theme.palette.text.secondary,
  },
  item: {
    marginTop: 10,
    marginBottom: 10,
  },
});

const ConfirmDialog = ({ id, open, onYes, onNo, title, description }) => (
  <Dialog open={open} onClose={onNo} aria-labelledby={`dialog-${id}-title`} aria-describedby={`dialog-${id}-description`} maxWidth='xs'>
    <DialogTitle id={`dialog-${id}-title`}>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id={`dialog-${id}-description`}>{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onNo} color='primary' autoFocus>
        <Translate id='no' />
      </Button>
      <Button onClick={onYes} color='primary'>
        <Translate id='yes' />
      </Button>
    </DialogActions>
  </Dialog>
);

class API extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      clients: [],
      client: null,
      apiKeyVisible: false,
      enabled: false,
      apiKey: '',
      ipFrom: '',
      endpoints: {
        meters: null,
        start: null,
        stop: null,
        rfid: null,
      },
      headersToAdd: {
        meters: { key: '', value: '' },
        start: { key: '', value: '' },
        stop: { key: '', value: '' },
        rfid: { key: '', value: '', doublecheck: true },
      },
      dialogs: {
        renew: false,
        delete: false,
      },
      accordion: '',
      snackbar: '',
    };
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.copyToClipboard = this.copyToClipboard.bind(this);
    this.toggleRenewDialog = this.toggleRenewDialog.bind(this);
    this.toggleDeleteDialog = this.toggleDeleteDialog.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.toggleAccordion = this.toggleAccordion.bind(this);
    this.addHeader = this.addHeader.bind(this);
    this.deleteHeader = this.deleteHeader.bind(this);
    this.createAPIKey = this.createAPIKey.bind(this);
    this.renewAPIKey = this.renewAPIKey.bind(this);
    this.delete = this.delete.bind(this);
    this.submit = this.submit.bind(this);
    this.get = this.get.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }
  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  toggleVisibility() {
    this.secureSetState({ apiKeyVisible: !this.state.apiKeyVisible });
  }

  toggleRenewDialog() {
    let { dialogs } = this.state;
    dialogs.renew = !dialogs.renew;
    this.secureSetState({ dialogs });
  }

  toggleDeleteDialog() {
    let { dialogs } = this.state;
    dialogs.delete = !dialogs.delete;
    this.secureSetState({ dialogs });
  }

  async copyToClipboard(text) {
    let success = await copyToClipboard(text);
    if (success) {
      this.secureSetState({ snackbar: 'copied' });
    }
  }

  handleChange(e) {
    if (this.state.processing) {
      return;
    }
    if (e.target.name === 'client') {
      this.secureSetState({ client: e.target.value }, e.target.value === null ? null : this.get);
      return;
    }

    let state = {};
    if (e.target.name === 'enabled') {
      state.enabled = !this.state.enabled;
    } else if (e.target.name === 'ipFrom') {
      state.ipFrom = e.target.value;
    } else if (e.target.name.startsWith('endpoint-')) {
      let { endpoints, headersToAdd } = this.state;
      let [, endpoint, field] = e.target.name.split('-');
      switch (field) {
        case 'enabled':
          if (endpoints[endpoint] === null) {
            endpoints[endpoint] = { headers: {}, host: '', port: '', path: '', protocol: '' };
          } else {
            endpoints[endpoint] = null;
          }
          break;
        case 'protocol':
        case 'doublecheck':
        case 'host':
        case 'path':
          endpoints[endpoint][field] = e.target.value.trim();
          break;

        case 'port':
          let port = e.target.value.trim();
          if (/^\d+$/.test(port)) {
            endpoints[endpoint][field] = port;
          }
          break;
        case 'headerkey':
          headersToAdd[endpoint].key = e.target.value.trim();
          break;
        case 'headervalue':
          headersToAdd[endpoint].value = e.target.value.trim();
          break;
        default:
          break;
      }
      state.endpoints = endpoints;
      state.headersToAdd = headersToAdd;
    }
    this.secureSetState(state);
  }

  toggleAccordion(a) {
    if (this.state.accordion === a) {
      this.secureSetState({ accordion: '' });
    } else {
      this.secureSetState({ accordion: a });
    }
  }

  addHeader(endpoint) {
    let { endpoints, headersToAdd } = this.state;
    endpoints[endpoint].headers[headersToAdd[endpoint].key] = headersToAdd[endpoint].value;
    headersToAdd[endpoint].key = '';
    headersToAdd[endpoint].value = '';
    this.secureSetState({ endpoints, headersToAdd });
  }

  deleteHeader(endpoint, key) {
    let { endpoints } = this.state;
    delete endpoints[endpoint].headers[key];
    this.secureSetState({ endpoints });
  }

  createAPIKey() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: false }, async () => {
      try {
        let apiKey = await Lambda.invoke('APIManageKey', {
          administrator: this.props.user.id,
          client: this.state.client.id,
          action: 'create',
          enable: false,
        });
        this.secureSetState({ processing: false, apiKey });
      } catch (err) {
        console.log(err);
        this.secureSetState({ snackbar: 'create', processing: false });
      }
    });
  }

  renewAPIKey() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: false }, async () => {
      try {
        await Lambda.invoke('APIManageKey', { administrator: this.props.user.id, client: this.state.client.id, action: 'delete' });
        let apiKey = await Lambda.invoke('APIManageKey', {
          administrator: this.props.user.id,
          client: this.state.client.id,
          action: 'create',
          enable: true,
        });
        this.secureSetState({ processing: false, apiKey, snackbar: 'success' }, this.toggleRenewDialog);
      } catch (err) {
        console.log(err);
        this.secureSetState({ snackbar: 'renew', processing: false });
      }
    });
  }

  delete() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: false }, async () => {
      try {
        await Lambda.invoke('APIManageKey', { administrator: this.props.user.id, client: this.state.client.id, action: 'delete' });
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.state.client.id,
            rest_api: {
              enabled: false,
              endpoints: { start: null, stop: null, meters: null, rfid: null },
              ip_from: '',
              key: '',
            },
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState(
          {
            processing: false,
            apiKeyVisible: false,
            enabled: false,
            apiKey: '',
            ipFrom: '',
            endpoints: { meters: null, start: null, stop: null, rfid: null },
            headersToAdd: {
              meters: { key: '', value: '' },
              start: { key: '', value: '' },
              stop: { key: '', value: '' },
              rfid: { key: '', value: '', doublecheck: true },
            },
            accordion: '',
            snackbar: 'success',
          },
          this.toggleDeleteDialog
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'delete' });
      }
    });
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: false }, async () => {
      let { enabled, apiKey, ipFrom, endpoints } = this.state;
      if (enabled && apiKey.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'missingAPIKey' });
        return;
      }

      ipFrom = ipFrom.trim();
      if (ipFrom.length > 0 && !isValidIP(ipFrom)) {
        this.secureSetState({ processing: false, snackbar: 'ipFrom' });
        return;
      }

      for (let e in endpoints) {
        if (!endpoints.hasOwnProperty(e) || endpoints[e] === null) {
          continue;
        }
        endpoints[e].host = endpoints[e].host.replace(/ /gim, '');
        endpoints[e].port = endpoints[e].port.replace(/ /gim, '');
        endpoints[e].path = endpoints[e].path.trim();
        endpoints[e].protocol = endpoints[e].protocol.replace(/ /gim, '');
        if (endpoints[e].host.startsWith('http://')) {
          endpoints[e].host = endpoints[e].host.substr('http://'.length);
        }
        if (endpoints[e].host.startsWith('https://')) {
          endpoints[e].host = endpoints[e].host.substr('https://'.length);
        }
        if (
          endpoints[e].host.length === 0 ||
          !/^\d+$/.test(endpoints[e].port) ||
          endpoints[e].path.includes(' ') ||
          (endpoints[e].protocol !== 'http' && endpoints[e].protocol !== 'https')
        ) {
          this.secureSetState({ processing: false, snackbar: `endpoint_${e}` });
          return;
        }
      }

      try {
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.state.client.id,
            rest_api: {
              enabled,
              endpoints,
              ip_from: ipFrom,
              key: apiKey,
            },
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false, snackbar: 'success' });
      } catch (err) {}
    });
  }

  async get() {
    try {
      let client = await DynamoDBClients.get(this.state.client.id);
      if (!client || !client.Item) {
        throw 'client_not_found';
      }
      if (!client.Item.rest_api) {
        this.secureSetState({
          processing: false,
          enabled: false,
          apiKey: '',
          ipFrom: '',
          endpoints: { meters: null, start: null, stop: null, rfid: null },
        });
      } else {
        this.secureSetState({
          processing: false,
          enabled: client.Item.rest_api.enabled,
          apiKey: client.Item.rest_api.key || '',
          ipFrom: client.Item.rest_api.ip_from,
          endpoints: {
            meters: client.Item.rest_api.endpoints.meters || null,
            start: client.Item.rest_api.endpoints.start || null,
            stop: client.Item.rest_api.endpoints.stop || null,
            rfid: client.Item.rest_api.endpoints.rfid || null,
          },
        });
      }
    } catch (err) {
      console.log(err);
      this.secureSetState({
        processing: true,
        snackbar: 'get',
        enabled: false,
        apiKey: '',
        ipFrom: '',
        endpoints: { meters: null, start: null, stop: null, rfid: null },
      });
    }
  }

  async componentDidMount() {
    this.isMounted = true;
    if (this.props.user.type === 'superuser') {
      let clients = await DynamoDBClients.getAll();
      this.secureSetState({ processing: false, client: null, clients: clients.Items.map((c) => ({ id: c.id, name: c.name })) });
    } else if (this.props.user.clientType === 'main' && this.props.user.clients.length > 0) {
      let clients = await DynamoDBClients.batchGet(this.props.user.clients);
      this.secureSetState({ processing: false, clients: clients.map((c) => ({ id: c.id, name: c.name })) });
    } else {
      this.secureSetState({ client: { id: this.props.user.client, name: '' } }, this.get);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    let accordions = ['start', 'stop', 'meters', 'rfid'];
    let body = null;

    if (this.state.client === null) {
      body = (
        <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
          <Autocomplete
            openOnFocus
            autoHighlight
            options={this.state.clients}
            getOptionLabel={getOptionLabel}
            getOptionSelected={getOptionSelected}
            fullWidth
            value={this.state.client}
            onChange={(event, newValue) => this.handleChange({ target: { name: 'client', value: newValue } })}
            disabled={this.state.processing}
            renderInput={(params) => <TextField {...params} label={this.props.translate('developers.api.client')} />}
          />
        </Grid>
      );
    } else {
      body = (
        <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
          {(this.props.user.type === 'superuser' || (this.props.user.clientType === 'main' && this.props.user.clients.length > 0)) && (
            <Autocomplete
              openOnFocus
              autoHighlight
              options={this.state.clients}
              getOptionLabel={getOptionLabel}
              getOptionSelected={getOptionSelected}
              fullWidth
              value={this.state.client}
              onChange={(event, newValue) => this.handleChange({ target: { name: 'client', value: newValue } })}
              disabled={this.state.processing}
              renderInput={(params) => <TextField {...params} label={this.props.translate('developers.api.client')} />}
            />
          )}
          <FormControlLabel
            className={this.props.classes.item}
            control={<Switch checked={this.state.enabled} onChange={this.handleChange} name='enabled' color='primary' disabled={this.state.processing} />}
            label={<Translate id='developers.api.enabled' />}
          />
          {this.state.apiKey.length > 0 ? (
            <FormControl className={this.props.classes.item}>
              <InputLabel htmlFor='apikey'>
                <Translate id='developers.api.apiKey' />
              </InputLabel>
              <Input
                id='apikey'
                type={this.state.apiKeyVisible ? 'text' : 'password'}
                value={this.state.apiKey}
                readOnly
                margin='dense'
                endAdornment={
                  <>
                    <InputAdornment position='end'>
                      <IconButton aria-label='toggle api key visibility' onClick={this.toggleVisibility} onMouseDown={(e) => e.preventDefault()}>
                        {this.state.apiKeyVisible ? <VisibilityIcon /> : <VisibilityOffIcon />}
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='copy to clipboard'
                        onClick={() => this.copyToClipboard(this.state.apiKey)}
                        onMouseDown={(e) => e.preventDefault()}
                      >
                        <FileCopyIcon />
                      </IconButton>
                    </InputAdornment>
                    <InputAdornment position='end'>
                      <IconButton
                        aria-label='copy to clipboard'
                        onClick={this.toggleRenewDialog}
                        onMouseDown={(e) => e.preventDefault()}
                        disabled={this.state.processing || !this.state.enabled}
                      >
                        <RepeatIcon />
                      </IconButton>
                    </InputAdornment>
                  </>
                }
              />
            </FormControl>
          ) : (
            <Button
              color='primary'
              variant='contained'
              className={this.props.classes.item}
              onClick={this.createAPIKey}
              disabled={this.state.processing || !this.state.enabled}
            >
              <Translate id='developers.api.generate' />
            </Button>
          )}
          <TextField
            className={this.props.classes.item}
            value={this.state.ipFrom}
            name='ipFrom'
            onChange={this.handleChange}
            aria-readonly
            label={<Translate id='developers.api.ipFrom' />}
            margin='dense'
            disabled={this.state.processing || !this.state.enabled}
          />
          {accordions.map((a) => (
            <Accordion key={a} expanded={this.state.accordion === a} onChange={() => this.toggleAccordion(a)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${a}-content`} id={`${a}-header`}>
                <Typography className={this.props.classes.accordionTitle}>
                  <Translate id={`developers.api.endpoints.${a}.title`} />
                </Typography>
                <Typography className={this.props.classes.accordionSubtitle}>
                  <Translate id={`developers.api.endpoints.${a}.subtitle`} />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container direction='column' alignItems='stretch' justifyContent='flex-start'>
                  <FormControlLabel
                    className={this.props.classes.item}
                    control={
                      <Switch
                        checked={this.state.endpoints[a] !== null}
                        onChange={this.handleChange}
                        name={`endpoint-${a}-enabled`}
                        color='primary'
                        disabled={this.state.processing || !this.state.enabled}
                      />
                    }
                    label={<Translate id='developers.api.endpointEnable' />}
                  />
                  {this.state.endpoints[a] !== null && (
                    <>
                      {a === 'rfid' && (
                        <FormControl component='fieldset'>
                          <FormLabel component='legend'>
                            <Translate id='developers.api.doublecheck' />
                          </FormLabel>
                          <RadioGroup
                            aria-label='doublecheck'
                            name={`endpoint-${a}-doublecheck`}
                            value={this.state.endpoints[a].doublecheck ? this.state.endpoints[a].doublecheck : true}
                            onChange={this.handleChange}
                          >
                            <FormControlLabel
                              value='false'
                              control={<Radio color='primary' disabled={this.state.processing || !this.state.enabled} />}
                              label='Only API'
                            />
                            <FormControlLabel
                              value='true'
                              control={<Radio color='primary' disabled={this.state.processing || !this.state.enabled} />}
                              label='Double check (Thor - API)'
                            />
                          </RadioGroup>
                        </FormControl>
                      )}
                      <FormControl component='fieldset'>
                        <FormLabel component='legend'>
                          <Translate id='developers.api.protocol' />
                        </FormLabel>
                        <RadioGroup aria-label='protocol' name={`endpoint-${a}-protocol`} value={this.state.endpoints[a].protocol} onChange={this.handleChange}>
                          <FormControlLabel
                            value='https'
                            control={<Radio color='primary' disabled={this.state.processing || !this.state.enabled} />}
                            label='HTTPS'
                          />
                          <FormControlLabel
                            value='http'
                            control={<Radio color='primary' disabled={this.state.processing || !this.state.enabled} />}
                            label='HTTP'
                          />
                        </RadioGroup>
                      </FormControl>
                      <Grid container direction='row'>
                        <Grid item xs={8}>
                          <TextField
                            className={this.props.classes.item}
                            value={this.state.endpoints[a].host}
                            name={`endpoint-${a}-host`}
                            onChange={this.handleChange}
                            label={<Translate id='developers.api.host' />}
                            margin='dense'
                            fullWidth
                            disabled={this.state.processing || !this.state.enabled}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            className={this.props.classes.item}
                            value={this.state.endpoints[a].port}
                            name={`endpoint-${a}-port`}
                            onChange={this.handleChange}
                            label={<Translate id='developers.api.port' />}
                            margin='dense'
                            fullWidth
                            disabled={this.state.processing || !this.state.enabled}
                          />
                        </Grid>
                      </Grid>
                      <TextField
                        className={this.props.classes.item}
                        value={this.state.endpoints[a].path}
                        name={`endpoint-${a}-path`}
                        onChange={this.handleChange}
                        label={<Translate id='developers.api.path' />}
                        margin='dense'
                        disabled={this.state.processing || !this.state.enabled}
                      />
                      <List
                        subheader={
                          <ListSubheader>
                            <Translate id='developers.api.headers' />
                          </ListSubheader>
                        }
                      >
                        {Object.keys(this.state.endpoints[a].headers).map((h, i) => (
                          <ListItem key={i} role={undefined}>
                            <ListItemText primary={h} secondary={this.state.endpoints[a].headers[h]} />
                            <ListItemSecondaryAction>
                              <IconButton edge='end' onClick={() => this.deleteHeader(a, h)} disabled={this.state.processing || !this.state.enabled}>
                                <DeleteIcon />
                              </IconButton>
                            </ListItemSecondaryAction>
                          </ListItem>
                        ))}
                        <ListItem role={undefined} dense>
                          <Grid container direction='row' spacing={1}>
                            <Grid item xs={6}>
                              <TextField
                                value={this.state.headersToAdd[a].key}
                                name={`endpoint-${a}-headerkey`}
                                onChange={this.handleChange}
                                placeholder={this.props.translate('developers.api.header.key')}
                                margin='dense'
                                fullWidth
                                disabled={this.state.processing || !this.state.enabled}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                value={this.state.headersToAdd[a].value}
                                name={`endpoint-${a}-headervalue`}
                                onChange={this.handleChange}
                                placeholder={this.props.translate('developers.api.header.value')}
                                margin='dense'
                                fullWidth
                                disabled={this.state.processing || !this.state.enabled}
                              />
                            </Grid>
                          </Grid>
                          <ListItemSecondaryAction>
                            <IconButton
                              edge='end'
                              onClick={() => this.addHeader(a)}
                              disabled={
                                this.state.processing ||
                                !this.state.enabled ||
                                this.state.headersToAdd[a].key.length === 0 ||
                                this.state.headersToAdd[a].value.length === 0
                              }
                            >
                              <AddIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      </List>
                    </>
                  )}
                </Grid>
              </AccordionDetails>
            </Accordion>
          ))}
          <Grid container direction='row' alignItems='center' justifyContent='space-between' className={this.props.classes.item} spacing={2}>
            <Grid item>
              <Button variant='contained' onClick={this.toggleDeleteDialog}>
                <Translate id='developers.api.delete' />
              </Button>
            </Grid>
            <Grid item>
              <Grid container direction='row' alignItems='center' justifyContent='flex-end' spacing={2}>
                <Grid item>
                  <Button variant='contained' onClick={this.get}>
                    <Translate id='developers.api.reset' />
                  </Button>
                </Grid>
                <Grid item>
                  <Button variant='contained' color='primary' onClick={this.submit}>
                    <Translate id='developers.api.submit' />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    }
    return (
      <Card>
        <Snackbar
          anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
          open={this.state.snackbar.length > 0}
          onClose={() => this.secureSetState({ snackbar: '' })}
          autoHideDuration={3000}
        >
          <Alert elevation={6} variant='filled' severity='info' icon={false}>
            {this.state.snackbar.length > 0 && <Translate id={`developers.api.snackbar.${this.state.snackbar}`} />}
          </Alert>
        </Snackbar>
        <ConfirmDialog
          id='renew'
          open={this.state.dialogs.renew}
          title={<Translate id='developers.api.dialogs.renew.title' />}
          description={<Translate id='developers.api.dialogs.renew.description' />}
          onNo={this.toggleRenewDialog}
          onYes={this.renewAPIKey}
        />
        <ConfirmDialog
          id='delete'
          open={this.state.dialogs.delete}
          title={<Translate id='developers.api.dialogs.delete.title' />}
          description={<Translate id='developers.api.dialogs.delete.description' />}
          onNo={this.toggleDeleteDialog}
          onYes={this.delete}
        />
        <CardContent>
          <Typography color='textPrimary' gutterBottom variant='h6'>
            <Translate id='developers.api.title' />
          </Typography>
          <Typography color='textSecondary' gutterBottom variant='subtitle1'>
            <Translate id='developers.api.subtitle' />
          </Typography>

          <Typography gutterBottom variant='body2'>
            <Link
              href='https://develop.thor.tools/api/getting-started/how-it-works/'
              onClick={(e) => e.preventDefault}
              target='_blank'
              rel='noopener noreferrer'
            >
              <Translate id='developers.api.documentation' />
            </Link>
          </Typography>
          {body}
        </CardContent>
      </Card>
    );
  }
}

export default withLocalize(withStyles(styles)(API));
