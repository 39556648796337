import React from 'react';
import { Link } from 'react-router-dom';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import AccountCircle from '@material-ui/icons/AccountCircle';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import VpnKey from '@material-ui/icons/VpnKey';

const Login = (props) => {
  let passwordFields = [];
  let passwordHTMLFields = [];
  let submitButton = null;
  let submitFunc = null;
  if (props.newPasswordRequired) {
    submitFunc = props.changePassw;
    passwordFields = ['oldPassw', 'newPassw', 'newPassw2'];
    submitButton = (
      <Button variant='contained' color='primary' onClick={props.changePassw} disabled={props.processing}>
        {props.processing ? <CircularProgress color='primary' size={24} /> : <Translate id='auth.login.changePassw' />}
      </Button>
    );
  } else {
    submitFunc = props.login;
    passwordFields = ['passw'];
    submitButton = (
      <Button variant='contained' color='primary' onClick={props.login} disabled={props.processing}>
        {props.processing ? <CircularProgress color='primary' size={24} /> : <Translate id='auth.login.login' />}
      </Button>
    );
  }

  passwordHTMLFields = passwordFields.map((field, idx) => (
    <Grid item container spacing={1} direction='row' alignItems='flex-end' wrap='nowrap' key={idx}>
      <Grid item>
        <VpnKey color='action' />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label={props.translate(`auth.login.${field}`)}
          name={field}
          value={props[field]}
          onChange={props.onChange}
          type={props.showPassw ? 'text' : 'password'}
          onKeyPress={props.onKeyPress(submitFunc)}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton aria-label='toggle password visibility' onClick={props.togglePasswordVisibility}>
                  {props.showPassw ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            ),
          }}
          fullWidth
        />
      </Grid>
    </Grid>
  ));

  return (
    <Grid container spacing={1} direction='column' justifyContent='center' alignItems='center'>
      <Grid item container spacing={1} direction='row' alignItems='flex-end' wrap='nowrap'>
        <Grid item>
          <AccountCircle color='action' />
        </Grid>
        <Grid item xs={12}>
          <TextField
            label={props.translate('auth.login.email')}
            name='email'
            value={props.email}
            onKeyPress={props.onKeyPress(submitFunc)}
            onChange={props.onChange}
            fullWidth
          />
        </Grid>
      </Grid>
      {passwordHTMLFields}
      <Grid item>
        <Typography color='error' variant='caption'>
          {props.error !== null ? <Translate id={`auth.login.errors.${props.error}`} /> : ' '}
        </Typography>
      </Grid>
      <Grid item>{submitButton}</Grid>
      <Grid item>
        <Link to='/forgot'>
          <Typography>
            <Translate id='auth.login.forgot' />
          </Typography>
        </Link>
      </Grid>
    </Grid>
  );
};

export default withLocalize(Login);
