import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import LinearProgress from '@material-ui/core/LinearProgress';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import { KeyboardDatePicker } from '@material-ui/pickers';
import withStyles from '@material-ui/core/styles/withStyles';
import DynamoDBTransactionsFinished from '../../../aws/dynamodb/transactions-finished';
import DynamoDBEndUsers from '../../../aws/dynamodb/endusers';
import { convertDataURIToBinary } from '../../../utilities';

const getOptionLabelC = (clients) => (option) => {
  if (typeof option === 'string') {
    let client = clients.find((c) => c.id === option);
    if (client === undefined) {
      return 'UNKNOWN';
    }
    return client.name;
  }
  return option.name;
};

const getOptionLabel = (users) => (option) => {
  if (typeof option === 'string') {
    let user = users.find((c) => c.id === option);
    if (user === undefined) {
      return 'UNKNOWN';
    }
    return user.name || user.company_name || user.ragsoc;
  }
  return option.name || option.company_name || option.ragsoc;
};

const getCurrency = (c) => {
  switch (c) {
    case 'eur':
      return '€';
    case 'gpb':
      return '£';
    case 'usd':
      return '$';
    case 'chf':
      return 'chf';
    default:
      return '';
  }
};

const DateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const getDate = (time, locale) => {
  let date = new Date(time);

  return date.toLocaleTimeString(locale.replace('_', '-'), DateOptions);
};

const styles = {
  input: {
    display: 'none',
  },
};
class DocumentsDialogUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: true,
      list: [],
      filteredList: [],
      users: {},
      filters: {
        type: 'invoice',
        client: null,
        user: null,
        month: null,
        users: [],
      },
      pagination: {
        page: 0,
        rowsPerPage: 10,
      },
    };
    this.getTransactions = this.getTransactions.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submitEnabled = this.submitEnabled.bind(this);
    this.cancel = this.cancel.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this.reader = new FileReader();
    this.reader.onloadend = this.submit;
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  handleChangePage(event, newPage) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = newPage;
    this.secureSetState({ pagination }, this.getChargeboxes);
  }

  handleChangeRowsPerPage(event) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    pagination.rowsPerPage = event.target.value;
    this.secureSetState({ pagination }, this.getChargeboxes);
  }

  async getTransactions() {
    try {
      let records;
      if (this.props.user.clientType === 'main' && this.props.user.clients.length > 0) {
        records = await DynamoDBTransactionsFinished.getAll(
          {
            type: this.props.user.type,
            client: this.state.filters.client && this.state.filters.client.id ? this.state.filters.client.id : this.props.user.client,
          },
          { getReadyForInvoicing: true },
          null
        );
      } else {
        records = await DynamoDBTransactionsFinished.getAll(
          {
            type: this.props.user.type,
            client: this.props.user.client,
          },
          { getReadyForInvoicing: true },
          null
        );
      }
      let usersToGet = [];
      for (let item of records.Items) {
        if (usersToGet.indexOf(item.user) === -1 /*&& !this.state.users.hasOwnProperty(item.user)*/) {
          usersToGet.push(item.user);
        }
      }
      let users = {};
      let usersFilter = [];
      for (let i = 0; i < Math.ceil(usersToGet.length / 25); i++) {
        let ddbUsers = await DynamoDBEndUsers.batchGet(usersToGet.slice(i * 25, (i + 1) * 25));
        usersFilter = usersFilter.concat(ddbUsers);
        for (let user of ddbUsers) {
          let { id, ...u } = user;
          users[id] = u;
        }
      }

      this.secureSetState({
        list: records.Items.slice(),
        filteredList: records.Items.slice(),
        users,
        filters: {
          type: 'invoice',
          client: this.state.filters.client ? this.state.filters.client : null,
          user: null,
          month: null,
          users: usersFilter,
        },
        pagination: {
          page: 0,
          rowsPerPage: 10,
        },
        processing: false,
      });
    } catch (err) {
      console.log(err);
      this.secureSetState({ processing: false });
    }
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name } = e.target;
    if (name === 'document') {
      let files = e.target.files;
      this.reader.readAsDataURL(files[0]);
      return;
    }

    let { value } = e.target;
    let { filters } = this.state;
    filters[name] = value;
    let filteredList = this.state.list.slice();
    if (filters.user !== null) {
      filteredList = filteredList.filter((el) => el.user === filters.user.id);
    }
    if (filters.month !== null) {
      let monthStart = new Date(filters.month.getTime());
      let monthFinish = new Date(filters.month.getTime());
      monthStart.setDate(1);
      monthStart.setHours(0, 0, 0, 0);
      monthFinish.setMonth(monthFinish.getMonth() + 1);
      monthFinish.setDate(monthFinish.getDate() - 1);
      monthFinish.setHours(23, 59, 59, 999);
      let start = monthStart.getTime();
      let finish = monthFinish.getTime();

      filteredList = filteredList.filter((el) => el.date_finished >= start && el.date_finished <= finish);
    }
    if (name === 'client' && this.state.filters.client.id !== e.target.value) {
      this.secureSetState({ filters, filteredList }, this.getTransactions);
    } else {
      this.secureSetState({ filters, filteredList });
    }
  }

  submitEnabled() {
    return this.state.filters.user !== null && this.state.filters.type !== null && this.state.filters.month !== null && this.state.filteredList.length > 0;
  }

  cancel() {
    this.props.handleClose(null);
  }

  submit() {
    this.secureSetState({ processing: true }, async () => {
      let date = parseInt(
        `${this.state.filters.month.getFullYear()}${this.state.filters.month.getMonth() >= 9 ? '' : 0}${this.state.filters.month.getMonth() + 1}`
      );
      let user = this.state.filters.user.id;
      let type = this.state.filters.type;
      let document = convertDataURIToBinary(this.reader.result);
      let client = this.state.filters.client && this.state.filters.client.id ? this.state.filters.client.id : this.props.user.client;
      this.props.handleClose({ date, user, type, document, client });
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.secureSetState(
        {
          processing: true,
          list: [],
          filteredList: [],
          users: {},
          filters: {
            type: 'invoice',
            //client: null,
            user: null,
            month: null,
            users: [],
          },
          pagination: {
            page: 0,
            rowsPerPage: 10,
          },
        },
        this.getTransactions
      );
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Dialog onClose={this.cancel} aria-labelledby='document-dialog-upload-title' open={this.props.open} maxWidth='md' fullWidth>
        <LinearProgress color='primary' hidden={!this.state.processing} />
        <DialogTitle id='document-dialog-upload-title'>
          <Translate id='documents.dialogs.upload.title' />
        </DialogTitle>
        <DialogContent>
          <Grid container direction='row' wrap='wrap' alignItems='center' justifyContent='flex-start' spacing={2}>
            <Grid item xs={2}>
              <FormControl>
                <InputLabel id='document-select-type'>
                  <Translate id='documents.type' />
                </InputLabel>
                <Select labelId='document-select-type' name='type' value={this.state.filters.type} onChange={this.onChange}>
                  <MenuItem value='invoice'>
                    <Translate id='documents.types.invoice' />
                  </MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {this.props.user.clientType === 'main' && this.props.user.clients.length > 0 && (
              <Grid item xs={3}>
                <Autocomplete
                  openOnFocus
                  options={this.props.clients}
                  getOptionLabel={getOptionLabelC(this.props.clients)}
                  getOptionSelected={(o, t) => o.id === t.id}
                  autoHighlight
                  value={this.state.filters.client}
                  onChange={(event, newValue) => this.onChange({ target: { name: 'client', value: newValue } })}
                  renderInput={(params) => <TextField {...params} label={this.props.translate('documents.client')} />}
                />
              </Grid>
            )}
            <Grid item xs={this.props.user.clientType === 'main' && this.props.user.clients.length > 0 ? 3 : 6}>
              <Autocomplete
                openOnFocus
                options={this.state.filters.users}
                getOptionLabel={getOptionLabel(this.state.filters.users)}
                getOptionSelected={(o, t) => o.id === t.id}
                autoHighlight
                value={this.state.filters.user}
                onChange={(event, newValue) => this.onChange({ target: { name: 'user', value: newValue } })}
                renderInput={(params) => <TextField {...params} label={this.props.translate('documents.user')} />}
              />
            </Grid>
            <Grid item xs={4}>
              <KeyboardDatePicker
                variant='modal'
                label={<Translate id='documents.month' />}
                openTo='month'
                views={['year', 'month']}
                name='month'
                clearable={true}
                value={this.state.filters.month}
                maxDate={new Date()}
                onChange={(d) => this.onChange({ target: { name: 'month', value: d } })}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
              />
            </Grid>
          </Grid>
          <TableContainer>
            <Table aria-label='table' size='small'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Translate id='documents.user' />
                  </TableCell>
                  <TableCell>
                    <Translate id='documents.started' />
                  </TableCell>
                  <TableCell>
                    <Translate id='documents.finished' />
                  </TableCell>
                  <TableCell>
                    <Translate id='documents.amount' />
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(this.state.pagination.rowsPerPage > 0
                  ? this.state.filteredList.slice(
                      this.state.pagination.page * this.state.pagination.rowsPerPage,
                      this.state.pagination.page * this.state.pagination.rowsPerPage + this.state.pagination.rowsPerPage
                    )
                  : this.state.filteredList
                ).map((row, id) => (
                  <>
                    <TableRow key={id}>
                      <TableCell>
                        {this.state.users[row.user]
                          ? this.state.users[row.user].name || this.state.users[row.user].company_name || this.state.users[row.user].ragsoc
                          : ''}
                      </TableCell>
                      <TableCell>{getDate(row.date_started, this.props.user.locale)}</TableCell>
                      <TableCell>{getDate(row.date_finished, this.props.user.locale)}</TableCell>
                      <TableCell>
                        {row.payment && row.payment.method && row.payment.method !== 'free' && row.payment.method !== null
                          ? `${row.payment.success ? row.payment.price || row.payment.amount || 0 : 0} ${getCurrency(row.payment.currency)}`
                          : this.props.translate('transactions.paymentMethods.gratis')}
                      </TableCell>
                    </TableRow>
                  </>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            component='div'
            rowsPerPageOptions={[10, 20, 50]}
            count={this.state.filteredList.length}
            rowsPerPage={this.state.pagination.rowsPerPage}
            page={this.state.pagination.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
            labelRowsPerPage={this.props.translate('table.rowsPerPage')}
            nextIconButtonText={this.props.translate('table.next')}
            backIconButtonText={this.props.translate('table.previous')}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancel} color='default' autoFocus>
            <Translate id='documents.dialogs.close' />
          </Button>
          <input
            accept='application/pdf'
            className={this.props.classes.input}
            id='icon-button-document-upload'
            type='file'
            name='document'
            onChange={this.onChange}
            disabled={this.state.processing || !this.submitEnabled()}
          />
          <label htmlFor='icon-button-document-upload'>
            <Button component='span' color='primary' disabled={this.state.processing || !this.submitEnabled()}>
              <Translate id='documents.dialogs.upload.submit' />
            </Button>
          </label>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(DocumentsDialogUpload));
