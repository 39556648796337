import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';

const VendorsDialogNewEdit = (props) => (
  <Dialog onClose={props.handleClose} aria-labelledby='vendor-dialog-new-edit-title' open={props.open} fullWidth maxWidth='xs'>
    <DialogTitle id='vendor-dialog-new-edit-title'>
      {props.isEdit ? <Translate id='vendors.dialogs.edit.title' /> : <Translate id='vendors.dialogs.create.title' />}
    </DialogTitle>
    <DialogContent>
      <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={2}>
        <TextField label={props.translate('vendors.id')} name='id' value={props.vendor.id} onChange={props.onChange} fullWidth disabled={props.isEdit} />
        <TextField label={props.translate('vendors.name')} name='name' value={props.vendor.name} onChange={props.onChange} fullWidth />
      </Grid>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.handleClose} color='default' autoFocus>
        <Translate id='vendors.dialogs.close' />
      </Button>
      <Button onClick={props.submit} color='primary'>
        {props.isEdit ? <Translate id='vendors.dialogs.edit.submit' /> : <Translate id='vendors.dialogs.create.submit' />}
      </Button>
    </DialogActions>
  </Dialog>
);

export default withLocalize(VendorsDialogNewEdit);
