import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Snackbar from '@material-ui/core/Snackbar';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import RefreshIcon from '@material-ui/icons/Refresh';
import SearchIcon from '@material-ui/icons/Search';
import DialogNewEdit from './presentation.dialog.newedit';
import DialogDelete from './presentation.dialog.delete';

const useStyles = makeStyles({
  searchTextField: {
    flex: 1,
    marginRight: '1rem',
  },
  headerCell: {
    borderBottom: 0,
  },
  listItemIcon: {
    minWidth: '2rem',
  },
});

const Vendors = (props) => {
  const classes = useStyles(props);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <Paper elevation={3}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.snackbar !== null} autoHideDuration={6000} onClose={props.onSnackbarClose}>
        <Alert
          elevation={6}
          variant='filled'
          severity='info'
          onClose={props.onSnackbarClose}
          icon={false}
          action={
            <IconButton size='small' aria-label='close' color='inherit' onClick={props.onSnackbarClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          {props.snackbar === null ? '' : <Translate id={`vendors.snackbar.${props.snackbar}`} />}
        </Alert>
      </Snackbar>
      <DialogNewEdit
        handleClose={() => props.closeCreateDialog(false)}
        submit={() => props.closeCreateDialog(true)}
        open={props.createVendorOpen}
        onChange={props.onChange}
        vendor={props.vendorToCreate}
      />
      <DialogNewEdit
        handleClose={() => props.closeEditDialog(false)}
        submit={() => props.closeEditDialog(true)}
        open={props.editVendorOpen}
        onChange={props.onChange}
        vendor={props.selectedVendor}
        isEdit
      />
      <DialogDelete
        handleClose={() => props.closeDeleteDialog(false)}
        submit={() => props.closeDeleteDialog(true)}
        open={props.deleteVendorOpen}
        vendor={props.selectedVendor}
      />
      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell className={classes.headerCell}>
                  <Translate id='vendors.id' />
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <Translate id='vendors.name' />
                </TableCell>
                <TableCell className={classes.headerCell}>
                  <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='nowrap'>
                    <Grid item>
                      <IconButton onClick={props.getVendors}>
                        <RefreshIcon />
                      </IconButton>
                      <IconButton onClick={props.openCreateDialog}>
                        <PlusOneIcon />
                      </IconButton>
                    </Grid>
                    <IconButton onClick={props.toggleFilters}>
                      <FilterListIcon />
                    </IconButton>
                  </Grid>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={99} style={{ paddingBottom: 0, paddingTop: 0 }}>
                  <Collapse in={props.filters.open} timeout='auto'>
                    <Grid container direction='row' justifyContent='flex-start' alignItems='center'>
                      <TextField
                        className={classes.searchTextField}
                        InputProps={{ disableUnderline: true }}
                        value={props.filters.value}
                        onChange={props.filterChanged}
                        placeholder={props.translate('filter')}
                      />
                      <IconButton onClick={props.filterSearch}>
                        <SearchIcon />
                      </IconButton>
                    </Grid>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? props.list.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : props.list).map((row) => (
                <TableRow key={row.id}>
                  <TableCell>{row.id}</TableCell>
                  <TableCell>{row.name}</TableCell>
                  <TableCell>
                    <IconButton onClick={props.openCloseMenu(row)}>
                      <MoreVertIcon />
                    </IconButton>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPageOptions={[10, 20, 50]}
          count={props.list.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={props.translate('table.rowsPerPage')}
          nextIconButtonText={props.translate('table.next')}
          backIconButtonText={props.translate('table.previous')}
        />
        <Menu anchorEl={props.menuAnchorEl} open={props.menuAnchorEl !== null} onClose={props.openCloseMenu(null)}>
          <MenuItem button onClick={props.openEditDialog}>
            <ListItemIcon className={classes.listItemIcon}>
              <SearchIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit' noWrap>
              <Translate id='vendors.details' />
            </Typography>
          </MenuItem>
          <MenuItem button onClick={props.openDeleteDialog}>
            <ListItemIcon className={classes.listItemIcon}>
              <DeleteIcon fontSize='small' />
            </ListItemIcon>
            <Typography variant='inherit' noWrap>
              <Translate id='vendors.delete' />
            </Typography>
          </MenuItem>
        </Menu>
      </Grid>
    </Paper>
  );
};
export default withLocalize(Vendors);
