import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';

const ClientsDialogNewEdit = (props) => {
  let content = null;
  if (props.isEdit) {
    content = (
      <DialogContent>
        <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
          <TextField label={props.translate('clients.name')} name='name' value={props.client.name} onChange={props.onChange} fullWidth />
        </Grid>
      </DialogContent>
    );
  } else {
    content = (
      <React.Fragment>
        <DialogContent>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
            <TextField label={props.translate('clients.name')} name='clientName' value={props.client.clientName} onChange={props.onChange} fullWidth />
          </Grid>
        </DialogContent>
        <DialogTitle>
          <Translate id='clients.dialogs.create.titleAdministrator' />
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
            <TextField label={props.translate('clients.email')} name='adminEmail' value={props.client.adminEmail} onChange={props.onChange} fullWidth />

            <TextField label={props.translate('clients.name')} name='adminName' value={props.client.adminName} onChange={props.onChange} fullWidth />

            <FormControl>
              <InputLabel id='client-select-language'>
                <Translate id='clients.language' />
              </InputLabel>
              <Select labelId='client-select-language' name='adminLanguage' value={props.client.adminLanguage} onChange={props.onChange}>
                <MenuItem value='it_IT'>Italiano</MenuItem>
                <MenuItem value='en_GB'>English</MenuItem>
              </Select>
            </FormControl>
            <FormControlLabel
              value='end'
              control={<Checkbox checked={props.client.hasPrivileges} name='hasPrivileges' onClick={props.onChange} color='primary' />}
              label={props.translate('clients.hasPrivileges')}
              labelPlacement='end'
            />
          </Grid>
        </DialogContent>
      </React.Fragment>
    );
  }
  return (
    <Dialog onClose={props.handleClose} aria-labelledby='client-dialog-new-edit-title' open={props.open} fullWidth maxWidth='xs'>
      <DialogTitle id='client-dialog-new-edit-title'>
        {props.isEdit ? <Translate id='clients.dialogs.edit.title' /> : <Translate id='clients.dialogs.create.title' />}
      </DialogTitle>
      {content}

      <DialogActions>
        <Button onClick={props.handleClose} color='default' autoFocus>
          <Translate id='clients.dialogs.close' />
        </Button>
        <Button onClick={props.submit} color='primary'>
          {props.isEdit ? <Translate id='clients.dialogs.edit.submit' /> : <Translate id='clients.dialogs.create.submit' />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withLocalize(ClientsDialogNewEdit);
