import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import RefreshIcon from '@material-ui/icons/Refresh';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DynamoDBHeartbeats from '../../../aws/dynamodb/heartbeats';

const getDuration = (timestamp) => {
  let secNum = Math.round(timestamp / 1000);
  let hours = Math.floor(secNum / 3600);
  let minutes = Math.floor((secNum - hours * 3600) / 60);
  let seconds = secNum - hours * 3600 - minutes * 60;
  let ret = '';
  if (hours > 0) {
    ret += `${hours}h`;
  }
  if (minutes > 0) {
    ret += `${minutes}m`;
  }
  if (seconds > 0) {
    ret += `${seconds}s`;
  }
  return ret;
};

const ChargeboxesDialogHeartbeats = (props) => {
  const [date, setDate] = React.useState(null);
  const [heartbeats, setHeartbeats] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => setPage(newPage);

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  React.useEffect(() => {
    if (date === null) {
      return;
    }
    const fetch = async () => {
      try {
        let from = new Date(date.getTime());
        let to = new Date(date.getTime());
        from.setHours(0, 0, 0, 0);
        to.setHours(24, 0, 0, 0);
        let timestamps = await DynamoDBHeartbeats.query(props.chargebox.id, from.getTime(), to.getTime());
        let h = [];
        let locale = props.user.locale.replace('_', '-');
        for (let i = 0; i < timestamps.length; i++) {
          let toPrev = i === timestamps.length - 1 ? '' : getDuration(timestamps[i] - timestamps[i + 1]);
          h.push({
            date: new Date(timestamps[i]).toLocaleTimeString(locale),
            toPrev: toPrev,
          });
        }
        setHeartbeats(h);
        setPage(0);
      } catch (err) {
        console.log(err);
      }
    };
    fetch();
  }, [date, props.chargebox.id, props.user.locale]);

  React.useEffect(() => {
    if (!props.open) {
      return;
    }
    setDate(new Date());
    return () => {
      setPage(0);
      setRowsPerPage(10);
      setDate(null);
      setHeartbeats([]);
    };
  }, [props.open]);

  let minDate = new Date();
  minDate.setFullYear(minDate.getFullYear() - 1);
  return (
    <Dialog onClose={props.handleClose} aria-labelledby='dialog-heartbeats-title' open={props.open} fullWidth maxWidth='sm'>
      <DialogTitle id='dialog-heartbeats-title' disableTypography>
        <Typography variant='h6'>
          <Translate id='chargeboxes.dialogs.heartbeats.title' />
        </Typography>
        <Typography variant='subtitle1' color='textSecondary'>
          {props.chargebox.alias}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Grid container direction='row' alignItems='flex-end' justifyContent='flex-start'>
          <KeyboardDatePicker
            variant='inline'
            format='dd/MM/yyyy'
            placeholder={props.translate('dateFormat')}
            margin='normal'
            label={<Translate id='chargeboxes.dialogs.heartbeats.date' />}
            name='to'
            value={date}
            maxDate={new Date()}
            minDate={minDate}
            onChange={setDate}
            KeyboardButtonProps={{ 'aria-label': 'change date' }}
            autoOk
          />
          <IconButton onClick={() => setDate(new Date(date.getTime()))}>
            <RefreshIcon />
          </IconButton>
        </Grid>
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Translate id='chargeboxes.dialogs.heartbeats.hour' />
                </TableCell>
                <TableCell>
                  <Translate id='chargeboxes.dialogs.heartbeats.previous' />
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {(rowsPerPage > 0 ? heartbeats.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : heartbeats).map((row, idx) => (
                <TableRow key={idx}>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.toPrev}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPageOptions={[10, 20, 50]}
          count={heartbeats.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          labelRowsPerPage={props.translate('table.rowsPerPage')}
          nextIconButtonText={props.translate('table.next')}
          backIconButtonText={props.translate('table.previous')}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={props.handleClose} color='default' autoFocus>
          <Translate id='chargeboxes.dialogs.close' />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withLocalize(ChargeboxesDialogHeartbeats);
