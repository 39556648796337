import React from 'react';
import { withRouter } from 'react-router-dom';
import { withLocalize } from 'react-localize-redux';
import { logout as CognitoLogout } from '../../aws/cognito';
import UserContext from '../../context';
import Home from './presentation';

class HomeContainer extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			drawerOpen: false,
			customizeMenuOpen: false,
		};
		this.handleClickCustom = this.handleClickCustom.bind(this);
		this.routeChanged = this.routeChanged.bind(this);
		this.logout = this.logout.bind(this);
		this.toggleDrawer = this.toggleDrawer.bind(this);
		this.secureSetState = this.secureSetState.bind(this);
		this._isMounted = false;
		this.openCustom = this.secureSetState.bind(this);
	}

	get isMounted() {
		return this._isMounted;
	}

	set isMounted(val) {
		this._isMounted = val;
	}

	secureSetState(state, callback) {
		if (!this.isMounted) {
			return;
		}
		this.setState(state, callback);
	}

	async logout() {
		try {
			await CognitoLogout();
		} catch (err) {
			console.log(err);
		}
		this.props.history.replace('/login');
	}

	toggleDrawer(event) {
		if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
			return;
		}

		let drawerOpen = !this.state.drawerOpen;
		this.secureSetState({ drawerOpen });
	}

	handleClickCustom() {
		let customizeMenuOpen = !this.state.customizeMenuOpen;
		this.secureSetState({ customizeMenuOpen });
	}

	routeChanged() {
		let path = this.props.location.pathname.replace(/\//gim, '');
		if (path.length === 0) {
			document.title = 'Thor';
		} else {
			document.title = 'Thor - ' + this.props.translate(`sidenav.${path}`);
		}
	}

	componentDidMount() {
		this.isMounted = true;
		this.routeChanged();
	}

	componentDidUpdate(prevProps) {
		if (this.props.location !== prevProps.location) {
			this.routeChanged();
		}
	}

	componentWillUnmount() {
		this.isMounted = false;
	}

	render() {
		return <Home {...this.state} user={this.context} logout={this.logout} toggleDrawer={this.toggleDrawer} handleClickCustom={this.handleClickCustom} />;
	}
}

HomeContainer.contextType = UserContext;
export default withLocalize(withRouter(HomeContainer));
