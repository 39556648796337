import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_transactions_running`;

const getByChargebox = (chargebox, startedOnly) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		KeyConditionExpression: '#chargebox = :chargebox',
		ExpressionAttributeNames: {
			'#chargebox': 'chargebox',
		},
		ExpressionAttributeValues: {
			':chargebox': chargebox,
		},
		ReturnConsumedCapacity: 'NONE',
	};
	if (startedOnly) {
		params.FilterExpression = '#started = :started';
		params.ExpressionAttributeNames['#started'] = 'started';
		params.ExpressionAttributeValues[':started'] = true;
	}
	return DDB.query(params).promise();
};

const getByClient = (client, startedOnly) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		IndexName: 'client-timestamp-index',
		KeyConditionExpression: '#client = :client',
		ExpressionAttributeNames: {
			'#client': 'client',
		},
		ExpressionAttributeValues: {
			':client': client,
		},
		ReturnConsumedCapacity: 'NONE',
	};

	if (startedOnly) {
		params.FilterExpression = '#started = :started';
		params.ExpressionAttributeNames['#started'] = 'started';
		params.ExpressionAttributeValues[':started'] = true;
	}
	return DDB.query(params).promise();
};

const getAll = (startedOnly) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		ReturnConsumedCapacity: 'NONE',
	};

	if (startedOnly) {
		params.FilterExpression = '#started = :started';
		params.ExpressionAttributeNames = { '#started': 'started' };
		params.ExpressionAttributeValues = { ':started': true };
	}
	return DDB.scan(params).promise();
};

export default {
	getByChargebox,
	getByClient,
	getAll,
};
