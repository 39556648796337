import React from 'react';
import DynamoDBChargeboxes from '../../../aws/dynamodb/chargeboxes';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import Lambda from '../../../aws/lambda';
import UserContext from '../../../context';
import Reports from './presentation';

const getReportFields = (type) => {
  switch (type) {
    case 'finished-transactions':
      return {
        type: type,
        from: null,
        to: null,
        chargebox: null,
        all: false,
      };
    case 'reservations':
      return {
        type: type,
        from: null,
        to: null,
        chargebox: null,
        all: false,
      };
    case 'monthly-report':
      return {
        type: type,
        from: null,
        to: null,
        chargebox: null,
        all: false,
      };
    case 'rfids-recharges':
      return {
        type: type,
        from: null,
        to: null,
        filter: 'rfid',
        email: '',
        rfid: '',
        client: null,
      };

    default:
      return { type, from: null, to: null };
  }
};
class ReportsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      snackbar: null,
      chargeboxes: [],
      clients: [],
      client: '',
      report: { type: '', from: null, to: null },
    };
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.selectClient = this.selectClient.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onSnackbarClose() {
    this.secureSetState({ snackbar: null });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    const { name, value } = e.target;
    let { report } = this.state;
    if (name === 'type') {
      report = getReportFields(value);
      this.secureSetState({ report });
      return;
    } else if (name === 'filter' && report.type === 'rfids-recharges') {
      report.email = '';
      report.rfid = '';
    } else if (name === 'all' && (report.type === 'finished-transactions' || report.type === 'reservations')) {
      report.all = !report.all;
      if (report.all) {
        report.chargebox = null;
      }
      this.secureSetState({ report });
      return;
    }

    report[name] = value;
    this.secureSetState({ report });
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        let { from, to, type } = this.state.report;
        if (type.trim().length === 0 || from === null || to === null || isNaN(from.getTime()) || isNaN(to.getTime())) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        let report = {
          user: this.context.id,
          client: this.state.client.id || null,
          clients: this.context.clients || null,
          type: type.trim(),
          dates: { start: null, end: null },
          options: {},
        };

        let start = from.getTime();
        let end = to.getTime();
        if (start > end) {
          this.secureSetState({ processing: false, snackbar: 'dates1' });
          return;
        }
        if (end - start > 7776000000) {
          this.secureSetState({ processing: false, snackbar: 'dates2' });
          return;
        }

        report.dates.start = from.getTime();
        report.dates.end = to.getTime();

        switch (type) {
          case 'finished-transactions':
          case 'reservations':
            let { all, chargebox } = this.state.report;
            if (!all && chargebox === null) {
              this.secureSetState({ processing: false, snackbar: 'mandatory' });
              return;
            }
            report.options.allChargeboxes = all;
            report.options.chargebox = chargebox === null ? null : chargebox.id;
            break;
          case 'rfids-recharges':
            let { filter, email, rfid, client } = this.state.report;
            report.options.timezoneOffset = new Date().getTimezoneOffset();
            if (this.context.type === 'superuser') {
              if (client === null || typeof client.id !== 'string' || client.id.length === 0) {
                this.secureSetState({ processing: false, snackbar: 'mandatory' });
                return;
              }
              report.options.client = client.id;
            } else if (this.context.clientType === 'main' && this.context.clients.length > 0) {
              if (this.state.client === null || typeof this.state.client.id !== 'string' || this.state.client.id.length === 0) {
                console.log(this.state.client);
                this.secureSetState({ processing: false, snackbar: 'mandatory' });
                return;
              }
              report.options.client = this.state.client.id;
            } else {
              report.options.client = this.context.client;
            }
            report.options.email = '';
            report.options.rfid = '';
            if (filter === 'email') {
              if (email.length === 0) {
                this.secureSetState({ processing: false, snackbar: 'mandatory' });
                return;
              }
              report.options.email = email.toLowerCase().trim();
            }
            if (filter === 'rfid') {
              if (rfid.length === 0) {
                this.secureSetState({ processing: false, snackbar: 'mandatory' });
                return;
              }
              report.options.rfid = rfid.trim();
            }
            break;
          case 'monthly-report':
            break;
          default:
            this.secureSetState({ processing: false, snackbar: 'type' });
            return;
        }

        await Lambda.invoke('RetrieveReportData', report);
        this.secureSetState({ processing: false, snackbar: 'success' });
      } catch (err) {
        this.secureSetState({ processing: false, snackbar: 'generic' });
      }
    });
  }

  selectClient(e, client) {
    if (this.state.processing) {
      return;
    }
    if (client) {
      this.secureSetState({ client: client });
    } else {
      this.secureSetState({ client: null });
    }
  }

  async componentDidMount() {
    this.isMounted = true;
    try {
      let data = await DynamoDBChargeboxes.getAll({ type: this.context.type, client: this.context.client });
      let chargeboxes = data.Items.map((c) => ({ id: c.id, alias: c.alias }));
      let clients = [];
      if (this.context.type === 'superuser') {
        data = await DynamoDBClients.getAll();
        clients = data.Items.map((c) => ({ id: c.id, alias: c.name }));
      } else if (this.context.clientType === 'main' && this.context.clients.length > 0) {
        let data = await DynamoDBClients.batchGet(this.context.clients);
        for (let c of data) {
          clients.push({
            id: c.id,
            name: c.name,
          });
        }
        chargeboxes = [];
        for (let c in this.context.clients) {
          data = await DynamoDBChargeboxes.getAll({ type: this.context.type, client: this.context.clients[c] });
          let records = data.Items.map((c) => ({ id: c.id, alias: c.alias }));
          chargeboxes = chargeboxes.concat(records);
        }
      }
      this.secureSetState({ chargeboxes, clients, processing: false });
    } catch (err) {
      console.log(err);
      this.secureSetState({ processing: true });
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Reports
        {...this.state}
        isMain={this.context.clientType === 'main' && this.context.clients.length > 0}
        isSuperuser={this.context.type === 'superuser'}
        onSnackbarClose={this.onSnackbarClose}
        selectClient={this.selectClient}
        onChange={this.onChange}
        submit={this.submit}
      />
    );
  }
}

ReportsContainer.contextType = UserContext;

export default ReportsContainer;
