import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Lambda from '../../../aws/lambda';

const styles = {
  button: { margin: '1rem' },
};
class ChargeboxesDialogReset extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processing: false, type: 'soft' };

    this.changeType = this.changeType.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  changeType(t) {
    return () => this.secureSetState({ type: t });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.secureSetState({ type: 'soft' });
    }
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let functionName = '';
        if (this.props.chargebox.type.msg === 'soap' && this.props.chargebox.type.version === '1.5') {
          functionName = 'OcppSoap15Reset';
        } else if (this.props.chargebox.type.msg === 'json' && this.props.chargebox.type.version === '1.6') {
          functionName = 'OcppJson16Reset';
        }
        let success = await Lambda.invoke(functionName, {
          chargebox: this.props.chargebox.id,
          user: this.props.user.id,
          hard: this.state.type === 'hard',
        });
        if (functionName === 'OcppSoap15Reset') {
          this.secureSetState({ processing: false }, () => this.props.handleClose(success, false));
        } else {
          this.secureSetState({ processing: false }, () => this.props.handleClose(true, false));
        }
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false }, () => this.props.handleClose(false, false));
      }
    });
  }

  cancel() {
    this.props.handleClose(false, true);
  }

  render() {
    return (
      <Dialog onClose={this.cancel} aria-labelledby='chargebox-dialog-reset-title' open={this.props.open} fullWidth maxWidth='xs'>
        <DialogTitle id='chargebox-dialog-reset-title'>
          <Translate id='chargeboxes.dialogs.reset.title' />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Translate id='chargeboxes.dialogs.reset.text' />
          </Typography>
          <Typography>{`${this.props.chargebox.id} - ${this.props.chargebox.alias}`}</Typography>
          <Grid container direction='row' alignItems='center' justifyContent='center' wrap='wrap'>
            <Button
              className={this.props.classes.button}
              onClick={this.changeType('hard')}
              color={this.state.type === 'hard' ? 'primary' : 'default'}
              variant='contained'
            >
              <Translate id='chargeboxes.dialogs.reset.hard' />
            </Button>
            <Button
              className={this.props.classes.button}
              onClick={this.changeType('soft')}
              color={this.state.type === 'soft' ? 'primary' : 'default'}
              variant='contained'
            >
              <Translate id='chargeboxes.dialogs.reset.soft' />
            </Button>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancel} color='default' autoFocus>
            <Translate id='chargeboxes.dialogs.close' />
          </Button>
          <Button onClick={this.submit} color='primary'>
            <Translate id='chargeboxes.dialogs.reset.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(ChargeboxesDialogReset));
