import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const AdministratorsDialogDelete = (props) => (
	<Dialog
		onClose={props.handleClose}
		aria-labelledby="administrator-dialog-delete-title"
		aria-describedby="administrator-dialog-delete-description"
		open={props.open}
		fullWidth
		maxWidth="xs">
		<DialogTitle id="administrator-dialog-delete-title">
			<Translate id="administrators.dialogs.delete.title" />
		</DialogTitle>
		<DialogContent>
			<DialogContentText id="administrator-dialog-delete-description">
				<Typography>
					<Translate id="administrators.dialogs.delete.text" />
				</Typography>
			</DialogContentText>
			<DialogContentText>
				<Typography>{`${props.administrator.email} - ${props.administrator.name}`}</Typography>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.handleClose} color="default" autoFocus>
				<Translate id="administrators.dialogs.close" />
			</Button>
			<Button onClick={props.submit} color="primary">
				<Translate id="administrators.dialogs.delete.submit" />
			</Button>
		</DialogActions>
	</Dialog>
);

export default withLocalize(AdministratorsDialogDelete);
