import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import Lambda from '../../../aws/lambda';

const styles = {
  button: { margin: '1rem' },
};
class ChargeboxesDialogUnlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = { processing: false, connector: null };

    this.changeConnector = this.changeConnector.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  changeConnector(c) {
    return () => this.secureSetState({ connector: c });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.secureSetState({ connector: null });
    }
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let functionName = '';
        if (this.props.chargebox.type.msg === 'soap' && this.props.chargebox.type.version === '1.5') {
          functionName = 'OcppSoap15UnlockConnector';
        } else if (this.props.chargebox.type.msg === 'json' && this.props.chargebox.type.version === '1.6') {
          functionName = 'OcppJson16UnlockConnector';
        }
        let unlocked = await Lambda.invoke(functionName, {
          chargebox: this.props.chargebox.id,
          user: this.props.user.id,
          connector: this.state.connector,
        });
        if (functionName === 'OcppSoap15UnlockConnector') {
          this.secureSetState({ processing: false }, () => this.props.handleClose(unlocked, false));
        } else {
          this.secureSetState({ processing: false }, () => this.props.handleClose(true, false));
        }
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false }, () => this.props.handleClose(false, false));
      }
    });
  }

  cancel() {
    this.props.handleClose(false, true);
  }

  render() {
    return (
      <Dialog onClose={this.cancel} aria-labelledby='chargebox-dialog-unlock-title' open={this.props.open} fullWidth maxWidth='xs'>
        <DialogTitle id='chargebox-dialog-unlock-title'>
          <Translate id='chargeboxes.dialogs.unlock.title' />
        </DialogTitle>
        <DialogContent>
          <Typography>
            <Translate id='chargeboxes.dialogs.unlock.text' />
          </Typography>
          <Typography>{`${this.props.chargebox.id} - ${this.props.chargebox.alias}`}</Typography>
          <Grid container direction='row' alignItems='center' justifyContent='center' wrap='wrap'>
            {this.props.chargebox.connectors.map((c, i) => (
              <Button
                key={i + 1}
                className={this.props.classes.button}
                onClick={this.changeConnector(i + 1)}
                color={this.state.connector === i + 1 ? 'primary' : 'default'}
                variant='contained'
              >
                {i + 1}
              </Button>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancel} color='default' autoFocus>
            <Translate id='chargeboxes.dialogs.close' />
          </Button>
          <Button onClick={this.submit} color='primary' disabled={this.state.connector === null}>
            <Translate id='chargeboxes.dialogs.unlock.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(ChargeboxesDialogUnlock));
