import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Popover from '@material-ui/core/Popover';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import withStyles from '@material-ui/core/styles/withStyles';
import DynamoDBChargeboxes from '../../../aws/dynamodb/chargeboxes';
import FilterListIcon from '@material-ui/icons/FilterList';

const styles = {
  email: {
    flex: 1,
    marginRight: '1rem',
  },
  filtersContainer: {
    padding: '1rem',
  },
  listItemIcon: {
    minWidth: '2rem',
  },
};

const FiltersMenu = (props) => {
  let [alias, setAlias] = React.useState('');
  let [vehicle, setVehicle] = React.useState('');

  React.useEffect(() => {
    if (props.anchorEl === null) {
      setAlias(props.filters.alias);
      setVehicle(props.filters.vehicle);
    }
  }, [props.anchorEl, props.filters.alias, props.filters.vehicle]);
  return (
    <Popover
      anchorEl={props.anchorEl}
      open={props.anchorEl !== null}
      onClose={props.onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      PaperProps={{ className: props.classes.filtersContainer }}
    >
      <Grid container spacing={3} direction='column'>
        <Grid item>
          <Typography color='textSecondary'>
            <Translate id='filter' />
          </Typography>
        </Grid>
        <Grid item>
          <TextField label={<Translate id='chargeboxes.alias' />} value={alias} onChange={(e) => setAlias(e.target.value)} fullWidth />
        </Grid>
        <Grid item>
          <FormControl component='fieldset'>
            <FormLabel component='legend'>
              <Translate id='chargeboxes.vehicle' />
            </FormLabel>
            <RadioGroup aria-label='vehicle' value={vehicle} onChange={(e) => setVehicle(e.target.value)}>
              <FormControlLabel value='car' control={<Radio color='primary' />} label={<Translate id='vehicles.car' />} />
              <FormControlLabel value='bicycle' control={<Radio color='primary' />} label={<Translate id='vehicles.bicycle' />} />
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item container direction='row' alignItems='center' justifyContent='center'>
          <Button
            onClick={() => {
              setAlias('');
              setVehicle('');
            }}
          >
            <Translate id='chargeboxes.filters.clear' />
          </Button>
          <Button color='primary' onClick={() => props.submit(alias, vehicle)}>
            <Translate id='chargeboxes.filters.submit' />
          </Button>
        </Grid>
      </Grid>
    </Popover>
  );
};

class RfidsDialogInfiniteOn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      linkToUser: true,
      email: '',
      searchResult: 'idle',
      ClientChargeboxes: [],
      filtersOpen: false,
      filtersMenuAnchorEl: null,
      filters: {
        alias: '',
        vehicle: '',
      },
    };
    this.secureSetState = this.secureSetState.bind(this);
    this.openCloseFilters = this.openCloseFilters.bind(this);
    this.getFilteredChargeboxes = this.getFilteredChargeboxes.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  async componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      if (this.props.rfid.id !== '') {
        this.secureSetState({ processing: true }, async () => {
          try {
            let data = await DynamoDBChargeboxes.getAll({ type: 'client', client: this.props.rfid.client });

            let ClientChargeboxes = [];
            for (let item in data.Items) {
              let chargebox = {
                id: data.Items[item].id,
                alias: data.Items[item].alias,
                vehicle: data.Items[item].type.vehicle,
              };
              ClientChargeboxes.push(chargebox);
            }

            this.secureSetState({ processing: false, ClientChargeboxes: ClientChargeboxes });
          } catch (err) {
            console.log(err);
            this.secureSetState({ processing: false, ClientChargeboxes: {} });
          }
        });
      } else {
        this.secureSetState({ ClientChargeboxes: {} });
      }
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  openCloseFilters(event) {
    if (this.state.filtersMenuAnchorEl === null) {
      this.secureSetState({ filtersMenuAnchorEl: event.currentTarget });
    } else {
      this.secureSetState({ filtersMenuAnchorEl: null });
    }
  }

  getFilteredChargeboxes(alias, vehicle) {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let filters = {};
        if (alias.length > 0) {
          filters.alias = alias;
        }
        if (this.props.rfid.client.length > 0) {
          filters.client = this.props.rfid.client;
        }
        if (vehicle.length > 0) {
          filters.type = { vehicle: vehicle };
        }
        let records = await DynamoDBChargeboxes.getAll(
          {
            type: this.context.type,
            client: this.context.client,
          },
          null,
          filters
        );

        let ClientChargeboxes = [];
        for (let item in records.Items) {
          let chargebox = {
            id: records.Items[item].id,
            alias: records.Items[item].alias,
            vehicle: records.Items[item].type.vehicle,
          };
          ClientChargeboxes.push(chargebox);
        }
        this.secureSetState({ ClientChargeboxes: ClientChargeboxes, filters: { vehicle, alias }, processing: false, filtersMenuAnchorEl: null });
      } catch (err) {
        console.log(err);
        this.secureSetState({ ClientChargeboxes: [], processing: false });
      }
    });
  }

  render() {
    let rfidDescription = null;
    if (this.props.rfid.user === null) {
      rfidDescription = (
        <React.Fragment>
          <Typography component='p' variant='body2'>
            {this.props.rfid.id}
          </Typography>
          <Typography component='p' variant='body2'>
            {this.props.rfid.alias}
          </Typography>
        </React.Fragment>
      );
    } else if (this.props.users.hasOwnProperty(this.props.rfid.user)) {
      rfidDescription = (
        <React.Fragment>
          <Typography component='p' variant='body2'>
            {this.props.rfid.id}
          </Typography>
          <Typography component='p' variant='body2'>
            {this.props.users[this.props.rfid.user].name || this.props.users[this.props.rfid.user].company_name}
          </Typography>
          <Typography component='p' variant='caption'>
            {this.props.users[this.props.rfid.user].email}
          </Typography>
        </React.Fragment>
      );
    }
    return (
      <Dialog onClose={this.props.handleClose} aria-labelledby='rfid-dialog-infinite-on-title' open={this.props.open} maxWidth={'lg'}>
        <DialogTitle id='rfid-dialog-infinite-on-title'>
          <Translate id='rfids.dialogs.infiniteOn.title' />
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'grid', gridTemplateColumns: '1fr 1fr', position: 'sticky', left: 0 }}>
            <div>
              <DialogContentText component='div'>{rfidDescription}</DialogContentText>
              <FormControlLabel
                value='end'
                control={<Checkbox checked={this.props.rfid.infiniteOn.enabled} name='enabled' onClick={this.props.onChange} color='primary' />}
                label={this.props.translate('rfids.dialogs.infiniteOn.enabled')}
                labelPlacement='end'
              />
            </div>
            {this.props.rfid.infiniteOn.enabled && (
              <div>
                <IconButton onClick={this.openCloseFilters} style={{ width: '40px', height: '40px', position: 'absolute', bottom: 0, right: 0 }}>
                  <FilterListIcon />
                </IconButton>
              </div>
            )}
          </div>
          <br></br>
          <div>
            {this.props.rfid.infiniteOn.enabled && (
              <Table
                aria-label='table'
                size='small'
                style={
                  {
                    /*overflow: 'hidden'*/
                    /*backgroundColor: this.span && this.span.offsetWidth < this.span.scrollWidth ? 'green' : 'yellow',*/
                  }
                }
              >
                <TableBody>
                  {this.state.ClientChargeboxes.map(
                    (row, index) =>
                      index % 3 === 0 && (
                        <TableRow key={index}>
                          <TableCell>
                            <Grid container direction='column' justifyContent='center'>
                              <Checkbox
                                checked={this.props.rfid.infiniteOn.enabled && this.props.rfid.infiniteOn.chargeboxes.includes(row.id)}
                                name={row.id}
                                onClick={this.props.onChange}
                                color='primary'
                                style={{ alignSelf: 'center' }}
                              />
                              <label style={{ textAlign: 'center' }}>{row.alias}</label>
                              <Typography variant='caption' style={{ textAlign: 'center', fontSize: '12px' }}>
                                {row.id}
                              </Typography>
                            </Grid>
                          </TableCell>
                          {index + 1 < this.state.ClientChargeboxes.length && (
                            <TableCell>
                              <Grid container direction='column' justifyContent='center'>
                                <Checkbox
                                  checked={
                                    this.props.rfid.infiniteOn.enabled &&
                                    this.props.rfid.infiniteOn.chargeboxes.includes(this.state.ClientChargeboxes[index + 1].id)
                                  }
                                  id={this.state.ClientChargeboxes[index + 1].id}
                                  name={this.state.ClientChargeboxes[index + 1].id}
                                  onClick={this.props.onChange}
                                  color='primary'
                                  style={{ alignSelf: 'center' }}
                                />
                                <label style={{ textAlign: 'center' }}>{this.state.ClientChargeboxes[index + 1].alias}</label>
                                <Typography variant='caption' style={{ textAlign: 'center', fontSize: '12px' }}>
                                  {this.state.ClientChargeboxes[index + 1].id}
                                </Typography>
                              </Grid>
                            </TableCell>
                          )}
                          {index + 2 < this.state.ClientChargeboxes.length && (
                            <TableCell>
                              <Grid container direction='column' justifyContent='center'>
                                <Checkbox
                                  checked={
                                    this.props.rfid.infiniteOn.enabled &&
                                    this.props.rfid.infiniteOn.chargeboxes.includes(this.state.ClientChargeboxes[index + 2].id)
                                  }
                                  id={this.state.ClientChargeboxes[index + 2].id}
                                  name={this.state.ClientChargeboxes[index + 2].id}
                                  onClick={this.props.onChange}
                                  color='primary'
                                  style={{ alignSelf: 'center' }}
                                />
                                <label style={{ textAlign: 'center' }}>{this.state.ClientChargeboxes[index + 2].alias}</label>
                                <Typography variant='caption' style={{ textAlign: 'center', fontSize: '12px' }}>
                                  {this.state.ClientChargeboxes[index + 2].id}
                                </Typography>
                              </Grid>
                            </TableCell>
                          )}
                        </TableRow>
                      )
                  )}
                </TableBody>
              </Table>
            )}
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => this.secureSetState({ filters: { alias: '', vehicle: '' } }, this.props.handleClose)} color='default' autoFocus>
            <Translate id='rfids.dialogs.close' />
          </Button>
          {(this.props.user.type.toLowerCase() === 'superuser' || this.props.user.hasPrivileges) && (
            <Button onClick={this.props.submit} color='primary'>
              <Translate id='rfids.dialogs.infiniteOn.submit' />
            </Button>
          )}
        </DialogActions>
        <FiltersMenu
          anchorEl={this.state.filtersMenuAnchorEl}
          onClose={this.openCloseFilters}
          filters={this.state.filters}
          submit={this.getFilteredChargeboxes}
          classes={this.props.classes}
        />
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(RfidsDialogInfiniteOn));
