import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_documents`;

const getAll = (client, paginationOptions) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		IndexName: 'client-date-index',
		KeyConditionExpression: '#client = :client',
		ExpressionAttributeNames: { '#client': 'client' },
		ExpressionAttributeValues: { ':client': client },
		ReturnConsumedCapacity: 'NONE',
	};

	if (paginationOptions) {
		params.Limit = paginationOptions.limit;
		if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
			params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
		}
	}
	return DDB.query(params).promise();
};

const getAllCount = (client) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		IndexName: 'client-date-index',
		KeyConditionExpression: '#client = :client',
		ExpressionAttributeNames: { '#client': 'client' },
		ExpressionAttributeValues: { ':client': client },
		ReturnConsumedCapacity: 'NONE',
		Select: 'COUNT',
	};
	return DDB.query(params).promise();
};

const get = (user, transaction) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		IndexName: 'user-transaction-index',
		KeyConditionExpression: '#user = :user and #transaction = :transaction',
		ExpressionAttributeNames: {
			'#user': 'user',
			'#transaction': 'transaction',
		},
		ExpressionAttributeValues: {
			':user': user,
			':transaction': transaction,
		},
		ReturnConsumedCapacity: 'NONE',
	};

	return DDB.query(params).promise();
};

const store = (data) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Item: data,
		ConditionExpression: 'attribute_not_exists(#user) and attribute_not_exists(#date)',
		ExpressionAttributeNames: {
			'#user': 'user',
			'#date': 'date',
		},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};

	return DDB.put(params).promise();
};

const remove = (user, date) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	let params = {
		TableName: TableName,
		Key: {
			user: user,
			date: date,
		},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};

	return DDB.delete(params).promise();
};

export default {
	getAll,
	getAllCount,
	get,
	store,
	remove,
};
