import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const RfidsDialogDelete = (props) => {
	let rfidDescription = null;
	if (props.rfid.user === null) {
		rfidDescription = (
			<React.Fragment>
				<Typography component="p" variant="body2">
					{props.rfid.id}
				</Typography>
				<Typography component="p" variant="body2">
					{props.rfid.alias}
				</Typography>
			</React.Fragment>
		);
	} else if (props.users.hasOwnProperty(props.rfid.user)) {
		rfidDescription = (
			<React.Fragment>
				<Typography component="p" variant="body2">
					{props.rfid.id}
				</Typography>
				<Typography component="p" variant="body2">
					{props.users[props.rfid.user].name || props.users[props.rfid.user].company_name}
				</Typography>
				<Typography component="p" variant="caption">
					{props.users[props.rfid.user].email}
				</Typography>
			</React.Fragment>
		);
	}
	return (
		<Dialog
			onClose={props.handleClose}
			aria-labelledby="rfid-dialog-delete-title"
			aria-describedby="rfid-dialog-delete-description"
			open={props.open}
			fullWidth
			maxWidth="xs"
		>
			<DialogTitle id="rfid-dialog-delete-title">
				<Translate id="rfids.dialogs.delete.title" />
			</DialogTitle>
			<DialogContent>
				<DialogContentText id="rfid-dialog-delete-description">
					<Typography>
						<Translate id="rfids.dialogs.delete.text" />
					</Typography>
				</DialogContentText>
				<DialogContentText>{rfidDescription}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleClose} color="default" autoFocus>
					<Translate id="rfids.dialogs.close" />
				</Button>
				<Button onClick={props.submit} color="primary">
					<Translate id="rfids.dialogs.delete.submit" />
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default withLocalize(RfidsDialogDelete);
