import React from 'react';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import DynamoDBEndUsers from '../../../aws/dynamodb/endusers';
import DynamoDBRFIDs from '../../../aws/dynamodb/rfids';
import DynamoDBRFIDsRecharges from '../../../aws/dynamodb/rfids-recharges';
import DynamoDBTransactionsRunning from '../../../aws/dynamodb/transactions-running';
import DDBTransactions from '../../../aws/dynamodb/ddb-transactions';
import UserContext from '../../../context';
import Rfids from './presentation';

class RfidsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      clientsList: [],
      clients: {},
      list: [],
      users: {},
      count: 0,
      menuAnchorEl: null,
      filtersMenuAnchorEl: null,
      rfidToCreate: {
        id: '',
        active: true,
        client: null,
        alias: null,
        user: null,
        budget: { amount: null, locked: 0 },
        currency: '',
      },
      selectedRfid: {
        id: '',
        active: true,
        client: '',
        alias: null,
        user: null,
        budget: { amount: null, locked: 0 },
        currency: '',
        infiniteOn: {
          enabled: false,
          chargeboxes: [],
        },
      },
      pagination: {
        page: 0,
        rowsPerPage: 10,
        nextTokens: [],
      },
      filters: {
        client: '',
        id: '',
        useAliasEmail: 'alias',
        aliasEmail: '',
      },
      snackbar: null,
      infiniteOnDialogShow: false,
      createRfidOpen: false,
      editRfidOpen: false,
      addBudgetOpen: false,
      removeBudgetOpen: false,
      deleteRfidOpen: false,
      infiniteOnOpen: false,
    };
    this.getRfids = this.getRfids.bind(this);
    this.getFilteredRFIDs = this.getFilteredRFIDs.bind(this);
    this.openCreateDialog = this.openCreateDialog.bind(this);
    this.closeCreateDialog = this.closeCreateDialog.bind(this);
    this.openEditDialog = this.openEditDialog.bind(this);
    this.closeEditDialog = this.closeEditDialog.bind(this);
    this.closeInfiniteOnDialog = this.closeInfiniteOnDialog.bind(this);
    this.openInfiniteOnDialog = this.openInfiniteOnDialog.bind(this);
    this.openAddBudgetDialog = this.openAddBudgetDialog.bind(this);
    this.openRemoveBudgetDialog = this.openRemoveBudgetDialog.bind(this);
    this.closeAddRemoveBudgetDialog = this.closeAddRemoveBudgetDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.openCloseMenu = this.openCloseMenu.bind(this);
    this.openCloseFilters = this.openCloseFilters.bind(this);
    this.onChange = this.onChange.bind(this);
    this.enableDisableRfid = this.enableDisableRfid.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  handleChangePage(event, newPage) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = newPage;
    this.secureSetState({ pagination }, this.getRfids);
  }

  handleChangeRowsPerPage(event) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    pagination.rowsPerPage = event.target.value;
    this.secureSetState({ pagination }, this.getRfids);
  }

  openCloseFilters(event) {
    if (this.state.filtersMenuAnchorEl === null) {
      this.secureSetState({ filtersMenuAnchorEl: event.currentTarget });
    } else {
      this.secureSetState({ filtersMenuAnchorEl: null });
    }
  }

  getFilteredRFIDs(client, id, useAliasEmail, aliasEmail) {
    let { pagination } = this.state;
    pagination.page = 0;
    this.secureSetState(
      {
        filters: { client, id, useAliasEmail, aliasEmail },
        filtersMenuAnchorEl: null,
        pagination,
      },
      this.getRfids
    );
  }

  getRfids() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let filters = {};
        if (this.state.filters.client.length > 0) {
          filters.client = this.state.filters.client;
        }
        if (this.state.filters.id.length > 0) {
          filters.id = this.state.filters.id;
        }
        if (this.state.filters.useAliasEmail === 'alias') {
          filters.alias = this.state.filters.aliasEmail;
        } else if (this.state.filters.aliasEmail.length > 0) {
          let user = await DynamoDBEndUsers.getByEmail(this.state.filters.aliasEmail);
          if (user) {
            filters.user = user.id;
          } else {
            // Placeholder used to get 0 elements because there is no user with the given e-mail address
            filters.user = '########################';
          }
        }
        let records = await DynamoDBRFIDs.getAll(
          {
            type: this.context.type,
            client: this.context.client,
          },
          {
            limit: this.state.pagination.rowsPerPage,
            page: this.state.pagination.page,
            nextTokens: this.state.pagination.page === 0 ? [] : this.state.pagination.nextTokens,
          },
          filters
        );
        let count = await DynamoDBRFIDs.getAllCount({ type: this.context.type, client: this.context.client }, filters);
        let { pagination } = this.state;
        if (records.LastEvaluatedKey) {
          if (pagination.page === 0) {
            pagination.nextTokens = [records.LastEvaluatedKey];
          } else if (pagination.nextTokens.length <= pagination.page) {
            pagination.nextTokens.push(records.LastEvaluatedKey);
          } else {
            pagination.nextTokens[pagination.page] = records.LastEvaluatedKey;
          }
        }

        let list = [];
        let usersToGet = [];
        for (let item of records.Items) {
          list.push({
            id: item.id.split('__')[1],
            client: item.client || null,
            active: item.active,
            alias: item.alias,
            user: item.user || null,
            budget: item.budget,
            currency: item.currency,
            infiniteOn: item.infiniteOn || { enabled: false, chargeboxes: [] },
          });
          if (typeof item.user === 'string' && item.user.length > 0 && usersToGet.indexOf(item.user) === -1 && !this.state.users.hasOwnProperty(item.user)) {
            usersToGet.push(item.user);
          }
        }
        let { users } = this.state;
        for (let i = 0; i < Math.ceil(usersToGet.length / 25); i++) {
          let ddbUsers = await DynamoDBEndUsers.batchGet(usersToGet.slice(i * 25, (i + 1) * 25));
          for (let user of ddbUsers) {
            let { id, ...u } = user;
            users[id] = u;
          }
        }
        this.secureSetState({ list, count: count.Count, users, processing: false });
      } catch (err) {
        console.log(err);
        this.secureSetState({ list: [], count: 0, processing: false });
      }
    });
  }

  openCloseMenu(rfid) {
    return (event) => {
      if (rfid === null) {
        this.secureSetState({
          menuAnchorEl: null,
          selectedRfid: {
            id: '',
            active: true,
            client: '',
            alias: null,
            budget: { amount: null, locked: 0 },
            currency: '',
            infiniteOn: {
              enabled: false,
              chargeboxes: [],
            },
          },
        });
      } else {
        let Rfid = JSON.parse(JSON.stringify(rfid));
        this.secureSetState({ menuAnchorEl: event.currentTarget, selectedRfid: Rfid, infiniteOnDialogShow: Rfid.budget ? true : false });
      }
    };
  }

  openCreateDialog() {
    this.secureSetState({
      createRfidOpen: true,
      rfidToCreate: {
        id: '',
        active: true,
        client: this.context.type === 'superuser' ? null : { id: this.context.client, name: '' },
        alias: null,
        user: null,
        budget: { amount: null, locked: 0 },
      },
    });
  }

  closeCreateDialog(create) {
    if (!create) {
      this.secureSetState({
        createRfidOpen: false,
        rfidToCreate: {
          id: '',
          active: true,
          client: this.context.type === 'superuser' ? null : { id: this.context.client, name: '' },
          alias: null,
          user: null,
          budget: { amount: null, locked: 0 },
        },
      });
      return;
    }
    if (this.state.processing) {
      return;
    }

    this.secureSetState({ processing: true, snackbar: null }, async () => {
      let { id, active, client, alias, user, budget } = this.state.rfidToCreate;
      id = id.trim();
      if (client.id.length === 0 || id.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }
      if (budget !== null && budget.amount === null) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }
      /*if (budget && budget.amount && budget.amount.substring(budget.amount.length - 1) === '.') {
        budget.amount = budget.amount.substring(0, budget.amount.length - 1);
      }*/
      if (alias === null && user === null) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }

      let lc_alias = null;
      if (alias !== null) {
        alias = alias.trim();
        if (alias.length === 0) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }
        lc_alias = alias.toLowerCase();
      } else if (user != null) {
        user = user.trim();
        if (user.length === 0) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }
      }
      try {
        let data = {
          id: `${client.id}__${id.toLowerCase()}`,
          active: active,
          client: client.id,
          alias: alias,
          lc_alias: lc_alias,
          budget: budget,
        };
        if (user !== null) {
          data.user = user;
        }
        await DynamoDBRFIDs.create(data);
        this.secureSetState(
          {
            processing: false,
            createRfidOpen: false,
            rfidToCreate: { id: '', active: true, client, alias: null, user: null, budget: { amount: null, locked: 0 } },
            pagination: {
              page: 0,
              rowsPerPage: 10,
              nextTokens: [],
            },
            snackbar: 'success',
          },
          this.getRfids
        );
      } catch (err) {
        console.log(err);
        if (err.code && err.code === 'ConditionalCheckFailedException') {
          this.secureSetState({ processing: false, snackbar: 'duplicate' });
        } else {
          this.secureSetState({ processing: false, snackbar: 'createErrror' });
        }
      }
    });
  }

  openEditDialog() {
    this.secureSetState({ menuAnchorEl: null, editRfidOpen: true });
  }

  closeEditDialog(edit) {
    if (!edit) {
      this.secureSetState({
        editRfidOpen: false,
        selectedRfid: {
          id: '',
          active: true,
          client: '',
          alias: null,
          user: null,
          budget: { amount: null, locked: 0 },
          currency: '',
          infiniteOn: {
            enabled: false,
            chargeboxes: [],
          },
        },
      });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      let { id, active, client, alias, user, budget, currency } = this.state.selectedRfid;
      id = id.trim();
      if (client.length === 0 || id.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }
      if (budget !== null && budget.amount === null) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }
      /*if (budget && budget.amount && budget.amount.substring(budget.amount.length - 1) === '.') {
        budget.amount = budget.amount.substring(0, budget.amount.length - 1);
      }*/
      if (currency && currency !== null && currency.amount && currency.amount === null) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }
      if (alias === null && user === null) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }

      let lc_alias = null;
      if (alias !== null) {
        alias = alias.trim();
        if (alias.length === 0) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }
        lc_alias = alias.toLowerCase();
      } else if (user !== null) {
        user = user.trim();
        if (user.length === 0) {
          this.secureSetState({ processing: false, snackbar: 'mandatory' });
          return;
        }
      }

      try {
        await DynamoDBRFIDs.edit(`${client}__${id.toLowerCase()}`, { active, alias, lc_alias, user, budget });
        this.secureSetState(
          {
            processing: false,
            editRfidOpen: false,
            selectedRfid: {
              id: '',
              active: true,
              client: '',
              alias: null,
              user: null,
              budget: { amount: null, locked: 0 },
              currency: '',
              infiniteOn: {
                enabled: false,
                chargeboxes: [],
              },
            },
            snackbar: 'success',
          },
          this.getRfids
        );
      } catch (err) {
        console.log(err);
        if (err.code && err.code === 'ConditionalCheckFailedException') {
          this.secureSetState({ processing: false, snackbar: 'duplicate' });
        } else {
          this.secureSetState({ processing: false, snackbar: 'editErrror' });
        }
      }
    });
  }

  openAddBudgetDialog() {
    this.secureSetState({ menuAnchorEl: null, addBudgetOpen: true, removeBudgetOpen: false });
  }

  openRemoveBudgetDialog() {
    this.secureSetState({ menuAnchorEl: null, addBudgetOpen: false, removeBudgetOpen: true });
  }

  closeAddRemoveBudgetDialog(data) {
    if (this.state.processing) {
      return;
    }
    if (!data) {
      this.secureSetState({
        addBudgetOpen: false,
        removeBudgetOpen: false,
        selectedRfid: {
          id: '',
          active: true,
          client: '',
          alias: null,
          user: null,
          budget: { amount: null, locked: 0 },
          currency: '',
          infiniteOn: {
            enabled: false,
            chargeboxes: [],
          },
        },
      });
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        if (this.state.addBudgetOpen) {
          data.value = Math.abs(data.value);
        } else {
          let runningTransactions = await DynamoDBTransactionsRunning.getByClient(this.context.client, false);
          let rfidIsCharging = runningTransactions.Items.findIndex((t) => t.id_tag === this.state.selectedRfid.id.toLowerCase());
          if (rfidIsCharging > -1) {
            this.secureSetState({ processing: false, snackbar: 'removeBudgetInUserError' });
            return;
          }
          data.value = -Math.abs(data.value);
        }
        await DDBTransactions.transactWrite([
          await DynamoDBRFIDs.updateBudget(
            `${this.context.client}__${this.state.selectedRfid.id.toLowerCase()}`,
            parseFloat(this.state.selectedRfid.budget.amount) + parseFloat(data.value),
            true
          ),
          await DynamoDBRFIDsRecharges.put(
            {
              id: `${this.context.client}__${this.state.selectedRfid.id.toLowerCase()}`,
              date: Math.round(Date.now() / 1000),
              amount: data.value,
              client: this.context.client,
              method: 'admin',
              reason: data.reason,
              user: null,
              admin: this.context.id,
            },
            true
          ),
        ]);
        this.secureSetState(
          {
            processing: false,
            addBudgetOpen: false,
            removeBudgetOpen: false,
            selectedRfid: {
              id: '',
              active: true,
              client: '',
              alias: null,
              user: null,
              budget: { amount: null, locked: 0 },
              currency: '',
              infiniteOn: {
                enabled: false,
                chargeboxes: [],
              },
            },
            snackbar: 'success',
          },
          this.getRfids
        );
      } catch (err) {
        console.log(err);
        if (this.state.addBudgetOpen) {
          this.secureSetState({ processing: false, snackbar: 'addBudgetErrror' });
        } else {
          this.secureSetState({ processing: false, snackbar: 'removeBudgetErrror' });
        }
      }
    });
  }

  openInfiniteOnDialog() {
    this.secureSetState({ menuAnchorEl: null, infiniteOnOpen: true });
  }

  closeInfiniteOnDialog(data) {
    if (this.state.processing) {
      return;
    }
    if (!data) {
      this.secureSetState(
        {
          infiniteOnOpen: false,
          selectedRfid: {
            id: '',
            active: true,
            client: '',
            alias: null,
            user: null,
            budget: { amount: null, locked: 0 },
            currency: '',
            infiniteOn: {
              enabled: false,
              chargeboxes: [],
            },
          },
        } /*,
        this.getRfids*/
      );
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      let { id, client, infiniteOn } = this.state.selectedRfid;
      id = id.trim();
      if (client.length === 0 || id.length === 0) {
        this.secureSetState({ processing: false, snackbar: 'mandatory' });
        return;
      }

      try {
        await DynamoDBRFIDs.edit(`${client}__${id.toLowerCase()}`, { infiniteOn });
        this.secureSetState(
          {
            processing: false,
            infiniteOnOpen: false,
            selectedRfid: {
              id: '',
              active: true,
              client: '',
              alias: null,
              user: null,
              budget: { amount: null, locked: 0 },
              currency: '',
              infiniteOn: {
                enabled: false,
                chargeboxes: [],
              },
            },
            snackbar: 'success',
          },
          this.getRfids
        );
      } catch (err) {
        console.log(err);
        if (err.code && err.code === 'ConditionalCheckFailedException') {
          this.secureSetState({ processing: false, snackbar: 'duplicate' });
        } else {
          this.secureSetState({ processing: false, snackbar: 'editErrror' });
        }
      }
    });
  }

  openDeleteDialog() {
    this.secureSetState({ menuAnchorEl: null, deleteRfidOpen: true });
  }

  closeDeleteDialog(remove) {
    if (!remove) {
      this.secureSetState({
        deleteRfidOpen: false,
        selectedRfid: {
          id: '',
          active: true,
          client: '',
          alias: null,
          user: null,
          budget: { amount: null, locked: 0 },
          currency: '',
          infiniteOn: {
            enabled: false,
            chargeboxes: [],
          },
        },
      });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        await DynamoDBRFIDs.remove(`${this.state.selectedRfid.client}__${this.state.selectedRfid.id.toLowerCase()}`);
        this.secureSetState(
          {
            processing: false,
            deleteRfidOpen: false,
            selectedRfid: {
              id: '',
              active: true,
              client: '',
              alias: null,
              user: null,
              budget: { amount: null, locked: 0 },
              currency: '',
              infiniteOn: {
                enabled: false,
                chargeboxes: [],
              },
            },
            pagination: {
              page: 0,
              rowsPerPage: 10,
              nextTokens: [],
            },
            snackbar: 'success',
          },
          this.getRfids
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'deleteErrror' });
      }
    });
  }

  enableDisableRfid(id, client, active) {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      id = id.trim();

      try {
        await DynamoDBRFIDs.edit(`${client}__${id.toLowerCase()}`, { active: !active });
        this.secureSetState({ processing: false }, this.getRfids);
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false });
      }
    });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    if (this.state.createRfidOpen) {
      let { rfidToCreate } = this.state;
      if (name === 'active') {
        rfidToCreate.active = !rfidToCreate.active;
      }
      if (name === 'amount') {
        value = value.replace(',', '.');
        if (value === '') {
          rfidToCreate.budget.amount = null;
        } else if (/^\d+\.?\d{0,2}?$/gim.test(value)) {
          rfidToCreate.budget.amount = parseFloat(value);
        }
      } else if (name === 'infinite') {
        rfidToCreate.budget = rfidToCreate.budget === null ? { amount: null, locked: 0 } : null;
      } else {
        rfidToCreate[name] = value;
      }
      this.secureSetState({ rfidToCreate });
    } else if (this.state.editRfidOpen) {
      let { selectedRfid } = this.state;
      if (name === 'active') {
        selectedRfid.active = !selectedRfid.active;
      } else if (name === 'amount') {
        if (value === '') {
          selectedRfid.budget.amount = null;
        } else if (/^\d+\.?\d{0,2}?$/gim.test(value)) {
          selectedRfid.budget.amount = parseFloat(value);
        }
      } else if (name === 'infinite') {
        selectedRfid.budget = selectedRfid.budget === null ? { amount: null, locked: 0 } : null;
      } else {
        selectedRfid[name] = value;
      }
      this.secureSetState({ selectedRfid });
    } else if (this.state.infiniteOnOpen) {
      let selectedRfid = { ...this.state.selectedRfid };
      if (name === 'enabled') {
        selectedRfid.infiniteOn.enabled = !selectedRfid.infiniteOn.enabled;
      } else {
        if (selectedRfid.infiniteOn.chargeboxes.includes(name)) {
          selectedRfid.infiniteOn.chargeboxes.splice(selectedRfid.infiniteOn.chargeboxes.indexOf(name), 1);
        } else {
          selectedRfid.infiniteOn.chargeboxes.push(name);
        }
      }
      this.secureSetState({ selectedRfid });
    }
  }

  onSnackbarClose() {
    this.secureSetState({ snackbar: null });
  }

  async componentDidMount() {
    this.isMounted = true;
    if (this.context.type === 'superuser') {
      try {
        let records = await DynamoDBClients.getAll();
        let clientsList = [];
        let clients = {};
        let clientsCurrency = {};
        for (let client of records.Items) {
          clientsList.push({ id: client.id, name: client.name, currency: client.payment_limit.currency });
          clients[client.id] = client.name;
          clientsCurrency[client.id] = client.payment_limit.currency;
        }
        this.secureSetState({ clientsList, clients, clientsCurrency });
      } catch (err) {
        console.log(err);
      }
    } else if (this.context.clientType === 'main' && this.context.clients.length > 0) {
      let records = {
        Items: [],
      };

      for (let i in this.context.clients) {
        let record = await DynamoDBClients.get(this.context.clients[i]);
        records.Items.push(record.Item);
      }
      let clientsList = [];
      let clients = {};
      let clientsCurrency = {};
      for (let client of records.Items) {
        clientsList.push({ id: client.id, name: client.name, currency: client.payment_limit.currency });
        clients[client.id] = client.name;
        clientsCurrency[client.id] = client.payment_limit.currency;
      }
      this.secureSetState({ clientsList, clients, clientsCurrency });
    } else {
      let records = await DynamoDBClients.get(this.context.client);
      let currency = records.Item.payment_limit.currency;
      this.secureSetState({ currency });
    }
    this.getRfids();
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Rfids
        {...this.state}
        user={this.context}
        openCloseMenu={this.openCloseMenu}
        openCloseFilters={this.openCloseFilters}
        openCreateDialog={this.openCreateDialog}
        closeCreateDialog={this.closeCreateDialog}
        openEditDialog={this.openEditDialog}
        closeEditDialog={this.closeEditDialog}
        closeInfiniteOnDialog={this.closeInfiniteOnDialog}
        openInfiniteOnDialog={this.openInfiniteOnDialog}
        openAddBudgetDialog={this.openAddBudgetDialog}
        openRemoveBudgetDialog={this.openRemoveBudgetDialog}
        closeAddRemoveBudgetDialog={this.closeAddRemoveBudgetDialog}
        openDeleteDialog={this.openDeleteDialog}
        closeDeleteDialog={this.closeDeleteDialog}
        enableDisableRfid={this.enableDisableRfid}
        getRfids={this.getRfids}
        getFilteredRFIDs={this.getFilteredRFIDs}
        onChange={this.onChange}
        onSnackbarClose={this.onSnackbarClose}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
      />
    );
  }
}

RfidsContainer.contextType = UserContext;
export default RfidsContainer;
