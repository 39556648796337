import React from 'react';
import { withLocalize } from 'react-localize-redux';
import DynamoDBChargeboxes from '../../../../aws/dynamodb/chargeboxes';
import DynamoDBEndUsers from '../../../../aws/dynamodb/endusers';
import DynamoDBReservations from '../../../../aws/dynamodb/reservations';
import DynamoDBClients from '../../../../aws/dynamodb/clients';
import UserContext from '../../../../context';
import Reservations from './presentation';

var lastSearch = {};

class FinishedReservationsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      list: [],
      count: 0,
      users: {},
      chargeboxes: {},
      menuAnchorEl: null,
      selectedReservation: null,
      filters: {
        chargebox: null,
        dateFrom: null,
        dateTo: null,
        client: null,
      },
      pagination: {
        page: 0,
        rowsPerPage: 10,
        nextTokens: [],
      },
      dialogPaymentOpen: false,
      snackbar: null,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getReservations = this.getReservations.bind(this);
    this.openCloseMenu = this.openCloseMenu.bind(this);
    this.toggleDialogPayment = this.toggleDialogPayment.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  handleChangePage(event, newPage) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = newPage;
    this.secureSetState({ pagination }, this.getReservations);
  }

  handleChangeRowsPerPage(event) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    pagination.rowsPerPage = event.target.value;
    this.secureSetState({ pagination }, this.getReservations);
  }

  toggleDialogPayment() {
    this.secureSetState({ dialogPaymentOpen: !this.state.dialogPaymentOpen, menuAnchorEl: null });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    const { name, value } = e.target;
    let { filters } = this.state;
    filters[name] = value;
    this.secureSetState({ filters });
  }

  clearFilters() {
    if (this.state.processing) {
      return;
    }
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters.chargebox = null;
    filters.dateFrom = null;
    filters.dateTo = null;
    this.secureSetState({ filters });
  }

  submitFilters() {
    lastSearch = { ...this.state.filters };
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    this.secureSetState({ pagination }, this.getReservations);
  }

  refresh() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ count: 0, filters: { ...lastSearch } });
    let { pagination } = this.state;
    pagination.page = 0;
    this.secureSetState({ pagination }, this.getReservations);
  }

  getReservations() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let filters = {
          client: this.state.filters.client === null && this.props.user.type !== 'superuser' ? null : this.state.filters.client,
          chargebox: this.state.filters.chargebox === null ? null : this.state.filters.chargebox.id,
          dateFrom: null,
          dateTo: null,

          status: ['cancelled', 'finished', 'expired'],
        };
        if (this.state.filters.dateFrom !== null) {
          let date = new Date(this.state.filters.dateFrom.getTime());
          date.setHours(0, 0, 0, 0);
          filters.dateFrom = date.getTime();
        }
        if (this.state.filters.dateTo !== null) {
          let date = new Date(this.state.filters.dateTo.getTime());
          date.setHours(23, 59, 59, 999);
          filters.dateTo = date.getTime();
        }
        let clients = await DynamoDBClients.getCurrency();
        let arrayCurrency = [];
        for (let client of clients.Items) {
          arrayCurrency[client.id] = client.payment_limit.currency;
        }
        let requestor = { type: this.props.user.type, client: filters.client ? filters.client.id : this.props.user.client };
        let records = await DynamoDBReservations.getAll(requestor, filters, {
          limit: this.state.pagination.rowsPerPage,
          page: this.state.pagination.page,
          nextTokens: this.state.pagination.page === 0 ? [] : this.state.pagination.nextTokens,
        });
        let count = await DynamoDBReservations.getAllCount(requestor, filters);
        let { pagination } = this.state;
        if (records.LastEvaluatedKey) {
          if (pagination.page === 0) {
            pagination.nextTokens = [records.LastEvaluatedKey];
          } else if (pagination.nextTokens.length <= pagination.page) {
            pagination.nextTokens.push(records.LastEvaluatedKey);
          } else {
            pagination.nextTokens[pagination.page] = records.LastEvaluatedKey;
          }
        }

        let usersToGet = [];
        let chargeboxesToGet = [];
        for (let item of records.Items) {
          if (typeof item.user === 'string' && usersToGet.indexOf(item.user) === -1 && !this.state.users.hasOwnProperty(item.user)) {
            usersToGet.push(item.user);
          }
          if (typeof item.chargebox === 'string' && chargeboxesToGet.indexOf(item.chargebox) === -1 && !this.state.chargeboxes.hasOwnProperty(item.chargebox)) {
            chargeboxesToGet.push(item.chargebox);
          }
        }
        let users = JSON.parse(JSON.stringify(this.state.users));
        let chargeboxes = JSON.parse(JSON.stringify(this.state.chargeboxes));
        for (let i = 0; i < Math.ceil(usersToGet.length / 25); i++) {
          let ddbUsers = await DynamoDBEndUsers.batchGet(usersToGet.slice(i * 25, (i + 1) * 25));
          for (let user of ddbUsers) {
            let { id, ...u } = user;
            users[id] = u;
          }
        }

        for (let i = 0; i < Math.ceil(chargeboxesToGet.length / 25); i++) {
          let ddbChargeboxes = await DynamoDBChargeboxes.batchGet(chargeboxesToGet.slice(i * 25, (i + 1) * 25), ['id', 'alias']);
          for (let chargebox of ddbChargeboxes) {
            let { id, alias } = chargebox;
            chargeboxes[id] = alias;
          }
        }
        this.secureSetState({ list: records.Items, count: count.Count, users, chargeboxes, processing: false, pagination, currencies: arrayCurrency });
      } catch (err) {
        console.log(err);
        this.secureSetState({ list: [], count: 0, pagination: { page: 0, rowsPerPage: 10, nextTokens: [] }, processing: false });
      }
    });
  }

  openCloseMenu(reservation) {
    return async (event) => {
      if (reservation === null) {
        this.secureSetState({ menuAnchorEl: null, selectedReservation: null });
      } else {
        let currentTarget = event.currentTarget;
        let rfidAlias = null;

        this.secureSetState({ menuAnchorEl: currentTarget, selectedReservation: { rfidAlias, ...reservation } });
      }
    };
  }

  componentDidMount() {
    this.isMounted = true;
    if (this.props.user.type !== 'superuser') {
      this.getReservations();
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Reservations
        {...this.state}
        user={this.props.user}
        chargeboxesList={this.props.chargeboxes}
        clientsList={this.props.clients}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        openCloseMenu={this.openCloseMenu}
        onChange={this.onChange}
        refresh={this.refresh}
        submitFilters={this.submitFilters}
        clearFilters={this.clearFilters}
        getReservations={this.getReservations}
        toggleDialogPayment={this.toggleDialogPayment}
      />
    );
  }
}

FinishedReservationsContainer.contextType = UserContext;
export default withLocalize(FinishedReservationsContainer);
