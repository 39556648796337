import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_rfids_recharges`;

const put = (item, transact) => {
	const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
	if (transact) {
		return {
			Put: {
				TableName: TableName,
				Item: item,
				ConditionExpression: 'attribute_not_exists(#id) and attribute_not_exists(#date)',
				ExpressionAttributeNames: {
					'#id': 'id',
					'#date': 'date',
				},
			},
		};
	}
	let params = {
		TableName: TableName,
		Item: item,
		ConditionExpression: 'attribute_not_exists(#id) and attribute_not_exists(#date)',
		ExpressionAttributeNames: {
			'#id': 'id',
			'#date': 'date',
		},
		ReturnConsumedCapacity: 'NONE',
		ReturnItemCollectionMetrics: 'NONE',
		ReturnValues: 'NONE',
	};
	return DDB.put(params).promise();
};

export default { put };
