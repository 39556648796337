import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

class RfidsDialogAddRemoveBudget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      value: '',
      reason: '',
    };
    this.onChange = this.onChange.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    if (value === 'value') {
      if (/^\d*$/gim.test(value)) {
        this.secureSetState({ [name]: value });
      }
    } else {
      this.secureSetState({ [name]: value });
    }
  }

  async submit() {
    try {
      let val = this.state.value;
      await this.props.submit({
        value: Math.round(parseFloat(val) * 100) / 100,
        reason: this.state.reason,
      });
      this.secureSetState({ processing: false });
    } catch (err) {
      this.secureSetState({ processing: false });
    }
  }

  async componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.secureSetState({ processing: false, value: '', reason: '' });
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    let rfidDescription = null;
    if (this.props.rfid.user === null) {
      rfidDescription = (
        <React.Fragment>
          <Typography component='p' variant='body2'>
            {this.props.rfid.id}
          </Typography>
          <Typography component='p' variant='body2'>
            {this.props.rfid.alias}
          </Typography>
        </React.Fragment>
      );
    } else if (this.props.users.hasOwnProperty(this.props.rfid.user)) {
      rfidDescription = (
        <React.Fragment>
          <Typography component='p' variant='body2'>
            {this.props.rfid.id}
          </Typography>
          <Typography component='p' variant='body2'>
            {this.props.users[this.props.rfid.user].name || this.props.users[this.props.rfid.user].company_name}
          </Typography>
          <Typography component='p' variant='caption'>
            {this.props.users[this.props.rfid.user].email}
          </Typography>
        </React.Fragment>
      );
    }

    return (
      <Dialog
        onClose={this.props.handleClose}
        aria-labelledby='rfid-dialog-add-remove-budget-title'
        aria-describedby='rfid-dialog-add-remove-budget-description'
        open={this.props.open}
        fullWidth
        maxWidth='xs'
      >
        <DialogTitle id='rfid-dialog-add-remove-budget-title'>
          {this.props.action === 'add' ? (
            <Translate id='rfids.dialogs.addRemoveBudget.addTitle' />
          ) : (
            <Translate id='rfids.dialogs.addRemoveBudget.removeTitle' />
          )}
        </DialogTitle>
        <DialogContent>
          <DialogContentText component='div'>{rfidDescription}</DialogContentText>
          <TextField
            type='number'
            label={
              this.props.action === 'add'
                ? this.props.translate('rfids.dialogs.addRemoveBudget.amountToAdd')
                : this.props.translate('rfids.dialogs.addRemoveBudget.amountToRemove')
            }
            value={this.state.value}
            name='value'
            onChange={this.onChange}
            margin='normal'
            InputProps={{
              startAdornment: <InputAdornment position='start'>{this.props.currency}</InputAdornment>,
            }}
            disabled={this.state.processing}
            fullWidth
          />
          <TextField
            label={this.props.translate('rfids.dialogs.addRemoveBudget.reason')}
            value={this.state.reason}
            name='reason'
            onChange={this.onChange}
            margin='normal'
            disabled={this.state.processing}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color='default' autoFocus>
            <Translate id='rfids.dialogs.close' />
          </Button>
          <Button onClick={this.submit} color='primary' disabled={this.state.value.length === 0 || this.state.reason.length < 5}>
            <Translate id='rfids.dialogs.addRemoveBudget.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withLocalize(RfidsDialogAddRemoveBudget);
