import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import EmailIcon from '@material-ui/icons/Email';
import FacebookIcon from '@material-ui/icons/Facebook';
import HelpIcon from '@material-ui/icons/Help';
import InstagramIcon from '@material-ui/icons/Instagram';
import LanguageIcon from '@material-ui/icons/Language';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import PhoneIcon from '@material-ui/icons/Phone';
import TwitterIcon from '@material-ui/icons/Twitter';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
};

class CustomizationContacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      email: '',
      facebook: '',
      instagram: '',
      linkedin: '',
      phone: '',
      twitter: '',
      website: '',
    };
    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    this.secureSetState({ [name]: value });
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({
      email: this.props.contacts.email,
      facebook: this.props.contacts.facebook,
      instagram: this.props.contacts.instagram,
      linkedin: this.props.contacts.linkedin,
      phone: this.props.contacts.phone,
      twitter: this.props.contacts.twitter,
      website: this.props.contacts.website,
    });
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      let { email, website, facebook, twitter, instagram, linkedin, phone } = this.state;
      email = email.trim();
      website = website.trim();
      facebook = facebook.trim();
      twitter = twitter.trim();
      instagram = instagram.trim();
      linkedin = linkedin.trim();
      phone = phone.trim();
      let contacts = {};
      if (email.length > 0) {
        contacts.email = email;
      }
      if (facebook.length > 0) {
        contacts.facebook = facebook;
      }
      if (instagram.length > 0) {
        contacts.instagram = instagram;
      }
      if (linkedin.length > 0) {
        contacts.linkedin = linkedin;
      }
      if (phone.length > 0) {
        contacts.phone = phone;
      }
      if (twitter.length > 0) {
        contacts.twitter = twitter;
      }
      if (website.length > 0) {
        contacts.website = website;
      }

      try {
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            contacts: contacts,
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('contacts', { email, website, facebook, twitter, instagram, linkedin, phone });
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('contactsError');
        });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let state = {};
    if (prevProps.contacts.email !== this.props.contacts.email) {
      state.email = this.props.contacts.email || '';
    }
    if (prevProps.contacts.facebook !== this.props.contacts.facebook) {
      state.facebook = this.props.contacts.facebook || '';
    }
    if (prevProps.contacts.instagram !== this.props.contacts.instagram) {
      state.instagram = this.props.contacts.instagram || '';
    }
    if (prevProps.contacts.linkedin !== this.props.contacts.linkedin) {
      state.linkedin = this.props.contacts.linkedin || '';
    }
    if (prevProps.contacts.phone !== this.props.contacts.phone) {
      state.phone = this.props.contacts.phone || '';
    }
    if (prevProps.contacts.twitter !== this.props.contacts.twitter) {
      state.twitter = this.props.contacts.twitter || '';
    }
    if (prevProps.contacts.website !== this.props.contacts.website) {
      state.website = this.props.contacts.website || '';
    }

    if (Object.keys(state).length > 0) {
      this.secureSetState(state);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.contacts.title' />} subheader={<Translate id='customization.contacts.subheader' />} />
        <CardContent>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch'>
            <TextField
              label={this.props.translate('customization.contacts.email')}
              name='email'
              value={this.state.email}
              onChange={this.onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <EmailIcon color='action' />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={this.props.translate('customization.contacts.phone')}
              name='phone'
              value={this.state.phone}
              onChange={this.onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <PhoneIcon color='action' />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={this.props.translate('customization.contacts.website')}
              name='website'
              value={this.state.website}
              onChange={this.onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LanguageIcon color='action' />
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={this.props.translate('customization.contacts.facebook')}
              name='facebook'
              value={this.state.facebook}
              onChange={this.onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <FacebookIcon color='action' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      placement='left'
                      title={
                        <Typography variant='caption'>
                          https://www.facebook.com/<b>XXXXXX</b>/
                        </Typography>
                      }
                    >
                      <HelpIcon color='action' />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={this.props.translate('customization.contacts.linkedin')}
              name='linkedin'
              value={this.state.linkedin}
              onChange={this.onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <LinkedInIcon color='action' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      placement='left'
                      title={
                        <Typography variant='caption'>
                          https://www.linkedin.com/in/<b>XXXXXX</b>/
                        </Typography>
                      }
                    >
                      <HelpIcon color='action' />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={this.props.translate('customization.contacts.twitter')}
              name='twitter'
              value={this.state.twitter}
              onChange={this.onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <TwitterIcon color='action' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      placement='left'
                      title={
                        <Typography variant='caption'>
                          https://www.twitter.com/<b>XXXXXX</b>
                        </Typography>
                      }
                    >
                      <HelpIcon color='action' />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
            <TextField
              label={this.props.translate('customization.contacts.instagram')}
              name='instagram'
              value={this.state.instagram}
              onChange={this.onChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>
                    <InstagramIcon color='action' />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position='end'>
                    <Tooltip
                      placement='left'
                      title={
                        <Typography variant='caption'>
                          https://www.instagram.com/<b>XXXXXX</b>/
                        </Typography>
                      }
                    >
                      <HelpIcon color='action' />
                    </Tooltip>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <Button size='small' onClick={this.reset}>
              <Translate id='customization.reset' />
            </Button>
            <Button size='small' color='primary' onClick={this.submit}>
              <Translate id='customization.confirm' />
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationContacts));
