import React from 'react';
import ApexChart from 'react-apexcharts';
import { withLocalize } from 'react-localize-redux';
import ApexChartLocaleIT from 'apexcharts/dist/locales/it.json';
import ApexChartLocaleEN from 'apexcharts/dist/locales/en.json';

const RevenueChart = ({ revenue, locale, translate }) => (
	<ApexChart
		type="bar"
		width="100%"
		options={{
			chart: {
				fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
				redrawOnParentResize: true,
				dropShadow: { enabled: false },
				toolbar: { show: false },
				zoom: { enabled: false },
				defaultLocale: locale.split('_')[0],
				locales: [ApexChartLocaleIT, ApexChartLocaleEN],
			},
			plotOptions: {
				bar: {
					horizontal: false,
					columnWidth: '50%',
				},
			},
			dataLabels: {
				enabled: false,
			},
			xaxis: {
				type: 'datetime',
				labels: { datetimeUTC: false },
				tooltip: { enabled: false },
				sorted: true,
			},
			yaxis: {
				forceNiceScale: true,
				min: 0,
				labels: { formatter: (v) => `${Math.round(v * 100) / 100} €` },
			},
			stroke: { show: false },
			colors: ['#83D6DE'],
		}}
		series={[
			{
				name: translate('home.revenue.title'),
				data: revenue,
			},
		]}
	/>
);

export default withLocalize(RevenueChart);
