import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_administrators`;

const get = (id) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    Key: { id: id },
    ReturnConsumedCapacity: 'NONE',
  };
  return DDB.get(params).promise();
};

const batchGet = async (ids) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });

  let params = {
    RequestItems: {},
    ReturnConsumedCapacity: 'NONE',
  };
  params.RequestItems[TableName] = {
    Keys: ids.map((id) => ({ id: id })),
  };
  let data = await DDB.batchGet(params).promise();
  return data.Responses[TableName];
};

const getAll = (requestor, paginationOptions) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    FilterExpression: '#id <> :id',
    ExpressionAttributeNames: {
      '#id': 'id',
    },
    ExpressionAttributeValues: {
      ':id': requestor.id,
    },
    ReturnConsumedCapacity: 'NONE',
  };

  if (paginationOptions) {
    params.Limit = paginationOptions.limit;
    if (paginationOptions.nextTokens[paginationOptions.page - 1]) {
      params.ExclusiveStartKey = paginationOptions.nextTokens[paginationOptions.page - 1];
    }
  }
  if (requestor.type !== 'superuser') {
    params.IndexName = 'client-index';
    params.KeyConditionExpression = '#client = :client';
    params.ExpressionAttributeNames['#client'] = 'client';
    params.ExpressionAttributeValues[':client'] = requestor.client;
    return DDB.query(params).promise();
  }

  return DDB.scan(params).promise();
};

const getAllCount = (requestor) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    FilterExpression: '#id <> :id',
    ExpressionAttributeNames: {
      '#id': 'id',
    },
    ExpressionAttributeValues: {
      ':id': requestor.id,
    },
    ReturnConsumedCapacity: 'NONE',
    Select: 'COUNT',
  };
  if (requestor.type !== 'superuser') {
    params.IndexName = 'client-index';
    params.KeyConditionExpression = '#client = :client';
    params.ExpressionAttributeNames['#client'] = 'client';
    params.ExpressionAttributeValues[':client'] = requestor.client;
    return DDB.query(params).promise();
  }
  return DDB.scan(params).promise();
};

export default {
  get,
  batchGet,
  getAll,
  getAllCount,
};
