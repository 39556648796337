import React from 'react';
import DynamoDBDocuments from '../../../aws/dynamodb/documents';
import DynamoDBEndUsers from '../../../aws/dynamodb/endusers';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import S3 from '../../../aws/s3';
import UserContext from '../../../context';
import { randomId } from '../../../utilities';
import Documents from './presentation';

class DocumentsContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      list: [],
      count: 0,
      users: {},
      menuAnchorEl: null,
      filtersMenuAnchorEl: null,
      selectedDocument: null,
      pagination: {
        page: 0,
        rowsPerPage: 10,
        nextTokens: [],
      },
      filters: {
        client: '',
      },
      clients: [],
      snackbar: null,
      uploadDocumentOpen: false,
      deleteDocumentOpen: false,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.getDocuments = this.getDocuments.bind(this);
    this.openUploadDialog = this.openUploadDialog.bind(this);
    this.closeUploadDialog = this.closeUploadDialog.bind(this);
    this.openDeleteDialog = this.openDeleteDialog.bind(this);
    this.closeDeleteDialog = this.closeDeleteDialog.bind(this);
    this.downloadDocument = this.downloadDocument.bind(this);
    this.openCloseMenu = this.openCloseMenu.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this.getFilteredDocuments = this.getFilteredDocuments.bind(this);
    this.openCloseFilters = this.openCloseFilters.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  handleChangePage(event, newPage) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = newPage;
    this.secureSetState({ pagination }, this.getDocuments);
  }

  handleChangeRowsPerPage(event) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    pagination.rowsPerPage = event.target.value;
    this.secureSetState({ pagination }, this.getDocuments);
  }

  getFilteredDocuments(client) {
    let { pagination } = this.state;
    pagination.page = 0;
    this.secureSetState(
      {
        filters: { client },
        filtersMenuAnchorEl: null,
        pagination,
      },
      this.getDocuments
    );
  }

  openCloseFilters(event) {
    if (this.state.filtersMenuAnchorEl === null) {
      this.secureSetState({ filtersMenuAnchorEl: event.currentTarget });
    } else {
      this.secureSetState({ filtersMenuAnchorEl: null });
    }
  }

  getDocuments() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        /*let records = await DynamoDBDocuments.getAll(this.context.client, {
          limit: this.state.pagination.rowsPerPage,
          page: this.state.pagination.page,
          nextTokens: this.state.pagination.page === 0 ? [] : this.state.pagination.nextTokens,
        });*/
        let records = await DynamoDBDocuments.getAll(this.state.filters.client || this.context.client, {
          limit: this.state.pagination.rowsPerPage,
          page: this.state.pagination.page,
          nextTokens: this.state.pagination.page === 0 ? [] : this.state.pagination.nextTokens,
        });
        //let count = await DynamoDBDocuments.getAllCount(this.context.client);
        let count = await DynamoDBDocuments.getAllCount(this.state.filters.client || this.context.client);
        let { pagination } = this.state;
        if (records.LastEvaluatedKey) {
          if (pagination.page === 0) {
            pagination.nextTokens = [records.LastEvaluatedKey];
          } else if (pagination.nextTokens.length <= pagination.page) {
            pagination.nextTokens.push(records.LastEvaluatedKey);
          } else {
            pagination.nextTokens[pagination.page] = records.LastEvaluatedKey;
          }
        }
        let usersToGet = [];
        for (let item of records.Items) {
          if (usersToGet.indexOf(item.user) === -1 && !this.state.users.hasOwnProperty(item.user)) {
            usersToGet.push(item.user);
          }
        }
        let { users } = this.state;
        for (let i = 0; i < Math.ceil(usersToGet.length / 25); i++) {
          let ddbUsers = await DynamoDBEndUsers.batchGet(usersToGet.slice(i * 25, (i + 1) * 25));
          for (let user of ddbUsers) {
            let { id, ...u } = user;
            users[id] = u;
          }
        }
        this.secureSetState({ list: records.Items, count: count.Count, users, processing: false, pagination });
      } catch (err) {
        console.log(err);
        this.secureSetState({ list: [], count: 0, pagination: { page: 0, rowsPerPage: 10, nextTokens: [] }, processing: false });
      }
    });
  }

  openCloseMenu(document) {
    return (event) => {
      if (document === null) {
        this.secureSetState({ menuAnchorEl: null, selectedDocument: null });
      } else {
        this.secureSetState({ menuAnchorEl: event.currentTarget, selectedDocument: { ...document } });
      }
    };
  }

  async downloadDocument() {
    if (this.state.selectedDocument === null) {
      return;
    }
    this.secureSetState({ menuAnchorEl: null });
    try {
      let data = await S3.getInvoice(this.state.selectedDocument.user, this.state.selectedDocument.path);
      const blob = new Blob([data.Body], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${this.state.selectedDocument.date}.pdf`;
      document.body.appendChild(a);
      a.style = 'display: none';
      a.click();
      a.remove();
      setTimeout(() => window.URL.revokeObjectURL(url), 500);
    } catch (err) {
      console.log(err);
    }
  }

  openUploadDialog() {
    this.secureSetState({ uploadDocumentOpen: true });
  }

  closeUploadDialog(data) {
    if (data === null) {
      this.secureSetState({ uploadDocumentOpen: false });
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        let s3key = await S3.putInvoice(data.user, data.document);
        let document = {
          user: data.user,
          date: `${data.date}_${randomId(12)}`,
          type: data.type,
          client: data.client, //this.context.client,
          path: s3key,
        };
        await DynamoDBDocuments.store(document);
        this.secureSetState(
          {
            processing: false,
            uploadDocumentOpen: false,
            snackbar: 'success',
          },
          this.getDocuments
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'uploadError' });
      }
    });
  }

  openDeleteDialog() {
    this.secureSetState({ menuAnchorEl: null, deleteDocumentOpen: true });
  }

  closeDeleteDialog(remove) {
    if (!remove || this.state.selectedDocument === null) {
      this.secureSetState({ deleteDocumentOpen: false, selectedDocument: null });
      return;
    }
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, snackbar: null }, async () => {
      try {
        await DynamoDBDocuments.remove(this.state.selectedDocument.user, this.state.selectedDocument.date);
        await S3.deleteInvoice(this.state.selectedDocument.user, this.state.selectedDocument.path);
        this.secureSetState(
          {
            processing: false,
            deleteDocumentOpen: false,
            selectedDocument: null,
            snackbar: 'success',
          },
          this.getDocuments
        );
      } catch (err) {
        console.log(err);
        this.secureSetState({ processing: false, snackbar: 'deleteError' });
      }
    });
  }

  onSnackbarClose() {
    this.secureSetState({ snackbar: null });
  }

  async componentDidMount() {
    this.isMounted = true;
    let records_clients = {
      Items: [],
    };
    let clients = [];
    if (this.context.clientType === 'main' && this.context.clients.length > 0) {
      for (let i in this.context.clients) {
        let record = await DynamoDBClients.get(this.context.clients[i]);
        records_clients.Items.push(record.Item);
      }
    }
    clients = records_clients.Items.map((r) => ({ id: r.id, name: r.name }));
    this.secureSetState({ clients }, this.getDocuments);
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Documents
        {...this.state}
        user={this.context}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        openCloseMenu={this.openCloseMenu}
        openCloseFilters={this.openCloseFilters}
        downloadDocument={this.downloadDocument}
        openUploadDialog={this.openUploadDialog}
        closeUploadDialog={this.closeUploadDialog}
        openDeleteDialog={this.openDeleteDialog}
        closeDeleteDialog={this.closeDeleteDialog}
        getDocuments={this.getDocuments}
        getFilteredDocuments={this.getFilteredDocuments}
        onSnackbarClose={this.onSnackbarClose}
      />
    );
  }
}

DocumentsContainer.contextType = UserContext;
export default DocumentsContainer;
