import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const TransactionsDialogDelete = (props) => (
	<Dialog
		onClose={props.handleClose}
		aria-labelledby="transaction-running-dialog-unlock-title"
		aria-describedby="transaction-running-dialog-unlock-description"
		open={props.open}
		fullWidth
		maxWidth="xs"
	>
		<DialogTitle id="transaction-running-dialog-unlock-title">
			<Translate id="transactions.dialogs.unlock.title" />
		</DialogTitle>
		<DialogContent>
			<DialogContentText id="transaction-running-dialog-unlock-description">
				<Typography>
					<Translate id="transactions.dialogs.unlock.text" />
				</Typography>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.handleClose} color="default" autoFocus>
				<Translate id="transactions.dialogs.unlock.cancel" />
			</Button>
			<Button onClick={props.submit} color="primary">
				<Translate id="transactions.dialogs.unlock.submit" />
			</Button>
		</DialogActions>
	</Dialog>
);

export default withLocalize(TransactionsDialogDelete);
