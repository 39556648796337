import React from 'react';
import DynamoDBClients from '../../../aws/dynamodb/clients';
import S3 from '../../../aws/s3';
import UserContext from '../../../context';
import Payments from './presentation';

class CustomizationContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      clients: [],
      client: null,
      snackbar: null,
      logo: null,
      contacts: {
        email: '',
        facebook: '',
        instagram: '',
        linkedin: '',
        phone: '',
        twitter: '',
        website: '',
      },
      payment_limit: {
        amount: null,
        currency: '',
        overflow_action: '',
        permit_negative_budget: false,
      },
      payment_methods: {
        paypal: { client_id: '', client_secret: '', enabled: false, exists: false },
        stripe: { enabled: false, publishable_key: '', secret_key: '', exists: false },
        nayax: { terminal_id: '', token: '', enabled: false, exists: false },
      },
      payment_type: {
        bicycle: { options: { unit: '', currency: '', amount: '', theshold_hour: '', start_charge: '' }, type: '' },
        car: { options: { unit: '', currency: '', amount: '', theshold_hour: '', start_charge: '' }, type: '' },
      },
      reservations: {
        bicycle: { amount: null, notice: null },
        car: { amount: null, notice: null },
      },
      effatta: { enabled: false, fiscalCode: '', password: '', pin: '', vat: '' },
      cdn: null,
      public: true,
    };
    this.showSnackbar = this.showSnackbar.bind(this);
    this.onSnackbarClose = this.onSnackbarClose.bind(this);
    this.getCustomizations = this.getCustomizations.bind(this);
    this.update = this.update.bind(this);
    this.selectClient = this.selectClient.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  showSnackbar(msg) {
    this.secureSetState({ snackbar: msg });
  }

  onSnackbarClose() {
    this.secureSetState({ snackbar: null });
  }

  update(key, value) {
    this.secureSetState({ [key]: value });
  }

  async getCustomizations() {
    try {
      let data = await DynamoDBClients.get(this.state.client.id);
      let logo = await S3.getLogo(this.state.client.id);
      let payment_methods = {
        paypal: { client_id: '', client_secret: '', enabled: false, exists: false },
        stripe: { enabled: false, publishable_key: '', secret_key: '', exists: false },
        nayax: { terminal_id: '', token: '', enabled: false, exists: false },
      };
      for (let m in data.Item.payment_methods) {
        payment_methods[m] = data.Item.payment_methods[m];
        payment_methods[m].exists = true;
      }
      this.secureSetState({
        logo: logo,
        contacts: data.Item.contacts,
        payment_limit: data.Item.payment_limit,
        payment_methods: payment_methods,
        payment_type: data.Item.payment_type,
        reservations: data.Item.reservations,
        public: data.Item.public,
        effatta: data.Item.effatta || { enabled: false },
        cdn: data.Item.cdn || null,
      });
    } catch (err) {
      console.log(err);
      this.showSnackbar('fetch');
    }
  }

  selectClient(e, client) {
    if (this.state.processing) {
      return;
    }
    if (client) {
      this.secureSetState({ client: client }, this.getCustomizations);
    } else {
      this.secureSetState({ client: null });
    }
  }

  async componentDidMount() {
    this.isMounted = true;
    if (this.context.type === 'superuser') {
      try {
        let clients = await DynamoDBClients.getAll();
        this.secureSetState({ processing: false, clients: clients.Items.map((c) => ({ id: c.id, name: c.name })) });
      } catch (err) {
        console.log(err);
      }
    } else if (this.context.clientType === 'main' && this.context.client.length > 0) {
      let clients = await DynamoDBClients.batchGet(this.context.clients);
      this.secureSetState({ processing: false, clients: clients.map((c) => ({ id: c.id, name: c.name })) });
    } else {
      this.secureSetState({ client: { id: this.context.client } }, this.getCustomizations);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Payments
        {...this.state}
        user={this.context}
        selectClient={this.selectClient}
        update={this.update}
        showSnackbar={this.showSnackbar}
        onSnackbarClose={this.onSnackbarClose}
        getCustomizations={this.getCustomizations}
      />
    );
  }
}

CustomizationContainer.contextType = UserContext;
export default CustomizationContainer;
