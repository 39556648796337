import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import HttpIcon from '@material-ui/icons/Http';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import AtmIcon from '@material-ui/icons/Atm';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import PhoneAndroidIcon from '@material-ui/icons/PhoneAndroid';
import PowerOffIcon from '@material-ui/icons/PowerOff';
import StyleIcon from '@material-ui/icons/Style';
import makeStyles from '@material-ui/core/styles/makeStyles';
import StopDialog from './presentation.dialog.stop';
import UnlockDialog from './presentation.dialog.unlock';
import DialogTrend from './presentation.dialog.trend';

const getOptionLabel = (chargeboxes) => (option) => {
  if (typeof option === 'string') {
    let chargebox = chargeboxes.find((c) => c.id === option);
    if (chargebox === undefined) {
      return 'UNKNOWN';
    }
    return chargebox.alias;
  }
  return option.alias;
};

const DateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const getDate = (timestamp, locale) => {
  let date = new Date(timestamp);
  return date.toLocaleTimeString(locale.replace('_', '-'), DateOptions);
};

const getType = (type, payment) => {
  switch (type) {
    case 'local':
      return (
        <Tooltip title={<Translate id='transactions.type.local' />}>
          <StyleIcon color='action' />
        </Tooltip>
      );

    case 'remote':
      if (payment.method === 'nayax') {
        return (
          <Tooltip title={<Translate id='transactions.type.remote' />}>
            <AtmIcon color='action' />
          </Tooltip>
        );
      }
      return (
        <Tooltip title={<Translate id='transactions.type.remote' />}>
          <PhoneAndroidIcon color='action' />
        </Tooltip>
      );

    case 'api':
      return (
        <Tooltip title={<Translate id='transactions.type.api' />}>
          <HttpIcon color='action' />
        </Tooltip>
      );
    default:
      return null;
  }
};

const getUser = (transaction, users, rfids) => {
  if (transaction.type === 'remote') {
    if (users.hasOwnProperty(transaction.user)) {
      return users[transaction.user].ragsoc || users[transaction.user].company_name || users[transaction.user].name;
    }
    return null;
  }

  if (transaction.type === 'local') {
    if (rfids.hasOwnProperty(transaction.idTag)) {
      if (typeof rfids[transaction.idTag].user === 'string' && rfids[transaction.idTag].user.length > 0) {
        return users[rfids[transaction.idTag].user].ragsoc || users[rfids[transaction.idTag].user].company_name || users[rfids[transaction.idTag].user].name;
      } else {
        return rfids[transaction.idTag].alias;
      }
    }
    return null;
  }
  return null;
};
const useStyles = makeStyles({
  filtersContainer: {
    padding: '1rem',
  },
});

const RunningTransactions = (props) => {
  const classes = useStyles(props);
  return (
    <Paper elevation={3}>
      <DialogTrend
        open={props.dialogTrendOpen}
        handleClose={props.toggleDialogTrend}
        transaction={props.selectedTransaction === null ? null : props.selectedTransaction}
        user={props.user}
      />
      <StopDialog open={props.dialogStopOpen} handleClose={() => props.stop(false)} submit={() => props.stop(true)} />
      <UnlockDialog open={props.dialogUnlockOpen} handleClose={() => props.unlock(false)} submit={() => props.unlock(true)} />
      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <Grid container direction='column' className={classes.filtersContainer}>
          <Typography variant='h5'>
            <Translate id='transactions.runningTitle' />
          </Typography>
          <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='wrap' spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                options={props.chargeboxesList}
                getOptionLabel={getOptionLabel(props.chargeboxesList)}
                getOptionSelected={(o, t) => o.id === t.id}
                openOnFocus
                autoHighlight
                fullWidth
                value={props.filters.chargebox}
                onChange={(event, newValue) => props.onChange({ target: { name: 'chargebox', value: newValue } })}
                renderInput={(params) => <TextField {...params} label={props.translate('transactions.chargebox')} />}
                disabled={props.filters.all}
              />
            </Grid>
            <Grid item xs={4}>
              <Button color='primary' variant='contained' onClick={props.search} disabled={props.filters.chargebox === null && !props.filters.all}>
                <Translate id='transactions.search' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {props.runningTransactions.length > 0 && (
          <React.Fragment>
            <TableContainer>
              <Table aria-label='table' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Translate id='transactions.chargebox' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.connector' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.transactionID' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.type.type' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.paymentMethod' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.user' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.started' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.consume' />
                    </TableCell>
                    <TableCell>
                      <Translate id='transactions.price' />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.runningTransactions.map((row, id) => (
                    <TableRow key={id}>
                      <TableCell>{props.chargeboxes[row.chargebox].alias}</TableCell>
                      <TableCell>{row.connector}</TableCell>
                      <TableCell>{row.transactionId}</TableCell>
                      <TableCell>{row.payment ? getType(row.type, row.payment) : getType(row.type, { method: null })}</TableCell>
                      {row.type === 'remote' && (
                        <TableCell>
                          {row.payment && row.payment.method ? (
                            <Translate id={`transactions.paymentMethods.${row.payment.method}`} />
                          ) : (
                            <Translate id='transactions.paymentMethods.gratis' />
                          )}
                        </TableCell>
                      )}
                      {row.type === 'local' && (
                        <TableCell>
                          <Translate id='transactions.paymentMethods.rfid' />
                        </TableCell>
                      )}
                      {row.type === 'api' && (
                        <TableCell>
                          <Translate id='transactions.paymentMethods.api' />
                        </TableCell>
                      )}
                      <TableCell>{getUser(row, props.users, props.rfids)}</TableCell>
                      <TableCell>{getDate(row.dateStarted, props.user.locale)}</TableCell>
                      <TableCell>{row.lastMeterValue}</TableCell>
                      <TableCell>{row.payment ? row.payment.amount : ''}</TableCell>
                      <TableCell>
                        <IconButton onClick={props.openCloseMenu(row)}>
                          <MoreVertIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Menu anchorEl={props.menuAnchorEl} open={props.menuAnchorEl !== null} onClose={props.openCloseMenu(null)}>
              <MenuItem button onClick={props.toggleStopDialog}>
                <ListItemIcon>
                  <PowerOffIcon fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit' noWrap>
                  <Translate id='transactions.stop' />
                </Typography>
              </MenuItem>
              <MenuItem button onClick={props.toggleUnlockDialog}>
                <ListItemIcon>
                  <LockOpenIcon fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit' noWrap>
                  <Translate id='transactions.unlock' />
                </Typography>
              </MenuItem>
              <MenuItem button onClick={props.toggleDialogTrend}>
                <ListItemIcon className={classes.listItemIcon}>
                  <ShowChartIcon fontSize='small' />
                </ListItemIcon>
                <Typography variant='inherit' noWrap>
                  <Translate id='transactions.trend' />
                </Typography>
              </MenuItem>
            </Menu>
          </React.Fragment>
        )}
      </Grid>
    </Paper>
  );
};

export default withLocalize(RunningTransactions);
