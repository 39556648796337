import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_chargeboxes_meter_values`;

const getLast = async (chargebox, connector, transaction) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    KeyConditionExpression: '#id = :id',
    ExpressionAttributeNames: {
      '#id': 'id',
    },
    ExpressionAttributeValues: {
      ':id': `${chargebox}__${connector}__${transaction}__eair`,
    },
    ScanIndexForward: false,
    Limit: 1,
    ReturnConsumedCapacity: 'NONE',
  };
  let data = await DDB.query(params).promise();
  return data.Count === 1 ? data.Items[0] : null;
};

const query = async (id) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  const params = {
    TableName: TableName,
    KeyConditionExpression: '#id = :id',
    ProjectionExpression: '#timestamp,#uom,#value',
    ExpressionAttributeNames: {
      '#id': 'id',
      '#timestamp': 'timestamp',
      '#uom': 'uom',
      '#value': 'value',
    },
    ExpressionAttributeValues: { ':id': id },
    ReturnConsumedCapacity: 'NONE',
  };
  let data = await DDB.query(params).promise();
  return data.Items;
};

export default {
  getLast,
  query,
};
