import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CancelReservationDialog from './presentation.dialog.cancel';

const getOptionLabel = (chargeboxes) => (option) => {
  if (typeof option === 'string') {
    let chargebox = chargeboxes.find((c) => c.id === option);
    if (chargebox === undefined) {
      return 'UNKNOWN';
    }
    return chargebox.alias;
  }
  return option.alias;
};

const DateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const getDate = (timestamp, locale) => {
  let date = new Date(timestamp);
  return date.toLocaleTimeString(locale.replace('_', '-'), DateOptions);
};

const getUser = (reservation, users) => {
  if (users.hasOwnProperty(reservation.user)) {
    return users[reservation.user].name || users[reservation.user].company_name;
  }
  return null;
};

const useStyles = makeStyles({
  filtersContainer: {
    padding: '1rem',
  },
});

const ActiveReservations = (props) => {
  const classes = useStyles(props);
  return (
    <Paper elevation={3}>
      <CancelReservationDialog
        open={props.dialogCancelReservationOpen}
        handleClose={() => props.cancelReservation(false, false)}
        submit={(pay) => props.cancelReservation(true, pay)}
        chargebox={props.selectedReservation === null ? null : props.chargeboxes[props.selectedReservation.chargebox].alias}
        connector={props.selectedReservation === null ? null : props.selectedReservation.connector}
        user={props.selectedReservation === null ? null : getUser(props.selectedReservation, props.users)}
        reservation={props.selectedReservation}
      />
      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <Grid container direction='column' className={classes.filtersContainer}>
          <Typography variant='h5'>
            <Translate id='reservations.activeTitle' />
          </Typography>
          <Grid container direction='row' alignItems='center' justifyContent='space-between' wrap='wrap' spacing={2}>
            <Grid item xs={8}>
              <Autocomplete
                options={props.chargeboxesList}
                getOptionLabel={getOptionLabel(props.chargeboxesList)}
                getOptionSelected={(o, t) => o.id === t.id}
                openOnFocus
                autoHighlight
                fullWidth
                value={props.filters.chargebox}
                onChange={(event, newValue) => props.onChange({ target: { name: 'chargebox', value: newValue } })}
                renderInput={(params) => <TextField {...params} label={props.translate('reservations.chargebox')} />}
                disabled={props.filters.all}
              />
            </Grid>
            <Grid item xs={4}>
              <Button color='primary' variant='contained' onClick={props.search} disabled={props.filters.chargebox === null && !props.filters.all}>
                <Translate id='reservations.search' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {props.activeReservations.length > 0 && (
          <React.Fragment>
            <TableContainer>
              <Table aria-label='table' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Translate id='reservations.chargebox' />
                    </TableCell>
                    <TableCell>
                      <Translate id='reservations.connector' />
                    </TableCell>
                    <TableCell>
                      <Translate id='reservations.reservationID' />
                    </TableCell>
                    <TableCell>
                      <Translate id='reservations.paymentMethod' />
                    </TableCell>
                    <TableCell>
                      <Translate id='reservations.user' />
                    </TableCell>
                    <TableCell>
                      <Translate id='reservations.started' />
                    </TableCell>
                    <TableCell>
                      <Translate id='reservations.expiry' />
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {props.activeReservations.map((row, id) => (
                    <TableRow key={id}>
                      <TableCell>{props.chargeboxes[row.chargebox].alias}</TableCell>
                      <TableCell>{row.connector}</TableCell>
                      <TableCell>{row.reservationId}</TableCell>
                      <TableCell>
                        {row.payment ? (
                          <Translate id={`reservations.paymentMethods.${row.payment.method}`} />
                        ) : (
                          <Translate id='reservations.paymentMethods.free' />
                        )}
                      </TableCell>
                      <TableCell>{getUser(row, props.users)}</TableCell>
                      <TableCell>{getDate(row.dateAccepted, props.user.locale)}</TableCell>
                      <TableCell>{getDate(row.expiryDate, props.user.locale)}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => props.toggleCancelReservationDialog(row)}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </React.Fragment>
        )}
      </Grid>
    </Paper>
  );
};

export default withLocalize(ActiveReservations);
