import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import { GoogleMap, Marker } from '@react-google-maps/api';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DeleteIcon from '@material-ui/icons/Delete';
import DirectionsBikeIcon from '@material-ui/icons/DirectionsBike';
import DirectionsCarIcon from '@material-ui/icons/DirectionsCar';
import PlusOneIcon from '@material-ui/icons/PlusOne';
import debounce from '@material-ui/core/utils/debounce';
import { asyncTimeout } from '../../../utilities';

const getOptionLabel = (list) => (option) => {
  if (typeof option === 'string') {
    let element = list.find((c) => c.id === option);
    if (element === undefined) {
      return 'UNKNOWN';
    }
    return element.name;
  }

  return option.name;
};

const getOptionSelected = (o, t) => {
  if (typeof t === 'string') {
    return o.id === t;
  }
  return o.id === t.id;
};

class ChargeboxesDialogNewEdit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      addressDisabled: true,
      address: '',
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onChange = this.onChange.bind(this);
    this.resolveAddress = debounce(this.resolveAddress.bind(this), 1000);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
    this._geocoder = null;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  get geocoder() {
    return this._geocoder;
  }

  set geocoder(val) {
    this._geocoder = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onDragEnd({ latLng }) {
    let lat = latLng.lat();
    let lng = latLng.lng();

    this.props.onChange({
      target: {
        name: 'coordinates',
        value: { latitude: lat, longitude: lng },
      },
    });

    if (this.geocoder !== null) {
      this.geocoder.geocode({ location: { lat, lng } }, async (results, status) => {
        if (status !== 'OK' || !results[0]) {
          return;
        }
        this.secureSetState({ address: results[0].formatted_address });
      });
    }
  }

  resolveAddress(address) {
    this.geocoder.geocode({ address }, async (results, status) => {
      if (status !== 'OK' || !results[0]) {
        return;
      }
      let lat = results[0].geometry.location.lat();
      let lng = results[0].geometry.location.lng();
      this.props.onChange({
        target: {
          name: 'coordinates',
          value: { latitude: lat, longitude: lng },
        },
      });
    });
  }

  onChange(e) {
    let { name, value } = e.target;
    if (name === 'address') {
      this.secureSetState({ [name]: value }, () => this.resolveAddress(value));
    } else {
      this.secureSetState({ [name]: value });
    }
  }

  async componentDidMount() {
    this.isMounted = true;

    for (;;) {
      if (!window.google) {
        await asyncTimeout(200);
        continue;
      }
      this.geocoder = new window.google.maps.Geocoder();

      this.secureSetState({ addressDisabled: false });
      break;
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open && this.geocoder !== null) {
      this.geocoder.geocode(
        {
          location: {
            lat: this.props.chargebox.coordinates.latitude,
            lng: this.props.chargebox.coordinates.longitude,
          },
        },
        async (results, status) => {
          if (status !== 'OK' || !results[0]) {
            return;
          }
          this.secureSetState({ address: results[0].formatted_address });
        }
      );
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    let formatValue = '';
    if (this.props.chargebox.type.msg.length > 0) {
      formatValue = `${this.props.chargebox.type.msg}-${this.props.chargebox.type.version}`;
    }
    return (
      <Dialog onClose={this.props.handleClose} aria-labelledby='chargebox-dialog-new-edit-title' open={this.props.open} maxWidth='lg' fullWidth>
        <DialogTitle id='chargebox-dialog-new-edit-title'>
          {this.props.isEdit ? <Translate id='chargeboxes.dialogs.edit.title' /> : <Translate id='chargeboxes.dialogs.create.title' />}
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={2}>
            {(this.props.user.type === 'superuser' || (this.props.user.clientType === 'main' && this.props.user.clients.length > 0)) && (
              <Grid item>
                <Autocomplete
                  openOnFocus
                  autoHighlight
                  options={this.props.clients}
                  getOptionLabel={getOptionLabel(this.props.clients)}
                  getOptionSelected={getOptionSelected}
                  fullWidth
                  value={this.props.chargebox.client}
                  onChange={(event, newValue) => this.props.onChange({ target: { name: 'client', value: newValue } })}
                  disabled={this.props.isEdit}
                  renderInput={(params) => <TextField {...params} label={this.props.translate('chargeboxes.client')} />}
                />
              </Grid>
            )}
            <Grid item container direction='row' alignItems='center' justifyContent='center' spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={this.props.translate('chargeboxes.id')}
                  name='id'
                  value={this.props.chargebox.id}
                  onChange={this.props.onChange}
                  fullWidth
                  disabled={this.props.isEdit || formatValue === 'nc-nc'}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={this.props.translate('chargeboxes.alias')}
                  name='alias'
                  value={this.props.chargebox.alias}
                  onChange={this.props.onChange}
                  fullWidth
                  inputProps={{ maxLength: 20 }}
                />
              </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center' justifyContent='center' spacing={2}>
              <Grid item xs={12} sm={6}>
                <Autocomplete
                  options={this.props.vendors}
                  getOptionLabel={getOptionLabel(this.props.vendors)}
                  getOptionSelected={getOptionSelected}
                  openOnFocus
                  autoHighlight
                  fullWidth
                  value={this.props.chargebox.vendor}
                  onChange={(event, newValue) => this.props.onChange({ target: { name: 'vendor', value: newValue } })}
                  renderInput={(params) => <TextField {...params} label={this.props.translate('chargeboxes.vendor')} />}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControlLabel
                  value='end'
                  control={<Checkbox checked={this.props.chargebox.active} name='active' onClick={this.props.onChange} color='primary' />}
                  label={this.props.translate('chargeboxes.enabled')}
                  labelPlacement='end'
                />
                <FormControlLabel
                  value='end'
                  control={
                    <Checkbox
                      checked={this.props.chargebox.public}
                      name='public'
                      onClick={this.props.onChange}
                      color='primary'
                      disabled={formatValue === 'nc-nc'}
                    />
                  }
                  label={this.props.translate('chargeboxes.public')}
                  labelPlacement='end'
                />
                <FormControlLabel
                  value='end'
                  control={
                    <Checkbox
                      checked={this.props.chargebox.reservable}
                      name='reservable'
                      onClick={this.props.onChange}
                      color='primary'
                      disabled={
                        formatValue !== 'json-1.6' ||
                        this.props.chargebox.vendor === null ||
                        (this.props.chargebox.vendor.id !== 'smartbit' && this.props.chargebox.vendor.id !== 'alfen')
                      }
                    />
                  }
                  label={this.props.translate('chargeboxes.reservable')}
                  labelPlacement='end'
                />
                <FormControlLabel
                  value='end'
                  control={
                    <Checkbox
                      checked={this.props.chargebox.e015}
                      name='e015'
                      onClick={this.props.onChange}
                      color='primary'
                      //disabled={formatValue !== 'json-1.6'}
                    />
                  }
                  label={this.props.translate('chargeboxes.e015')}
                  labelPlacement='end'
                />
                {this.props.chargebox.e015 && (
                  <FormControlLabel
                    value='end'
                    control={
                      <Checkbox
                        checked={this.props.chargebox.e015Params.sostenibilità}
                        name='sostenibilità'
                        onClick={this.props.onChange}
                        color='primary'
                        //disabled={formatValue !== 'json-1.6'}
                      />
                    }
                    label={this.props.translate('chargeboxes.sustainable')}
                    labelPlacement='end'
                  />
                )}
              </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center' justifyContent='center' spacing={2}>
              <Grid item xs={12} sm={6}>
                <IconButton
                  onClick={() => this.props.onChange({ target: { name: 'vehicle', value: 'car' } })}
                  color={this.props.chargebox.type.vehicle === 'car' ? 'primary' : 'default'}
                >
                  <DirectionsCarIcon />
                </IconButton>
                <IconButton
                  onClick={() => this.props.onChange({ target: { name: 'vehicle', value: 'bicycle' } })}
                  color={this.props.chargebox.type.vehicle === 'bicycle' ? 'primary' : 'default'}
                >
                  <DirectionsBikeIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth>
                  <InputLabel id='chargebox-dialog-newedit-select-format'>
                    <Translate id='chargeboxes.format' />
                  </InputLabel>
                  <Select labelId='chargebox-dialog-newedit-select-format' name='format' value={formatValue} onChange={this.props.onChange} fullWidth>
                    <MenuItem value='soap-1.5'>OCPP SOAP v1.5</MenuItem>
                    <MenuItem value='json-1.6'>OCPP JSON v1.6</MenuItem>
                    <MenuItem value='nc-nc'>
                      <Translate id='chargeboxes.notConnected' />
                    </MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid item container direction='row' alignItems='center' justifyContent='center' spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={this.props.translate('chargeboxes.text_it')}
                  name='text_it'
                  value={this.props.chargebox.text_it}
                  onChange={this.props.onChange}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  label={this.props.translate('chargeboxes.text_en')}
                  name='text_en'
                  value={this.props.chargebox.text_en}
                  onChange={this.props.onChange}
                  fullWidth
                />
              </Grid>
            </Grid>
            {this.props.chargebox.type.msg === 'soap' && this.props.chargebox.type.version === '1.5' && (
              <Grid item container direction='row' alignItems='flex-end' justifyContent='flex-start'>
                <Button
                  size='small'
                  onClick={() => this.props.onChange({ target: { name: 'connectionType', value: 'http' } })}
                  color={this.props.chargebox.connection.type === 'http' ? 'primary' : 'default'}
                >
                  HTTP
                </Button>
                <Button
                  size='small'
                  onClick={() => this.props.onChange({ target: { name: 'connectionType', value: 'https' } })}
                  color={this.props.chargebox.connection.type === 'https' ? 'primary' : 'default'}
                >
                  HTTPS
                </Button>
                <Typography>://</Typography>
                <TextField
                  label={this.props.translate('chargeboxes.ip')}
                  name='host'
                  value={this.props.chargebox.connection.host || 'XX.XX.XX.XX'}
                  onChange={this.props.onChange}
                  disabled
                />
                <Typography>:</Typography>
                <TextField
                  label={this.props.translate('chargeboxes.port')}
                  name='port'
                  value={this.props.chargebox.connection.port}
                  onChange={this.props.onChange}
                />
                <Typography>/</Typography>
                <TextField
                  label={this.props.translate('chargeboxes.path')}
                  name='path'
                  value={this.props.chargebox.connection.path === null ? '' : this.props.chargebox.connection.path.replace(/^\/+/gim, '')}
                  onChange={this.props.onChange}
                />
              </Grid>
            )}
            <TableContainer>
              <Table aria-label='table' size='small'>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <Translate id='chargeboxes.connectorID' />
                    </TableCell>
                    <TableCell>
                      <Translate id='chargeboxes.type' />
                    </TableCell>
                    <TableCell>
                      <Translate id='chargeboxes.format' />
                    </TableCell>
                    <TableCell>
                      <Translate id='chargeboxes.power_type' />
                    </TableCell>
                    <TableCell>
                      <Translate id='chargeboxes.power' />
                    </TableCell>
                    {this.props.chargebox && this.props.chargebox.client && this.props.chargebox.client.nayax && (
                      <>
                        <TableCell>
                          <Translate id='chargeboxes.nayax.terminalId' />
                        </TableCell>
                        <TableCell>
                          <Translate id='chargeboxes.nayax.token' />
                        </TableCell>
                      </>
                    )}
                    <TableCell>
                      <Translate id='chargeboxes.max_amperage' />
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={() => this.props.onChange({ target: { name: 'add-connector', value: null } })}>
                        <PlusOneIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody id='connectors'>
                  {this.props.chargebox.connectors.map((row, id) => (
                    <TableRow key={id}>
                      <TableCell>{id + 1}</TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id={`chargebox-dialog-newedit-select-connectort-type-${id}`} fontSize='xx-small'>
                            <Translate id='chargeboxes.type' />
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId={`chargebox-dialog-newedit-select-connectort-type-${id}`}
                            value={
                              !this.props.chargebox.connectors[id].type ||
                              (this.props.chargebox.connectors[id].type && this.props.chargebox.connectors[id].type === null)
                                ? ''
                                : this.props.chargebox.connectors[id].type
                            }
                            onChange={(e) => this.props.onChange({ target: { name: 'connector-type', value: `${id}-${e.target.value}` } })}
                          >
                            <MenuItem value='ccs'>CCS</MenuItem>
                            <MenuItem value='chademo'>Chademo</MenuItem>
                            <MenuItem value='commando'>Commando</MenuItem>
                            <MenuItem value='type_1'>Type 1</MenuItem>
                            <MenuItem value='type_2'>Type 2</MenuItem>
                            <MenuItem value='type_3a'>Type 3A</MenuItem>
                            <MenuItem value='type_3c'>Type 3C</MenuItem>
                            <MenuItem value='schuko'>Schuko</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id={`chargebox-dialog-newedit-select-connectort-format-${id}`}>
                            <Translate id='chargeboxes.format' />
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId={`chargebox-dialog-newedit-select-connectort-format-${id}`}
                            value={
                              !this.props.chargebox.connectors[id].format ||
                              (this.props.chargebox.connectors[id].format && this.props.chargebox.connectors[id].format === null)
                                ? ''
                                : this.props.chargebox.connectors[id].format
                            }
                            onChange={(e) => this.props.onChange({ target: { name: 'connector-format', value: `${id}-${e.target.value}` } })}
                          >
                            <MenuItem value='SOCKET'>SOCKET</MenuItem>
                            <MenuItem value='CABLE'>CABLE</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth>
                          <InputLabel id={`chargebox-dialog-newedit-select-connectort-power_type-${id}`}>
                            <Translate id='chargeboxes.power_type' />
                          </InputLabel>
                          <Select
                            fullWidth
                            labelId={`chargebox-dialog-newedit-select-connectort-power_type-${id}`}
                            value={
                              !this.props.chargebox.connectors[id].power_type ||
                              (this.props.chargebox.connectors[id].power_type && this.props.chargebox.connectors[id].power_type === null)
                                ? ''
                                : this.props.chargebox.connectors[id].power_type
                            }
                            onChange={(e) => this.props.onChange({ target: { name: 'connector-power_type', value: `${id}-${e.target.value}` } })}
                          >
                            <MenuItem value='AC_1_PHASE'>AC single Phase</MenuItem>
                            <MenuItem value='AC_3_PHASE'>AC three Phase</MenuItem>
                            <MenuItem value='DC'>DC</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <TextField
                          label={this.props.translate('chargeboxes.power')}
                          value={this.props.chargebox.connectors[id].power === null ? '' : this.props.chargebox.connectors[id].power.toString()}
                          onChange={(e) => this.props.onChange({ target: { name: 'connector-power', value: `${id}-${e.target.value}` } })}
                          fullWidth
                        />
                      </TableCell>
                      {this.props.chargebox && this.props.chargebox.client && this.props.chargebox.client.nayax && (
                        <>
                          <TableCell>
                            <TextField
                              label={this.props.translate('chargeboxes.nayax.terminalId')}
                              value={
                                (!this.props.chargebox.connectors[id].terminal_id || this.props.chargebox.connectors[id].terminal_id) === null
                                  ? ''
                                  : this.props.chargebox.connectors[id].terminal_id
                              }
                              onChange={(e) =>
                                this.props.onChange({
                                  target: { name: 'connector-terminal_id', value: `${id}|${e.target.value}` },
                                })
                              }
                              fullWidth
                            />
                          </TableCell>
                          <TableCell>
                            <TextField
                              label={this.props.translate('chargeboxes.nayax.token')}
                              value={this.props.chargebox.connectors[id].token === null ? '' : this.props.chargebox.connectors[id].token}
                              onChange={(e) =>
                                this.props.onChange({
                                  target: { name: 'connector-token', value: `${id}|${e.target.value}` },
                                })
                              }
                              fullWidth
                            />
                          </TableCell>
                        </>
                      )}
                      <TableCell>
                        <TextField
                          label={this.props.translate('chargeboxes.max_amperage')}
                          value={
                            !this.props.chargebox.connectors[id].max_amperage ||
                            (this.props.chargebox.connectors[id].max_amperage && this.props.chargebox.connectors[id].max_amperage === null)
                              ? ''
                              : this.props.chargebox.connectors[id].max_amperage
                          }
                          onChange={(e) => this.props.onChange({ target: { name: 'connector-max_amperage', value: `${id}-${e.target.value}` } })}
                          fullWidth
                        />
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => this.props.onChange({ target: { name: 'delete-connector', value: id } })}>
                          <DeleteIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid item>
              <TextField
                disabled={this.state.addressDisabled}
                value={this.state.address}
                label={this.props.translate('chargeboxes.address')}
                name='address'
                onChange={this.onChange}
                fullWidth
              />
            </Grid>
            <GoogleMap
              mapContainerStyle={{
                height: '20rem',
                width: '100%',
              }}
              options={{
                zoomControl: true,
                mapTypeControl: false,
                scaleControl: false,
                streetViewControl: false,
                rotateControl: false,
                fullscreenControl: false,
              }}
              zoom={14}
              center={{
                lat: this.props.chargebox.coordinates.latitude,
                lng: this.props.chargebox.coordinates.longitude,
              }}
            >
              <Marker
                position={{
                  lat: this.props.chargebox.coordinates.latitude,
                  lng: this.props.chargebox.coordinates.longitude,
                }}
                draggable
                onDragEnd={this.onDragEnd}
              />
            </GoogleMap>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color='default' autoFocus>
            <Translate id='chargeboxes.dialogs.close' />
          </Button>
          {(this.props.user.type.toLowerCase() === 'superuser' || this.props.user.hasPrivileges) && (
            <Button onClick={this.props.submit} color='primary'>
              {this.props.isEdit ? <Translate id='chargeboxes.dialogs.edit.submit' /> : <Translate id='chargeboxes.dialogs.create.submit' />}
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withLocalize(ChargeboxesDialogNewEdit);
