import React from 'react';
import { Translate } from 'react-localize-redux';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const MostUsedChargeboxes = ({ mostUsedChargeboxes, chargeboxes }) => (
	<TableContainer>
		<Table aria-label="table" size="small">
			<TableHead>
				<TableRow>
					<TableCell>
						<Translate id="home.mostUsed.chargebox" />
					</TableCell>
					<TableCell>
						<Translate id="home.mostUsed.numberOfCharges" />
					</TableCell>
				</TableRow>
			</TableHead>
			<TableBody>
				{mostUsedChargeboxes.map((d, idx) => (
					<TableRow key={idx}>
						<TableCell>{chargeboxes[d.chargebox]}</TableCell>
						<TableCell>{d.charges}</TableCell>
					</TableRow>
				))}
			</TableBody>
		</Table>
	</TableContainer>
);

export default MostUsedChargeboxes;
