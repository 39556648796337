import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import withStyles from '@material-ui/core/styles/withStyles';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
};

class CustomizationVisibility extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      public: true,
    };
    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, checked } = e.target;
    this.secureSetState({ [name]: checked });
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ public: this.props.public });
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      let pblc = this.state.public;
      try {
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            public: pblc,
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('public', pblc);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('visibilityError');
        });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let state = {};
    if (prevProps.public !== this.props.public) {
      state.public = this.props.public && true;
    }

    if (Object.keys(state).length > 0) {
      this.secureSetState(state);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.visibility.title' />} subheader={<Translate id='customization.visibility.subheader' />} />
        <CardContent>
          <FormControlLabel
            control={<Checkbox checked={this.state.public} onChange={this.onChange} name='public' color='primary' />}
            label={this.props.translate('customization.visibility.show')}
          />
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <Button size='small' onClick={this.reset}>
              <Translate id='customization.reset' />
            </Button>
            <Button size='small' color='primary' onClick={this.submit}>
              <Translate id='customization.confirm' />
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationVisibility));
