import React from 'react';
import Typography from '@material-ui/core/Typography';

const version = 'v1.11';

const Version = (props) => {
  return <Typography className='version'>{version}</Typography>;
};

export default Version;
