import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import withStyles from '@material-ui/core/styles/withStyles';
import { KeyboardDatePicker } from '@material-ui/pickers';
import DynamoDBChargeboxesStatus from '../../../aws/dynamodb/chargeboxes-status';

const styles = {
  button: { margin: '1rem' },
};

const getConnector = (id) => {
  let arr = id.split('__');
  if (arr.length > 1) {
    return arr[1];
  }
  return null;
};

const DateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const getDate = (timestamp, locale) => {
  let date = new Date(timestamp);
  return date.toLocaleTimeString(locale.replace('_', '-'), DateOptions);
};
const getDefaultState = () => {
  let from = new Date();
  let to = new Date();
  from.setDate(from.getDate() - 7);
  return {
    connector: null,
    whole: true,
    from: from,
    to: to,
    list: null,
    count: 0,
    pagination: {
      page: 0,
      rowsPerPage: 10,
      nextTokens: [],
    },
  };
};
class ChargeboxesDialogStatus extends React.Component {
  constructor(props) {
    super(props);
    this.state = getDefaultState();

    this.changeConnector = this.changeConnector.bind(this);
    this.onChange = this.onChange.bind(this);
    this.isSubmitDisabled = this.isSubmitDisabled.bind(this);
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  changeConnector(c) {
    return () => this.secureSetState({ connector: c });
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let { name, value } = e.target;
    if (name === 'whole') {
      this.secureSetState({ list: null, count: 0, pagination: { page: 0, rowsPerPage: 10, nextTokens: [] }, whole: !this.state.whole });
    } else {
      this.secureSetState({ list: null, count: 0, pagination: { page: 0, rowsPerPage: 10, nextTokens: [] }, [name]: value });
    }
  }

  isSubmitDisabled() {
    if (!this.state.whole && this.state.connector === null) {
      return true;
    }
    if (this.state.from === null || this.state.to === null) {
      return true;
    }
    let from = this.state.from.getTime();
    let to = this.state.to.getTime();
    if (isNaN(from) || isNaN(to)) {
      return true;
    }
    if (from > to) {
      return true;
    }
    return false;
  }

  handleChangePage(event, newPage) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = newPage;
    this.secureSetState({ pagination }, this.submit);
  }

  handleChangeRowsPerPage(event) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    pagination.rowsPerPage = event.target.value;
    this.secureSetState({ pagination }, this.submit);
  }

  submit() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true, count: 0, list: null }, async () => {
      try {
        let connector = this.state.whole ? null : this.state.connector;
        let { from, to } = this.state;
        from.setHours(0, 0, 0, 0);
        to.setHours(23, 59, 59, 999);
        let timestamps = { start: from.getTime(), end: to.getTime() };
        let count = await DynamoDBChargeboxesStatus.getAllCount(this.props.chargebox.id, connector, timestamps);
        let records = await DynamoDBChargeboxesStatus.getAll(this.props.chargebox.id, connector, timestamps, false, {
          limit: this.state.pagination.rowsPerPage,
          page: this.state.pagination.page,
          nextTokens: this.state.pagination.page === 0 ? [] : this.state.pagination.nextTokens,
        });
        let { pagination } = this.state;
        if (records.LastEvaluatedKey) {
          if (pagination.page === 0) {
            pagination.nextTokens = [records.LastEvaluatedKey];
          } else if (pagination.nextTokens.length <= pagination.page) {
            pagination.nextTokens.push(records.LastEvaluatedKey);
          } else {
            pagination.nextTokens[pagination.page] = records.LastEvaluatedKey;
          }
        }
        this.secureSetState({ list: records.Items, count: count.Count, processing: false, pagination });
      } catch (err) {
        console.log(err);
        this.secureSetState({ list: null, count: 0, pagination: { page: 0, rowsPerPage: 10, nextTokens: [] }, processing: false });
      }
    });
  }

  cancel() {
    this.props.handleClose(false, true);
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.secureSetState(getDefaultState());
    }
  }

  render() {
    return (
      <Dialog onClose={this.cancel} aria-labelledby='chargebox-dialog-status-title' open={this.props.open} maxWidth='lg'>
        <DialogTitle id='chargebox-dialog-status-title'>
          <Translate id='chargeboxes.dialogs.status.title' />
        </DialogTitle>
        <DialogContent>
          <Grid container direction='column' alignItems='flex-start' justifyContent='flex-start'>
            <TextField label={this.props.translate('chargeboxes.dialogs.status.chargebox')} value={this.props.chargebox.alias} disabled />
            <Grid container direction='row' spacing={2}>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  variant='inline'
                  format='dd/MM/yyyy'
                  placeholder={this.props.translate('dateFormat')}
                  margin='normal'
                  label={<Translate id='chargeboxes.dialogs.status.from' />}
                  name='from'
                  value={this.state.from}
                  maxDate={new Date()}
                  onChange={(d) => this.onChange({ target: { name: 'from', value: d } })}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  autoOk
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <KeyboardDatePicker
                  fullWidth
                  variant='inline'
                  format='dd/MM/yyyy'
                  placeholder={this.props.translate('dateFormat')}
                  margin='normal'
                  label={<Translate id='chargeboxes.dialogs.status.to' />}
                  name='to'
                  value={this.state.to}
                  maxDate={new Date()}
                  onChange={(d) => this.onChange({ target: { name: 'to', value: d } })}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  autoOk
                />
              </Grid>
            </Grid>
            <FormControlLabel
              value='end'
              control={<Checkbox checked={this.state.whole} name='whole' onClick={this.onChange} color='primary' />}
              label={this.props.translate('chargeboxes.dialogs.status.whole')}
              labelPlacement='end'
            />
            {!this.state.whole && (
              <Grid container direction='row' alignItems='center' justifyContent='center' wrap='wrap'>
                {this.props.chargebox.connectors.map((c, i) => (
                  <Button
                    key={i + 1}
                    className={this.props.classes.button}
                    onClick={() => this.onChange({ target: { name: 'connector', value: i + 1 } })}
                    color={this.state.connector === i + 1 ? 'primary' : 'default'}
                    variant='contained'
                  >
                    {i + 1}
                  </Button>
                ))}
              </Grid>
            )}
          </Grid>
          {Array.isArray(this.state.list) && (
            <React.Fragment>
              <TableContainer>
                <Table aria-label='table' size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        <Translate id='chargeboxes.dialogs.status.date' />
                      </TableCell>
                      <TableCell>
                        <Translate id='chargeboxes.dialogs.status.connector' />
                      </TableCell>
                      <TableCell>
                        <Translate id='chargeboxes.dialogs.status.status' />
                      </TableCell>
                      <TableCell>
                        <Translate id='chargeboxes.dialogs.status.errorCode' />
                      </TableCell>
                      <TableCell>
                        <Translate id='chargeboxes.dialogs.status.vendorErrorCode' />
                      </TableCell>
                      <TableCell>
                        <Translate id='chargeboxes.dialogs.status.info' />
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.list.map((row, i) => (
                      <TableRow key={i}>
                        <TableCell>{getDate(row.timestamp, this.props.user.locale)}</TableCell>
                        <TableCell>{getConnector(row.id)}</TableCell>
                        <TableCell>{row.status}</TableCell>
                        <TableCell>{row.error_code}</TableCell>
                        <TableCell>{row.vendor_error_code}</TableCell>
                        <TableCell>{row.info}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                component='div'
                rowsPerPageOptions={[10, 20, 50]}
                count={this.state.count}
                rowsPerPage={this.state.pagination.rowsPerPage}
                page={this.state.pagination.page}
                onChangePage={this.handleChangePage}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                labelRowsPerPage={this.props.translate('table.rowsPerPage')}
                nextIconButtonText={this.props.translate('table.next')}
                backIconButtonText={this.props.translate('table.previous')}
              />
            </React.Fragment>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={this.cancel} color='default' autoFocus>
            <Translate id='chargeboxes.dialogs.close' />
          </Button>
          <Button onClick={this.submit} color='primary' disabled={this.isSubmitDisabled()}>
            <Translate id='chargeboxes.dialogs.status.submit' />
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(styles)(withLocalize(ChargeboxesDialogStatus));
