import React from 'react';
import { Translate } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import IconButton from '@material-ui/core/IconButton';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import Snackbar from '@material-ui/core/Snackbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import WarningIcon from '@material-ui/icons/Warning';
import env from '../../../env';
import { copyToClipboard } from '../../../utilities';

const ThorURLs = {
	dev: {
		ocpp15: [
			{
				idx: 0,
				text: 'https://dev.server.thor.tools:443/latest/ocpp/soap/1.5',
				copy: 'https://dev.server.thor.tools:443/latest/ocpp/soap/1.5',
				secure: true,
			},
			{
				idx: 1,
				text: 'http://34.254.4.216:80/latest/ocpp/soap/1.5',
				copy: 'http://34.254.4.216:80/latest/ocpp/soap/1.5',
				secure: false,
			},
		],
		ocpp16j: [
			{
				idx: 2,
				text: (
					<>
						<span>wss://dev.16.ocpp.thor.tools:443/latest/ocpp/json/1.6/</span>
						<b>
							<Translate id="developers.connection.chargeboxID" />
						</b>
					</>
				),
				copy: 'wss://dev.16.ocpp.thor.tools:443/latest/ocpp/json/1.6/############',
				secure: true,
			},
			{
				idx: 3,
				text: (
					<>
						<span>wss://34.254.4.216:443/latest/ocpp/json/1.6/</span>
						<b>
							<Translate id="developers.connection.chargeboxID" />
						</b>
					</>
				),
				copy: 'wss://34.254.4.216:443/latest/ocpp/json/1.6/############',
				secure: true,
			},
			{
				idx: 4,
				text: (
					<>
						<span>ws://34.254.4.216:81/latest/ocpp/json/1.6/</span>
						<b>
							<Translate id="developers.connection.chargeboxID" />
						</b>
					</>
				),
				copy: 'ws://34.254.4.216:81/latest/ocpp/json/1.6/############',
				secure: false,
			},
		],
	},
	prd: {
		ocpp15: [
			{
				idx: 0,
				text: 'https://server.thor.tools:443/latest/ocpp/soap/1.5',
				copy: 'https://server.thor.tools:443/latest/ocpp/soap/1.5',
				secure: true,
			},
			{
				idx: 1,
				text: 'http://52.209.46.230:80/latest/ocpp/soap/1.5',
				copy: 'http://52.209.46.230:80/latest/ocpp/soap/1.5',
				secure: false,
			},
		],
		ocpp16j: [
			{
				idx: 2,
				text: (
					<>
						<span>wss://16.ocpp.thor.tools:443/latest/ocpp/json/1.6/</span>
						<b>
							<Translate id="developers.connection.chargeboxID" />
						</b>
					</>
				),
				copy: 'wss://16.ocpp.thor.tools:443/latest/ocpp/json/1.6/############',
				secure: true,
			},
			{
				idx: 3,
				text: (
					<>
						<span>wss://52.209.46.230:81/latest/ocpp/json/1.6/</span>
						<b>
							<Translate id="developers.connection.chargeboxID" />
						</b>
					</>
				),
				copy: 'ws://52.209.46.230:81/latest/ocpp/json/1.6/############',
				secure: false,
			},
			{
				idx: 4,
				text: (
					<>
						<span>ws://52.209.46.230:81/latest/ocpp/json/1.6/</span>
						<b>
							<Translate id="developers.connection.chargeboxID" />
						</b>
					</>
				),
				copy: 'ws://52.209.46.230:81/latest/ocpp/json/1.6/############',
				secure: false,
			},
		],
	},
};

const useStyles = makeStyles({
	text: { wordBreak: 'break-word' },
});

const Developers = (props) => {
	let styles = useStyles(props);
	let [showSnackbar, setShowSnakbar] = React.useState(false);
	let onCopyToClipboardClick = (idx, text) => {
		return async () => {
			let success = await copyToClipboard(text);
			if (success) {
				setShowSnakbar(true);
			}
		};
	};
	return (
		<Card>
			<Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={showSnackbar} onClose={() => setShowSnakbar(false)} autoHideDuration={1000}>
				<Alert elevation={6} variant="filled" severity="info" icon={false}>
					<Translate id="developers.connection.copied" />
				</Alert>
			</Snackbar>
			<CardContent>
				<Typography color="textPrimary" gutterBottom variant="h6">
					<Translate id="developers.connection.title" />
				</Typography>
				<Typography color="textSecondary" gutterBottom variant="subtitle1">
					<Translate id="developers.connection.subtitle" />
				</Typography>
				{Object.keys(ThorURLs[env]).map((v) => (
					<List
						subheader={
							<ListSubheader>
								<Translate id={`developers.connection.${v}`} />
							</ListSubheader>
						}
						key={v}
					>
						{ThorURLs[env][v].map((item) => (
							<ListItem key={item.idx} role={undefined}>
								{item.secure ? (
									<ListItemIcon />
								) : (
									<ListItemIcon>
										<Tooltip title={<Translate id="developers.connection.insecure" />} aria-label="insecure">
											<WarningIcon />
										</Tooltip>
									</ListItemIcon>
								)}
								<ListItemText primary={item.text} className={styles.text} />
								<ListItemSecondaryAction>
									<IconButton aria-label="copy" edge="end" onClick={onCopyToClipboardClick(item.idx, item.copy)}>
										<FileCopyIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))}
					</List>
				))}
			</CardContent>
		</Card>
	);
};

export default Developers;
