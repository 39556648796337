import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import FormControl from '@material-ui/core/FormControl';
import Grid from '@material-ui/core/Grid';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Lambda from '../../../aws/lambda';

const styles = {
  card: {
    maxWidth: '90vw',
  },
  buttonsContainer: {
    marginBottom: '1rem',
  },
  buttonRow: {
    marginTop: '1rem',
  },
};

const times = [60, 120, 180, 360, 720, 1440];

class CustomizationReservations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      processing: false,
      reservations: {
        bicycle: { amount: '', notice: '60' },
        car: { amount: '', notice: '60' },
      },
    };
    this.onChange = this.onChange.bind(this);
    this.reset = this.reset.bind(this);
    this.submitEnabled = this.submitEnabled.bind(this);
    this.submit = this.submit.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  onChange(e) {
    if (this.state.processing) {
      return;
    }
    let reservations = JSON.parse(JSON.stringify(this.state.reservations));
    let { name, value } = e.target;
    let nameParts = name.split('-');
    if (nameParts[1] === 'notice') {
      reservations[nameParts[0]].notice = value;
    } else if (nameParts[1] === 'amount') {
      reservations[nameParts[0]].amount = value;
    }
    this.secureSetState({ reservations: reservations });
  }

  submitEnabled(vehicle) {
    return this.state.reservations[vehicle].hasOwnProperty('amount') && /^\d+(\.\d+)?$/gim.test(this.state.reservations[vehicle].amount);
  }

  reset() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({
      reservations: JSON.parse(JSON.stringify(this.props.reservations)),
    });
  }

  submit(vehicle) {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let reservations = JSON.parse(JSON.stringify(this.props.reservations));
        reservations[vehicle] = JSON.parse(JSON.stringify(this.state.reservations[vehicle]));
        if (reservations[vehicle].hasOwnProperty('amount')) {
          reservations[vehicle].amount = parseFloat(reservations[vehicle].amount);
        }
        let params = {
          action: 'edit',
          user: this.props.user.id,
          client: {
            id: this.props.user.client,
            reservations: reservations,
          },
        };
        await Lambda.invoke('ManageClient', params);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('success');
          this.props.update('reservations', reservations);
        });
      } catch (err) {
        console.error(err);
        this.secureSetState({ processing: false }, () => {
          this.props.showSnackbar('reservationsError');
        });
      }
    });
  }

  componentDidMount() {
    this.isMounted = true;
  }

  componentDidUpdate(prevProps) {
    let reservations = JSON.parse(JSON.stringify(this.state.reservations));
    if (
      // Bicycle
      prevProps.reservations.bicycle.amount !== this.props.reservations.bicycle.amount ||
      prevProps.reservations.bicycle.notice !== this.props.reservations.bicycle.notice ||
      // Car
      prevProps.reservations.car.amount !== this.props.reservations.car.amount ||
      prevProps.reservations.car.notice !== this.props.reservations.car.notice
    ) {
      reservations.bicycle = JSON.parse(JSON.stringify(this.props.reservations.bicycle));
      reservations.car = JSON.parse(JSON.stringify(this.props.reservations.car));
      if (reservations.bicycle.hasOwnProperty('amount')) {
        reservations.bicycle.amount = reservations.bicycle.amount.toString();
      }
      if (reservations.car.hasOwnProperty('amount')) {
        reservations.car.amount = reservations.car.amount.toString();
      }
      this.secureSetState({ reservations: reservations });
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  getAccordion(vehicle) {
    let fields = null;
    let currency = '€';
    switch (this.props.currency) {
      case 'chf':
        currency = 'chf';
        break;
      case 'eur':
      default:
        currency = '€';
        break;
    }
    fields = (
      <Grid container spacing={1} alignItems='flex-end'>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel htmlFor={this.props.translate('customization.reservations.amount')}>
              <Translate id={'customization.reservations.amount'} />
            </InputLabel>
            <Input
              label={this.props.translate('customization.reservations.amount')}
              value={this.state.reservations[vehicle].amount}
              onChange={this.onChange}
              fullWidth
              startAdornment={<InputAdornment position='start'>{currency}</InputAdornment>}
            />
          </FormControl>
        </Grid>
      </Grid>
    );

    return (
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`panel-${vehicle}-content`} id={`panel-${vehicle}-header`}>
          <Typography>
            <Translate id={`vehicles.${vehicle}`} />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container direction='column'>
            <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2} className={this.props.classes.buttonsContainer}>
              <Grid item xs={12}>
                <FormControl fullWidth>
                  <InputLabel id='_reservation-notice'>
                    <Translate id='customization.reservations.notice' />
                  </InputLabel>
                  <Select
                    labelId='reservation-notice'
                    name={`${vehicle}-notice`}
                    value={this.state.reservations[vehicle].notice}
                    onChange={this.onChange}
                    fullWidth
                  >
                    {times.map((t, i) => (
                      <MenuItem value={t} key={i}>
                        <Translate id={`customization.reservations.${t}`} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {fields}
            <Grid container direction='row' alignItems='center' justifyContent='center' className={this.props.classes.buttonRow}>
              <Button size='small' color='primary' onClick={() => this.submit(vehicle)} disabled={!this.submitEnabled(vehicle)}>
                <Translate id='customization.reservations.edit' />
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    );
  }

  render() {
    return (
      <Card className={this.props.classes.card}>
        <CardHeader title={<Translate id='customization.reservations.title' />} subheader={<Translate id='customization.reservations.subheader' />} />
        <CardContent>
          {this.getAccordion('bicycle')}
          {this.getAccordion('car')}
        </CardContent>
        <CardActions>
          <Grid container direction='row' alignItems='center' justifyContent='flex-end'>
            <Button size='small' onClick={this.reset}>
              <Translate id='customization.reset' />
            </Button>
          </Grid>
        </CardActions>
      </Card>
    );
  }
}

export default withStyles(styles)(withLocalize(CustomizationReservations));
