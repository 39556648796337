import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

const ClientsDialogDelete = (props) => (
	<Dialog
		onClose={props.handleClose}
		aria-labelledby="client-dialog-delete-title"
		aria-describedby="client-dialog-delete-description"
		open={props.open}
		fullWidth
		maxWidth="xs"
	>
		<DialogTitle id="client-dialog-delete-title">
			<Translate id="clients.dialogs.delete.title" />
		</DialogTitle>
		<DialogContent>
			<DialogContentText id="client-dialog-delete-description">
				<Typography>
					<Translate id="clients.dialogs.delete.text" />
				</Typography>
			</DialogContentText>
			<DialogContentText>
				<Typography>{`${props.client.id} - ${props.client.name}`}</Typography>
			</DialogContentText>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.handleClose} color="default" autoFocus>
				<Translate id="clients.dialogs.close" />
			</Button>
			<Button onClick={props.submit} color="primary">
				<Translate id="clients.dialogs.delete.submit" />
			</Button>
		</DialogActions>
	</Dialog>
);

export default withLocalize(ClientsDialogDelete);
