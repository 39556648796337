import React from 'react';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Translate } from 'react-localize-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles({
  divider: {
    alignSelf: 'stretch',
    margin: '1rem 0',
  },
});

const ApiCalls = (props) => {
  const classes = useStyles(props);
  return (
    <Grid container direction='column' alignItems='center' justifyContent='flex-start'>
      {/*<Typography variant='h5' color='textPrimary'>
        <Translate id='home.api.title' />
      </Typography>*/}
      <Typography variant='h6' color='textSecondary'>
        <Translate id='home.api.callsIn' />
      </Typography>
      <Typography variant='h2'>{props.callsIn}</Typography>
      <Divider variant='fullWidth' className={classes.divider} />
      <Typography variant='h6' color='textSecondary'>
        <Translate id='home.api.callsOut' />
      </Typography>
      <Typography variant='h2'>{props.callsOut}</Typography>
    </Grid>
  );
};

export default ApiCalls;
