import React from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Paper from '@material-ui/core/Paper';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { KeyboardDatePicker } from '@material-ui/pickers';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EuroIcon from '@material-ui/icons/Euro';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import RefreshIcon from '@material-ui/icons/Refresh';
import DialogPayment from './presentation.dialog.payment';

const useStyles = makeStyles({
  filtersContainer: {
    padding: '1rem',
  },
  button: {
    margin: '0.5rem',
  },
  listItemIcon: {
    minWidth: '2rem',
  },
});
const getOptionClientLabel = (clients) => (option) => {
  if (typeof option === 'string') {
    let client = clients.find((c) => c.id === option);
    if (client === undefined) {
      return 'UNKNOWN';
    }
    return client.name;
  }
  return option.name;
};

const DateOptions = { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' };
const getDate = (timestamp, locale) => {
  let date = new Date(timestamp);
  return date.toLocaleTimeString(locale.replace('_', '-'), DateOptions);
};

const getOptionLabel = (chargeboxes) => (option) => {
  if (typeof option === 'string') {
    let chargebox = chargeboxes.find((c) => c.id === option);
    if (chargebox === undefined) {
      return 'UNKNOWN';
    }
    return chargebox.alias;
  }
  return option.alias;
};

const FinishedReservations = (props) => {
  const classes = useStyles(props);
  return (
    <Paper elevation={3}>
      <DialogPayment
        open={props.dialogPaymentOpen}
        handleClose={props.toggleDialogPayment}
        user={props.selectedReservation === null ? null : props.users[props.selectedReservation.user]}
        payment={props.selectedReservation === null ? null : props.selectedReservation.payment}
        currencies={props.currencies}
        client={props.selectedReservation === null ? null : props.selectedReservation.client}
      />
      <Grid container direction='column'>
        <LinearProgress color='primary' hidden={!props.processing} />
        <Grid container direction='column' className={classes.filtersContainer}>
          <Typography variant='h5'>
            <Translate id='reservations.finishedTitle' />
          </Typography>
          {(props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) && (
            <Autocomplete
              options={props.clientsList}
              getOptionLabel={getOptionClientLabel(props.clientsList)}
              getOptionSelected={(o, t) => o.id === t.id}
              openOnFocus
              autoHighlight
              fullWidth
              value={props.filters.client}
              onChange={(event, newValue) => props.onChange({ target: { name: 'client', value: newValue } })}
              renderInput={(params) => <TextField {...params} label={props.translate('transactions.client')} />}
            />
          )}
          <Autocomplete
            options={props.chargeboxesList}
            getOptionLabel={getOptionLabel(props.chargeboxesList)}
            getOptionSelected={(o, t) => o.id === t.id}
            openOnFocus
            autoHighlight
            fullWidth
            value={props.filters.chargebox}
            onChange={(event, newValue) => props.onChange({ target: { name: 'chargebox', value: newValue } })}
            renderInput={(params) => <TextField {...params} label={props.translate('reservations.chargebox')} />}
          />
          <Grid container direction='row' wrap='wrap' alignItems='center' justifyContent='space-between' spacing={2}>
            <Grid container direction='row' alignItems='center' justifyContent='flex-start' spacing={2} item xs={12} md={6}>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  variant='inline'
                  format='dd/MM/yyyy'
                  placeholder={props.translate('dateFormat')}
                  margin='normal'
                  label={<Translate id='reservations.from' />}
                  name='dateFrom'
                  value={props.filters.dateFrom}
                  maxDate={props.filters.dateTo === null ? new Date() : props.filters.dateTo}
                  onChange={(d) => props.onChange({ target: { name: 'dateFrom', value: d } })}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  autoOk
                />
              </Grid>
              <Grid item xs={6}>
                <KeyboardDatePicker
                  fullWidth
                  variant='inline'
                  format='dd/MM/yyyy'
                  placeholder={props.translate('dateFormat')}
                  margin='normal'
                  label={<Translate id='reservations.to' />}
                  name='dateTo'
                  value={props.filters.dateTo}
                  minDate={props.filters.dateFrom === null ? undefined : props.filters.dateFrom}
                  maxDate={new Date()}
                  onChange={(d) => props.onChange({ target: { name: 'dateTo', value: d } })}
                  KeyboardButtonProps={{ 'aria-label': 'change date' }}
                  autoOk
                />
              </Grid>
            </Grid>
            <Grid container direction='row' alignItems='center' justifyContent='center' spacing={2} item xs={12} md={6}>
              <Button
                variant='contained'
                color='primary'
                className={classes.button}
                onClick={props.submitFilters}
                disabled={
                  ((props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) &&
                    !props.filters.client &&
                    !props.filters.chargebox) ||
                  props.processing
                }
              >
                <Translate id='reservations.search' />
              </Button>
              <Button variant='contained' className={classes.button} onClick={props.clearFilters}>
                <Translate id='reservations.clear' />
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <TableContainer>
          <Table aria-label='table' size='small'>
            <TableHead>
              <TableRow>
                <TableCell>
                  <Translate id='reservations.chargebox' />
                </TableCell>
                <TableCell>
                  <Translate id='reservations.connector' />
                </TableCell>
                <TableCell>
                  <Translate id='reservations.reservationID' />
                </TableCell>
                <TableCell>
                  <Translate id='reservations.started' />
                </TableCell>
                <TableCell>
                  <Translate id='reservations.finished' />
                </TableCell>
                <TableCell>
                  <Translate id='reservations.status.status' />
                </TableCell>
                <TableCell>
                  <IconButton onClick={props.refresh} disable={props.processing}>
                    <RefreshIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.list.map((row, chargebox) => {
                return (
                  <TableRow key={chargebox}>
                    <TableCell>{props.chargeboxes[row.chargebox]}</TableCell>
                    <TableCell>{row.connector}</TableCell>
                    <TableCell>{row.reservation_id}</TableCell>
                    <TableCell>{getDate(row.date_accepted, props.user.locale)}</TableCell>
                    <TableCell>{getDate(row.date_finished || row.date_cancelled || row.expiry_date, props.user.locale)}</TableCell>
                    <TableCell>
                      <Translate id={`reservations.status.${row.status}`} />
                      {row.status === 'finished' && row.transaction_id && (
                        <>
                          <br />
                          <Translate id='reservations.transaction' data={{ id: row.transaction_id }} />
                        </>
                      )}
                    </TableCell>
                    <TableCell>
                      <IconButton onClick={props.openCloseMenu(row)}>
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component='div'
          rowsPerPageOptions={[10, 20, 50]}
          count={props.count}
          rowsPerPage={props.pagination.rowsPerPage}
          page={props.pagination.page}
          onChangePage={props.handleChangePage}
          onChangeRowsPerPage={props.handleChangeRowsPerPage}
          labelRowsPerPage={props.translate('table.rowsPerPage')}
          nextIconButtonText={props.translate('table.next')}
          backIconButtonText={props.translate('table.previous')}
        />
        <Menu anchorEl={props.menuAnchorEl} open={props.menuAnchorEl !== null} onClose={props.openCloseMenu(null)}>
          {props.selectedReservation !== null && (
            <MenuItem button onClick={props.toggleDialogPayment}>
              <ListItemIcon className={classes.listItemIcon}>
                <EuroIcon fontSize='small' />
              </ListItemIcon>
              <Typography variant='inherit' noWrap>
                <Translate id='reservations.payment' />
              </Typography>
            </MenuItem>
          )}
        </Menu>
      </Grid>
    </Paper>
  );
};
export default withLocalize(FinishedReservations);
