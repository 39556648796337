import React from 'react';
import { withLocalize, Translate } from 'react-localize-redux';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';

const Currencies = { eur: '€', usd: '$', gbp: '£', chf: 'chf' };

const DialogFinishedReservationPayment = (props) => (
	<Dialog onClose={props.handleClose} aria-labelledby="finished-reservations-dialog-payment-title" open={props.open} maxWidth="sm">
		<DialogContent>
			<TableContainer>
				<Table aria-label="table" size="small">
					<TableBody>
						{!props.payment && (
							<TableRow>
								<TableCell>
									<Translate id="reservations.price" />
								</TableCell>
								<TableCell>
									<Translate id="reservations.paymentMethods.free" />
								</TableCell>
							</TableRow>
						)}
						{props.payment && (
							<React.Fragment>
								{props.payment.method && (
									<TableRow>
										<TableCell>
											<Translate id="reservations.paymentMethod" />
										</TableCell>
										<TableCell>
											<Translate id={`reservations.paymentMethods.${props.payment.method}`} />
										</TableCell>
									</TableRow>
								)}

								{props.payment.hasOwnProperty('amount') && props.payment.hasOwnProperty('currency') && (
									<TableRow>
										<TableCell>
											<Translate id="reservations.amount" />
										</TableCell>
										<TableCell>{`${Currencies[props.currencies[props.client]]} ${props.payment.amount}`}</TableCell>
									</TableRow>
								)}
								{props.payment.transaction && (
									<TableRow>
										<TableCell>
											<Translate id="reservations.paymentReservationID" />
										</TableCell>
										<TableCell>{props.payment.transaction}</TableCell>
									</TableRow>
								)}
							</React.Fragment>
						)}
						{props.user && (
							<TableRow>
								<TableCell>
									<Translate id="reservations.enduser" />
								</TableCell>
								<TableCell>
									<Typography component="p" variant="body2">
										{props.user.name || props.user.company_name}
									</Typography>
									<Typography component="a" variant="body2" color="textSecondary" href={`mailto:${props.user.email}`}>
										{props.user.email}
									</Typography>
									<Typography component="p" variant="body2">
										{props.user.tax_code || props.user.vat_number}
									</Typography>
									<Typography component="p" variant="body2">
										{props.user.address}
									</Typography>
									<Typography component="p" variant="body2">
										{props.user.city} - {props.user.zip_code} ({props.user.country})
									</Typography>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</Table>
			</TableContainer>
		</DialogContent>
		<DialogActions>
			<Button onClick={props.handleClose} color="default" autoFocus>
				<Translate id="documents.dialogs.close" />
			</Button>
		</DialogActions>
	</Dialog>
);

export default withLocalize(DialogFinishedReservationPayment);
