import React from 'react';
import ApexChart from 'react-apexcharts';

const DateOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
const MetricsCount = 4;

const ChargesChart = ({ charges, locale, month: yearmonth }) => {
	let metrics = Array(MetricsCount);
	for (let i = 0; i < MetricsCount; i++) {
		metrics[i] = [];
	}
	for (let i = 0; i < charges.length; i++) {
		metrics[i % MetricsCount].push(charges[i]);
	}
	let year = parseInt(yearmonth.substr(0, 4));
	let month = parseInt(yearmonth.substr(5)) - 1;
	let l = locale.split('_');

	return (
		<ApexChart
			type="heatmap"
			width="100%"
			options={{
				chart: {
					fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
					redrawOnParentResize: true,
					dropShadow: { enabled: false },
					toolbar: { show: false },
					zoom: { enabled: false },
				},
				grid: { show: false },
				xaxis: {
					labels: { show: false },
					tooltip: { enabled: false },
					axisTicks: { show: false },
					axisBorder: { show: false },
					crosshairs: { show: false },
				},
				yaxis: {
					show: false,
					axisTicks: { show: false },
					axisBorder: { show: false },
					crosshairs: { show: false },
				},
				colors: ['#00796b'],
				tooltip: {
					y: {
						formatter: (value, { seriesIndex, dataPointIndex }) => {
							let date = new Date(year, month, dataPointIndex * MetricsCount + (MetricsCount - seriesIndex));
							return `${date.toLocaleDateString(l, DateOptions)}: ${value}`;
						},
						title: { formatter: () => '' },
					},
				},
			}}
			series={metrics.map((m, i) => ({ name: `Metric ${i + 1}`, data: m })).reverse()}
		/>
	);
};

export default ChargesChart;
