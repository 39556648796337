import React from 'react';
import { withLocalize } from 'react-localize-redux';
import DynamoDBChargeboxes from '../../../../aws/dynamodb/chargeboxes';
import DynamoDBEndUsers from '../../../../aws/dynamodb/endusers';
import DynamoDBAdministrators from '../../../../aws/dynamodb/administrators';
import DynamoDBTransactionsFinished from '../../../../aws/dynamodb/transactions-finished';
import DynamoDBRFIDs from '../../../../aws/dynamodb/rfids';
import DynamoDBClients from '../../../../aws/dynamodb/clients';
import UserContext from '../../../../context';
import Transactions from './presentation';

var lastSearch = {};

class FinishedTransactionsContainer extends React.Component {
  constructor(props) {
    super(props);
    let date = new Date(Date.now());
    this.state = {
      processing: false,
      list: [],
      count: 0,
      users: {},
      chargeboxes: {},
      menuAnchorEl: null,
      selectedTransaction: null,
      filters: {
        chargebox: null,
        dateFrom: new Date(date.setMonth(date.getMonth() - 3)),
        dateTo: null,
        client: null,
        method: null,
      },
      pagination: {
        page: 0,
        rowsPerPage: 10,
        nextTokens: [],
      },
      dialogPaymentOpen: false,
      dialogTrendOpen: false,
      snackbar: null,
    };
    this.handleChangePage = this.handleChangePage.bind(this);
    this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    this.onChange = this.onChange.bind(this);
    this.clearFilters = this.clearFilters.bind(this);
    this.submitFilters = this.submitFilters.bind(this);
    this.refresh = this.refresh.bind(this);
    this.getTransactions = this.getTransactions.bind(this);
    this.openCloseMenu = this.openCloseMenu.bind(this);
    this.toggleDialogPayment = this.toggleDialogPayment.bind(this);
    this.toggleDialogTrend = this.toggleDialogTrend.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  handleChangePage(event, newPage) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = newPage;
    this.secureSetState({ pagination }, this.getTransactions);
  }

  handleChangeRowsPerPage(event) {
    if (this.state.processing) {
      return;
    }
    let { pagination } = this.state;
    pagination.page = 0;
    pagination.rowsPerPage = event.target.value;
    this.secureSetState({ pagination }, this.getTransactions);
  }

  toggleDialogPayment() {
    this.secureSetState({ dialogPaymentOpen: !this.state.dialogPaymentOpen, menuAnchorEl: null });
  }

  toggleDialogTrend() {
    this.secureSetState({ dialogTrendOpen: !this.state.dialogTrendOpen, menuAnchorEl: null });
  }

  onChange(e) {
    let date = new Date(Date.now());
    if (this.state.processing) {
      return;
    }
    const { name, value } = e.target;
    let { filters } = this.state;
    filters[name] = value;
    if (name === 'dateFrom' && (value === null || value === '')) {
      filters[name] = new Date(date.setMonth(date.getMonth() - 3));
    }
    this.secureSetState({ filters });
  }

  clearFilters() {
    let date = new Date(Date.now());
    if (this.state.processing) {
      return;
    }
    let filters = JSON.parse(JSON.stringify(this.state.filters));
    filters.chargebox = null;
    filters.dateFrom = new Date(date.setMonth(date.getMonth() - 3));
    filters.dateTo = null;
    filters.method = null;
    filters.type = null;
    this.secureSetState({ filters });
  }

  submitFilters() {
    lastSearch = { ...this.state.filters };
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ count: 0 });
    let { pagination } = this.state;
    pagination.page = 0;
    this.secureSetState({ pagination }, this.getTransactions);
  }

  refresh() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ count: 0, filters: { ...lastSearch } });
    let { pagination } = this.state;
    pagination.page = 0;
    this.secureSetState({ pagination }, this.getTransactions);
  }

  getTransactions() {
    if (this.state.processing) {
      return;
    }
    this.secureSetState({ processing: true }, async () => {
      try {
        let over = false;
        let count = {};
        let method = null;
        if (this.state.filters.method) {
          if (this.state.filters.method === 'RFID') {
            method = 'local';
          } else {
            method = this.state.filters.method;
          }
        }
        let filters = {
          client: this.state.filters.client === null && this.props.user.type !== 'superuser' ? null : this.state.filters.client,
          chargebox: this.state.filters.chargebox === null ? null : this.state.filters.chargebox.id,
          dateFrom: null,
          dateTo: null,
          method: method === null || method === 'local' || method === 'API' ? null : method.toLowerCase(),
          type: method === 'local' || method === 'API' ? method.toLowerCase() : null,
        };
        if (this.state.filters.dateFrom !== null) {
          let date = new Date(this.state.filters.dateFrom.getTime());
          date.setHours(0, 0, 0, 0);
          filters.dateFrom = date.getTime();
        }
        if (this.state.filters.dateTo !== null) {
          let date = new Date(this.state.filters.dateTo.getTime());
          date.setHours(23, 59, 59, 999);
          filters.dateTo = date.getTime();
        }
        let clientsCurrency = await DynamoDBClients.getCurrency();
        let arrayCurrency = [];
        for (let client of clientsCurrency.Items) {
          arrayCurrency[client.id] = client.payment_limit.currency;
        }
        let requestor = { type: this.props.user.type, client: filters.client ? filters.client.id : this.props.user.client };
        let Optpagination = {
          limit: this.state.pagination.rowsPerPage,
          page: this.state.pagination.page,
          nextTokens: this.state.pagination.page === 0 ? [] : this.state.pagination.nextTokens,
        };
        let records = await DynamoDBTransactionsFinished.getAll(requestor, filters, Optpagination);
        let orderedRecords = records.Items.sort(function (a, b) {
          var keyA = new Date(a.date_started),
            keyB = new Date(b.date_started);
          // Compare the 2 dates
          if (keyA < keyB) return 1;
          if (keyA > keyB) return -1;
          return 0;
        });
        if (this.state.count === 0) {
          count = await DynamoDBTransactionsFinished.getAllCount(requestor, filters);
        } else {
          count.Count = 0;
        }
        if (count.hasOwnProperty('LastEvaluatedKey')) {
          over = true;
        } else {
          over = false;
        }
        let { pagination } = this.state;
        if (records.LastEvaluatedKey) {
          if (pagination.page === 0) {
            pagination.nextTokens = [records.LastEvaluatedKey];
          } else if (pagination.nextTokens.length <= pagination.page) {
            pagination.nextTokens.push(records.LastEvaluatedKey);
          } else {
            pagination.nextTokens[pagination.page] = records.LastEvaluatedKey;
          }
        }

        let usersToGet = [];
        let adminsToGet = [];
        let chargeboxesToGet = [];
        for (let item of records.Items) {
          if (typeof item.user === 'string' && usersToGet.indexOf(item.user) === -1 && !this.state.users.hasOwnProperty(item.user)) {
            if (item.payment && item.payment.method && item.payment.method === 'free') {
              if (adminsToGet.indexOf(item.user) === -1 && !this.state.users.hasOwnProperty(item.user)) {
                adminsToGet.push(item.user);
              }
            } else {
              usersToGet.push(item.user);
            }
          }
          if (typeof item.chargebox === 'string' && chargeboxesToGet.indexOf(item.chargebox) === -1 && !this.state.chargeboxes.hasOwnProperty(item.chargebox)) {
            chargeboxesToGet.push(item.chargebox);
          }
        }
        let users = JSON.parse(JSON.stringify(this.state.users));
        let chargeboxes = JSON.parse(JSON.stringify(this.state.chargeboxes));

        for (let i = 0; i < Math.ceil(usersToGet.length / 25); i++) {
          let ddbUsers = await DynamoDBEndUsers.batchGet(usersToGet.slice(i * 25, (i + 1) * 25));
          for (let user of ddbUsers) {
            let { id, ...u } = user;
            users[id] = u;
          }
        }

        for (let i = 0; i < Math.ceil(adminsToGet.length / 25); i++) {
          let ddbAdmins = await DynamoDBAdministrators.batchGet(adminsToGet.slice(i * 25, (i + 1) * 25));
          for (let admin of ddbAdmins) {
            let { id, ...u } = admin;
            u.admin = true;
            users[id] = u;
          }
        }

        for (let i = 0; i < Math.ceil(chargeboxesToGet.length / 25); i++) {
          let ddbChargeboxes = await DynamoDBChargeboxes.batchGet(chargeboxesToGet.slice(i * 25, (i + 1) * 25), ['id', 'alias']);
          for (let chargebox of ddbChargeboxes) {
            let { id, alias } = chargebox;
            chargeboxes[id] = alias;
          }
        }
        let state = {
          list: orderedRecords,
          users,
          chargeboxes,
          processing: false,
          pagination,
          currencies: arrayCurrency,
        };

        if (count.Count !== 0) {
          state.count = count.Count;
        }
        if (over === true) {
          this.props.showHideSnackbar('TooManyTransactionsError');
        }
        this.secureSetState(state);
      } catch (err) {
        console.log(err);
        this.secureSetState({ list: [], count: 0, pagination: { page: 0, rowsPerPage: 10, nextTokens: [] }, processing: false });
      }
    });
  }

  openCloseMenu(transaction) {
    return async (event) => {
      if (transaction === null) {
        this.secureSetState({ menuAnchorEl: null, selectedTransaction: null });
      } else {
        let currentTarget = event.currentTarget;
        let rfidAlias = null;
        if (transaction.type === 'local' && !transaction.user) {
          let data = await DynamoDBRFIDs.get(`${transaction.client}__${transaction.id_tag}`);
          if (data.hasOwnProperty('Item') && Object.keys(data.Item).length > 0) {
            rfidAlias = data.Item.alias || this.props.translate('transactions.rfid.unknown');
          } else {
            rfidAlias = this.props.translate('transactions.rfid.unknown');
          }
        }
        this.secureSetState({ menuAnchorEl: currentTarget, selectedTransaction: { rfidAlias, ...transaction } });
      }
    };
  }

  componentDidMount() {
    this.isMounted = true;
    if (this.props.user.type !== 'superuser') {
      this.getTransactions();
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    return (
      <Transactions
        {...this.state}
        user={this.props.user}
        chargeboxesList={this.props.chargeboxes}
        clientsList={this.props.clients}
        handleChangePage={this.handleChangePage}
        handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        openCloseMenu={this.openCloseMenu}
        onChange={this.onChange}
        submitFilters={this.submitFilters}
        refresh={this.refresh}
        clearFilters={this.clearFilters}
        getTransactions={this.getTransactions}
        toggleDialogPayment={this.toggleDialogPayment}
        toggleDialogTrend={this.toggleDialogTrend}
      />
    );
  }
}

FinishedTransactionsContainer.contextType = UserContext;
export default withLocalize(FinishedTransactionsContainer);
