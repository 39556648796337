import { DynamoDB, config as AWSConfig } from 'aws-sdk';
import env from '../../env';
const TableName = `${env}_thor_rest_api_calls`;

const get = (id, month) => {
  const DDB = new DynamoDB.DocumentClient({ credentials: AWSConfig.credentials });
  let params = {
    TableName: TableName,
    Key: { id: id, month: month },
    ReturnConsumedCapacity: 'NONE',
  };

  return DDB.get(params).promise();
};

export default {
  get,
};
