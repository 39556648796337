import 'date-fns';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import { LoadScript } from '@react-google-maps/api';
import { MuiThemeProvider, createTheme } from '@material-ui/core/styles';
import App from './presentation';
import teal from '@material-ui/core/colors/teal';

const theme = createTheme({
  typography: {
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: teal['400'],
      main: teal['700'],
      dark: teal['800'],
      contrastText: '#fff',
    },
    info: {
      light: teal['400'],
      main: teal['700'],
      dark: teal['800'],
      contrastText: '#fff',
    },
  },
});

const AppContainer = () => (
  <LocalizeProvider>
    <BrowserRouter basename='/'>
      <LoadScript id='gmap-script-loader' googleMapsApiKey='AIzaSyAbHSxXy67QyEKEdJLXpWDn85yHSNsMuII' loadingElement={<></>}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </LoadScript>
    </BrowserRouter>
  </LocalizeProvider>
);

ReactDOM.render(<AppContainer />, document.getElementById('root'));
