import React from 'react';
import { Translate } from 'react-localize-redux';
import Alert from '@material-ui/lab/Alert';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import TextField from '@material-ui/core/TextField';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CloseIcon from '@material-ui/icons/Close';
import CustomizationPaymentLimit from './presentation.paymentlimit';
import CustomizationPaymentMethods from './presentation.paymentmethods';
import CustomizationReservations from './presentation.reservations';
import CustomizationPaymentType from './presentation.paymenttype';
import CustomizationPaymentReceipt from './presentation.paymentreceipt';
//import { PinDropRounded } from '@material-ui/icons';

const getOptionLabel = (option) => option.name;
const getOptionSelected = (o, t) => o.id === t.id;
const useStyles = makeStyles({
  card: {
    maxWidth: '90vw',
  },
});

const Payments = (props) => {
  const styles = useStyles(props);
  return (
    <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' wrap='wrap' spacing={2}>
      <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'right' }} open={props.snackbar !== null} autoHideDuration={6000} onClose={props.onSnackbarClose}>
        <Alert
          elevation={6}
          variant='filled'
          severity='info'
          onClose={props.onSnackbarClose}
          icon={false}
          action={
            <IconButton size='small' aria-label='close' color='inherit' onClick={props.onSnackbarClose}>
              <CloseIcon fontSize='small' />
            </IconButton>
          }
        >
          {props.snackbar === null ? '' : <Translate id={`customization.snackbar.${props.snackbar}`} />}
        </Alert>
      </Snackbar>
      <Grid item container direction='column' xs={12} md={6} lg={4} xl={3} spacing={2}>
        {(props.user.type === 'superuser' || (props.user.clientType === 'main' && props.user.clients.length > 0)) && (
          <Grid item xs={12}>
            <Card className={styles.card}>
              <CardContent>
                <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' spacing={1}>
                  <Grid item>
                    <Autocomplete
                      openOnFocus
                      autoHighlight
                      options={props.clients}
                      getOptionLabel={getOptionLabel}
                      getOptionSelected={getOptionSelected}
                      fullWidth
                      value={props.client}
                      onChange={props.selectClient}
                      disabled={props.processing}
                      renderInput={(params) => <TextField {...params} label={<Translate id='customization.client' />} />}
                      // className={classes.autocomplete}
                    />
                  </Grid>
                  <Grid item>
                    <Button
                      variant='contained'
                      fullWidth
                      color='primary'
                      onClick={props.getCustomizations}
                      disabled={props.processing || props.client === null}
                    >
                      <Translate id='customization.refresh' />
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        )}
        {props.client !== null && (
          <Grid item xs={12}>
            <CustomizationPaymentLimit
              showSnackbar={props.showSnackbar}
              user={{ ...props.user, client: props.client.id }}
              paymentLimit={props.payment_limit}
              update={props.update}
            />
          </Grid>
        )}
      </Grid>
      {props.client !== null && (
        <>
          <Grid item container direction='column' xs={12} md={6} lg={4} xl={3} spacing={2}>
            <Grid item xs={12}>
              <CustomizationPaymentType
                showSnackbar={props.showSnackbar}
                user={{ ...props.user, client: props.client.id }}
                paymentType={props.payment_type}
                update={props.update}
                currency={props.payment_limit.currency}
              />
            </Grid>
            <Grid item xs={12}>
              <CustomizationReservations
                showSnackbar={props.showSnackbar}
                user={{ ...props.user, client: props.client.id }}
                reservations={props.reservations}
                update={props.update}
                currency={props.payment_limit.currency}
              />
            </Grid>
            {props.client !== null && (props.user.hasPrivileges || props.user.type === 'superuser' || props.user.canChangePayment) && (
              <Grid item xs={12}>
                <CustomizationPaymentMethods
                  showSnackbar={props.showSnackbar}
                  user={{ ...props.user, client: props.client.id }}
                  paymentMethods={props.payment_methods}
                  update={props.update}
                  currency={props.payment_limit.currency}
                />
              </Grid>
            )}
          </Grid>
        </>
      )}
      {props.client !== null && (
        <Grid item container direction='column' xs={12} md={6} lg={4} xl={3} spacing={2}>
          {props.client !== null && (props.user.hasPrivileges || props.user.type === 'superuser' || props.user.canChangePayment) && (
            <Grid item xs={12}>
              <CustomizationPaymentReceipt
                showSnackbar={props.showSnackbar}
                user={{ ...props.user, client: props.client.id }}
                effatta={props.effatta}
                update={props.update}
              />
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default Payments;
