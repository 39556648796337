import React from 'react';
import PropTypes from 'prop-types';
import { renderToStaticMarkup } from 'react-dom/server';
import { withLocalize } from 'react-localize-redux';
import { withRouter, Route, Switch } from 'react-router-dom';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { refresh as CognitoRefresh, getAttributes as CognitoGetAttributes, updateAttribute as CognitoUpdateAttribute } from '../../aws/cognito';
import Lambda from '../../aws/lambda';
import DynamoDBClients from '../../aws/dynamodb/clients';
import translations from '../../l10n';
import UserContext from '../../context';
import Loading from '../../elements/loading';
import Login from '../auth/login';
import Forgot from '../auth/forgot';
import Home from '../home';

import itLocale from 'date-fns/locale/it';
import enLocale from 'date-fns/locale/en-GB';
import { asyncTimeout, CheckForIndexHTMLChange } from '../../utilities';
import Env from '../../env';
import { Region } from '../../aws';
import { isArray } from '@craco/craco/lib/utils';

const IndexHTMLURL = 'https://s3-' + Region + '.amazonaws.com/' + (Env === 'prd' ? '' : `${Env}.`) + 'admin.thor.tools/index.html';

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ready: false,
      context: {
        id: '',
        email: '',
        name: '',
        locale: '',
        type: '',
        client: '',
        hasPrivileges: false,
        canChangePayment: false,
        changeLanguage: this.changeLanguage,
        changeName: this.changeName,
      },
    };
    this.loopRefresh = this.loopRefresh.bind(this);
    this.changeName = this.changeName.bind(this);
    this.changeLanguage = this.changeLanguage.bind(this);
    this.secureSetState = this.secureSetState.bind(this);
    this._isMounted = false;

    let defaultLanguage = navigator.userLanguage || navigator.language || navigator.browserLanguage || 'en';
    defaultLanguage = defaultLanguage.split('-')[0].toLowerCase();
    if (['it_IT', 'en_GB'].indexOf(defaultLanguage) === -1) {
      defaultLanguage = 'en_GB';
    }
    this.defaultLanguage = defaultLanguage;
  }

  get isMounted() {
    return this._isMounted;
  }

  set isMounted(val) {
    this._isMounted = val;
  }

  secureSetState(state, callback) {
    if (!this.isMounted) {
      return;
    }
    this.setState(state, callback);
  }

  async loopRefresh() {
    for (;;) {
      try {
        await asyncTimeout(600000);
        await CognitoRefresh(false);
      } catch (err) {
        console.log(err);
      }
    }
  }

  async changeName(n) {
    let { context } = this.state;
    context.name = n;
    this.secureSetState({ context });
    try {
      await CognitoUpdateAttribute('name', n);
      await Lambda.invoke('ManageAdmin', {
        action: 'edit',
        requestor: this.state.context.id,
        user: { id: this.state.context.id, name: n },
      });
    } catch (err) {
      console.log(err);
    }
  }

  async changeLanguage(l) {
    this.props.setActiveLanguage(l);
    let { context } = this.state;
    context.locale = l;
    this.secureSetState({ context });
    try {
      await CognitoUpdateAttribute('locale', l);
      await Lambda.invoke('ManageAdmin', {
        action: 'edit',
        requestor: this.state.context.id,
        user: { id: this.state.context.id, locale: l },
      });
    } catch (err) {
      console.log(err);
    }
  }

  async componentDidMount() {
    this.isMounted = true;

    let reloadOnNextChange = false;
    let checkIndexInterval = setInterval(async () => {
      reloadOnNextChange = await CheckForIndexHTMLChange(IndexHTMLURL);
      if (reloadOnNextChange) {
        console.log('Found a new version of the dashboard. Reloading on next page change.');
        clearInterval(checkIndexInterval);
      }
    }, 600000);

    this.props.history.listen((ev) => {
      if (reloadOnNextChange) {
        window.location.href = ev.pathname;
      }
    });

    this.props.initialize({
      languages: [
        { name: 'English', code: 'en_GB' },
        { name: 'Italian', code: 'it_IT' },
      ],
      options: { renderToStaticMarkup, renderInnerHtml: true, defaultLanguage: this.defaultLanguage },
    });
    this.props.addTranslationForLanguage(translations.en, 'en_GB');
    this.props.addTranslationForLanguage(translations.it, 'it_IT');
    try {
      await CognitoRefresh(true);
      let attributes = await CognitoGetAttributes();
      let client;
      if (attributes['custom:type'] === 'client') {
        let dataC = await DynamoDBClients.get(attributes['custom:client']);
        if (dataC.Item.type === 'main') {
          if (dataC.Item.sub && dataC.Item.sub.length > 0) {
            dataC.Item.sub.push(dataC.Item.id);
            client = dataC.Item.sub;
          } else {
            client = [];
          }
        } else {
          client = dataC.Item.id;
        }
      }
      let context = {
        id: attributes.id || '',
        email: attributes.email || '',
        name: attributes.name || '',
        locale: attributes.locale || this.defaultLanguage,
        type: attributes['custom:type'] || '',
        client: attributes['custom:client'] || '',
        clientType: isArray(client) ? 'main' : 'sub',
        clients: client,
        hasPrivileges: attributes['custom:has_privileges'] === 'true',
        canChangePayment: attributes['custom:can_change_payment'] === '1',
        changeLanguage: this.changeLanguage,
        changeName: this.changeName,
      };
      this.props.setActiveLanguage(context.locale);
      this.secureSetState({ ready: true, context: context });
      await this.loopRefresh();
    } catch (err) {
      console.log(err);
      if (this.props.history.location.pathname !== '/login' && !/^\/forgot(\/[a-zA-Z0-9]+)?$/gim.test(this.props.history.location.pathname)) {
        this.props.history.replace('/login');
      }
      setTimeout(() => this.secureSetState({ ready: true }), 1000);
    }
  }

  componentWillUnmount() {
    this.isMounted = false;
  }

  render() {
    if (!this.state.ready) {
      return <Loading />;
    }
    let l = this.state.context.locale.length === 0 ? this.defaultLanguage.substr(0, 2) : this.state.context.locale.substr(0, 2);
    let locale = null;
    switch (l) {
      case 'it':
        locale = itLocale;
        break;

      default:
        locale = enLocale;
        break;
    }
    return (
      <UserContext.Provider value={this.state.context}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={locale}>
          <Switch>
            <Route path='/login' exact component={Login} />
            <Route path='/forgot/:id?' exact component={Forgot} />
            <Route path='*' exact component={Home} />
          </Switch>
        </MuiPickersUtilsProvider>
      </UserContext.Provider>
    );
  }
}

App.propTypes = {
  initialize: PropTypes.func.isRequired,
  addTranslationForLanguage: PropTypes.func.isRequired,
  setActiveLanguage: PropTypes.func.isRequired,
  location: PropTypes.shape({ pathname: PropTypes.string.isRequired }).isRequired,
  history: PropTypes.shape({ replace: PropTypes.func.isRequired }).isRequired,
};

export default withRouter(withLocalize(App));
