import React from 'react';
import { Link, Switch, Route, Redirect, withRouter } from 'react-router-dom';
import { Translate } from 'react-localize-redux';
import Drawer from '@material-ui/core/Drawer';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HomeIcon from '@material-ui/icons/Home';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import ExtensionIcon from '@material-ui/icons/Extension';
import Today from '@material-ui/icons/Today';
import WorkIcon from '@material-ui/icons/Work';
import PersonIcon from '@material-ui/icons/Person';
import EvStationIcon from '@material-ui/icons/EvStation';
import TrendingUpIcon from '@material-ui/icons/TrendingUp';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import FolderSharedIcon from '@material-ui/icons/FolderShared';
import CodeIcon from '@material-ui/icons/Code';
import TollIcon from '@material-ui/icons/Toll';
import UnfoldMore from '@material-ui/icons/UnfoldMore';
import MenuIcon from '@material-ui/icons/Menu';
import PictureInPictureAltIcon from '@material-ui/icons/PictureInPictureAlt';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import SettingsIcon from '@material-ui/icons/Settings';
import ThorHorizontal from '../../img/thor/thor-horizontal.png';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Collapse from '@material-ui/core/Collapse';
import Version from '../../elements/version';

import './home.scss';

import HomePage from './home';
import Vendors from './vendors';
import Clients from './clients';
import Administrators from './administrators';
import Chargeboxes from './chargeboxes';
import Transactions from './transactions';
import Reservations from './reservations';
import Reports from './reports';
import Documents from './documents';
import Rfids from './rfids';
import Customization from './customization';
import Payments from './payments';
import Settings from './settings';
import Developers from './developers';

let sidenavItems = [
  { label: 'vendors', icon: ExtensionIcon, constraint: ['superuser'], link: { external: false, to: '/vendors' } },
  { label: 'clients', icon: WorkIcon, constraint: ['superuser', 'main'], link: { external: false, to: '/clients' } },
  { label: 'administrators', icon: PersonIcon, constraint: [], link: { external: false, to: '/administrators' } },
  { label: 'chargeboxes', icon: EvStationIcon, constraint: [], link: { external: false, to: '/chargeboxes' } },
  { label: 'transactions', icon: TrendingUpIcon, constraint: [], link: { external: false, to: '/transactions' } },
  { label: 'reservations', icon: Today, constraint: [], link: { external: false, to: '/reservations' } },
  { label: 'reports', icon: DataUsageIcon, constraint: [], link: { external: false, to: '/reports' } },
  { label: 'documents', icon: FolderSharedIcon, constraint: ['hasPrivileges'], link: { external: false, to: '/documents' } },
  { label: 'rfids', icon: PictureInPictureAltIcon, constraint: [], link: { external: false, to: '/rfids' } },
  {
    label: 'advanced',
    accordion: [
      {
        label: 'customization',
        icon: FingerprintIcon,
        constraint: ['superuser', 'hasPrivileges'],
        link: { external: false, to: '/advanced/customization' },
      },
      {
        label: 'payments',
        father: 'customization',
        icon: TollIcon,
        constraint: ['superuser', 'hasPrivileges', 'canChangePayment'],
        link: { external: false, to: '/advanced/payments' },
      },
      { label: 'developers', icon: CodeIcon, constraint: [], link: { external: false, to: '/advanced/developers' } },
    ],
    icon: UnfoldMore,
    constraint: [],
    link: { external: false, to: '/' },
  },
  { label: 'settings', icon: SettingsIcon, constraint: [], link: { external: false, to: '/settings' } },
  { label: 'terms', icon: null, constraint: [], link: { external: true, to: 'https://thor.tools/privacy-policy-e-condizioni-contrattuali/' } },
];

const Header = (props) => (
  <Grid container direction='column' justifyContent='space-between' alignItems='stretch' id='header'>
    <Grid container direction='row' justifyContent='space-between' alignItems='center' id='headerMobile'>
      <IconButton onClick={props.toggleDrawer}>
        <MenuIcon htmlColor='#ffffff' />
      </IconButton>
      <img src={ThorHorizontal} alt='Logo' id='thorImage' />
    </Grid>
    <Grid container direction='row' justifyContent='space-between' alignItems='center' id='headerTop'>
      <img src={ThorHorizontal} alt='Logo' id='thorImage' />
      <Grid className='userCard'>
        <Typography variant='body1'>{props.name}</Typography>
        <Typography variant='body2'>{props.email}</Typography>
      </Grid>
    </Grid>
    <Grid container direction='row' justifyContent='center' alignItems='center' id='headerBottom'>
      <Link to='/'>
        <IconButton aria-label='home' className='iconButton'>
          <HomeIcon fontSize='large' />
        </IconButton>
      </Link>
      <IconButton aria-label='logout' className='iconButton' onClick={props.logout}>
        <ExitToAppIcon fontSize='large' />
      </IconButton>
    </Grid>
  </Grid>
);

const SidenavList = (props) => (
  <List dense>
    {sidenavItems.map((item, idx) => {
      let exclude = false;
      for (let c of item.constraint) {
        if (c === 'superuser') {
          if (props.type === 'superuser') {
            exclude = false;
            break;
          } else {
            exclude = true;
          }
        } else if (c === 'hasPrivileges') {
          if (props.type === 'client' && props.hasPrivileges) {
            exclude = false;
            break;
          } else {
            exclude = true;
          }
        } else if (c === 'canChangePayment') {
          if (props.type === 'client' && !props.hasPrivileges && props.canChangePayment) {
            exclude = false;
            break;
          } else {
            exclude = true;
          }
        } else if (c === 'main') {
          if (props.clientType === 'main') {
            exclude = false;
            break;
          } else {
            exclude = true;
          }
        }
      }
      if (exclude) {
        return null;
      }
      if (item.accordion) {
        return (
          <React.Fragment key={idx}>
            <ListItem button onClick={props.handleClickCustom} component='a'>
              {item.icon === null ? null : (
                <ListItemIcon color='red'>
                  <item.icon color='inherit' />
                </ListItemIcon>
              )}
              <ListItemText
                primary={
                  <Typography color='initial'>
                    <Translate id={`sidenav.${item.label}`} />
                  </Typography>
                }
              />
              {props.customizeMenuOpen ? <ExpandLess color='action' /> : <ExpandMore color='action' />}
            </ListItem>

            <Collapse in={props.customizeMenuOpen} timeout='auto' unmountOnExit>
              <List component='div' disablePadding>
                {item.accordion.map((acc, index) => {
                  let exclude = false;
                  for (let c of acc.constraint) {
                    if (c === 'superuser') {
                      if (props.type === 'superuser') {
                        exclude = false;
                        break;
                      } else {
                        exclude = true;
                      }
                    } else if (c === 'hasPrivileges') {
                      if (props.type === 'client' && props.hasPrivileges) {
                        exclude = false;
                        break;
                      } else {
                        exclude = true;
                      }
                    } else if (c === 'canChangePayment') {
                      if (props.type === 'client' && !props.hasPrivileges && props.canChangePayment) {
                        exclude = false;
                        break;
                      } else {
                        exclude = true;
                      }
                    }
                  }
                  if (exclude) {
                    return null;
                  }
                  return (
                    <ListItem key={index} button component={Link} to={acc.link.to} onClick={props.onClick} className='nested'>
                      <ListItemIcon color='red'>
                        <acc.icon color='inherit' />
                      </ListItemIcon>
                      <ListItemText primary={<Translate id={`sidenav.${acc.label}`} />} />
                    </ListItem>
                  );
                })}
              </List>
            </Collapse>
          </React.Fragment>
        );
      }
      if (item.link.external) {
        return (
          <ListItem key={idx} button component='a' href={item.link.to}>
            {item.icon === null ? null : <ListItemIcon>{item.icon}</ListItemIcon>}
            <ListItemText primary={<Translate id={`sidenav.${item.label}`} />} />
          </ListItem>
        );
      } else {
        return (
          <ListItem key={idx} button component={Link} to={item.link.to} onClick={props.onClick}>
            {item.icon === null ? null : (
              <ListItemIcon color='red'>
                <item.icon color={props.currentRoute === item.link.to ? 'primary' : 'inherit'} />
              </ListItemIcon>
            )}
            <ListItemText
              primary={
                <Typography color={props.currentRoute === item.link.to ? 'primary' : 'initial'}>
                  <Translate id={`sidenav.${item.label}`} />
                </Typography>
              }
            />
          </ListItem>
        );
      }
    })}
  </List>
);

const SidenavMobile = (props) => (
  <Drawer anchor='left' open={props.drawerOpen} onClose={props.toggleDrawer} id='sidenav-mobile'>
    <Grid container direction='column' justifyContent='space-between' alignItems='center' id='drawerHeader'>
      <Grid container direction='row' justifyContent='space-between' alignItems='center' spacing={2}>
        <Grid className='userCard' item xs={9}>
          <Typography variant='subtitle1'>{props.user.name}</Typography>
          <Typography variant='subtitle2'>{props.user.email}</Typography>
        </Grid>
        <Grid item xs={3}>
          <IconButton onClick={props.toggleDrawer} className='iconButton'>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction='row' justifyContent='center' alignItems='center'>
        <Link to='/'>
          <IconButton aria-label='home' className='iconButton'>
            <HomeIcon />
          </IconButton>
        </Link>
        <IconButton aria-label='logout' className='iconButton' onClick={props.logout}>
          <ExitToAppIcon />
        </IconButton>
      </Grid>
    </Grid>
    <SidenavList
      {...props.user}
      currentRoute={props.currentRoute}
      onClick={props.toggleDrawer}
      handleClickCustom={props.handleClickCustom}
      customizeMenuOpen={props.customizeMenuOpen}
    />
  </Drawer>
);

const Home = (props) => {
  let currentRoute = props.history.location.pathname;
  return (
    <Grid container direction='column' justifyContent='flex-start' alignItems='stretch' id='home'>
      <Header {...props.user} logout={props.logout} toggleDrawer={props.toggleDrawer} />
      <SidenavMobile
        drawerOpen={props.drawerOpen}
        toggleDrawer={props.toggleDrawer}
        user={props.user}
        logout={props.logout}
        currentRoute={currentRoute}
        handleClickCustom={props.handleClickCustom}
        customizeMenuOpen={props.customizeMenuOpen}
      />
      <Grid container direction='row' justifyContent='flex-start' alignItems='flex-start' wrap='wrap' id='bodyContainer'>
        <div id='sidenav'>
          <SidenavList {...props.user} currentRoute={currentRoute} handleClickCustom={props.handleClickCustom} customizeMenuOpen={props.customizeMenuOpen} />
          <Version />
        </div>
        <Grid id='body'>
          <Switch>
            <Route path='/' exact component={HomePage} />
            {props.user.type === 'superuser' || props.user.clientType === 'main'
              ? [<Route key='v' path='/vendors' exact component={Vendors} />, <Route key='c' path='/clients' exact component={Clients} />]
              : null}
            {props.user.type === 'superuser' || (props.user.type === 'client' && props.user.hasPrivileges)
              ? [
                  <Route key='d' path='/documents' exact component={Documents} />,
                  <Route key='p' path='/advanced/payments' exact component={Payments} />,
                  <Route key='c' path='/advanced/customization' exact component={Customization} />,
                ]
              : props.user.type === 'client' && props.user.canChangePayment
              ? [<Route key='p' path='/advanced/payments' exact component={Payments} />]
              : null}
            <Route path='/administrators' exact component={Administrators} />
            <Route path='/chargeboxes' exact component={Chargeboxes} />
            <Route path='/transactions' exact component={Transactions} />
            <Route path='/reservations' exact component={Reservations} />
            <Route path='/reports' exact component={Reports} />
            <Route path='/rfids' exact component={Rfids} />
            <Route path='/advanced/developers' exact component={Developers} />
            <Route path='/settings' exact component={Settings} />
            <Redirect to='/' />
          </Switch>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default withRouter(Home);
